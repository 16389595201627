import React from 'react'

const ConvertToPDF =React.forwardRef(({children}:ConvertToPDFProps,ref:any) => {
  return (
    <section ref={ref}>
        {children}
    </section>
  )
})

export default ConvertToPDF
interface ConvertToPDFProps{
    children:React.ReactNode
}

