import React from "react";



import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AddService from "./AddService";
import EditService from "./EditService";
import ServiceMain from "./ServiceMain";


const ServiceMaster = () => {
  const { path, url } = useRouteMatch();
  
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <Redirect to={`${path}/service-main`} />}
      />
      <Route exact path={`${path}/service-main`} render={() => <ServiceMain/>} />
      <Route exact path={`${path}/add-service`} render={() => <AddService />} />
      <Route path={`${path}/edit-service/:id/:readable`} render={() => <EditService />} />
    </Switch>
  );
};

export default ServiceMaster;