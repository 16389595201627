import { GetServiceOccupency } from "../../Services/Services";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { UserLogoutSuccess } from "./userAction";

export enum serviceOccupencyTypes{
    Service_occupency_getAll="[SERVICE_OCCUPENCY] Service_occupency_getAll"
}

export const GetAllServiceOccupencyAction=()=>(dispatch:any,getState:any)=>{
    dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Loading Zone Please Wait...",
        })
      );
      return GetServiceOccupency().then((resp)=>{
          if(resp.status!==200){
            dispatch(ApiCallErrorAction(resp.data));
          }else{
              dispatch({
                  type:serviceOccupencyTypes.Service_occupency_getAll,
                  payload:resp.data
              })
          }
      }).catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        }
        else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
         else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
    });
}
