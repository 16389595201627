import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useHistory } from "react-router-dom";
import { FMSList } from "../../../models/fmsModel";
import { ZoneList } from "../../../models/zoneModels";
import { StationList } from "../../../models/stationModel";
import { FMSLocationList } from "../../../models/fmsLocationModel";
import Autocompletes from "../../../components/Autocompletes";
import {
  Button,
  Card,
  CardContent,
  Box,
  CardHeader,
  Dialog,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DomainList } from "../../../models/domainModel";
const EditFMSView = ({
  SingleFMS,
  GetLocationByStationAction,
  GetStationByZoneAction,
  stationByZone,
  locationByStation,
  updateData,
  readable,
  fmstype,
  installationtype,
  zone_list,
  GetFMSLocationWiseAction,
  fmsListByLoc,
  adapterType,
}: EditFMSProps) => {
  const { control, register, handleSubmit, formState, setValue, getValues } =
    useForm();
  const { errors } = formState;
  const [showNoOfPort, setShowNoOfPort] = useState<boolean>(false);
  const [showParentFMS, setshowParentFMS] = useState<boolean>(false);
  const [flag, Setflag] = useState<boolean>(true);
  const handlePortShow = () => {
    if (
      !!getValues("fms_type") &&
      (getValues("fms_type") === "FODB" || getValues("fms_type") === "FDB")
    ) {
      setShowNoOfPort(true);
    } else setShowNoOfPort(false);
  };
  useEffect(() => {
    if (!!SingleFMS?.zone_id) GetStationByZoneAction(SingleFMS.zone_id);
    if (!!SingleFMS?.station_id)
      GetLocationByStationAction(SingleFMS.station_id);
    setShowNoOfPort(
      SingleFMS?.fms_type === "FODB" || SingleFMS?.fms_type === "FDB"
        ? true
        : false
    );
  }, [SingleFMS]);

  const [zoneSelect, setZoneSelect] = useState<boolean>(false);
  const [stationSelect, setStationSelect] = useState<boolean>(false);
  const [locationSelect, setLocationSelect] = useState<boolean>(false);
  const history = useHistory();
  const HandleZoneReq = (data: number) => {
    GetStationByZoneAction(data);
  };
  const HandleStationReq = (data: number) => {
    TeeDes(data);
    GetLocationByStationAction(data);
  };
  const TeeDes = (data: number) => {
    if (
      !!stationByZone &&
      stationByZone.find((m) => m.station_id == +data)?.station_type ==
        "Tee/Branch Joint"
    ) {
      Setflag(false);
    } else {
      Setflag(true);
    }
  };
  const onSubmit = (props: any) => {
    updateData({
      ...props,
      port_count:
        !!props.fms_type &&
        (props.fms_type == "FODB" || props.fms_type == "FDB")
          ? +props.port_count
          : null,
    });
  };
  const handleParentFMSShow = () => {
    if (getValues("fms_type") === "FDB") {
      setshowParentFMS(true);
      const selectedLocation: FMSLocationList | undefined =
        !!locationByStation &&
        locationByStation.find(
          (loc: FMSLocationList) => loc.location_id === getValues("location_id")
        );

      !!selectedLocation &&
        GetFMSLocationWiseAction({
          location_id: +selectedLocation.location_id,
        });
    } else {
      setshowParentFMS(false);
    }
  };

  useEffect(() => {
    if (!!SingleFMS) {
      setValue("zone_id", SingleFMS.zone_id);
      setValue("station_id", SingleFMS.station_id);
      setValue("location_id", SingleFMS.location_id);
      setValue("fms_name", SingleFMS.fms_name);
      setValue("fms_type", SingleFMS.fms_type);
      setValue("fms_installation_name", SingleFMS.fms_installation_name);
      setValue("fms_installation_type", SingleFMS.fms_installation_type);
      setValue("fms_code", SingleFMS.fms_code);
      setValue("port_count", SingleFMS.port_count);
      setValue("fms_cost", SingleFMS.fms_cost);
      setValue("port_type", SingleFMS.port_type);
      !!SingleFMS?.parent_fms_id &&
        setValue("parent_fms_id", SingleFMS.parent_fms_id);
      //Boolean(readable) && setValue("zone_id_readable")
      // let myzone:number=+SingleFMS.zone_id
      TeeDes(SingleFMS.station_id);
      Boolean(readable) &&
        setValue(
          "zone_id_readable",
          zone_list?.find((zne) => zne.zone_id === SingleFMS.zone_id)?.zone_name
        );
      Boolean(readable) &&
        setValue(
          "station_readable",
          stationByZone?.find(
            (station) => station.station_id === SingleFMS.station_id
          )?.station_name
        );
      Boolean(readable) &&
        setValue(
          "location_readable",
          locationByStation?.find(
            (location) => location.location_id === SingleFMS.location_id
          )?.location_name
        );
      setValue("readable_fms_code", SingleFMS.fms_type);
      Boolean(readable) &&
        setValue(
          "readable_fms_installation_type",
          SingleFMS.fms_installation_type
        );
    }
  }, [SingleFMS, readable, locationByStation, stationByZone]);

  return (
    <>
      <Card sx={{}}>
        <CardHeader
          style={{ background: "#202a37", color: "#fff", height: 14 }}
          titleTypographyProps={{ fontWeight: 500, fontSize: "1.3rem" }}
          title={Boolean(readable) ? "View FMS" : "Edit FMS"}
        />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  {!Boolean(readable) && "Select"} Zone
                </Typography>
                {!Boolean(readable) && <Box sx={{ color: "red" }}>*</Box>}
              </Grid>

              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                {Boolean(readable) ? (
                  <TextField
                    {...register("zone_id_readable", { required: true })}
                    style={{ width: "100%", margin: "5px" }}
                    type="text"
                    variant="outlined"
                    size="small"
                    name="zone_id_readable"
                    placeholder="FMS Name"
                    error={!!errors["zone_id_readable"]}
                    InputProps={{
                      readOnly: Boolean(readable),
                    }}
                  />
                ) : (
                  <Controller
                    control={control}
                    name={"zone_id"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        option={zone_list}
                        keys={"zone_id"}
                        label={"zone_name"}
                        onBlur={onBlur}
                        onChange={(val: number) => (
                          onChange(val), HandleZoneReq(val)
                        )}
                        placeholder={"Zone"}
                        value={value}
                        errors={errors["zone_id"]}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            {/* gird container  */}
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  {!Boolean(readable) && "Select"} Station
                </Typography>
                {!Boolean(readable) && <Box sx={{ color: "red" }}>*</Box>}
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                {Boolean(readable) ? (
                  <TextField
                    {...register("station_readable", { required: true })}
                    style={{ width: "100%", margin: "5px" }}
                    type="text"
                    variant="outlined"
                    size="small"
                    name="station_readable"
                    placeholder="FMS Name"
                    error={!!errors["station_readable"]}
                    InputProps={{
                      readOnly: Boolean(readable),
                    }}
                  />
                ) : (
                  <Controller
                    control={control}
                    name={"station_id"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        option={stationByZone}
                        keys={"station_id"}
                        label={"station_name"}
                        onBlur={onBlur}
                        onChange={(val: number) => (
                          onChange(val), HandleStationReq(val)
                        )}
                        placeholder={"Station"}
                        value={value}
                        errors={errors["station_id"]}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            {/* gird container  */}
            {/* gird container  */}
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  {!Boolean(readable) && "Select"} FMS Location
                </Typography>
                {!Boolean(readable) && <Box sx={{ color: "red" }}>*</Box>}
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                {Boolean(readable) ? (
                  <TextField
                    {...register("location_readable", { required: true })}
                    style={{ width: "100%", margin: "5px" }}
                    type="text"
                    variant="outlined"
                    size="small"
                    name="location_readable"
                    placeholder="FMSLocation"
                    error={!!errors["location_readable"]}
                    InputProps={{
                      readOnly: Boolean(readable),
                    }}
                  />
                ) : (
                  <Controller
                    control={control}
                    name={"location_id"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        option={locationByStation}
                        keys={"location_id"}
                        label={"location_name"}
                        onBlur={onBlur}
                        onChange={(value: number) => (
                          onChange(value), handleParentFMSShow()
                        )}
                        placeholder={"Location"}
                        value={value}
                        errors={errors["location_id"]}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            {/* gird container  */}
            {/* gird container  */}
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>FMS Name</Typography>
                {!Boolean(readable) && <Box sx={{ color: "red" }}>*</Box>}
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("fms_code", { required: true })}
                  style={{ width: "100%", margin: "5px" }}
                  type="text"
                  variant="outlined"
                  size="small"
                  name="fms_code"
                  placeholder="FMS Name"
                  error={!!errors["fms_code"]}
                  InputProps={{
                    readOnly: Boolean(readable),
                  }}
                />
              </Grid>
            </Grid>
            {/* gird container  */}
            {/* gird container  */}

            {/* gird container  */}
            {/* gird container  */}
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>FMS Type</Typography>
                <Box sx={{ color: "red" }}>*</Box>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("readable_fms_code", { required: true })}
                  style={{ width: "100%", margin: "5px" }}
                  type="text"
                  variant="outlined"
                  size="small"
                  name="fms_code"
                  placeholder="FMS Name"
                  error={!!errors["fms_code"]}
                  InputProps={{
                    disabled: true,
                  }}
                />
              </Grid>
            </Grid>
            {/* gird container  */}
            {showNoOfPort && (
              <Grid
                container
                paddingTop={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4} sx={{ display: "flex" }}>
                  <Typography style={{ fontWeight: 550 }}>
                    No. Of Port
                  </Typography>
                  {!Boolean(readable) && <Box sx={{ color: "red" }}>*</Box>}
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ fontWeight: 550 }}>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    {...register("port_count", { required: true })}
                    style={{ width: "100%", margin: "5px" }}
                    type="number"
                    variant="outlined"
                    size="small"
                    name="port_count"
                    placeholder="No Of Port"
                    error={!!errors["port_count"]}
                    InputProps={{
                      readOnly: Boolean(readable),
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>Port Type</Typography>
                {!Boolean(readable) && <Box sx={{ color: "red" }}>*</Box>}
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                {Boolean(readable) ? (
                  <TextField
                    {...register("port_type", {
                      required: true,
                    })}
                    style={{ width: "100%", margin: "5px" }}
                    type="text"
                    variant="outlined"
                    size="small"
                    name="port_type"
                    placeholder="Port Type"
                    error={!!errors["port_type"]}
                    InputProps={{
                      readOnly: Boolean(readable),
                    }}
                  />
                ) : (
                  <Controller
                    control={control}
                    name={"port_type"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        option={adapterType}
                        keys={"domain_value"}
                        label={"domain_value"}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={"Port Type"}
                        value={value}
                        errors={errors["port_type"]}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>

            {showParentFMS && (
              <Grid
                container
                paddingTop={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4} sx={{ display: "flex" }}>
                  <Typography style={{ fontWeight: 550 }}>
                    Select Parent FMS
                  </Typography>
                  <Box sx={{ color: "red" }}>*</Box>
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ fontWeight: 550 }}>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Controller
                    control={control}
                    name={"parent_fms_id"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        option={fmsListByLoc?.filter(
                          (fms: FMSList) =>
                            fms.fms_type === "GCO" || fms.fms_type === "TJB"
                        )}
                        keys={"fms_id"}
                        label={"fms_code"}
                        onBlur={onBlur}
                        onChange={(val: string) => onChange(val)}
                        placeholder={"parent FMS"}
                        value={value}
                        errors={errors["parent_fms_id"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}

            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  FMS Instatllation Type
                </Typography>
                {!Boolean(readable) && <Box sx={{ color: "red" }}>*</Box>}
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                {Boolean(readable) ? (
                  <TextField
                    {...register("readable_fms_installation_type", {
                      required: true,
                    })}
                    style={{ width: "100%", margin: "5px" }}
                    type="text"
                    variant="outlined"
                    size="small"
                    name="readable_fms_installation_type"
                    placeholder="No Of Port"
                    error={!!errors["readable_fms_installation_type"]}
                    InputProps={{
                      readOnly: Boolean(readable),
                    }}
                  />
                ) : (
                  <Controller
                    control={control}
                    name={"fms_installation_type"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        option={
                          flag
                            ? installationtype.filter(
                                (m: any) => m.domain_text != "TEE"
                              )
                            : installationtype.filter(
                                (m: any) => m.domain_text == "TEE"
                              )
                        }
                        keys={"domain_value"}
                        label={"domain_value"}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={"FMS Installation Type"}
                        value={value}
                        errors={errors["fms_installation_type"]}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            {/* gird container  */}
            {/* gird container  */}
            {flag && (
              <Grid
                container
                paddingTop={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4} sx={{ display: "flex" }}>
                  <Typography style={{ fontWeight: 550 }}>
                    FMS Installation Position
                  </Typography>
                  {/* {!Boolean(readable) && <Box sx={{ color: "red" }}>*</Box>} */}
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ fontWeight: 550 }}>:</Typography>
                </Grid>

                <Grid item xs={5}>
                  <TextField
                    {...register("fms_installation_name")}
                    style={{ width: "100%", margin: "5px" }}
                    type="text"
                    variant="outlined"
                    size="small"
                    name="fms_installation_name"
                    placeholder="FMS Installation Position"
                    error={!!errors["fms_installation_name"]}
                    InputProps={{
                      readOnly: Boolean(readable),
                    }}
                  />
                </Grid>
              </Grid>
            )}

            {/* gird container  */}
            {/* gird container  */}
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>FMS Cost</Typography>
                {/* <Box sx={{ color: "red" }}>*</Box> */}
                <Box sx={{ marginLeft: "0.2rem" }}>
                  {" "}
                  <div style={{ fontWeight: 550 }}>(₹)</div>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("fms_cost")}
                  style={{ width: "100%", margin: "5px" }}
                  type="number"
                  variant="outlined"
                  size="small"
                  name="fms_cost"
                  placeholder="FMS Cost"
                  error={!!errors["fms_cost"]}
                  InputProps={{
                    readOnly: Boolean(readable),
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  FMS Description
                </Typography>
                {/* <Box sx={{color:"red"}}>*</Box>  */}
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("fms_name", { required: false })}
                  style={{ width: "100%", margin: "5px" }}
                  type="text"
                  variant="outlined"
                  size="small"
                  multiline
                  rows={4}
                  name="fms_name"
                  placeholder="FMS Description"
                  error={!!errors["fms_name"]}
                  InputProps={{
                    readOnly: Boolean(readable),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems={"center"}>
              <Grid item>
                <Button
                  onClick={() => history.push(`/fms`)}
                  style={{
                    color: "#fff",
                    marginRight: 10,
                    background: "#C1B4B3",
                  }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Back
                </Button>
                {!Boolean(readable) && (
                  <Button
                    type="submit"
                    style={{ color: "#fff" }}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Update
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );
};

export default EditFMSView;
interface EditFMSProps {
  SingleFMS?: FMSList;
  zone_list: ZoneList[];
  GetLocationByStationAction?: any;
  GetStationByZoneAction?: any;
  locationByStation: FMSLocationList[];
  stationByZone: StationList[];
  updateData?: any;
  readable: number;
  fmstype?: any;
  installationtype?: any;
  GetFMSLocationWiseAction?: any;
  fmsListByLoc: FMSList[];
  adapterType: DomainList[];
}
