import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { AnyIfEmpty, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ColorCode,
  FibreMater,
  TubeCreates,
} from "../../../models/cableMasterModel";
import { StoreState } from "../../../models/reduxModels";
import {
  TubeCreateLoads,
  TubeCreate,
  FibreCreateLoads,
} from "../../../Stores/actions/cableMasterActions";
import AddTubeView from "./AddTubeView";

function AddTube({
  color,
  TubeCreate,
  TubeCreateLoads,
  FibreCreateLoads,
  allfibre,
}: AddTubeProps) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    TubeCreateLoads();
    FibreCreateLoads();
  }, []);

  // const submitData=(props: any)=> {
  //     TubeCreate({
  //         payload: props,
  //         history: history,

  //         enqueueSnackbar: enqueueSnackbar,
  //       });
  // }

  const submitData = (props: any) => {
    TubeCreate({
      payload: {
        tube_code: props.tube_code,
        tube_colour_code: props.tube_colour_code,
        no_of_fibres: +props.no_of_fibres,
        fibres: props.fibres,
      },
      history: history,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
    <AddTubeView color={color} submitData={submitData} allfibre={allfibre} />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    color: state.cable_master.color_code,
    allfibre: state.cable_master.fibre_master,
  };
};
const mapDispatchToProps = {
  TubeCreate,
  TubeCreateLoads,
  FibreCreateLoads,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTube);
interface AddTubeProps {
  TubeCreateLoads?: any;
  FibreCreateLoads?: any;
  TubeCreate?: any;
  color: ColorCode[];
  allfibre: FibreMater[];
}
