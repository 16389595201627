import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  TablePagination,
  Fab,
  styled,
  TableCell,
  tableCellClasses,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
  Tooltip,
  Card,
  Typography,
} from "@mui/material";
import { ReportData, ReportTFData } from "../../../models/ReportModel";

const ReportTable = ({
  tableData,
  transactionTypes,
  selectedFMSCode,
}: ReportTableProps) => {
  const TColumnFMS: any[] = [
    "FMS Code",
    "Event Type",
    "FMS Type",
    "Installation Name",
    "Installation Type",
    "Updated At",
    "Updated By",
  ];
  const styles = {
    marginRight: "50px",
  };
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<ReportTFData[]>([]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  useEffect(() => {
    let mainData: ReportTFData[] = [];
    if (transactionTypes === "FMS") {
      !!tableData &&
        tableData.map((tData: ReportData) => {
          let data = JSON.parse(tData.transaction_data);
          mainData.push({ ...tData, transaction_data: data });
        });
      setRows(mainData);
    }
  }, [tableData, transactionTypes]);

  return (
    <Paper elevation={3} sx={{ paddingTop: "1rem " }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "gray",
        }}
      >
        <Typography
          sx={{
            // margin: "0.6rem 0",
            textShadow: "0px 0px 0px #fff, 3px 3px 0px rgba(0,0,0,0.15)",
            fontWeight: 620,
          }}
          variant="h5"
        >
          TRANSACTIONS OF FMS
        </Typography>
      </Box>

      <TableContainer sx={{ marginTop: "1.4rem" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {TColumnFMS.map(column => (
                <StyledTableCell align="justify" key={column}>
                  {column}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!rows &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      <StyledTableCell align="left" key={TColumnFMS[0]}>
                        {row.transaction_data.fms_code}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={TColumnFMS[1]}>
                        {!!row.event_name ? row.event_name : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={TColumnFMS[2]}>
                        {row.transaction_data.fms_type}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={TColumnFMS[3]}>
                        {row.transaction_data.fms_installation_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={TColumnFMS[4]}>
                        {row.transaction_data.fms_installation_type}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={TColumnFMS[5]}>
                        {moment(row.updated_at).format(
                          "DD/MM/YYYY  hh:mm:ss a"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={TColumnFMS[6]}>
                        {row.user_name}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={styles}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ReportTable;
interface ReportTableProps {
  tableData: ReportData[];
  transactionTypes: string;
  selectedFMSCode: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
