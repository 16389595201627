import { Clear, Filter } from "@mui/icons-material";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  Box,
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  Grid,
  Typography,
  Paper,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  useTheme,
  TableCell,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../components/Autocompletes";
import { StationList } from "../../models/stationModel";
import { ZoneList } from "../../models/zoneModels";
import FilterListIcon from "@mui/icons-material/FilterList";
import { CableList } from "../../models/cableModel";
import { FMSLocationList } from "../../models/fmsLocationModel";
import { FMSList } from "../../models/fmsModel";
import { DamageFibre } from "../../models/ReportModel";
import colorGenerator from "../../Common/utility/colorGenerator";
function DamageFibreView({
  allstation,
  allzone,
  cable_list,
  allFMS,
  allfmslocation,
  getZoneStations,
  getStationLocation,
  GetFMSLocationWiseAction,
  showFmsWiseCableAction,
  Filters,
  damageFibre,
  clear,
}: DamageFibreProps) {
  const [expanded, setExpanded] = useState<string | false>(false);
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm();
  const { errors } = formState;
  const styles = {
    marginRight: "50px",
  };
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState<number>(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };
  const Filter = (props: any) => {
    Filters(props);
    setExpanded(false);
  };
  const stationFilter = (id: number) => {
    getZoneStations(id);
  };
  const Clear = () => {
    reset();
    clear();
  };
  return (
    <Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange1("panel1")}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              endIcon={<FilterListIcon />}
              sx={{ color: "#fff" }}
              onClick={() => handleChange1("panel1")}
            >
              Filter
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <form>
            {/* grid container */}
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                {/* grid container */}
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>Zone</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"zone_id"}
                      defaultValue={""}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          option={allzone}
                          keys={"zone_id"}
                          label={"zone_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), !!value && stationFilter(+value)
                          )}
                          placeholder={"Zone"}
                          value={value}
                          errors={errors["zone_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Station
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"station_id"}
                        defaultValue={""}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            infoEvent={() => {}}
                            option={allstation}
                            keys={"station_id"}
                            label={"station_code"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value),
                              !!value && getStationLocation(+value)
                            )}
                            placeholder={"Station"}
                            value={value}
                            errors={errors["station_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select Location
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"location_id"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={allfmslocation}
                          keys={"location_id"}
                          label={"location_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value),
                            !!value && GetFMSLocationWiseAction(+value)
                          )}
                          placeholder={"Select Location"}
                          value={value}
                          errors={errors["location_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select FMS
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"fms_id"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={allFMS}
                          keys={"fms_id"}
                          label={"fms_code"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value),
                            !!value && showFmsWiseCableAction(+value)
                          )}
                          placeholder={"Select FMS"}
                          value={value}
                          errors={errors["fms_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select Cable
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"cable_detail_id"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={cable_list}
                          keys={"cable_detail_id"}
                          label={"cable_short_code"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Select Cable"}
                          value={value}
                          errors={errors["cable_detail_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end ",
                marginTop: "1.5rem",
                marginRight: "3rem",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#808090",
                }}
                onClick={() => Clear()}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#2e7d32",
                  margin: "0 1rem",
                }}
                onClick={handleSubmit(Filter)}
              >
                Show
              </Button>
              {!!damageFibre.length && (
                <Button
                  variant="contained"
                  sx={{ color: "#fff" }}
                  onClick={() => {
                    var temp: any[] = [];
                    for (let i = 0; i < damageFibre.length; i++) {
                      temp[i] = [
                        damageFibre[i].cable_short_code,
                        `${damageFibre[i].station_name} - ${damageFibre[i].fms_code}`,

                        `${damageFibre[i].tube_colour} - ${damageFibre[i].fibre_colour} `,

                        damageFibre[i].remarks,
                        `${damageFibre[i].as_station_name} - ${damageFibre[i].as_fms_code}`,
                        `${damageFibre[i].as_tube_colour} - ${damageFibre[i].as_fibre_colour} `,
                        damageFibre[i].as_remarks,

                        `${damageFibre[i].cable_actual_length} mt`,
                      ];
                    }
                    pdfMake.vfs = pdfFonts.pdfMake.vfs;
                    var dd: any = {
                      pageOrientation: "landscape",
                      content: [
                        { text: "Damage Fibre", style: "header" },
                        {
                          style: "tableExample",
                          table: {
                            headerRows: 1,
                            body: [
                              [
                                {
                                  text: "Cable Short Code ",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Station - FMS (Source A) ",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Tube Fibre Color (Source A) ",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Remarks (Source A) ",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Station - FMS (Source B)",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Tube Fibre Color (Source B) ",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Remarks (Source B)",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Fibre km",
                                  style: "tableHeader",
                                },
                              ],

                              ...temp,
                            ],
                          },
                          layout: {
                            fillColor: function (rowIndex: any) {
                              return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                            },
                            hLineWidth: function (i: any, node: any) {
                              return i === 0 || i === node.table.body.length
                                ? 2
                                : 1;
                            },
                            vLineWidth: function (i: any, node: any) {
                              return i === 0 || i === node.table.widths.length
                                ? 2
                                : 1;
                            },
                            hLineColor: function (i: any, node: any) {
                              return i === 0 || i === node.table.body.length
                                ? "black"
                                : "gray";
                            },
                            vLineColor: function (i: any, node: any) {
                              return i === 0 || i === node.table.widths.length
                                ? "black"
                                : "gray";
                            },
                            // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            // paddingLeft: function(i, node) { return 4; },
                            // paddingRight: function(i, node) { return 4; },
                            // paddingTop: function(i, node) { return 2; },
                            // paddingBottom: function(i, node) { return 2; },
                            // fillColor: function (rowIndex, node, columnIndex) { return null; }
                          },
                        },
                      ],
                      styles: {
                        header: {
                          fontSize: 18,
                          bold: true,
                          margin: [0, 0, 0, 10],
                          color: "#9d56a2",
                        },
                        subheader: {
                          fontSize: 16,
                          bold: true,
                          margin: [0, 10, 0, 5],
                        },
                        tableExample: {
                          margin: [0, 5, 0, 15],
                          fontSize: 12,
                        },
                        tableHeader: {
                          bold: true,
                          fontSize: 12,
                          color: "white",
                          fillColor: "#035779",
                        },
                      },
                      defaultStyle: {
                        // alignment: 'justify'
                      },
                    };

                    pdfMake.createPdf(dd).open();
                  }}
                >
                  Generate PDF
                </Button>
              )}
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          margin: "1rem 0",
          paddingTop: "1rem",
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box>
            <Typography
              sx={{
                color: "gray",
                textAlign: "center",
                textShadow: "0px 0px 0px #fff, 3px 3px 0px rgba(0,0,0,0.15)",
                fontWeight: 620,
              }}
              variant="h5"
            >
              {"Damaged Fibre"}
            </Typography>
          </Box>
          <TableContainer sx={{ marginTop: "1.4rem" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Cable Short Code
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Station - FMS (Source A)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Tube Fibre Color (Source A)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Remarks (Source A)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Station - FMS (Source B)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Tube Fibre Color (Source B)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Remarks (B)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Fibre km
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!damageFibre && damageFibre.length > 0 ? (
                  damageFibre
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((tData: DamageFibre, index: number) => (
                      <StyledTableRow
                        // style={{
                        //
                        //   textAlign: "center",
                        //   backgroundColor: colorGenerator(
                        //     String(tData.cable_short_code),
                        //     120,
                        //     220
                        //   ),
                        // }}
                        // key={index}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          {tData.cable_short_code
                            ? tData.cable_short_code
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          {/* {tData.zone_name} */}
                          <br />
                          {tData.station_name} <br />
                          {/* {tData.location_name} <br /> */}
                          {tData.fms_code} <br />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          {tData.tube_colour}-{tData.fibre_colour}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          {tData.remarks}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          {tData.as_station_name} <br />
                          {tData.as_fms_code} <br />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          {tData.as_tube_colour}-{tData.as_fibre_colour}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          {tData.as_remarks}
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          {tData.cable_actual_length} mt
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                ) : (
                  <tr
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <td
                      colSpan={8}
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      No record found
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={styles}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={damageFibre.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default DamageFibreView;
interface DamageFibreProps {
  allzone: ZoneList[];
  allstation: StationList[];
  cable_list: CableList[];
  allFMS: FMSList[];
  allfmslocation: FMSLocationList[];
  getZoneStations?: any;
  getStationLocation?: any;
  GetFMSLocationWiseAction?: any;
  showFmsWiseCableAction?: any;
  Filters?: any;
  damageFibre: DamageFibre[];
  clear?: any;
}
