import React from 'react'
import {Switch,Route,Redirect,useRouteMatch} from "react-router-dom"
import ReportMainView  from './ReportMain/ReportMainView'
import ReportMain from "./ReportMain"
const Report = () => {
  const {path,url}=useRouteMatch()

  return (
    <Switch>
      <Route 
        exact
        path={`${path}`}
        render={()=><Redirect to={`${path}/report-main`}/>}
      />
      <Route exact
        path={`${path}/report-main`}
        render={()=><ReportMain/>}
      />
    </Switch>
  )
}

export default Report