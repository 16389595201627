import {
  Grid,
  Typography,
  Box,
  TextField,
  IconButton,
  Paper,
  Button,
  Fab,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { BulkUploadReport } from "../../models/ReportModel";
import { UserDetails } from "../../models/userModels";
import moment from "moment";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import UploadFileModal from "./UploadFileModal";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { width } from "@mui/system";
function BulkuploadView({
  uploaddata,
  user,
  uploadFileEvent,
  ReportDownloadEvent,
}: BulkuploadViewProps) {
  const theme = useTheme();
  const columns: any[] = ["Created At", "Created By", "Status", "Action"];
  const column1: any[] = ["Table Name", "Insert Count", "Total Count"];
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  const matchesmdTheme = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslgTheme = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = React.useState(false);
  const [dot, Setdot] = useState<string>("");
  let timmer: any;
  useEffect(() => {
    if (matchesmdTheme) {
      setIsLargeScreen(false);
    } else if (matcheslgTheme) {
      setIsLargeScreen(true);
    }
  }, [matchesmdTheme, matcheslgTheme]);
  useEffect(() => {
    if (
      !!uploaddata &&
      uploaddata.length > 0 &&
      !!uploaddata.find((m) => m.status == 1)
    ) {
      if (!timmer) {
        timmer = setTimeout(() => {
          if (dot.length >= 4) {
            Setdot(".");
          } else {
            Setdot(dot + ".");
          }
        }, 1000);
      }
    } else {
      clearInterval(timmer);
    }
    return () => {
      clearInterval(timmer);
    };
  }, [uploaddata, dot]);
  return (
    <>
      <UploadFileModal
        uploadFileEvent={uploadFileEvent}
        user={user}
        open={open}
        onClose={setOpen}
      />
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <TableContainer sx={{ maxHeight: 480 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell align="justify" key={column}>
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!uploaddata &&
                uploaddata.map((row) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.bulk_upload_detail_id}
                    >
                      <StyledTableCell align="left" key={columns[0]}>
                        {moment(row.created_at).format("DD/MM/YYYY hh:mm:ss a")}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                        {row.user_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[2]}>
                        <div
                          style={{
                            width: "70%",
                            backgroundColor:
                              row.status == 2 ? "green" : "#fcba03",
                            textAlign: "center",
                            color: "#ffff",
                          }}
                        >
                          {row?.status == 2 ? "Done" : "In-Progress" + dot}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[3]}>
                        {user?.roleType !== "R" &&
                          user?.roleType !== "FW" &&
                          row.status == 2 &&
                          (!isLargeScreen ? (
                            <Tooltip title="   Download Report" arrow>
                              <CloudDownloadIcon
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  ReportDownloadEvent(row.bulk_upload_detail_id)
                                }
                              />
                            </Tooltip>
                          ) : (
                            <>
                              <Button
                                onClick={() =>
                                  ReportDownloadEvent(row.bulk_upload_detail_id)
                                }
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<CloudDownloadIcon />}
                                style={{ color: "#fff" }}
                              >
                                Download Report
                              </Button>

                              <Tooltip
                                placement="top-start"
                                PopperProps={{
                                  sx: {
                                    "& .MuiTooltip-tooltip": {
                                      width: 500,
                                      padding: 0
                                      
                                    }
                                  }
                                }}
                                
                                title={

                                    <Grid container direction={"column"}>
                                        <Grid container direction={"row"} style={{backgroundColor: '#ac932b'}}>
                                          <Grid item xs={5.5} style={{padding: 5}}>
                                            <Typography style={{fontSize: 14, fontWeight: '600'}}>Name </Typography>
                                          </Grid>
                                          <Grid item xs={6.5} style={{padding: 5}}>
                                            <Typography style={{fontSize: 14, fontWeight: '600'}}>
                                              Insert / Total Count
                                            </Typography>
                                          </Grid>
                                    </Grid>
                                        <Grid container direction={"row"}>
                                          <Grid item xs={5.5} style={{padding: 5}}>
                                            <Typography style={{fontSize: 14}}>Station </Typography>
                                          </Grid>
                                          <Grid item xs={1}>
                                            <Typography style={{fontSize: 14}}></Typography>
                                          </Grid>
                                          <Grid item xs={5.5} style={{padding: 5}}>
                                            <Typography style={{fontSize: 14}}>
                                              {
                                                row.short_report?.station
                                                  .insert_count
                                              }{" "}
                                              /{" "}
                                              {row.short_report?.station.total}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                        <Grid container direction={"row"}>
                                          <Grid item md={5.5} style={{padding: 5}}>
                                            <Typography style={{fontSize: 14}}>
                                              FMS Location{" "}
                                            </Typography>
                                          </Grid>
                                          <Grid item md={1}>
                                            <Typography></Typography>
                                          </Grid>
                                          <Grid item md={5.5} style={{padding: 5}}>
                                            <Typography style={{fontSize: 14}}>
                                              {
                                                row.short_report?.Fms_location
                                                  .insert_count
                                              }{" "}
                                              /{" "}
                                              {
                                                row.short_report?.Fms_location
                                                  .total
                                              }
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                        <Grid container direction={"row"}>
                                          <Grid item md={5.5} style={{padding: 5}}>
                                            <Typography style={{fontSize: 14}}>FMS </Typography>
                                          </Grid>
                                          <Grid item md={1}>
                                            <Typography></Typography>
                                          </Grid>
                                          <Grid item md={5.5} style={{padding: 5}}>
                                            <Typography style={{fontSize: 14}}>
                                              {
                                                row.short_report?.fms
                                                  .insert_count
                                              }{" "}
                                              / {row.short_report?.fms.total}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                        <Grid container direction={"row"}>
                                          <Grid item md={5.5} style={{padding: 5}}>
                                            <Typography style={{fontSize: 14}}>Cable </Typography>
                                          </Grid>
                                          <Grid item md={1}>
                                            <Typography></Typography>
                                          </Grid>
                                          <Grid item md={5.5} style={{padding: 5}}>
                                            <Typography style={{fontSize: 14}}>
                                              {
                                                row.short_report?.cable
                                                  .insert_count
                                              }{" "}
                                              / {row.short_report?.cable.total}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                    </Grid>
                                }
                               
                              >
                                <IconButton color="info">
                                  <ContactSupportIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          ))}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {user?.roleType !== "R" && user?.roleType !== "FW" && (
          <Fab
            sx={fabStyle}
            aria-label={"Add"}
            color={"primary"}
            onClick={() => setOpen(true)}
            style={{ marginBottom: 15 }}
          >
            {<AddIcon style={{ color: "#ffff" }} />}
          </Fab>
        )}
      </Paper>
    </>
  );
}

export default BulkuploadView;
interface BulkuploadViewProps {
  uploaddata: BulkUploadReport[];
  user?: UserDetails;
  uploadFileEvent?: any;
  ReportDownloadEvent?: any;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: { md: 17, sm: 12, xs: 8 },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const fabStyle = {
  position: "absolute",
  bottom: 40,
  right: 16,
};
