import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { StoreState } from "../../../models/reduxModels";
import { useSnackbar } from "notistack";
import {
  AddFMSRequestAction,
  GetLocationByStationAction,
  GetStationByZoneAction,
  AddFMSAction,
  GetFMSLocationWiseAction,
} from "../../../Stores/actions/fmsAction";
import { ZoneList } from "../../../models/zoneModels";
import { StationList } from "../../../models/stationModel";
import { FMSLocationList } from "../../../models/fmsLocationModel";
import FMSAddView from "./FMSAddView";
import { DomainTypes } from "../../../Stores/actions/domainAction";
import { DomainList } from "../../../models/domainModel";
import { FMSList } from "../../../models/fmsModel";
const AddFMS = ({
  AddFMSRequestAction,
  zone_list,
  station_by_zone,
  filter,
  GetStationByZoneAction,
  GetLocationByStationAction,
  location_by_station,
  AddFMSAction,
  DomainTypes,
  fmstype,
  installationtype,
  zoneFilter,
  stationFilter,
  GetFMSLocationWiseAction,
  fmsListByLoc,
  adapterType,
}: AddFMSProps) => {
  useEffect(() => {
    DomainTypes("fms_type");
    DomainTypes("installation_type");
    DomainTypes("adapter_type");
  }, []);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    AddFMSRequestAction();
  }, []);
  const submitData = (data: any) => {
    AddFMSAction({
      payload: data,
      history: history,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  // console.log("location_filter : ",fmsListByLoc?.filter((fms:FMSList)=>fms.fms_type==="GCO"||fms.fms_type==="TJB"))
  // console.log("FMS LIST  : ",fmsListByLoc)

  return (
    <FMSAddView
      filter={filter}
      zone_list={zone_list}
      GetLocationByStationAction={GetLocationByStationAction}
      GetStationByZoneAction={GetStationByZoneAction}
      stationByZone={station_by_zone}
      locationByStation={location_by_station}
      submitData={submitData}
      fmstype={fmstype}
      installationtype={installationtype}
      zoneFilter={zoneFilter}
      stationFilter={stationFilter}
      fmsListByLoc={fmsListByLoc}
      GetFMSLocationWiseAction={GetFMSLocationWiseAction}
      adapterType={adapterType}
    />
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    zone_list: state.fms.zone_list,
    station_by_zone: state.fms.station_by_zone,
    location_by_station: state.fms.location_by_station,
    filter: state.fms.has_filter,
    fmstype: state.domain.fms_type,
    installationtype: state.domain.installation_type,
    stationFilter: state.fmslocation.has_filter,
    zoneFilter: state.station.has_filter,
    fmsListByLoc: state.fms.fms_list,
    adapterType: state.domain.adapter_type,
  };
};
const mapDispatchToProps = {
  AddFMSRequestAction,
  GetStationByZoneAction,
  GetLocationByStationAction,
  AddFMSAction,
  DomainTypes,
  GetFMSLocationWiseAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFMS);
interface AddFMSProps {
  GetFMSLocationWiseAction?: any;
  AddFMSRequestAction?: any;
  GetLocationByStationAction?: any;
  GetStationByZoneAction?: any;
  AddFMSAction?: any;
  zone_list: ZoneList[];
  station_by_zone: StationList[];
  location_by_station: FMSLocationList[];
  filter?: FMSLocationList;
  DomainTypes?: any;
  fmstype?: DomainList[];
  installationtype?: DomainList[];
  zoneFilter?: any;
  stationFilter?: any;
  fmsListByLoc: FMSList[];
  adapterType: DomainList[];
}
