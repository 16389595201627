import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  TablePagination,
  Fab,
  styled,
  TableCell,
  tableCellClasses,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
  Tooltip,
  Card,
  Typography,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  OutlinedInput,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
} from "@mui/material";
import { GetAssetData } from "../../../Stores/actions/ReportAction";
import { StoreState } from "../../../models/reduxModels";
import { AssetMainModel, ReportMainModel } from "../../../models/ReportModel";
import CableDetailsTable from "./CableDetailsTable";
import FMSDetailsTable from "./FMSDetailsTable";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../components/Autocompletes";
import FilterListIcon from "@mui/icons-material/FilterList";
import { GetAllZones } from "../../../Stores/actions/zoneActions";
import { ZoneList } from "../../../models/zoneModels";
import { StationList } from "../../../models/stationModel";
import {
  GetAllStations,
  getZoneStations,
} from "../../../Stores/actions/stationAction";
import { DomainTypes } from "../../../Stores/actions/domainAction";
import { DomainList } from "../../../models/domainModel";
const AssetMain = ({
  reportData,
  GetAssetData,
  GetAllZones,
  allzone,
  GetAllStations,
  allstation,
  DomainTypes,
  cableTypes,
  fmstype,
  getZoneStations,
}: AssetMainProps) => {
  const [expanded, setExpanded] = useState<any | false>(false);
  // const [cbb, setcabbName] = React.useState<number[]>([]);
  const assettype: any[] = [
    { type: "Cable", value: "cable" },
    { type: "FMS", value: "fms" },
    { type: "All", value: "All" },
  ];
  const [FabSatA, SetFabSatA] = useState<string | undefined>();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm();
  const { errors } = formState;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  // const handleChange = (event: SelectChangeEvent<any>) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setcabbName(value);
  // };
  useEffect(() => {
    GetAssetData({
      station_id: null,
      zone_id: null,
    });
    GetAllZones();
    GetAllStations();
    DomainTypes("cable_type");
    DomainTypes("fms_type");
  }, []);
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const Filter = (props: any) => {
    GetAssetData({
      ...props,
      fms_cable: props.fms_cable == "All" ? null : props.fms_cable,
    });
  };
  const stationFilter = (id: number) => {
    getZoneStations(id);
  };
  return (
    <Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange1("panel1")}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              endIcon={<FilterListIcon />}
              sx={{ color: "#fff" }}
              onClick={() => handleChange1("panel1")}
            >
              Filter
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <form>
            {/* grid container */}
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={5}>
                {/* grid container */}
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>Zone</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"zone_id"}
                      defaultValue={""}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          option={allzone}
                          keys={"zone_id"}
                          label={"zone_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), stationFilter(+value)
                          )}
                          placeholder={"Zone"}
                          value={value}
                          errors={errors["zone_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Station
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"station_id"}
                        defaultValue={""}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            infoEvent={() => {}}
                            option={allstation}
                            keys={"station_id"}
                            label={"station_code"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Station"}
                            value={value}
                            errors={errors["station_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Asset Type
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"fms_cable"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        // (value = value === "null" ? null : value),
                        <Autocompletes
                          hasInfo={false}
                          option={assettype}
                          keys={"value"}
                          label={"type"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            SetFabSatA(value != "All" ? value : null),
                            onChange(value),
                            setValue("cable_type", []),
                            setValue("fms_type", [])
                          )}
                          placeholder={"Select Asset Type"}
                          value={!!value ? value : ""}
                          errors={errors["fms_cable"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {(FabSatA == "cable" || FabSatA == null) && (
                <Grid item xs={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Select Cable Type
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      :
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"cable_type"}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Select
                            multiple
                            value={!!value ? value : []}
                            onChange={(value) =>
                              onChange(
                                !!value?.target?.value ? value.target.value : []
                              )
                            }
                            fullWidth
                            size="small"
                            style={{ marginLeft: "5px" }}
                            input={<OutlinedInput />}
                            renderValue={(selected) =>
                              cableTypes
                                .filter((name: any) =>
                                  selected.includes(name.domain_value)
                                )
                                .map((record: any) => record.domain_value)
                                .join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {cableTypes.map((item: any) => (
                              <MenuItem
                                key={item.domain_value}
                                value={item.domain_value}
                              >
                                <Checkbox
                                  checked={
                                    !!value &&
                                    !!value.find(
                                      (m: any) => m == item.domain_value
                                    )
                                  }
                                />
                                <ListItemText primary={item.domain_value} />
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {(FabSatA == "fms" || FabSatA == null) && (
                <Grid item xs={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Select FMS Type
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      :
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"fms_type"}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Select
                            multiple
                            style={{ marginLeft: "5px", marginTop: "5px" }}
                            value={!!value ? value : []}
                            onChange={(value) =>
                              onChange(
                                !!value?.target?.value ? value.target.value : []
                              )
                            }
                            fullWidth
                            size="small"
                            input={<OutlinedInput />}
                            renderValue={(selected) =>
                              fmstype
                                .filter((name: any) =>
                                  selected.includes(name.domain_value)
                                )
                                .map((record: any) => record.domain_value)
                                .join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {fmstype.map((item: any) => (
                              <MenuItem
                                key={item.domain_value}
                                value={item.domain_value}
                              >
                                <Checkbox
                                  checked={
                                    !!value &&
                                    !!value.find(
                                      (m: any) => m == item.domain_value
                                    )
                                  }
                                />
                                <ListItemText primary={item.domain_value} />
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end ",
                marginTop: "1.5rem",
                marginRight: "3rem",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#2e7d32",
                  margin: "0 1rem",
                }}
                // onClick={() => {
                //   handleSubmit(Filter);
                //     console.log(reportData);
                // }}

                onClick={handleSubmit(Filter)}
              >
                Show
              </Button>

              {reportData.assets != undefined &&
                (!!reportData.assets.allCabledetails.length ||
                  !!reportData.assets.allFmsdetails.length) && (
                  <Button
                    variant="contained"
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      if (reportData.assets != undefined) {
                        var temp: any[] = [];
                        var temp1: any[] = [];
                        for (
                          let i = 0;
                          i < reportData.assets.allCabledetails.length;
                          i++
                        ) {
                          temp[i] = [
                            reportData.assets.allCabledetails[i]
                              .cable_short_code,

                            reportData.assets.allCabledetails[i].cable_colour ==
                            null
                              ? `N/A`
                              : reportData.assets.allCabledetails[i]
                                  .cable_colour,
                            reportData.assets.allCabledetails[i].cable_make,
                            reportData.assets.allCabledetails[i]
                              .cable_strength == null
                              ? `N/A`
                              : `${reportData.assets.allCabledetails[i].cable_strength} F`,
                            `${reportData.assets.allCabledetails[i].cable_actual_length} mt`,
                            reportData.assets.allCabledetails[i].cable_type,
                            reportData.assets.allCabledetails[i].cost_per_unit,
                            reportData.assets.allCabledetails[i].total_cost,
                          ];
                        }

                        for (
                          let j = 0;
                          j < reportData.assets.allFmsdetails.length;
                          j++
                        ) {
                          temp1[j] = [
                            reportData.assets.allFmsdetails[j].fms_code,
                            reportData.assets.allFmsdetails[j].fms_type,
                            reportData.assets.allFmsdetails[j]
                              .fms_installation_name,
                            reportData.assets.allFmsdetails[j]
                              .fms_installation_type,
                            reportData.assets.allFmsdetails[j].station_code,
                            reportData.assets.allFmsdetails[j].location_name,
                            reportData.assets.allFmsdetails[j].fms_cost,
                          ];
                        }
                        pdfMake.vfs = pdfFonts.pdfMake.vfs;
                        var dd: any = {
                          pageOrientation: "landscape",
                          content: [
                            !!reportData?.assets?.allCabledetails &&
                            reportData?.assets?.allCabledetails.length > 0
                              ? {
                                  text: "Cable Cost Detail",
                                  style: "header",
                                }
                              : "",
                            !!reportData?.assets?.allCabledetails &&
                            reportData?.assets?.allCabledetails.length > 0
                              ? {
                                  style: "tableExample",
                                  table: {
                                    headerRows: 1,
                                    pageOrientation: "landscape",
                                    body: [
                                      [
                                        {
                                          text: "Cable Short Code ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: "Cable Color ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: "Cable Make ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: "Cable Strength ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: "Cable Length ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: "Cable Type ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: "Cost Per Unit (₹) ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: "Total Cost (₹) ",
                                          style: "tableHeader",
                                        },
                                      ],

                                      ...temp,
                                    ],
                                  },

                                  layout: {
                                    fillColor: function (rowIndex: any) {
                                      return rowIndex % 2 === 0
                                        ? "#CCCCCC"
                                        : null;
                                    },
                                    hLineWidth: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.body.length
                                        ? 2
                                        : 1;
                                    },
                                    vLineWidth: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.widths.length
                                        ? 2
                                        : 1;
                                    },
                                    hLineColor: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.body.length
                                        ? "black"
                                        : "gray";
                                    },
                                    vLineColor: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.widths.length
                                        ? "black"
                                        : "gray";
                                    },
                                    // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // paddingLeft: function(i, node) { return 4; },
                                    // paddingRight: function(i, node) { return 4; },
                                    // paddingTop: function(i, node) { return 2; },
                                    // paddingBottom: function(i, node) { return 2; },
                                    // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                  },
                                }
                              : "",
                            !!reportData?.assets?.allCabledetails &&
                            reportData?.assets?.allCabledetails.length > 0
                              ? {
                                  style: "tableExample",
                                  table: {
                                    headerRows: 1,
                                    body: [
                                      [
                                        {
                                          text: "Total Cost ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: `₹${reportData.assets.total_cable_cost}`,
                                          style: "tableHeader",
                                        },
                                      ],
                                    ],
                                  },

                                  layout: {
                                    hLineWidth: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.body.length
                                        ? 2
                                        : 1;
                                    },
                                    vLineWidth: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.widths.length
                                        ? 2
                                        : 1;
                                    },
                                    hLineColor: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.body.length
                                        ? "black"
                                        : "gray";
                                    },
                                    vLineColor: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.widths.length
                                        ? "black"
                                        : "gray";
                                    },
                                    // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // paddingLeft: function(i, node) { return 4; },
                                    // paddingRight: function(i, node) { return 4; },
                                    // paddingTop: function(i, node) { return 2; },
                                    // paddingBottom: function(i, node) { return 2; },
                                    // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                  },
                                }
                              : "",
                            !!reportData?.assets?.allCabledetails &&
                            reportData?.assets?.allFmsdetails.length > 0
                              ? { text: "FMS Cost Detail", style: "header" }
                              : "",
                            !!reportData?.assets?.allCabledetails &&
                            reportData?.assets?.allFmsdetails.length > 0
                              ? {
                                  style: "tableExample2",
                                  table: {
                                    headerRows: 1,
                                    body: [
                                      [
                                        {
                                          text: "FMS Code ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: "FMS Type ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: "FMS Installation Name ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: "FMS Installation Type ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: "Station ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: "FMS Location ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: "FMS Cost (₹) ",
                                          style: "tableHeader",
                                        },
                                      ],

                                      ...temp1,
                                    ],
                                  },

                                  layout: {
                                    fillColor: function (rowIndex: any) {
                                      return rowIndex % 2 === 0
                                        ? "#CCCCCC"
                                        : null;
                                    },
                                    hLineWidth: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.body.length
                                        ? 2
                                        : 1;
                                    },
                                    vLineWidth: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.widths.length
                                        ? 2
                                        : 1;
                                    },
                                    hLineColor: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.body.length
                                        ? "black"
                                        : "gray";
                                    },
                                    vLineColor: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.widths.length
                                        ? "black"
                                        : "gray";
                                    },
                                    // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // paddingLeft: function(i, node) { return 4; },
                                    // paddingRight: function(i, node) { return 4; },
                                    // paddingTop: function(i, node) { return 2; },
                                    // paddingBottom: function(i, node) { return 2; },
                                    // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                  },
                                }
                              : "",
                            !!reportData?.assets?.allCabledetails &&
                            reportData?.assets?.allFmsdetails.length > 0
                              ? {
                                  style: "tableExample",
                                  table: {
                                    headerRows: 1,
                                    body: [
                                      [
                                        {
                                          text: "Total Cost ",
                                          style: "tableHeader",
                                        },
                                        {
                                          text: `₹${reportData.assets.total_fms_cost}`,
                                          style: "tableHeader",
                                        },
                                      ],
                                    ],
                                  },

                                  layout: {
                                    hLineWidth: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.body.length
                                        ? 2
                                        : 1;
                                    },
                                    vLineWidth: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.widths.length
                                        ? 2
                                        : 1;
                                    },
                                    hLineColor: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.body.length
                                        ? "black"
                                        : "gray";
                                    },
                                    vLineColor: function (i: any, node: any) {
                                      return i === 0 ||
                                        i === node.table.widths.length
                                        ? "black"
                                        : "gray";
                                    },
                                    // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // paddingLeft: function(i, node) { return 4; },
                                    // paddingRight: function(i, node) { return 4; },
                                    // paddingTop: function(i, node) { return 2; },
                                    // paddingBottom: function(i, node) { return 2; },
                                    // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                  },
                                }
                              : "",
                            {
                              style: "tableExample",
                              table: {
                                headerRows: 1,
                                body: [
                                  [
                                    {
                                      text: "Gross Cost ",
                                      style: "tableHeader1",
                                    },
                                    {
                                      text: `₹${reportData.assets.gross_cost}`,
                                      style: "tableHeader1",
                                    },
                                  ],
                                ],
                              },
                            },
                          ],
                          styles: {
                            header: {
                              fontSize: 18,
                              bold: true,
                              margin: [0, 0, 0, 10],
                              color: "#9d56a2",
                            },
                            subheader: {
                              fontSize: 16,
                              bold: true,
                              margin: [0, 10, 0, 5],
                            },
                            tableExample: {
                              margin: [0, 5, 0, 15],
                              fontSize: 12,
                            },
                            tableHeader: {
                              bold: true,
                              fontSize: 12,
                              color: "white",
                              fillColor: "#035779",
                            },
                            tableHeader1: {
                              bold: true,
                              fontSize: 20,
                              color: "white",
                              fillColor: "#035779",
                              //   backgroundColor: "green",
                              margin: [100, 10, 100, 10],
                            },
                          },
                          defaultStyle: {
                            // alignment: 'justify'
                          },
                        };
                        pdfMake.createPdf(dd).open();
                      }
                    }}
                  >
                    Generate PDF
                  </Button>
                )}
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>
      {!!reportData?.assets?.allCabledetails.length && (
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            paddingTop: "1rem",
            // margin: "1rem 0",
          }}
        >
          {!!reportData?.assets && (
            <CableDetailsTable
              tabData={reportData.assets.allCabledetails}
              caption="Cable Cost Detail"
              totalCost={reportData.assets.total_cable_cost}
            />
          )}
        </Paper>
      )}
      {!!reportData.assets?.allFmsdetails.length && (
        <Paper
          elevation={3}
          sx={{
            width: "98%",
            paddingTop: "1rem",
            margin: "1rem 0",
          }}
        >
          {!!reportData?.assets && (
            <FMSDetailsTable
              tabData={reportData.assets.allFmsdetails}
              caption="FMS Cost Detail"
              totalCost={reportData.assets.total_fms_cost}
            />
          )}
        </Paper>
      )}
      {!!reportData?.assets && !!reportData.assets.gross_cost && (
        <Paper
          elevation={3}
          sx={{
            width: "98%",
            padding: "0.5rem 0",
            margin: "1rem 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Typography variant="h6">Gross Cost</Typography>
            <Typography variant="h6">:</Typography>
            <h4>₹ {reportData.assets.gross_cost}</h4>
          </Box>
        </Paper>
      )}
    </Box>
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    reportData: state.report,
    allzone: state.zone.zone_list,
    allstation: state.station.station_list,
    cableTypes: state.domain.cable_type,
    fmstype: state.domain.fms_type,
  };
};
const mapDispatchToProps = {
  GetAssetData,
  GetAllZones,
  GetAllStations,
  DomainTypes,
  getZoneStations,
};
export default connect(mapStateToProps, mapDispatchToProps)(AssetMain);
interface AssetMainProps {
  reportData: ReportMainModel;
  GetAssetData?: any;
  GetAllZones?: any;
  allzone: ZoneList[];
  GetAllStations?: any;
  allstation: StationList[];
  DomainTypes?: any;
  cableTypes?: any;
  fmstype: DomainList[];
  getZoneStations?: any;
}
