import { CableMater, ColorCode, FibreMater, TubeMater } from "../../models/cableMasterModel";
import { CableCreateService, DeleteCable, DeleteFibre, DeleteTube, EditCable, FibreCreateLoad, FibreUpdate, GetAllMasterCable, GetAllMasterFibre, GetAllMasterTube, GetCableDetail, GetTubeDetail, MasterFibreCreate, MasterTubeCreate, TubeCreateLoad, TubeUpdate } from "../../Services/Services";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
// import { GetAllTubeSuccessAction } from "./tubeMasterAction";
import { UserLogoutSuccess } from "./userAction";

export enum CableMasterActionTypes {
    Get_All_Fibre_Master_Success_Action = "[CABLE MASTER] Get All Fibre Master Success Action",
    Fibre_Create_Success_Action='[CABLE MASTER] Fibre Create Success Action',
    Fibre_Create_Load_Success_Action='[CABLE MASTER] Fibre Create Load Success Action',
    Update_Fibre_Success_Action='[CABLE MASTER] Update Fibre Success Action',
    Delete_Fibre_Success_Action='[CABLE MASTER] Delete Fibre Success Action',
    Get_All_Tube_Master_Success_Action = "[TUBE MASTER] Get All Tube Master Success Action",
    Tube_Create_Success_Action = '[CABLE MASTER] Tube Create Success Action',
    Get_All_Cable_Master_Success_Action='[CABLE MASTER] Get All Cable Master Success Action',
    Get_Cable_Detail_Success_Action='[CABLE MASTER] Get Cable Detail Succeess Action',
    Tube_Create_Load_Success_Action = '[CABLE MASTER] Tube Create Load Success Action',
    Update_Tube_Success_Action='[CABLE MASTER] Update Tube Success Action',
    Cable_Create_Success_Action='[CABLE MASTER] Cable Create Success Action',
    Delete_Tube_Success_Action='[CABLE MASTER] Delete Tube Success Action',
    Get_Tube_Detail_Success_Action='[CABLE MASTER] Get Tube Detail Succeess Action',
    Delete_Cable_Success_Action='[CABLE MASTER] Delete Cable Success Action',
  }

  export const GetAllCable = () => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Loading Fibre. Please Wait...",
        })
      );
      return GetAllMasterFibre()
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            dispatch(GetAllCableSuccessAction(response.data));
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  
  export const GetAllCableSuccessAction = (data: FibreMater[]) => {
    return { type: CableMasterActionTypes.Get_All_Fibre_Master_Success_Action, payload: data };
  };
  
  export const FibreCreate = (data: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Creating Fibre. Please Wait...",
        })
      );
      return MasterFibreCreate(data.payload)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            data.history.replace("/cable-master/fibre-main");
            await data.enqueueSnackbar("Fibre Successfully Created!", {
              variant: "success",
            });
            dispatch(FibreCreateSuccess());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  
  export const FibreCreateSuccess = () => {
    return { type: CableMasterActionTypes.Fibre_Create_Success_Action };
  };
  
  export const FibreCreateLoads = () => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Loading Color Code. Please Wait...",
        })
      );
      return FibreCreateLoad()
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            dispatch(FibreCreateLoadsSuccessAction(response.data));
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  
  export const FibreCreateLoadsSuccessAction = (data: ColorCode[]) => {
    return { type: CableMasterActionTypes.Fibre_Create_Load_Success_Action, payload: data };
  };

  export const UpdateFibre = (data: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Updating Fibre Please Wait...",
        })
      );
      return FibreUpdate(data.payload)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            data.history.replace("/cable-master/fibre-main");
            await data.enqueueSnackbar("Fibre Successfully Updated!", {
              variant: "success",
            });
            dispatch(UpdateFibreSuccess());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  
  export const UpdateFibreSuccess = () => {
    return { type: CableMasterActionTypes.Update_Fibre_Success_Action };
  };
  
  export const DeleteFibres = (data: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Deleting Fibre Please Wait...",
        })
      );
      return DeleteFibre(data.payload)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            await data.enqueueSnackbar("Deleted Successfully!", {
              variant: "success",
            });
            dispatch(DeleteFibreSuccess());
            dispatch(GetAllCable());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
          else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
        });
    };
  };
  
  export const DeleteFibreSuccess = () => {
    return { type: CableMasterActionTypes.Delete_Fibre_Success_Action };
  };

        // :: Tube Action :: //

  export const GetAllTube = () => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Loading Tube. Please Wait...",
        })
      );
      return GetAllMasterTube()
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            dispatch(GetAllTubeSuccessAction(response.data));
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  
   

  export const GetAllCableMaster = () => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Loading Cable. Please Wait...",
        })
      );
      return GetAllMasterCable()
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            dispatch(GetAllCableMsterSuccessAction(response.data));
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  export const GetAllCableMsterSuccessAction = (data: FibreMater[]) => {
    return { type: CableMasterActionTypes.Get_All_Cable_Master_Success_Action, payload: data };
  };  

  export const GetCableDetails = (data: number) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Loading Fibre. Please Wait...",
        })
      );
      return GetCableDetail(data)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            dispatch(GetCableDetailSuccessAction(response.data));
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  
  export const GetCableDetailSuccessAction = (data: CableMater) => {
    return { type: CableMasterActionTypes.Get_Cable_Detail_Success_Action, payload: data };
  };
  export const GetAllTubeSuccessAction = (data: TubeMater[]) => {
    return { type: CableMasterActionTypes.Get_All_Tube_Master_Success_Action, payload: data };
  };
  export const TubeCreate = (data: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Creating Tube. Please Wait...",
        })
      );
      return MasterTubeCreate(data.payload)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            data.history.replace("/cable-master/tube-main");
            await data.enqueueSnackbar("Tube Successfully Created!", {
              variant: "success",
            });
            dispatch(TubeCreateSuccess());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  export const TubeCreateSuccess = () => {
    return { type: CableMasterActionTypes.Tube_Create_Success_Action };
  };
  export const TubeCreateLoads = () => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Loading Tube. Please Wait...",
        })
      );
      return TubeCreateLoad()
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            dispatch(TubeCreateLoadsSuccessAction(response.data));
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  export const TubeCreateLoadsSuccessAction = (data: TubeMater[]) => {
    return { type: CableMasterActionTypes.Tube_Create_Load_Success_Action, payload: data };
  };
  export const UpdateTube = (data: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Updating Tube Please Wait...",
        })
      );
      return TubeUpdate(data.payload)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            data.history.replace("/cable-master/tube-main");
            await data.enqueueSnackbar("Fibre Successfully Updated!", {
              variant: "success",
            });
            dispatch(UpdateFibreSuccess());
            dispatch(GetAllTube());

          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  export const UpdateTubeSuccess = () => {
    return { type: CableMasterActionTypes.Update_Tube_Success_Action };
  };

  export const CableCreate = (data: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Creating Cable. Please Wait...",
        })
      );
      return CableCreateService(data.payload)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            data.history.replace("/cable-master/cable-main");
            await data.enqueueSnackbar("Cable Successfully Created!", {
              variant: "success",
            });
            dispatch(CableCreateSuccess());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  
  export const CableCreateSuccess = () => {
    return { type: CableMasterActionTypes.Cable_Create_Success_Action };
  };
  
  export const DeleteTubes = (data: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Deleting Tube Please Wait...",
        })
      );
      return DeleteTube(data.payload)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            await data.enqueueSnackbar("Deleted Successfully!", {
              variant: "success",
            });
            dispatch(DeleteFibreSuccess());
            dispatch(GetAllTube());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
          else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
        });
    };
  };
  
  export const DeleteTubeSuccess = () => {
    return { type: CableMasterActionTypes.Delete_Tube_Success_Action };
  };



  export const GetTubeDetails = (data: number) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Loading Fibre. Please Wait...",
        })
      );
      return GetTubeDetail(data)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            dispatch(GetTubeDetailSuccessAction(response.data));
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          } 
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
          else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  
  export const GetTubeDetailSuccessAction = (data: CableMater) => {
    return { type: CableMasterActionTypes.Get_Tube_Detail_Success_Action, payload: data };
  };
  export const UpdateCable = (data: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Updating Cable. Please Wait...",
        })
      );
      return EditCable(data.payload)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            data.history.replace("/cable-master/cable-main");
            await data.enqueueSnackbar("Cable Successfully Updated!", {
              variant: "success",
            });
            dispatch(UpdateCableSuccess());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  
  export const UpdateCableSuccess = () => {
    return { type: CableMasterActionTypes.Update_Fibre_Success_Action };
  };
  
  export const DeleteCables = (data: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Deleting Cable Please Wait...",
        })
      );
      return DeleteCable(data.payload)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            await data.enqueueSnackbar("Deleted Successfully!", {
              variant: "success",
            });
            dispatch(DeleteCableSuccess());
            dispatch(GetAllCableMaster());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
          else{
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
        });
    };
  };
  
  export const DeleteCableSuccess = () => {
    return { type: CableMasterActionTypes.Delete_Cable_Success_Action };
  };