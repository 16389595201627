import { ErrorModel } from "../../models/errorModels";
import { UserDetails, UserLoginModel } from "../../models/userModels";
import { ChangePassword, UserLoginService } from "../../Services/Services";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
export enum UserActionTypes {
  User_Login_Success = "[USER] User Login Success",

  User_Logout_Success = "[USER] User Logout Success",

  Change_Password_Success = "[USER] Change Password Success",
}
export const UserLogin = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Login. Please Wait.",
      })
    );
    return UserLoginService(payload.data)
      .then((response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          setTimeout(() => {
            payload.history.replace("/zone");
          }, 200);
          dispatch(UserLoginSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const UserLoginSuccess = (data: UserDetails) => {
  return { type: UserActionTypes.User_Login_Success, payload: data };
};

export const UserLogoutSuccess = () => {
  return { type: UserActionTypes.User_Logout_Success };
};

export const ChangePasswordAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Password changing. Please Wait.",
      })
    );
    return ChangePassword(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          setTimeout(() => {
            payload.history.replace("/login");
          }, 200);
          await payload.enqueueSnackbar(
            "Password successfully changed. please login again.",
            {
              variant: "success",
            }
          );
          dispatch(UserLogoutSuccess());
          dispatch(ChangePasswordSuccess());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const ChangePasswordSuccess = () => {
  return { type: UserActionTypes.Change_Password_Success };
};
