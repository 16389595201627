import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { StoreState } from "../../../models/reduxModels";
import { useSnackbar } from "notistack";
import { FMSList } from "../../../models/fmsModel";
import { ZoneList } from "../../../models/zoneModels";
import { StationList } from "../../../models/stationModel";
import { FMSLocationList } from "../../../models/fmsLocationModel";
import EditFMSView from "./EditFMSView";
import {
  GetFMSByIdAction,
  AddFMSRequestAction,
  GetStationByZoneAction,
  GetLocationByStationAction,
  FMSEditActions,
  GetFMSLocationWiseAction,
} from "../../../Stores/actions/fmsAction";
import { DomainTypes } from "../../../Stores/actions/domainAction";
import { DomainList } from "../../../models/domainModel";

const EditFMS = ({
  GetFMSByIdAction,
  AddFMSRequestAction,
  GetStationByZoneAction,
  GetLocationByStationAction,
  SingleFMS,
  zone_list,
  station_by_zone,
  location_by_station,
  FMSEditActions,
  fmstype,
  installationtype,
  DomainTypes,
  GetFMSLocationWiseAction,
  fmsListByLoc,
  adapterType,
}: EditFMSProps) => {
  let { id, readable }: any = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const submitData = (data: any) => {
    let payload: FMSList = JSON.parse(JSON.stringify(SingleFMS));
    FMSEditActions({
      payload: { ...payload, ...data },
      history: history,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  useEffect(() => {
    GetFMSByIdAction(+id);
    AddFMSRequestAction();
    DomainTypes("fms_type");
    DomainTypes("installation_type");
    DomainTypes("adapter_type");
  }, []);
  return (
    <EditFMSView
      SingleFMS={SingleFMS}
      zone_list={zone_list}
      stationByZone={station_by_zone}
      locationByStation={location_by_station}
      GetLocationByStationAction={GetLocationByStationAction}
      GetStationByZoneAction={GetStationByZoneAction}
      updateData={submitData}
      readable={+readable}
      installationtype={installationtype}
      fmstype={fmstype}
      fmsListByLoc={fmsListByLoc}
      GetFMSLocationWiseAction={GetFMSLocationWiseAction}
      adapterType={adapterType}
    />
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    SingleFMS: state.fms.single_fms,
    zone_list: state.fms.zone_list,
    station_by_zone: state.fms.station_by_zone,
    location_by_station: state.fms.location_by_station,
    fmstype: state.domain.fms_type,
    installationtype: state.domain.installation_type,
    fmsListByLoc: state.fms.fms_list,
    adapterType: state.domain.adapter_type,
  };
};
const mapDispatchToProps = {
  GetFMSByIdAction,
  AddFMSRequestAction,
  GetStationByZoneAction,
  GetLocationByStationAction,
  FMSEditActions,
  DomainTypes,
  GetFMSLocationWiseAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFMS);
interface EditFMSProps {
  GetFMSByIdAction?: any;
  AddFMSRequestAction?: any;
  GetStationByZoneAction?: any;
  GetLocationByStationAction?: any;
  SingleFMS?: FMSList;
  zone_list: ZoneList[];
  station_by_zone: StationList[];
  location_by_station: FMSLocationList[];
  FMSEditActions?: any;
  DomainTypes?: any;
  fmstype?: DomainList[];
  installationtype?: DomainList[];
  GetFMSLocationWiseAction?: any;
  fmsListByLoc: FMSList[];
  adapterType: DomainList[];
}
