import {ShowCableTree} from "../../Services/Services"
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { UserLogoutSuccess } from "./userAction";
export enum CableTreeTypes{
  Get_Cable_Tree_Success_Action="[Cable] Get_Cable_Tree_Success_Action",
}
// get Cable Tree

const GetCableTreeSuccess=(payload:any)=>{
  return {type:CableTreeTypes.Get_Cable_Tree_Success_Action,payload:payload}

}

export const GetCableTreeAction=(data:number)=>{
  return (dispatch:any,getState:any)=>{
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading Cable Tree. Please Wait...",
      })
    );
    return ShowCableTree(data)
    .then((resp)=>{
      if(resp.status!==200){
        dispatch(ApiCallErrorAction(resp.data));
      }else{
        dispatch(GetCableTreeSuccess(resp.data));
      }
    })
    .catch((error)=>{
      if(error.response.status===401){
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Please Login again to continue.",
          })
        );
        dispatch(UserLogoutSuccess());
      }
      else if (error.response.status === 500) {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: error?.response?.data?.message,
          })
        );
      }
       else {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Error encountered please try again later",
          })
        );
      }
    })

  }
}
