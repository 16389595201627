import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Image from "../../../assets/profile.png";
import { UserDetails } from "../../../models/userModels";
import EditIcon from "@mui/icons-material/Edit";
import { Controller, useForm } from "react-hook-form";
function ProfileMainView({ user, submitAc }: ReportMainViewProps) {
  const [flag, SetFlag] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [submitData, SetsubmitData] = useState<any>(undefined);
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
  } = useForm();
  const { errors } = formState;
  const submitRequest = (props: any) => {
    if (props.new_password != props.confirm_password) {
      setError("confirm_password", {
        type: "custom",
        message: "Password and confirm password missmatch",
      });
    } else {
      setOpen(true);
      SetsubmitData(props);
    }
  };
  const SumitF = () => {
    setOpen(false);
    submitAc(submitData);
  };
  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Grid
        container
        direction={"row"}
        padding={3}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid container xs={3} justifyContent={"center"}>
          <Grid>
            <img src={Image} style={{ height: "120px", width: "120px" }} />
          </Grid>
        </Grid>
        <Grid container xs={9} direction={"column"} alignItems={"start"}>
          <Grid container direction={"row"} margin={1}>
            <Grid container xs={5.5} direction={"row"}>
              <Grid
                xs={4}
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                User Name
              </Grid>
              <Grid
                xs={2}
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                :
              </Grid>
              <Grid
                xs={5}
                style={{
                  fontSize: 18,
                }}
              >
                {user?.userName}{" "}
              </Grid>
            </Grid>
            <Grid container xs={5.5} direction={"row"}>
              <Grid
                xs={4}
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                User Type
              </Grid>
              <Grid
                xs={2}
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                :
              </Grid>
              <Grid
                xs={5}
                style={{
                  fontSize: 18,
                }}
              >
                {user?.userType}
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction={"row"} margin={1}>
            <Grid container xs={5.5} direction={"row"}>
              {" "}
              <Grid
                xs={4}
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                User Role
              </Grid>
              <Grid
                xs={2}
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                :
              </Grid>
              <Grid
                xs={5}
                style={{
                  fontSize: 18,
                }}
              >
                {user?.roleType}
              </Grid>
            </Grid>
            <Grid container xs={5.5} direction={"row"}>
              <Grid
                xs={4}
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                User Email
              </Grid>
              <Grid
                xs={2}
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                :
              </Grid>
              <Grid
                xs={5}
                style={{
                  fontSize: 18,
                }}
              >
                {user?.email}
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction={"row"} margin={1}>
            <Grid container xs={5.5} direction={"row"}>
              <Grid
                xs={4}
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                User Contact
              </Grid>
              <Grid
                xs={2}
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                :
              </Grid>
              <Grid
                xs={5}
                style={{
                  fontSize: 18,
                }}
              >
                {user?.contact}
              </Grid>
            </Grid>
            <Grid container xs={5.5} direction={"row"}>
              <Grid
                xs={4}
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                Change Password
              </Grid>
              <Grid
                xs={2}
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                :
              </Grid>
              <Grid xs={5}>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  style={{ padding: 0 }}
                  onClick={() => SetFlag(!flag)}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Change Password"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change your login password ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            No
          </Button>
          <Button onClick={() => SumitF()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {flag && (
        <Grid container>
          <Grid
            container
            justifyContent={"center"}
            style={{ padding: 5, backgroundColor: "#035779", width: "100%" }}
          >
            <Typography style={{ color: "#fff" }}>Change Password</Typography>
          </Grid>
          <form
            onSubmit={handleSubmit(submitRequest)}
            style={{ width: "100%" }}
          >
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  Old Password
                </Typography>
                <Box sx={{ color: "red" }}>*</Box>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("old_password", { required: true })}
                  style={{ width: "100%", margin: "5px" }}
                  type="password"
                  variant="outlined"
                  size="small"
                  name="old_password"
                  placeholder="  Old Password"
                  error={!!errors["old_password"]}
                />
              </Grid>
            </Grid>
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  New Password
                </Typography>
                <Box sx={{ color: "red" }}>*</Box>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("new_password", { required: true })}
                  style={{ width: "100%", margin: "5px" }}
                  type="password"
                  variant="outlined"
                  size="small"
                  name="new_password"
                  placeholder="New Password"
                  error={!!errors["new_password"]}
                />
              </Grid>
            </Grid>
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  Confirm Password
                </Typography>
                <Box sx={{ color: "red" }}>*</Box>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("confirm_password", { required: true })}
                  style={{ width: "100%", margin: "5px" }}
                  type="text"
                  variant="outlined"
                  size="small"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  error={!!errors["confirm_password"]}
                />
                {errors.confirm_password && (
                  <span style={{ color: "red" }}>
                    {errors.confirm_password.message}
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems={"center"}>
              <Grid item>
                <Button
                  type="submit"
                  style={{ color: "#fff" }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Change
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
    </Paper>
  );
}

export default ProfileMainView;
interface ReportMainViewProps {
  user?: UserDetails;
  submitAc?: any;
}
