import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  Box,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { ZoneList } from "../../../models/zoneModels";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { StationList } from "../../../models/stationModel";
import { FMSLocationList } from "../../../models/fmsLocationModel";
import Autocompletes from "../../../components/Autocompletes";
import { FMSList } from "../../../models/fmsModel";
import { DomainList } from "../../../models/domainModel";

const FMSAddView = ({
  zone_list,
  GetLocationByStationAction,
  GetStationByZoneAction,
  stationByZone,
  locationByStation,
  submitData,
  filter,
  fmstype,
  installationtype,
  zoneFilter,
  stationFilter,
  fmsListByLoc,
  GetFMSLocationWiseAction,
  ClearAllFDBByFMS,
  adapterType,
}: FMSAddViewProps) => {
  const history = useHistory();
  const [showNoOfPort, setShowNoOfPort] = useState<boolean>(false);
  const [showParentFMS, setshowParentFMS] = useState<boolean>(false);
  const [flag, Setflag] = useState<boolean>(true);
  const { control, register, handleSubmit, formState, setValue, getValues } =
    useForm();
  const { errors } = formState;
  const submitRequest = (props: any) => {
    submitData({
      fms_code: props.fms_code,
      fms_name: props.fms_name,
      fms_type: props.fms_type,
      fms_installation_name: props.fms_installation_name,
      fms_installation_type: props.fms_installation_type,
      location_id: +props.location_id,
      station_id: +props.station_id,
      zone_id: +props.zone_id,
      parent_fms_id:
        !!props.fms_type && props.fms_type == "FDB"
          ? +props.parent_fms_id
          : null,
      port_count:
        !!props.fms_type &&
        (props.fms_type == "FODB" ||
          props.fms_type == "FDB" ||
          props.fms_type == "LIU")
          ? +props.port_count
          : null,
      fms_cost: +props.fms_cost,
      port_type: props.port_type,
    });
  };

  const handleParentFMSShow = () => {
    if (getValues("fms_type") === "FDB") {
      setshowParentFMS(true);
      const selectedLocation: FMSLocationList | undefined =
        !!locationByStation &&
        locationByStation.find(
          (loc: FMSLocationList) => loc.location_id === getValues("location_id")
        );

      !!selectedLocation &&
        GetFMSLocationWiseAction({
          location_id: +selectedLocation.location_id,
        });
    } else {
      setshowParentFMS(false);
    }
  };

  const handlePortShow = () => {
    if (
      (!!getValues("fms_type") &&
        (getValues("fms_type") === "FODB" ||
          getValues("fms_type") === "FDB")) ||
      getValues("fms_type") === "LIU"
    ) {
      setShowNoOfPort(true);
    } else setShowNoOfPort(false);
  };
  const HandleZoneReq = (data: number) => {
    GetStationByZoneAction(data);
  };
  const HandleStationReq = (data: number) => {
    if (
      !!stationByZone &&
      stationByZone.find((m) => m.station_id == +data)?.station_type ==
        "Tee/Branch Joint"
    ) {
      Setflag(false);
    } else {
      Setflag(true);
    }
    GetLocationByStationAction(data);
  };
  // useEffect(()=>{
  //   if(!!zoneFilter){
  //     setValue("zone_id",zoneFilter.zone_id)
  // };

  useEffect(() => {
    if (!!zoneFilter) {
      HandleZoneReq(+zoneFilter.zone_id);
      setValue("zone_id", zoneFilter.zone_id);
    }
    if (!!zoneFilter && !!stationFilter) {
      HandleStationReq(+stationFilter.station_id);
      setValue("station_id", stationFilter.station_id);
    }

    if (!!zoneFilter && !!stationFilter && !!filter)
      setValue("location_id", filter.location_id);

    if (!!filter && !zoneFilter && !stationFilter) {
      if (filter?.zone_id) {
        HandleZoneReq(+filter.zone_id);
        setValue("zone_id", filter.zone_id);
      }
      if (filter?.station_id) {
        HandleStationReq(+filter.station_id);
        setValue("station_id", filter.station_id);
      }
      setValue("location_id", filter.location_id);
      if (stationFilter.station_type == "Tee/Branch Joint") {
        Setflag(false);
      } else {
        Setflag(true);
      }
    }
  }, [zoneFilter, filter]);

  return (
    <>
      <Card sx={{}}>
        <CardHeader
          style={{ backgroundColor: "#202a37", color: "#fff", height: 14 }}
          titleTypographyProps={{ fontWeight: 500, fontSize: "1.3rem" }}
          title="Add FMS"
        />
        <CardContent>
          <form onSubmit={handleSubmit(submitRequest)}>
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>Select Zone</Typography>
                <Box sx={{ color: "red" }}>*</Box>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <Controller
                  control={control}
                  name={"zone_id"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      option={zone_list}
                      keys={"zone_id"}
                      label={"zone_name"}
                      onBlur={onBlur}
                      onChange={(val: number) => (
                        onChange(val), HandleZoneReq(val)
                      )}
                      placeholder={"Zone"}
                      value={value}
                      errors={errors["zone_id"]}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* gird container  */}
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  Select Station
                </Typography>
                <Box sx={{ color: "red" }}>*</Box>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <Controller
                  control={control}
                  name={"station_id"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      option={stationByZone}
                      keys={"station_id"}
                      label={"station_name"}
                      onBlur={onBlur}
                      onChange={(val: number) => (
                        onChange(val), HandleStationReq(val)
                      )}
                      placeholder={"Station"}
                      value={value}
                      errors={errors["station_id"]}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* gird container  */}
            {/* gird container  */}
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  Select FMS Location
                </Typography>
                <Box sx={{ color: "red" }}>*</Box>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <Controller
                  control={control}
                  name={"location_id"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      option={locationByStation}
                      keys={"location_id"}
                      label={"location_name"}
                      onBlur={onBlur}
                      onChange={(value: number) => (
                        onChange(value), handleParentFMSShow()
                      )}
                      placeholder={"Location"}
                      value={value}
                      errors={errors["location_id"]}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* gird container  */}
            {/* gird container  */}
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>FMS Name</Typography>
                <Box sx={{ color: "red" }}>*</Box>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("fms_code", { required: true })}
                  style={{ width: "100%", margin: "5px" }}
                  type="text"
                  variant="outlined"
                  size="small"
                  name="fms_code"
                  placeholder="FMS Name"
                  error={!!errors["fms_code"]}
                />
              </Grid>
            </Grid>
            {/* gird container  */}
            {/* gird container  */}

            {/* gird container  */}
            {/* gird container  */}
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  Select FMS Type
                </Typography>
                <Box sx={{ color: "red" }}>*</Box>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <Controller
                  control={control}
                  name={"fms_type"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      option={
                        flag
                          ? fmstype.filter(
                              (m: any) => m.domain_value != "TEE/BRANCH JOINT"
                            )
                          : fmstype.filter(
                              (m: any) => m.domain_value == "TEE/BRANCH JOINT"
                            )
                      }
                      keys={"domain_value"}
                      label={"domain_value"}
                      onBlur={onBlur}
                      onChange={(val: string) => (
                        onChange(val), handlePortShow(), handleParentFMSShow()
                      )}
                      placeholder={"FMS type"}
                      value={value}
                      errors={errors["fms_type"]}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* gird container  */}
            {/* gird container  */}
            {showNoOfPort && (
              <Grid
                container
                paddingTop={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4} sx={{ display: "flex" }}>
                  <Typography style={{ fontWeight: 550 }}>
                    No. Of Port
                  </Typography>
                  <Box sx={{ color: "red" }}>*</Box>
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ fontWeight: 550 }}>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    {...register("port_count", { required: true })}
                    style={{ width: "100%", margin: "5px" }}
                    type="number"
                    variant="outlined"
                    size="small"
                    name="port_count"
                    placeholder="No Of Port"
                    error={!!errors["port_count"]}
                  />
                </Grid>
              </Grid>
            )}
            {showNoOfPort && (
              <Grid
                container
                paddingTop={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4} sx={{ display: "flex" }}>
                  <Typography style={{ fontWeight: 550 }}>Port Type</Typography>
                  <Box sx={{ color: "red" }}>*</Box>
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ fontWeight: 550 }}>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Controller
                    control={control}
                    name={"port_type"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        option={adapterType}
                        keys={"domain_value"}
                        label={"domain_value"}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={"Port Type"}
                        value={value}
                        errors={errors["port_type"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}

            {/* gird container  */}
            {showParentFMS && (
              <Grid
                container
                paddingTop={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4} sx={{ display: "flex" }}>
                  <Typography style={{ fontWeight: 550 }}>
                    Select Parent FMS
                  </Typography>
                  <Box sx={{ color: "red" }}>*</Box>
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ fontWeight: 550 }}>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Controller
                    control={control}
                    name={"parent_fms_id"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        option={fmsListByLoc?.filter(
                          (fms: FMSList) =>
                            fms.fms_type === "GCO" || fms.fms_type === "TJB"
                        )}
                        keys={"fms_id"}
                        label={"fms_code"}
                        onBlur={onBlur}
                        onChange={(val: string) => onChange(val)}
                        placeholder={"parent FMS"}
                        value={value}
                        errors={errors["parent_fms_id"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}

            {/* gird container  */}
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  FMS Installation Type
                </Typography>
                <Box sx={{ color: "red" }}>*</Box>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <Controller
                  control={control}
                  name={"fms_installation_type"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      option={
                        flag
                          ? installationtype.filter(
                              (m: any) => m.domain_text != "TEE"
                            )
                          : installationtype.filter(
                              (m: any) => m.domain_text == "TEE"
                            )
                      }
                      keys={"domain_value"}
                      label={"domain_value"}
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder={"FMS Installation Type"}
                      value={value}
                      errors={errors["fms_installation_type"]}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* gird container  */}
            {flag && (
              <Grid
                container
                paddingTop={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4} sx={{ display: "flex" }}>
                  <Typography style={{ fontWeight: 550 }}>
                    FMS Installation Position
                  </Typography>
                  {/* <Box sx={{ color: "red" }}>*</Box> */}
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ fontWeight: 550 }}>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    {...register("fms_installation_name")}
                    style={{ width: "100%", margin: "5px" }}
                    type="text"
                    variant="outlined"
                    size="small"
                    name="fms_installation_name"
                    placeholder="FMS Installation Position"
                    error={!!errors["fms_installation_name"]}
                  />
                </Grid>
              </Grid>
            )}

            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>FMS Cost</Typography>
                {/* <Box sx={{ color: "red" }}>*</Box> */}
                <Box sx={{ marginLeft: "0.2rem" }}>
                  <div style={{ fontWeight: 550 }}>(₹)</div>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("fms_cost")}
                  style={{ width: "100%", margin: "5px" }}
                  type="number"
                  variant="outlined"
                  size="small"
                  name="fms_cost"
                  placeholder="FMS Cost"
                  error={!!errors["fms_cost"]}
                />
              </Grid>
            </Grid>
            {/* gird container  */}
            <Grid
              container
              paddingTop={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  FMS Description
                </Typography>
                {/* <Box sx={{color:"red"}}>*</Box>  */}
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("fms_name", { required: false })}
                  style={{ width: "100%", margin: "5px" }}
                  type="text"
                  variant="outlined"
                  size="small"
                  multiline
                  rows={4}
                  name="fms_name"
                  placeholder="FMS Description"
                  error={!!errors["fms_name"]}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems={"center"}>
              <Grid item>
                <Button
                  onClick={() => history.push(`/fms`)}
                  style={{
                    color: "#fff",
                    marginRight: 10,
                    background: "#C1B4B3",
                  }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  style={{ color: "#fff" }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );
};

export default FMSAddView;
interface FMSAddViewProps {
  zone_list: ZoneList[];
  GetLocationByStationAction?: any;
  GetStationByZoneAction?: any;
  stationByZone: StationList[];
  locationByStation: FMSLocationList[];
  submitData?: any;
  filter?: FMSLocationList;
  fmstype?: any;
  installationtype?: any;
  zoneFilter?: any;
  stationFilter?: any;
  fmsListByLoc: FMSList[];
  GetFMSLocationWiseAction?: any;
  ClearAllFDBByFMS?: any;
  adapterType: DomainList[];
}
