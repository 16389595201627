import {
  Breadcrumbs,
  Chip,
  emphasize,
  styled,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { StoreState } from "../../models/reduxModels";
import { FiltreUpdateSuccessAction } from "../../Stores/actions/stationAction";
import { updateFMSToLocation } from "../../Stores/actions/fmsAction";
import { FmsWiseCable } from "../../Stores/actions/cableAction";
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = "#00AAEE";
  return {
    backgroundColor,
    height: theme.spacing(4),
    color: "#ffff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 12,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;
const Path = (props: any) => {
  const location = useLocation();
  const history = useHistory();

 const cleanFMSAllChip=()=>{
  props.FmsWiseCable(undefined);
 }

//  const cleanFMSLocationAllChip=()=>{
//   props.updateFMSToLocation(undefined);
//   props.FmsWiseCable(undefined);
//  } 

 const cleanLocationChip=()=>{
  props.updateFMSToLocation(undefined)
  props.FmsWiseCable(undefined);

 }
  

  return (
    <>
      {!!props?.userDetail &&
      location.pathname != "/dashboard/dashboard-main" ? (
        <div style={{ margin: 5 }}>
          <Breadcrumbs aria-label="breadcrumb" separator="›">
            {!!props.zoneSet && (
              <StyledBreadcrumb
                component="a"
                onClick={() =>( history.push("/zone"),cleanLocationChip())}
                label={
                  <Typography>
                    <b>ZONE : </b>
                    {props.zoneSet.zone_name}
                  </Typography>
                }
              />
            )}
            {!!props.station && (
              <StyledBreadcrumb
                component="a"
                onClick={() => (history.push("/station"),cleanLocationChip())
                }
                label={
                  <Typography>
                    <b>STATION : </b>
                    {props.station.station_name}
                  </Typography>
                }
              />
            )}
            {!!props.fmslocation && (
              <StyledBreadcrumb
                component="a"
                onClick={() =>( history.push("/fms-location"),cleanLocationChip())}
                label={
                  <Typography>
                    <b>FMSLOCATION : </b>
                    {props.fmslocation.location_name}
                  </Typography>
                }
              />
            )}
            {!!props.fms && (
              <StyledBreadcrumb
                component="a"
                onClick={() => (history.push("/fms"),cleanFMSAllChip())}
                label={
                  <Typography>
                    <b>FMS : </b>
                    {props.fms.fms_code}
                  </Typography>
                }
              />
            )}
          </Breadcrumbs>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    userDetail: state.user.userDetails,
    zoneSet: state.station.has_filter,
    station: state.fmslocation.has_filter,
    fmslocation: state.fms.has_filter,
    fms: state.cable.has_filter,
  };
};
const mapDispatchToProps={
  FiltreUpdateSuccessAction,
  updateFMSToLocation,
  FmsWiseCable
}
export default connect(mapStateToProps,mapDispatchToProps)(Path);
