import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { FibreMater } from '../../../models/cableMasterModel';
import { StoreState } from '../../../models/reduxModels';
import { DeleteFibres, GetAllCable } from '../../../Stores/actions/cableMasterActions';
import FibreMainView from './FibreMainView'

function FibreMain({GetAllCable,allfibre,DeleteFibres}: FibreMainProps) {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(()=>{
    GetAllCable()
  },[])
  const Delete = (data: number) => {
    DeleteFibres({
      payload: data,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
 <FibreMainView allfibre={allfibre} Delete={Delete} />
  )
}

const mapStateToProps = (state: StoreState) => {
  return {
    allfibre: state.cable_master.fibre_master
  };
};

const mapDispatchToProps = {
 GetAllCable,
 DeleteFibres
};

export default connect(mapStateToProps, mapDispatchToProps)(FibreMain);
interface FibreMainProps {
  GetAllCable?: any
  allfibre: FibreMater[];
  DeleteFibres?: any;
}