import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { serviceOccupency } from "../../../models/serviceOccupencyModel";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { FMSLocationList } from "../../../models/fmsLocationModel";
import {
  DeleteButton,
  InfoButton,
  DetailButton,
} from "../../../components/Button";
import PreviewIcon from "@mui/icons-material/Preview";
import CableTreeDialog from "../../../components/CableTreeDialog";
import Doc from "../../../Common/ConvertToPDF/DocService";
import React from "react";
import ConvertToPDF from "../../../Common/ConvertToPDF";

const ServiceOccupencyMainView = ({
  serviceOccupencyList,
}: ServiceOccupencyMainViewProps) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const createPdfReff = React.createRef();
  const createPDF = (html: any) => Doc.createPdf(html);
  const theme = useTheme();
  const columns: any[] = [
    "Cable Code",
    "Cable Type",
    "Cable Color",
    "Cable Strength",
    "Fms A",
    "Fms B",
    "Station A",
    "Station B",
    "Service Occupency ( % )",
    "Actions",
  ];
  const [page, setPage] = useState(0);
  const [cableDetailsId, setCableDetailsId] = useState<number>(0);
  const [isCableTreeOpen, setCableTreeOpen] = useState<boolean>(false);
  const [rows, setRows] = useState<serviceOccupency[]>([]);
  const [searched, setSearched] = useState<string>("");
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const requestSearch = (searchVal: string) => {
    const filterRows = !!serviceOccupencyList
      ? serviceOccupencyList.filter((row: serviceOccupency) => {
          return (
            row.cable_short_code
              .toLowerCase()
              .includes(searchVal.toLowerCase()) ||
            row.cable_type.toLowerCase().includes(searchVal.toLowerCase()) ||
            row.cable_colour.toLowerCase().includes(searchVal.toLowerCase()) ||
            row.source_fms_code
              .toLowerCase()
              .includes(searchVal.toLowerCase()) ||
            row.source_station_name
              .toLowerCase()
              .includes(searchVal.toLowerCase()) ||
            row.des_source_station_name
              .toLowerCase()
              .includes(searchVal.toLowerCase())
          );
        })
      : [];
    setRows(filterRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const showCableTree = (cableId: number) => {
    setCableDetailsId(cableId);
    setCableTreeOpen(true);
  };
  useEffect(() => {
    if (!!serviceOccupencyList) cancelSearch();
  }, [serviceOccupencyList]);
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  const matchesmdTheme = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslgTheme = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    if (matchesmdTheme) {
      setIsLargeScreen(false);
    } else if (matcheslgTheme) {
      setIsLargeScreen(true);
    }
  }, [matchesmdTheme, matcheslgTheme]);
  const styles = {
    marginRight: "50px",
  };
  const GeneratePdf = () => {
    createPDF(createPdfReff);
  };
  return (
    <>
      <CableTreeDialog
        isOpen={isCableTreeOpen}
        setOpen={setCableTreeOpen}
        cable_id={cableDetailsId}
      />
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Grid
          container
          justifyContent="space-around"
          alignItems="center"
          margin={2}
        >
          <Grid item xs={2}></Grid>
          <Grid item xs={5}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => {
                requestSearch(e.target.value);
                setSearched(e.target.value);
              }}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => GeneratePdf()}
            >
              Generate Pdf
            </Button>
          </Grid>
        </Grid>
        <ConvertToPDF ref={createPdfReff}>
          <TableContainer sx={{ maxHeight: 480, marginTop: "1.4rem" }}>
            <Table stickyHeader>
              <TableHead sx={{ fontSize: "0.8rem" }}>
                <TableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      align="justify"
                      key={column}
                      sx={{ fontSize: "0.5rem important!" }}
                    >
                      {column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!rows &&
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.cable_detail_id}
                        >
                          <StyledTableCell align="left" key={columns[0]}>
                            {row.cable_short_code}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[1]}>
                            {row.cable_type}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[2]}>
                            {row.cable_colour}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[3]}>
                            {row.cable_strength}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[4]}>
                            {row.source_fms_code}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[6]}>
                            {row.des_fms_code}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[5]}>
                            {row.source_station_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[7]}>
                            {row.des_source_station_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[8]}>
                            {row.service_occupancy_persent}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[9]}>
                            <Button
                              variant="contained"
                              sx={{
                                color: "#fff",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              onClick={() => showCableTree(row.cable_detail_id)}
                            >
                              <InfoIcon sx={{ marginRight: "0.2rem" }} />
                              Cable Info
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </ConvertToPDF>
        <TablePagination
          style={styles}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={serviceOccupencyList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* <Fab
          sx={fabStyle}
          aria-label={"Add"}
          color={"primary"}
          onClick={() => history.push("/fms/add-fms")}
          style={{ marginBottom: 15 }}
        >
          {<AddIcon style={{ color: "#ffff" }} />}
        </Fab> */}
      </Paper>
    </>
  );
};

export default ServiceOccupencyMainView;
interface ServiceOccupencyMainViewProps {
  serviceOccupencyList: serviceOccupency[];
}

// const fabStyle = {
//     position: "absolute",
//     bottom: 40,
//     right: 16,
//   };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: 17,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
