import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import { useSnackbar } from "notistack";
import { FMSLocationList } from "../../../models/fmsLocationModel";
import { DeleteFMSLocations, FiltreUpdate2SuccessAction,  GetAllFMSLocations, getStationLocation } from "../../../Stores/actions/fmsLocationAction";
import FMSLocationMainView from "./FMSLocationMainView";
import {useSelector,useDispatch} from "react-redux"
import { StationList } from "../../../models/stationModel";
import {updateFMSToLocation} from "../../../Stores/actions/fmsAction"
import {useHistory} from "react-router-dom"
import { UserDetails } from "../../../models/userModels";
const FMSLocationMain = ({DeleteFMSLocations,
          GetAllFMSLocations,allfmslocation,
          station,getStationLocation,
          updateFMSToLocation,
          FiltreUpdate2SuccessAction,
          filter,
          user}:FMSLocationProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const[filters, SetFilter]= useState<number>(0)
  const history=useHistory()
  
  
  useEffect(() => {
      if(!!filter){
        SetFilter(filter.station_id)
        getStationLocation(+filter.station_id)
      }else{
        GetAllFMSLocations();
        SetFilter(0)
      }
  }, []);
  const Delete = (data: number) => {
  DeleteFMSLocations({
      payload: data,
      enqueueSnackbar: enqueueSnackbar,
    })
  };
  const FilterData   =(data: number)=> {
    if(data != null){
      const zone = station.find(m=> m.station_id == +data)
      SetFilter(data)
      FiltreUpdate2SuccessAction(zone)
      getStationLocation(+data)
    } 
  }
  const goToFMS=(data:FMSLocationList)=>{
    updateFMSToLocation(data);
    history.push("/fms/fms-main");
  }
  return (<FMSLocationMainView 
              filters={filters} 
              station={station} 
              FilterData={FilterData} 
              allfmslocation={allfmslocation} 
              Delete={Delete} 
              goToFMS={goToFMS}
              user={user}
          />);
};

const mapStateToProps = (state: StoreState) => {
  return {
     allfmslocation: state.fmslocation.fmslocation_list,
     station: state.fmslocation.station_list,
     filter: state.fmslocation.has_filter,
     user: state.user.userDetails,

  };
};
const mapDispatchToProps = {
  GetAllFMSLocations,
  DeleteFMSLocations,
  getStationLocation,
  FiltreUpdate2SuccessAction,
  updateFMSToLocation
};
export default connect(mapStateToProps, mapDispatchToProps)(FMSLocationMain);

interface FMSLocationProps {
  GetAllFMSLocations?: any;
  allfmslocation: FMSLocationList[];
  DeleteFMSLocations?: any;
  station: StationList[];
  getStationLocation?: any
  FiltreUpdate2SuccessAction?: any;
  updateFMSToLocation?:any;
  filter?: StationList;
  user?: UserDetails;
  
}
