import { connect } from "react-redux";
import React, { useEffect } from "react";
import { CableList } from "../../models/cableModel";
import { FMSLocationList } from "../../models/fmsLocationModel";
import { FMSList } from "../../models/fmsModel";
import { StoreState } from "../../models/reduxModels";
import { StationList } from "../../models/stationModel";
import { ZoneList } from "../../models/zoneModels";
import { showFmsWiseCableAction } from "../../Stores/actions/cableAction";
import { GetFMSLocationWiseAction } from "../../Stores/actions/fmsAction";
import { getStationLocation } from "../../Stores/actions/fmsLocationAction";
import {
  GetCableByService,
  GetCableByServiceClear,
} from "../../Stores/actions/ReportAction";
import {
  GetAllStations,
  getZoneStations,
} from "../../Stores/actions/stationAction";

import { CableByService, UtilizeCable } from "../../models/ReportModel";
import ServiceWiseReportView from "./ServiceWiseReportView";
import { GetAllServices } from "../../Stores/actions/serviceMasterAction";

function ServiceWiseReport({
  service_list,
  GetAllServices,
  GetCableByService,
  cableByService,
  GetCableByServiceClear,
  allstation,
  GetAllStations,
}: ServiceWiseReportProps) {
  useEffect(() => {
    GetAllServices();
    GetCableByServiceClear();
    GetAllStations();
  }, []);

  //   useEffect(() => {
  //     GetUtilzCable();
  //     GetDamageFibreLoad();
  //   }, []);
  const Filters = (props: any) => {
    GetCableByService(props);
    // console.log(props);
  };
  const clearFilter = () => {
    // GetCableByService();
    GetCableByServiceClear();
  };
  return (
    <ServiceWiseReportView
      service_list={service_list}
      allstation={allstation}
      clear={clearFilter}
      Filters={Filters}
      cableByService={cableByService}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    service_list: state.service.service_list,
    cableByService: state.report.cable_by_service,
    allstation: state.station.station_list,
  };
};
const mapDispatchToProps = {
  GetAllServices,
  GetCableByService,
  GetCableByServiceClear,
  GetAllStations,
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceWiseReport);
interface ServiceWiseReportProps {
  GetAllStations?: any;
  service_list: any[];
  GetAllServices?: any;
  GetCableByService?: any;
  allstation: StationList[];
  cableByService: CableByService[];
  GetCableByServiceClear?: any;
}
