import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { FMSList, FmsSchematic } from "../../../models/fmsModel";
import { StoreState } from "../../../models/reduxModels";
import { GetFmsSchematics } from "../../../Stores/actions/fmsAction";
import FMSSchematicView from "./FMSSchematicView";
import { BeginApiCallAction } from "../../../Stores/actions/apiStatusActions";
import { AddZoneSuccess } from "../../../Stores/actions/zoneActions";

function FMSSchematic({
  schematic,
  GetFmsSchematics,
  allFMS,
}: FMSSchematicProps) {
  let { id }: any = useParams();
  useEffect(() => {
    GetFmsSchematics(+id);
  }, []);
  return !!schematic ? (
    <FMSSchematicView
      schematic={!!schematic?.cable_list ? schematic.cable_list : []}
      schematic2={!!schematic?.fdb_list ? schematic.fdb_list : []}
      cable_count={!!schematic?.cable_count ? schematic?.cable_count : 0}
      fdb_count={!!schematic?.fdb_count ? schematic?.fdb_count : 0}
      schematic3={!!schematic?.splice_fms ? schematic.splice_fms : []}
      fms={allFMS.find((m) => m.fms_id == +id)}
      AddZoneSuccess={AddZoneSuccess}
      BeginApiCallAction={BeginApiCallAction}
    />
  ) : (
    <></>
  );
}

const mapDispatchToProps = {
  GetFmsSchematics,
  AddZoneSuccess,
  BeginApiCallAction,
};

const mapStateToProps = (state: StoreState) => {
  return {
    schematic: state.fms.FmsSchematic,
    allFMS: state.fms.fms_list,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FMSSchematic);
interface FMSSchematicProps {
  schematic?: FmsSchematic;
  GetFmsSchematics?: any;
  allFMS: FMSList[];
  BeginApiCallAction: any;
  AddZoneSuccess: any;
}
