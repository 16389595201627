import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { DomainList } from "../../../models/domainModel";
import { StoreState } from "../../../models/reduxModels";
import { ZoneList } from "../../../models/zoneModels";
import { DomainTypes } from "../../../Stores/actions/domainAction";
import { AddStations } from "../../../Stores/actions/stationAction";
import StationAddView from "./StationAddView";

function AddStation({ AddStations, allzone ,filter,DomainTypes,stationtype}: AddZoneProps) {
  useEffect(()=>{
    DomainTypes("station_type")
  },[])
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const submitData = (data: any) => {
    AddStations({
      payload: data,
      history: history,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return <StationAddView filter={filter} submitData={submitData} allzone={allzone} stationtype={stationtype}  />;
}

const mapStateToProps = (state: StoreState) => {
  return {
    allzone: state.zone.zone_list,
    filter: state.station.has_filter,
    stationtype: state.domain.station_type,
  };
};
const mapDispatchToProps = {
  AddStations,
  DomainTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStation);
interface AddZoneProps {
  allzone: ZoneList[];
  AddStations?: any;
  filter?: ZoneList;
  DomainTypes?:any;
  stationtype?: DomainList[];
}
