import React, { useEffect } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
} from "react-flow-renderer";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";

const initialNodes2: any[] = [
  {
    id: "horizontal-t-1",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: -120, y: 100 },
    style: { height: "30px", width: "15px" },
  },
  {
    id: "horizontal-t-2",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: -80, y: 100 },
    style: { height: "30px", width: "15px" },
  },
  {
    id: "horizontal-t-3",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: -40, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-4",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 0, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-5",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 40, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-6",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 80, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-7",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 120, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-7",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 160, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-8",
    sourcePosition: "bottom",
    targetPosition: "bottom",

    className: "dark-node",
    data: { label: null },
    position: { x: 200, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-9",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 240, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-10",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 280, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-11",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 320, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-12",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 360, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-13",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 400, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  //  bottom
  {
    id: "horizontal-t-14",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 440, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-15",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 480, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-16",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 520, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-17",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 560, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-18",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 600, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-19",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 640, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-20",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 680, y: 100 },
    style: { height: "30px", width: "15px" },
  },
  {
    id: "horizontal-t-21",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 720, y: 100 },
    style: { height: "30px", width: "15px" },
  },
  {
    id: "horizontal-t-22",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 760, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-23",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 800, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-24",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 840, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-25",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 880, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-26",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 920, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-27",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 960, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-28",
    sourcePosition: "bottom",
    targetPosition: "bottom",

    className: "dark-node",
    data: { label: null },
    position: { x: 1000, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-29",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 1040, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-30",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 1080, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-31",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 1120, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-32",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 1160, y: 100 },
    style: { height: "30px", width: "20px" },
  },

  {
    id: "horizontal-t-33",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 1200, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  //  bottom
  {
    id: "horizontal-t-34",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 1240, y: 100 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-t-35",
    sourcePosition: "bottom",
    targetPosition: "bottom",
    className: "dark-node",
    data: { label: null },
    position: { x: 1280, y: 100 },
    style: { height: "30px", width: "20px" },
  },

  {
    id: "horizontal-l-1",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 100 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-2",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 130 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-3",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 160 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-4",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 190 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-5",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 220 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-6",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 250 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-7",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 280 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-7",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 310 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-8",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 340 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-9",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 370 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-10",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 410 },
    style: { height: "20px", width: "30px" },
  },
  //  bottom
  {
    id: "horizontal-l-11",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 440 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-12",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 470 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-13",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 500 },
    style: { height: "20px", width: "30px" },
  },
  //  bottom
  {
    id: "horizontal-l-14",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 530 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-15",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 560 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-16",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 590 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-17",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 620 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-18",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 650 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-19",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 680 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-20",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 710 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-21",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 740 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-22",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 770 },
    style: { height: "20px", width: "30px" },
  },
  //  bottom
  {
    id: "horizontal-l-23",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 800 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-24",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 830 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-25",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 860 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-26",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 890 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-27",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 920 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-28",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 950 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-29",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 980 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-30",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 1010 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-31",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 1040 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-32",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 1070 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-33",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 1100 },
    style: { height: "20px", width: "30px" },
  },
  //  bottom
  {
    id: "horizontal-l-34",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 1130 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-35",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 1160 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-l-36",
    sourcePosition: "right",
    targetPosition: "right",
    className: "dark-node",
    data: { label: null },
    position: { x: -200, y: 1190 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-1",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 100 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-2",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 130 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-3",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 160 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-4",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 190 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-5",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 220 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-6",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 250 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-7",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 280 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-8",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 340 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-9",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 370 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-10",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 410 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-11",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 440 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-12",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 470 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-13",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 500 },
    style: { height: "20px", width: "30px" },
  },
  //  bottom
  {
    id: "horizontal-r-14",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 530 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-15",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 560 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-16",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 590 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-17",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 620 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-18",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 650 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-19",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 680 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-20",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 710 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-21",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 740 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-22",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 770 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-23",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 800 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-24",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 830 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-25",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 860 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-26",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 890 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-27",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 920 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-28",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 950 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-29",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 980 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-30",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 1010 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-31",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 1040 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-32",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 1070 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-33",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 1100 },
    style: { height: "20px", width: "30px" },
  },
  //  bottom
  {
    id: "horizontal-r-34",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 1130 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-35",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 1160 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-36",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 1190 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-37",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 1220 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-38",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 1250 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-39",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 1280 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-r-40",
    sourcePosition: "left",
    targetPosition: "left",
    className: "dark-node",
    data: { label: null },
    position: { x: 1360, y: 1310 },
    style: { height: "20px", width: "30px" },
  },
  {
    id: "horizontal-b-1",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: -120, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-2",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: -80, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-3",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: -40, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-4",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 0, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-5",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 40, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-6",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 80, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-7",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 120, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-8",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 160, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-9",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 200, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-10",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 240, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-11",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 280, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-12",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 320, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-13",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 360, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  //  bottom
  {
    id: "horizontal-b-14",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 400, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-15",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 440, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-16",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 480, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-17",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 520, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-18",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 560, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-19",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 600, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-20",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 640, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-21",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 680, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-22",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 720, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-23",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 760, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-24",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 800, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-25",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 840, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-26",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 880, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-27",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 920, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-28",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 960, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-29",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 1000, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-30",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 1040, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-31",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 1080, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-32",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 1120, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-33",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 1160, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  //  bottom
  {
    id: "horizontal-b-34",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 1200, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-35",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 1240, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-36",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: null },
    position: { x: 1280, y: 1230 },
    style: { height: "30px", width: "20px" },
  },
  {
    id: "horizontal-b-FDB-1",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: "VH-6 FDB" },
    position: { x: -130, y: 1280 },
    style: { height: "50px", width: "150px", fontSize: "1.2rem" },
  },
  {
    id: "horizontal-b-FDB-2",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: "VH-3 FDB" },
    position: { x: 30, y: 1280 },
    style: { height: "50px", width: "240px", fontSize: "1.2rem" },
  },
  {
    id: "horizontal-b-FDB-3",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: "NCGS-2 FDB" },
    position: { x: 280, y: 1280 },
    style: { height: "50px", width: "150px", fontSize: "1.2rem" },
  },
  {
    id: "horizontal-b-FDB-4",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: "PC FDB" },
    position: { x: 435, y: 1280 },
    style: { height: "50px", width: "150px", fontSize: "1.2rem" },
  },
  {
    id: "horizontal-b-FDB-5",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: "ECSS FDB" },
    position: { x: 670, y: 1280 },
    style: { height: "50px", width: "480px", fontSize: "1.2rem" },
  },
  {
    id: "horizontal-t-FDB-6",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: "ECSS FDB" },
    position: { x: -140, y: 46 },
    style: { height: "40px", width: "480px", fontSize: "1.2rem" },
  },
  {
    id: "horizontal-t-FDB-7",
    sourcePosition: "top",
    targetPosition: "top",
    className: "dark-node",
    data: { label: "MH-1 FDB" },
    position: { x: 350, y: 46 },
    style: { height: "40px", width: "80px", fontSize: "1rem" },
  },
];

const initialEdges2: any[] = [
  {
    id: "link-t1-l1",
    source: "horizontal-t-1",
    target: "horizontal-l-1",
    style: { stroke: "blue" },
    label: "4FN-4BL-1",
  },
  {
    id: "link-t2-l2",
    source: "horizontal-t-2",
    target: "horizontal-l-2",
    style: { stroke: "#33E6FF" },
    label: "4FN-4BLN-2",
  },
  {
    id: "link-t3-l3",
    source: "horizontal-t-3",
    type: "smoothstep",
    target: "horizontal-l-3",
    style: { stroke: "#641E16" },
    label: "5FN-BR-3",
  },
  {
    id: "link-t5-l5",
    source: "horizontal-t-5",
    type: "smoothstep",
    target: "horizontal-l-5",
    style: { stroke: "red" },
    label: "5FN-RD-5",
  },
  {
    id: "link-t6-l6",
    source: "horizontal-t-6",
    type: "smoothstep",
    target: "horizontal-l-6",
    style: { stroke: "#33E6FF" },
    label: "5FN-RDN-6",
  },
  {
    id: "link-t7-l13",
    source: "horizontal-t-7",
    type: "smoothstep",
    target: "horizontal-l-13",
    style: { stroke: "#F39C12" },
    label: "6F-OR-7",
  },
  {
    id: "link-t8-l7",
    source: "horizontal-t-8",
    type: "smoothstep",
    target: "horizontal-l-7",
    style: { stroke: "#33E6FF" },
    label: "6F-ORN-8",
  },
  {
    id: "link-t9-l7",
    source: "horizontal-t-9",
    type: "smoothstep",
    target: "horizontal-l-15",
    style: { stroke: "#FAD908" },
    label: "6F-YL-9",
  },
  {
    id: "link-t10-l16",
    source: "horizontal-t-10",
    type: "smoothstep",
    target: "horizontal-l-16",
    style: { stroke: "#33E6FF" },
    label: "6F-YLN-10",
  },
  {
    id: "link-t11-l17",
    source: "horizontal-t-11",
    type: "smoothstep",
    target: "horizontal-l-17",
    style: { stroke: "green" },
    label: "7F-GR-11",
  },
  {
    id: "link-t11-l18",
    source: "horizontal-t-12",
    type: "smoothstep",
    target: "horizontal-l-18",
    style: { stroke: "#33E6FF" },
    label: "7F-GRN-12",
  },
  {
    id: "link-t13-l19",
    source: "horizontal-t-13",
    type: "smoothstep",
    target: "horizontal-l-19",
    style: { stroke: "#33E6FF" },
    label: "22B-YLN-6",
  },
  {
    id: "link-t13-l19",
    source: "horizontal-t-14",
    type: "smoothstep",
    target: "horizontal-l-20",
    style: { stroke: "#FAD908" },
    label: "22B-YL-5",
  },
  {
    id: "link-t14-l19",
    source: "horizontal-t-14",
    type: "smoothstep",
    target: "horizontal-l-20",
    style: { stroke: "#FAD908" },
    label: "22B-YL-5",
  },
  {
    id: "link-t15-l21",
    source: "horizontal-t-15",
    type: "smoothstep",
    target: "horizontal-l-21",
    style: { stroke: "#33E6FF" },
    label: "22B-ORN-4",
  },
  {
    id: "link-t16-l22",
    source: "horizontal-t-16",
    type: "smoothstep",
    target: "horizontal-l-22",
    style: { stroke: "#F39C12" },
    label: "22B-OR-3",
  },
  {
    id: "link-t16-l22",
    source: "horizontal-t-16",
    type: "smoothstep",
    target: "horizontal-l-22",
    style: { stroke: "#F39C12" },
    label: "22B-OR-3",
  },
  {
    id: "link-t16-l22",
    source: "horizontal-t-16",
    type: "smoothstep",
    target: "horizontal-l-22",
    style: { stroke: "#F39C12" },
    label: "22B-OR-3",
  },
  {
    id: "link-t17-l23",
    source: "horizontal-t-17",
    type: "smoothstep",
    target: "horizontal-l-23",
    style: { stroke: "#33E6FF" },
    label: "21B-GRN-2",
  },
  {
    id: "link-t18-l24",
    source: "horizontal-t-18",
    type: "smoothstep",
    target: "horizontal-l-24",
    style: { stroke: "green" },
    label: "21B-GR-1",
  },

  {
    id: "link-t19-l31",
    source: "horizontal-t-19",
    type: "smoothstep",
    target: "horizontal-l-31",
    style: { stroke: "#33E6FF" },
    label: "4B-BRN-4",
  },
  {
    id: "link-t20-l32",
    source: "horizontal-t-20",
    type: "smoothstep",
    target: "horizontal-l-32",
    style: { stroke: "#641E16" },
    label: "4B-BR-3",
  },
  {
    id: "link-t21-l7",
    source: "horizontal-t-21",
    type: "smoothstep",
    target: "horizontal-l-8",
    style: { stroke: "blue" },
    label: "3F-1BL-1",
  },
  {
    id: "link-t22-l8",
    source: "horizontal-t-22",
    type: "smoothstep",
    target: "horizontal-l-9",
    style: { stroke: "#33e6ff" },
    label: "3F-1BLN-2",
  },
  {
    id: "link-t23-r30",
    source: "horizontal-t-23",
    type: "smoothstep",
    target: "horizontal-r-30",
    style: { stroke: "#33e6ff" },
    label: "17B-GR-BL-1",
  },
  {
    id: "link-t24-r29",
    source: "horizontal-t-24",
    type: "smoothstep",
    target: "horizontal-r-29",
    style: { stroke: "green" },
    label: "6B-GR-GR-2",
  },
  {
    id: "link-t25-r27",
    source: "horizontal-t-25",
    type: "smoothstep",
    target: "horizontal-r-27",
    style: { stroke: "blue" },
    label: "6B-GR-NAT-3",
  },
  {
    id: "link-t26-r27",
    source: "horizontal-t-26",
    type: "smoothstep",
    target: "horizontal-r-27",
    style: { stroke: "blue" },
    label: "6B-GR-NAT-3",
  },
  {
    id: "link-t27-r21",
    source: "horizontal-t-27",
    type: "smoothstep",
    target: "horizontal-r-21",
    style: { stroke: "blue" },
    label: "6B-OR-BL-1",
  },
  {
    id: "link-t27-r21",
    source: "horizontal-t-27",
    type: "smoothstep",
    target: "horizontal-r-21",
    style: { stroke: "blue" },
    label: "6B-OR-BL-1",
  },
  {
    id: "link-t28-r22",
    source: "horizontal-t-28",
    type: "smoothstep",
    target: "horizontal-r-22",
    style: { stroke: "#D35400" },
    label: "6B-OR-OR-2",
  },
  {
    id: "link-t29-r23",
    source: "horizontal-t-29",
    type: "smoothstep",
    target: "horizontal-r-23",
    style: { stroke: "GREEN" },
    label: "7B-OR-GR-3",
  },
  {
    id: "link-t30-r24",
    source: "horizontal-t-30",
    type: "smoothstep",
    target: "horizontal-r-24",
    style: { stroke: "#33e6ff" },
    label: "7B-OR-NAT-4",
  },
  {
    id: "link-t35-r1",
    source: "horizontal-t-35",
    type: "smoothstep",
    target: "horizontal-r-1",
    style: { stroke: "#33e6ff" },
    label: "2F-OR-RDN-6",
  },
  {
    id: "link-t34-r2",
    source: "horizontal-t-34",
    type: "smoothstep",
    target: "horizontal-r-2",
    style: { stroke: "RED" },
    label: "2F-OR-RD-5",
  },
  {
    id: "link-t33-r3",
    source: "horizontal-t-33",
    type: "smoothstep",
    target: "horizontal-r-3",
    style: { stroke: "#33e6ff" },
    label: "1F-OR-BRN-4",
  },
  {
    id: "link-t32-r4",
    source: "horizontal-t-32",
    type: "smoothstep",
    target: "horizontal-r-4",
    style: { stroke: "brown" },
    label: "1F-OR-BR-3",
  },
  {
    id: "link-t31-r5",
    source: "horizontal-t-31",
    type: "smoothstep",
    target: "horizontal-r-5",
    style: { stroke: "blue" },
    label: "1F-OR-BL-1",
  },
  {
    id: "link-b3-l36",
    source: "horizontal-l-36",
    type: "smoothstep",
    target: "horizontal-b-3",
    style: { stroke: "blue" },
    label: "3BL-4BL-3",
  },
  {
    id: "link-b2-l33",
    source: "horizontal-l-33",
    type: "smoothstep",
    target: "horizontal-b-2",
    style: { stroke: "#33e6ff" },
    label: "3B-4BL-3",
  },
  {
    id: "link-b4-l35",
    source: "horizontal-l-35",
    type: "smoothstep",
    target: "horizontal-b-4",
    style: { stroke: "#33e6ff" },
    label: "3B-4BLN-1",
  },
  {
    id: "link-b1-l34",
    source: "horizontal-l-34",
    type: "smoothstep",
    target: "horizontal-b-1",
    style: { stroke: "BLUE" },
    label: "3B-3BL-1",
  },
  {
    id: "link-b5-l30",
    source: "horizontal-l-30",
    type: "smoothstep",
    target: "horizontal-b-5",
    style: { stroke: "BLUE" },
    label: "1B-1BL-1",
  },
  {
    id: "link-b6-l29",
    source: "horizontal-l-29",
    type: "smoothstep",
    target: "horizontal-b-6",
    style: { stroke: "#33E6FF" },
    label: "1B-1BLN-2",
  },
  {
    id: "link-b10-l25",
    source: "horizontal-l-25",
    type: "smoothstep",
    target: "horizontal-b-10",
    style: { stroke: "#33E6FF" },
    label: "21B-RDN-6",
  },
  {
    id: "link-b9-l26",
    source: "horizontal-l-26",
    type: "smoothstep",
    target: "horizontal-b-9",
    style: { stroke: "RED" },
    label: "21B-RD-5",
  },
  {
    id: "link-b8-l27",
    source: "horizontal-l-27",
    type: "smoothstep",
    target: "horizontal-b-8",
    style: { stroke: "#33E6FF" },
    label: "4B-2BLN-4",
  },
  {
    id: "link-b7-l28",
    source: "horizontal-l-28",
    type: "smoothstep",
    target: "horizontal-b-7",
    style: { stroke: "BLUE" },
    label: "4B-2BL-3",
  },
  {
    id: "link-b11-r13",
    source: "horizontal-r-13",
    type: "smoothstep",
    target: "horizontal-b-11",
    style: { stroke: "BLUE" },
    label: "13-BL-BL-1",
  },
  {
    id: "link-b12-r14",
    source: "horizontal-r-14",
    type: "smoothstep",
    target: "horizontal-b-12",
    style: { stroke: "orange" },
    label: "13-BL-OR-2",
  },
  {
    id: "link-b13-r15",
    source: "horizontal-r-15",
    type: "smoothstep",
    target: "horizontal-b-13",
    style: { stroke: "GREEN" },
    label: "13-BL-GR-3",
  },
  {
    id: "link-b14-r16",
    source: "horizontal-r-16",
    type: "smoothstep",
    target: "horizontal-b-14",
    style: { stroke: "#33e6ff" },
    label: "13-BL-NAT-4",
  },
  {
    id: "link-b15-r17",
    source: "horizontal-r-17",
    type: "smoothstep",
    target: "horizontal-b-15",
    style: { stroke: "BLUE" },
    label: "15-BR-BL-5",
  },
  {
    id: "link-b16-r18",
    source: "horizontal-r-18",
    type: "smoothstep",
    target: "horizontal-b-16",
    style: { stroke: "ORANGE" },
    label: "15-BR-OR-6",
  },
  {
    id: "link-b17-r19",
    source: "horizontal-r-19",
    type: "smoothstep",
    target: "horizontal-b-17",
    style: { stroke: "GREEN" },
    label: "16-BR-GR-7",
  },
  {
    id: "link-b18-r20",
    source: "horizontal-r-20",
    type: "smoothstep",
    target: "horizontal-b-18",
    style: { stroke: "#33E6FF" },
    label: "16-BR-NAT-8",
  },
  {
    id: "link-b19-r25",
    source: "horizontal-r-25",
    type: "smoothstep",
    target: "horizontal-b-19",
    style: { stroke: "BLUE" },
    label: "18B-NAT-BL-1",
  },
  {
    id: "link-b20-r26",
    source: "horizontal-r-26",
    type: "smoothstep",
    target: "horizontal-b-20",
    style: { stroke: "#33E6FF" },
    label: "18B-NAT-NAT-2",
  },
];

const buttonStyle = { position: "absolute", right: 10, top: 30, zIndex: 4 };

const SchematicMain = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState<any[]>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<any[]>([]);

  useEffect(() => {
    if (!!initialNodes2) {
      setNodes(initialNodes2);
    }
    if (!!initialEdges2) {
      setEdges(initialEdges2);
    }
  }, []);

  return (
    <Grid container height={"100%"} width={"100%"}>
      <Grid item height={"100%"} width={"100%"}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          fitView
          maxZoom={2}
          attributionPosition="bottom-center"
          style={{
            justifyContent: "center",
            alignItems: "bottom",
            margin: "0 auto",
            zIndex: "999",
          }}
          zoomOnScroll={true}
          defaultZoom={0.5}
          snapToGrid={true}
        ></ReactFlow>
      </Grid>
    </Grid>
  );
};

export default SchematicMain;
interface Style {
  stroke: string;
}
interface initEdges {
  id: string;
  source: string;
  target: string;
  style: Style;
  label: string;
}
interface Data {
  label?: any;
}
interface Position {
  x: number;
  y: number;
}
interface Style2 {
  height: string;
  width: string;
}
interface initNodes {
  id: string;
  sourcePosition: string;
  targetPosition: string;
  className: string;
  data: Data;
  position: Position;
  style: Style2;
}
