import {
  Paper,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  TableCell,
  tableCellClasses,
  styled,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  ReportStData,
  ReportData,
  ReportSerData,
} from "../../../models/ReportModel";

function ServiceTable({ tableData, transactionTypes }: ServiceTableProps) {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<ReportSerData[]>([]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };
  const TColumnFMS: any[] = [
    "Service Name",
    "Service Description",
    "Event Type",
    "Updated At",
    "Updated By",
  ];
  const styles = {
    marginRight: "50px",
  };
  useEffect(() => {
    let mainData: ReportSerData[] = [];
    if (transactionTypes === "Service") {
      !!tableData &&
        tableData.map((tData: ReportData) => {
          let data = JSON.parse(tData.transaction_data);
          mainData.push({ ...tData, transaction_data: data });
        });
      setRows(mainData);
    }
  }, [tableData, transactionTypes]);
  return (
    <Paper elevation={3} sx={{ paddingTop: "1rem " }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "gray",
        }}
      >
        <Typography
          sx={{
            textShadow: "0px 0px 0px #fff, 3px 3px 0px rgba(0,0,0,0.15)",
            fontWeight: 620,
          }}
          variant="h5"
        >
          TRANSACTIONS OF SERVICE
        </Typography>
      </Box>

      <TableContainer sx={{ marginTop: "1.4rem" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {TColumnFMS.map(column => (
                <StyledTableCell align="justify" key={column}>
                  {column}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!rows &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      <StyledTableCell align="left" key={TColumnFMS[0]}>
                        {row.transaction_data.service_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={TColumnFMS[1]}>
                        {row.transaction_data.service_desc}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={TColumnFMS[5]}>
                        {row.event_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={TColumnFMS[6]}>
                        {moment(row.updated_at).format(
                          "DD/MM/YYYY  hh:mm:ss a"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={TColumnFMS[7]}>
                        {row.user_name}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={styles}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default ServiceTable;

interface ServiceTableProps {
  tableData: ReportData[];
  transactionTypes: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
