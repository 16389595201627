import { ErrorModel } from "../../models/errorModels";
import { FMSList } from "../../models/fmsModel";
import { FMSLocationList } from "../../models/fmsLocationModel";
import {
  GetAllFMS,
  FMSAddService,
  DeleteFMS,
  GetAllStation,
  GetFMSById,
  GetAllFMSLocation,
  FMSEditService,
  GetAllZone,
  GetStationByZoneId,
  GetLocationByStationId,
  GetFMSByLocation,
  getAllFDBByFMS,
  getJointInfo,
  GetFmsSchematic,
} from "../../Services/Services";
import FMS from "../../Views/FMS";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { UserLogoutSuccess } from "./userAction";
export enum FMSActionTypes {
  FMS_GetAll_Success = "[FMS] FMS_GetAll_Success",
  FMS_Add_Success_Action = "[FMS] FMS_Add_Success_Action",
  FMS_Add_Request_Action = "[FMS] FMS_Add_Request_Action",
  Get_Station_By_Zone_Action = "[FMS] Get_Station_By_Zone_Action",
  Get_Location_By_Station_Action = "[FMS] Get_Location_By_Station_Action",
  Get_FMS_By_Id_Success_Action = "[FMS] Get_FMS_By_Id_Success_Action",
  Update_FMS_Success_Action = "[FMS] Update_FMSLocation_Success_Action",
  Get_FMS_By_Id_Action = "[FMS] Get_FMS_By_Id",
  FMS_Delete_Success_Action = "[FMS] FMS_Delete_Success_Action",
  SHOW_FMS_LOCATION_WISE = "[FMS] SHOW_FMS_LOCATION_WISE",
  GET_FILTERED_DATA_FROM_LOCATION = "[FMS] GET_FILTERED_DATA_FROM_LOCATION",
  Get_Only_FMS_Action_Success = "[FMS] Get_Only_FMS_Action_Success",
  Get_All_FDB_By_FMS_ACTION_SUCCESS = "[FMS] Get_All_FDB_By_FMS_ACTION_SUCCESS",
  Clear_All_FDB_By_FMS_ACTION_SUCCESS = "[FMS] Clear_All_FDB_By_FMS_ACTION_SUCCESS",
  Get_Joint_Info_Success_Action = "[FMS] Get Joint Info Success Action",
  Fms_Schematic_Get_Success_Action = "[FMS] Fms Schematic Get Success Action",
}

// Get Only FMS Data
export const GetOnlyFMS = () => (dispatch: any, getState: any) => {
  return GetAllFMS()
    .then((response) => {
      if (response.status != 200) {
        dispatch(ApiCallErrorAction(response.data));
      } else {
        dispatch({
          type: FMSActionTypes.Get_Only_FMS_Action_Success,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(UserLogoutSuccess());
      } else {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Error encountered please try again later",
          })
        );
      }
    });
};

export const GetAllFMSSuccess = (data: any) => {
  return { type: FMSActionTypes.FMS_GetAll_Success, payload: data };
};

export const GetAllFDBByFMS =
  (data: number) => (dispatch: any, getState: any) => {
    getAllFDBByFMS(data)
      .then((resp) => {
        if (resp.status !== 200) {
          dispatch(ApiCallErrorAction(resp.data));
        } else {
          dispatch({
            type: FMSActionTypes.Get_All_FDB_By_FMS_ACTION_SUCCESS,
            payload: resp.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(UserLogoutSuccess());
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
export const ClearAllFDBByFMS = () => (dispatch: any, getState: any) => {
  dispatch({ type: FMSActionTypes.Clear_All_FDB_By_FMS_ACTION_SUCCESS });
};

export const GetALLFMS = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading FMS Please Wait ....",
      })
    );
    return Promise.all([GetAllFMS(), GetAllFMSLocation()])
      .then((response) => {
        if (response[0].status !== 200) {
          dispatch(ApiCallErrorAction(response[0].data));
        } else if (response[1].status != 200) {
          dispatch(ApiCallErrorAction(response[1].data));
        } else {
          dispatch(
            GetAllFMSSuccess({
              fms_list: response[0].data,
              fms_location_list: response[1].data,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(UserLogoutSuccess());
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

// Add FMS Data request  Actions
const AddFMSDataRequestSuccess = (payload: any) => {
  return { type: FMSActionTypes.FMS_Add_Request_Action, payload: payload };
};

export const AddFMSRequestAction = () => {
  return (dispatch: any, payload: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading Data Please Wait ....",
      })
    );
    return GetAllZone()
      .then((response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            AddFMSDataRequestSuccess({
              zone_list: response.data,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

// Get FMS Location Wise +++++++++++++++++++++++++++++
const GetFMSLocationWiseSuccess = (payload: any) => {
  return { type: FMSActionTypes.SHOW_FMS_LOCATION_WISE, payload: payload };
};

export const GetFMSLocationWiseAction = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading Data Please Wait ....",
      })
    );
    return Promise.all([GetAllFMSLocation(), GetFMSByLocation(data)])
      .then((response) => {
        if (response[0].status != 200) {
          dispatch(ApiCallErrorAction(response[0].data));
        } else if (response[1].status != 200) {
          dispatch(ApiCallErrorAction(response[1].data));
        } else {
          dispatch(
            GetFMSLocationWiseSuccess({
              fms_location_list: response[0].data,
              filter_data: response[1].data,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

// Get Station By Zone request Actions
const GetStationByZoneSuccess = (payload: any) => {
  return { type: FMSActionTypes.Get_Station_By_Zone_Action, payload: payload };
};
export const GetStationByZoneAction = (data: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading  Please Wait ....",
      })
    );
    return GetStationByZoneId(data)
      .then((response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            GetStationByZoneSuccess({
              station_by_zone: response.data,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

// Get Location By Station Actions
const GetLocationByStationSuccess = (payload: any) => {
  return {
    type: FMSActionTypes.Get_Location_By_Station_Action,
    payload: payload,
  };
};
export const GetLocationByStationAction = (data: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading FMS Please Wait ....",
      })
    );
    return GetLocationByStationId(data)
      .then((response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            GetLocationByStationSuccess({
              location_by_station: response.data,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

// Add FMS Actions
const AddFMSSuccess = () => {
  return { type: FMSActionTypes.FMS_Add_Success_Action };
};

export const AddFMSAction = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading FMS Please Wait ....",
      })
    );
    return FMSAddService(data.payload)
      .then(async (resp) => {
        if (resp.status !== 200) {
          dispatch(ApiCallErrorAction(resp.data.Errors));
        } else {
          data.history.push("/fms/fms-main");
          await data.enqueueSnackbar("FMSLocation Successfully Added!", {
            variant: "success",
          });
          dispatch(AddFMSSuccess());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};
export const DeleteFMSSuccess = () => {
  return { type: FMSActionTypes.FMS_Delete_Success_Action };
};
export const DeleteFMSAction = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Deleting FMSLocation Please Wait",
      })
    );
    return DeleteFMS(data.payload)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data.Errors));
        } else {
          await data.enqueueSnackbar("Deleted Successfully !", {
            variant: "success",
          });
          dispatch(DeleteFMSSuccess());
          dispatch(GetALLFMS());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered Please try again later",
            })
          );
        }
      });
  };
};
const GetFMSByIdSuccess = (payload: FMSList) => {
  return {
    type: FMSActionTypes.Get_FMS_By_Id_Success_Action,
    payload: payload,
  };
};

// Get FMS By Id  action
export const GetFMSByIdAction = (data: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading FMS Please wait ...",
      })
    );
    return GetFMSById(data)
      .then((response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data.Errors));
        } else {
          dispatch(GetFMSByIdSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered Please try again later",
            })
          );
        }
      });
  };
};

const FMSEditSuccess = () => {
  return {
    type: FMSActionTypes.Update_FMS_Success_Action,
  };
};

// edit FMS Action

export const FMSEditActions = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading FMS Please wait ...",
      })
    );
    return FMSEditService(data.payload)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data.Errors));
        } else {
          await data.enqueueSnackbar("updated Successfully !", {
            variant: "success",
          });
          data.history.push("/fms/fms-main");
          dispatch(FMSEditSuccess());
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered Please try again later",
            })
          );
        }
      });
  };
};

/////    collect filter data from location page   ///////////////////

export const updateFMSToLocation = (data: FMSLocationList | undefined) => {
  return {
    type: FMSActionTypes.GET_FILTERED_DATA_FROM_LOCATION,
    payload: data,
  };
};

export const GetJointInfo =
  (data: number) => (dispatch: any, getState: any) => {
    return getJointInfo(data)
      .then((response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: FMSActionTypes.Get_Joint_Info_Success_Action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(UserLogoutSuccess());
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const GetFmsSchematicSuccess = (data: any) => {
  return {
    type: FMSActionTypes.Fms_Schematic_Get_Success_Action,
    payload: data,
  };
};

export const GetFmsSchematics =
  (data: number) => (dispatch: any, getState: any) => {
    GetFmsSchematic(data)
      .then((resp) => {
        if (resp.status !== 200) {
          dispatch(ApiCallErrorAction(resp.data));
        } else {
          dispatch(GetFmsSchematicSuccess(resp.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(UserLogoutSuccess());
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
