import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    MenuItem,
    Paper,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect } from "react";
  import { Controller, useForm } from "react-hook-form";
  import { useHistory } from "react-router-dom";
import Autocompletes from "../../../components/Autocompletes";
import { ColorCode, FibreMater } from "../../../models/cableMasterModel";
function EditFibreView({color,submitData,fibre}: EditFibreViewProps) {
    const history = useHistory();
    const { control,register, handleSubmit, formState, setValue } = useForm();
    const { errors } = formState;
    const onSubmit = (props: any) => {
        submitData(props)
    };
    const fbType: any[] = [
        {
          key: "SM",
          value: "SM",
        },
        {
          key: "MM",
          value: "MM",
        },
      ];
    useEffect(() => {
        if (!!fibre) {
          setValue("fibre_code", fibre.fibre_code);
          setValue("fibre_type", fibre.fibre_type);
          setValue("fibre_colour_code", fibre.fibre_colour_code);
        }
      }, [fibre]);
  return (
    <Card sx={{ marginTop: "10px" }}>
    <CardHeader
      style={{ backgroundColor: "#202a37", color: "#fff", height:14  }}
      titleTypographyProps={{ fontWeight: 500, fontSize:'1.3rem' }}
      title="Edit Fibre"
    />
    <CardContent>
    <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container justifyContent="center" alignItems={"center"}>

              {/* <Grid item xs={5}>
                <Grid
                  container
                  paddingTop={2}
                  justifyContent="left"
                  alignItems={"center"}
                >
                  <Grid item xs={11.5}>
                    <TextField
                      label="Fibre Code"
                      {...register("fibre_code", { required: true })}
                      style={{ width: "100%", margin: "5px" }}
                      type="text"
                      variant="outlined"
                      size="small"
                      name="fibre_code"
                      error={!!errors["fibre_code"]}
                    />
                  </Grid>
                </Grid>
              </Grid> */}

        <Grid container justifyContent="center" alignItems={"center"}>
            {/* <Grid item xs={4}>
              <Typography style={{ fontWeight: 550 }}>Fibre Code</Typography>
            </Grid> */}
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Fibre Code</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("fibre_code", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="fibre_code"
                placeholder="Fibre Code"
                error={!!errors["fibre_code"]}
              />
            </Grid>
          </Grid>



              {/* <Grid item xs={5}>
                <Grid
                  container
                  flexDirection={"row"}
                  paddingTop={2}
                  justifyContent="right"
                  alignItems={"center"}
                >
                  <Grid item xs={11.5}>
                <Controller
                control={control}
                name={"fibre_type"}
                defaultValue={""}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    style={{ width: "100%", margin: "5px" }}
                    variant="outlined"
                    select
                    label="Fibre Type"
                    size="small"
                    onBlur={onBlur}
                    name="fibre_type"
                    defaultValue={""}
                    error={!!errors["fibre_type"]}
                    value={value}
                    onChange={(value) =>
                      onChange(value.target.value)
                    }
                  >
                <MenuItem key={"SM"} value={"SM"}>
                  SM
                </MenuItem>
                <MenuItem key={"MM"} value={"MM"}>
                    MM
                </MenuItem>
              </TextField>
                )}
                />
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid container justifyContent="center" alignItems={"center"}>
            {/* <Grid item xs={4}>
              <Typography style={{ fontWeight: 550 }}>Fibre Type</Typography>
            </Grid> */}
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Fibre Type</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Controller
                control={control}
                name={"fibre_type"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={fbType}
                    keys={"key"}
                    label={"value"}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"Fibre Type"}
                    value={value}
                    errors={errors["fibre_type"]}
                  />
                )}
              />
            </Grid>
          </Grid>
            </Grid>


            <Grid container justifyContent="center" alignItems={"center"}>
            {/* <Grid item xs={4}>
              <Typography style={{fontWeight: 550}}>Fibre Color</Typography>
            </Grid> */}
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Fibre Color</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
            <Grid item xs={1}>
              <Typography style={{fontWeight: 550}}>:</Typography>
            </Grid>
            <Grid item xs={5}>
            <Controller
            control={control}
            name={"fibre_colour_code"}
            defaultValue={""}
            render={({ field: { onBlur, value, onChange } }) => (
            <TextField
                {...register("fibre_colour_code", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                select
                onBlur={onBlur}
                value={value}
                onChange={(value)=> onChange(value.target.value)}
                name="fibre_colour_code"
                placeholder="Fibre Color"
                defaultValue={""}
                error={!!errors["fibre_colour_code"]}
              >
                  {
              !!color && color.map((item)=> (
                <MenuItem key={item.colour_master_id} value={item.colour_code}>
                  <Grid container gap={1} direction={'row'}>
                    <Box style={{height: '20px', width: '20px' ,backgroundColor: item.colour_code}}></Box>{item.colour_name}
                  </Grid>
                </MenuItem>
              ))
            }
              </TextField>
            )}/>
          </Grid>
      </Grid>

            {/* <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              paddingTop={2}
            >
              <Grid item xs={10}>
              <Controller
                control={control}
                name={"fibre_colour_code"}
                defaultValue={""}
                
                render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                onBlur={onBlur}
                style={{ width: "100%", margin: "5px" }}
                variant="outlined"
                select
                label="Fibre Color"
                size="small"
                defaultValue={""}
                name="fibre_colour_code"
                error={!!errors["fibre_colour_code"]}
                value={value}
                onChange={(value) =>
                  onChange(value.target.value)
                }
              >
                  {
                      !!color && color.map((item)=> (
                        <MenuItem key={item.colour_master_id} value={item.colour_code}>
                        <Grid container gap={1} direction={'row'}>
                           <Box style={{height: '20px', width: '20px' ,backgroundColor: item.colour_code}}></Box>{item.colour_name}
                       </Grid>
                       </MenuItem>
                      ))
                  }
              </TextField>
                )}/>
              </Grid>
            </Grid> */}



            <Grid container justifyContent="center" alignItems={"center"}>
              <Grid item>
                <Button
                  onClick={() => history.push(`/cable-master/fibre-main`)}
                  style={{
                    color: "#fff",
                    marginRight: 10,
                    background: "#C1B4B3",
                  }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  style={{ color: "#fff" }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
    </CardContent>
  </Card>
  )
}
interface EditFibreViewProps {
    color: ColorCode[];
    submitData?: any;
    fibre?: FibreMater;
}

export default EditFibreView