import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import EditFMS from "./EditFMS";
import AddFMS from "./AddFMS";
import FMSMain from "./FMSMain";
import JointInfos from "./JointInfos";
import FMSSchematic from "./FMSS";

const FMS = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <Redirect to={`${path}/fms-main`} />}
      />
      <Route exact path={`${path}/add-fms`} render={() => <AddFMS />} />
      <Route
        exact
        path={`${path}/edit-fms/:id/:readable`}
        render={() => <EditFMS />}
      />

      <Route exact path={`${path}/fms-main`} render={() => <FMSMain />} />

      <Route
        exact
        path={`${path}/joint-infos/:id`}
        render={() => <JointInfos />}
      />
      <Route
        exact
        path={`${path}/fms-schematic/:id`}
        render={() => <FMSSchematic />}
      />
    </Switch>
  );
};

export default FMS;
