import { serviceOccupencyMain } from "../../models/serviceOccupencyModel";
import { serviceOccupencyTypes } from "../actions/serviceOccupencyAction";
import InitialState from "./initialState";


const initialState:serviceOccupencyMain=InitialState.service_occupency;

export default function ServiceOccupencyMasterReducer(
    state:serviceOccupencyMain=initialState,
    action:any
){
    switch(action.type){
        case serviceOccupencyTypes.Service_occupency_getAll:
            return {
                ...state,
                service_occupency_list:action.payload
            }
        default: return state
    }
}