import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Autocompletes from "../../../components/Autocompletes";
import { CableMsterList } from "../../../models/cableMasterModel";
import { CableList } from "../../../models/cableModel";
import { FMSList } from "../../../models/fmsModel";
import SearchIcon from "@mui/icons-material/Search";
import FmsFindpopOver from "../FMsFindPopover";
import CableTreeDialog from "../../../components/CableTreeDialog";

function EditcableView({
  submitData,
  allFMS,
  master_cable_list,
  cable,
}: EeditCableViewProps) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [FmsA, SetFMSA] = useState<any>();
  const [FmsB, SetFMSB] = useState<any>();
  const [isCableTreeOpen, setIsCableTreeOpen] = useState<boolean>(false);
  const [cableDetailsId, setCableDetailsId] = useState<number | undefined>();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
  } = useForm();
  const { errors } = formState;
  const onSubmit = (props: any) => {
    let newShortCode =
      props.cable_short_code_readable + "-" + props.cable_short_code_manual;
    submitData({
      cable_short_code: newShortCode,
      cable_actual_length: props.cable_actual_length,
      cable_id: props.cable_id,
      source_fms: !!FmsA ? FmsA.fms_id : cable?.source_fms,
      destination_fms: !!FmsB ? FmsA.fms_id : cable?.destination_fms,
    });
  };

  const onOpenCableDialog = (val: number) => {
    setCableDetailsId(val);
    setIsCableTreeOpen(true);
  };
  const generateCabShortCode = () => {
    let code: any = "";
    if (!!getValues("cable_id")) {
      code = master_cable_list.find((m) => m.cable_id == +getValues("cable_id"))
        ?.cable_code;
    }
    if (!!FmsA) {
      code = code + "-" + FmsA.station_code;
    }
    if (!!FmsB) {
      code = code + "-" + FmsB.station_code;
    }
    setValue("cable_short_code_readable", code);
  };
  useEffect(() => {
    generateCabShortCode();
  }, [FmsA, FmsB]);

  useEffect(() => {
    if (!!cable) {
      setValue("cable_actual_length", cable.cable_actual_length);
      setValue("cable_id", cable.cable_id);
      setValue("source_fms", cable.source_fms_code);
      setValue("destination_fms", cable.destination_fms_code);
      //setValue("cable_short_code", cable.cable_id);
      let lastDash = cable.cable_short_code.lastIndexOf("-");
      let firstShortCode = cable.cable_short_code.slice(0, lastDash - 1);
      let secondShortCode = cable.cable_short_code.slice(lastDash + 1);
      setValue("cable_short_code_readable", firstShortCode);
      setValue("cable_short_code_manual", secondShortCode);
    }
  }, [cable]);
  const SourceAChoose = (data: any) => {
    setOpen(false);
    setValue("source_fms", data.fms_name);
    SetFMSA(data);
  };
  const SourceBChoose = (data: any) => {
    setOpen1(false);
    setValue("destination_fms", data.fms_name);
    SetFMSB(data);
  };

  return (
    <Card sx={{ marginTop: "10px" }}>
      <CardHeader
        style={{ backgroundColor: "#202a37", color: "#fff", height: 14 }}
        titleTypographyProps={{ fontWeight: 500, fontSize: "1.3rem" }}
        title={"Edit Cable"}
      />
      <CardContent style={{ padding: 0 }}>
        <FmsFindpopOver
          onClose={setOpen}
          open={open}
          onSubmits={SourceAChoose}
        />
        <FmsFindpopOver
          onClose={setOpen1}
          open={open1}
          onSubmits={SourceBChoose}
        />
        <CableTreeDialog
          isOpen={isCableTreeOpen}
          setOpen={setIsCableTreeOpen}
          cable_id={cableDetailsId}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Cable Type</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Controller
                control={control}
                name={"cable_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={true}
                    infoEvent={onOpenCableDialog}
                    option={master_cable_list}
                    keys={"cable_id"}
                    label={"cable_code"}
                    onBlur={onBlur}
                    onChange={(value: any) => {
                      onChange(value);
                      generateCabShortCode();
                    }}
                    placeholder={"Cable Type"}
                    value={value}
                    errors={errors["cable_id"]}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>
                Cable Actual Length
              </Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("cable_actual_length", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="cable_actual_length"
                placeholder="Cable Actual Length"
                error={!!errors["cable_actual_length"]}
                defaultValue={""}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>
                Select Source FMS
              </Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                onClick={() => {
                  setOpen(true);
                }}
                inputProps={{ readOnly: true }}
                {...register("source_fms", { required: true })}
                size="small"
                style={{ width: "100%", margin: "5px" }}
                placeholder="Source FMS A"
                id="fullWidth"
                InputProps={{
                  endAdornment: (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>
                Select Destination 
              </Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                onClick={() => {
                  setOpen(true);
                }}
                inputProps={{ readOnly: true }}
                {...register("destination_fms", { required: true })}
                size="small"
                style={{ width: "100%", margin: "5px" }}
                placeholder="Source FMS B"
                id="fullWidth"
                InputProps={{
                  endAdornment: (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>
                Cable Short Code
              </Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register("cable_short_code_readable", { required: true })}
                inputProps={{ readOnly: true }}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="cable_short_code_readable"
                placeholder="Cable Short Code"
                error={!!errors["cable_short_code_readable"]}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                {...register("cable_short_code_manual", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="cable_short_code_manual"
                placeholder="Type your Code"
                error={!!errors["cable_short_code_manual"]}
                defaultValue={""}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item>
              <Button
                onClick={() => history.push(`/cable`)}
                style={{
                  color: "#fff",
                  marginRight: 10,
                  background: "#C1B4B3",
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Back
              </Button>

              <Button
                type="submit"
                style={{ color: "#fff" }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

export default EditcableView;
interface EeditCableViewProps {
  submitData?: any;
  master_cable_list: CableMsterList[];
  allFMS: FMSList[];
  cable?: CableList;
}
