import React, { useEffect } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import { ZoneList } from "../../../models/zoneModels";
import { DeleteZones, GetAllZones } from "../../../Stores/actions/zoneActions";
// import ZoneMainView from "./ZoneMainView";
import { useSnackbar } from "notistack";
import { FiltreUpdateSuccessAction } from "../../../Stores/actions/stationAction";
import { useHistory } from "react-router-dom";
import ServiceMainView from "./ServiceMainView";
import {
  DeleteServices,
  GetAllServices,
} from "../../../Stores/actions/serviceMasterAction";
import { ServiceList } from "../../../models/serviceMasterModel";
import { UserDetails } from "../../../models/userModels";
const ServiceMain = ({
  GetAllServices,
  allservice,
  DeleteServices,
  user
}: ServiceProps) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    GetAllServices();
  }, []);

  const Delete = (data: number) => {
    DeleteServices({
      payload: data,
      enqueueSnackbar: enqueueSnackbar,
    });
  };

  //   const gotoZone=(data: ZoneList)=>{
  //     FiltreUpdateSuccessAction(data);
  //     history.push(`/station/station-main`)
  //   }
  return <ServiceMainView allservice={allservice} user ={user} />;
};

const mapStateToProps = (state: StoreState) => {
  return {
    allservice: state.service.service_list,
    user: state.user.userDetails,
    // allservice: []
  };
};
const mapDispatchToProps = {
  GetAllServices,
  DeleteServices,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceMain);
interface ServiceProps {
  GetAllServices?: any;
  allservice: ServiceList[];
  DeleteServices?: any;
  user?: UserDetails;
}
