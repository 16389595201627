import "./ZoneMainView.css";
import CreateIcon from "@mui/icons-material/Create";
import shortTextFunc from "../../../components/shortTextFunc";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  styled,
  tableCellClasses,
  TextField,
  Grid,
  Fab,
  SxProps,
  Zoom,
  useTheme,
  Breadcrumbs,
  Link,
  Typography,
  Chip,
  emphasize,
  Button,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ButtonProps,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from "react-router-dom";
import { ZoneList } from "../../../models/zoneModels";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  DeleteButton,
  InfoButton,
  DetailButton,
} from "../../../components/Button";
import { UserDetails } from "../../../models/userModels";

const ZoneMainView = ({ allzone, Delete, gotoZone, user }: ZoneViewProps) => {
  const theme = useTheme();
  const history = useHistory();
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  const matchesmdTheme = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslgTheme = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    if (matchesmdTheme) {
      setIsLargeScreen(false);
    } else if (matcheslgTheme) {
      setIsLargeScreen(true);
    }
  }, [matchesmdTheme, matcheslgTheme]);

  const columns: any[] = ["Zone Name", "Short Code", "Action"];
  const [open, setOpen] = useState<boolean>(false);
  const [id, SetId] = useState<number>(-1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState<ZoneList[]>([]);
  const [searched, setSearched] = useState<string>("");
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!allzone
      ? allzone.filter((row: ZoneList) => {
          return (
            row.zone_code.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row.zone_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row.zone_desc.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  useEffect(() => {
    if (!!allzone) {
      cancelSearch();
    }
  }, [allzone]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const del = () => {
    setOpen(false);
    Delete(id);
  };
  const styles = {
    marginRight: "50px",
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this zone?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            No
          </Button>
          <Button onClick={() => del()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          margin={1}
        >
          <Grid item xs={8}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer sx={{ maxHeight: 480 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell align="justify" key={column}>
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.zone_id}
                      >
                        <StyledTableCell align="left" key={columns[0]}>
                          {row.zone_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[1]}>
                          {row.zone_code}
                        </StyledTableCell>
                        {/* <StyledTableCell align="left" key={columns[2]}>
                          {shortTextFunc(row.zone_desc)}
                        </StyledTableCell> */}
                        <StyledTableCell align="left" key={columns[3]}>
                          {user?.roleType !== "R" &&
                            user?.roleType !== "FW" &&
                            (!isLargeScreen ? (
                              <Tooltip title="Edit" arrow>
                                <EditIcon
                                  color="primary"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() =>
                                    history.push(
                                      `/zone/edit-zone/${row.zone_id}`
                                    )
                                  }
                                />
                              </Tooltip>
                            ) : (
                              <Button
                                onClick={() =>
                                  history.push(
                                    `/zone/edit-zone/${row.zone_id}/0`
                                  )
                                }
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<EditIcon />}
                                style={{ color: "#fff" }}
                              >
                                Edit
                              </Button>
                            ))}

                          {!isLargeScreen ? (
                            <Tooltip title="View All" arrow>
                              <PreviewIcon
                                color="success"
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  history.push(
                                    `/zone/edit-zone/${row.zone_id}/1`
                                  )
                                }
                              />
                            </Tooltip>
                          ) : (
                            <DetailButton
                              onClick={() =>
                                history.push(`/zone/edit-zone/${row.zone_id}/1`)
                              }
                              style={{ marginLeft: 10 }}
                              variant="outlined"
                              color="primary"
                              size="small"
                              // import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

                              startIcon={<PreviewIcon />}
                            >
                              view
                            </DetailButton>
                          )}

                          {/* <button className="edit2"
                            onClick={() =>
                              history.push(`/zone/edit-zone/${row.zone_id}`)
                            }>
                              <CreateIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>
                                <span className="level">Edit</span>
                            
                          </button> */}
                          {/* {
                            !isLargeScreen ? (
                              <Tooltip title="Delete" arrow>
                                 
                                  <DeleteIcon
                                    color="error"
                                    sx={{cursor:"pointer"}}
                                    onClick={() => (SetId(row.zone_id), setOpen(true))}
                                  />
                              </Tooltip>

                            ):(
                              <DeleteButton
                              onClick={() => (SetId(row.zone_id), setOpen(true))}
                              style={{ marginLeft: 10 }}
                              variant="contained"
                              color="error"
                              size="small"
                              startIcon={<DeleteIcon />}
                            >
                              Delete
                            </DeleteButton>

                            )
                          } */}

                          {/* <button className="delete" onClick={() => (SetId(row.zone_id), setOpen(true))}>
                          <DeleteIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>
                           
                            <span className="level">Delete</span>
                              
                          </button> */}

                          {!isLargeScreen ? (
                            <Tooltip title="Show Station" arrow>
                              <InfoIcon
                                color="success"
                                onClick={() => gotoZone(row)}
                                sx={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          ) : (
                            <InfoButton
                              onClick={() => gotoZone(row)}
                              style={{ marginLeft: 10 }}
                              variant="contained"
                              color="success"
                              size="small"
                              startIcon={<InfoIcon />}
                            >
                              Show Station
                            </InfoButton>
                          )}
                          {/* <button className="show" onClick={() =>
                              gotoZone(row)
                            }>
                                <InfoIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>
                                <span className="level">Show Station</span>
                          </button> */}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={styles}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={allzone.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {user?.roleType !== "R" && user?.roleType !== "FW" && (
          <Fab
            sx={fabStyle}
            aria-label={"Add"}
            color={"primary"}
            onClick={() => history.push("/zone/add-zone")}
            style={{ marginBottom: 15 }}
          >
            {<AddIcon style={{ color: "#ffff" }} />}
          </Fab>
        )}
      </Paper>
    </>
  );
};

export default ZoneMainView;

interface ZoneViewProps {
  allzone: ZoneList[];
  Delete?: any;
  gotoZone?: any;
  user?: UserDetails;
}
const fabStyle = {
  position: "absolute",
  bottom: 40,
  right: 16,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: { md: 17, sm: 12, xs: 8 },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
