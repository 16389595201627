import React,{useEffect} from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import { serviceOccupency } from "../../../models/serviceOccupencyModel";
import { GetAllServiceOccupencyAction } from "../../../Stores/actions/serviceOccupencyAction";
import ServiceOcupencyMainView from "./ServiceOccupencyMainView"
function ServiceOcupencyMain({GetAllServiceOccupencyAction, service_occpency_list}:ServiceOcupencyMainProps) {
  useEffect(()=>{
    GetAllServiceOccupencyAction()
  },[])
 
  return(
    <ServiceOcupencyMainView  serviceOccupencyList={service_occpency_list}/>
  )
  
}

const mapStateToProps = (state:StoreState) => {
  return {
    service_occpency_list:state.service_occupency.service_occupency_list
  } 
}
const mapDispatchToProps ={
  GetAllServiceOccupencyAction
}
//export default connect(mapStateToProps, mapDispatchToProps)(ServiceMain);
export default connect(mapStateToProps, mapDispatchToProps)(ServiceOcupencyMain);
interface ServiceOcupencyMainProps{
  GetAllServiceOccupencyAction?:any;
  service_occpency_list:serviceOccupency[];

}