import { useState, useEffect } from "react";
import { connect } from "react-redux";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { StoreState } from "../models/reduxModels";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Stack,
  Slide,
  Grid,
  Paper,
  Tooltip,
  Accordion,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TableCell,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { TreeView } from "@mui/lab";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import {
  CableDetailLoadAction,
  removeCableDetailLoadAction,
} from "../Stores/actions/cableAction";
import { CableDetails } from "../models/cableModel";
import { DestinationCableInfo, SourceCableInfo } from "../models/cableModel";
import { EndToEndFibreTraceAction } from "../Stores/actions/ReportAction";

const EndToEndDialog = ({
  isOpen,
  setOpen,
  EndToEndFibreTraceAction,
  fibresEndToEnd,
  impl_id,
  show_pdf,
}: EndToEndProps) => {
  const handleClose = () => {
    setOpen(false);
  };
  //   const [expanded, setExpanded] = useState<string | false>("panel1");
  //   const handleChange1 =
  //     (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //       setExpanded(newExpanded ? panel : false);
  //     };
  const [expanded, setExpanded] = useState<string[]>([]);
  const payload = {
    destination_station_id: "",
    fibre_impl_id: impl_id,
  };
  useEffect(() => {
    if (isOpen && impl_id !== 0) {
      EndToEndFibreTraceAction(payload);
    }
  }, [isOpen]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      fullScreen
      style={{ padding: 0 }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          textAlign: "center",
          backgroundColor: "#065c7e",
          color: "#fff",
          fontSize: "1.4rem",
          padding: 0.5,
        }}
      >
        End To End Info
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ overflow: "hidden", padding: 0 }}
        >
          {/* <h1>{impl_id}</h1> */}
          <Box>
            <Paper
              elevation={3}
              sx={{
                width: "100%",
                margin: "1rem 0",
                paddingTop: "1rem",
              }}
            >
              <Box sx={{ width: "100%", height: "100%" }}>
                <Box>
                  {/* <Typography
                    sx={{
                      color: "gray",
                      textAlign: "center",
                      textShadow:
                        "0px 0px 0px #fff, 3px 3px 0px rgba(0,0,0,0.15)",
                      fontWeight: 620,
                    }}
                    variant="h5"
                  >
                    {" End To End Fibre Trace"}
                  </Typography> */}
                </Box>
                <TableContainer sx={{ marginTop: "1.4rem" }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          Cable Short Code
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          Station / FMS code (Source A)
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          Tube Fibre Color (Source A)
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          Fibre Status / Ferule Name (Source A)
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          Service (Source A)
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          Station - FMS code (Source B)
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          Tube Fibre Color (Source B)
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          Fibre Status / Ferule Name (Source A)
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          Service (Source B)
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          Connected With
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderCollapse: "collapse",
                          }}
                        >
                          Fibre km
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!fibresEndToEnd?.fibre &&
                      fibresEndToEnd.fibre.length > 0 ? (
                        fibresEndToEnd.fibre.map(
                          (tData: any, index: number) => (
                            <StyledTableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              <StyledTableCell>
                                {tData.cable_short_code}
                              </StyledTableCell>
                              <StyledTableCell>
                                {`${tData.station_name}  `} <br />
                                {tData.fms_code}
                              </StyledTableCell>
                              <StyledTableCell>
                                {tData.tube_colour} - {tData.fibre_colour}
                              </StyledTableCell>
                              <StyledTableCell>
                                {tData.fibre_status} <br />
                                {tData.ferule_name ? tData.ferule_name : `N/A`}
                              </StyledTableCell>
                              <StyledTableCell>
                                {tData.service_name
                                  ? tData.service_name
                                  : `N/A`}{" "}
                                <br />
                                {tData.service_desc}
                              </StyledTableCell>
                              <StyledTableCell>
                                {tData.des_station_name} <br />
                                {tData.des_fms_code}
                              </StyledTableCell>
                              <StyledTableCell>
                                {tData.des_tube_colour} -{" "}
                                {tData.des_fibre_colour}
                              </StyledTableCell>

                              <StyledTableCell>
                                {tData.des_fibre_status} <br />
                                {tData.des_ferule_name
                                  ? tData.des_ferule_name
                                  : `N/A`}
                              </StyledTableCell>
                              <StyledTableCell>
                                {tData.des_service_name
                                  ? tData.des_service_name
                                  : `N/A`}{" "}
                                <br />
                                {tData.des_service_desc}
                              </StyledTableCell>
                              <StyledTableCell>
                                {tData.spliced_fibre_impl_id !== null ? 
                                tData.fibre_status === "TERMINATED"
                                  ? `${tData.spliced_tube_colour} -
                                ${tData.spliced_fibre_colour}
                                ${tData.spliced_cable_short_code} (PATCHED)`
                                  : `${tData.spliced_tube_colour} -
                                ${tData.spliced_fibre_colour}
                                ${tData.spliced_cable_short_code} (SPLICED)` : 'N/A'}
                                {/* {tData.spliced_tube_colour} {"-"}{" "}
                                {tData.spliced_fibre_colour} <br />
                                {tData.spliced_cable_short_code}{" "} */}
                              </StyledTableCell>
                              <StyledTableCell>
                                {tData.km_distance} km
                              </StyledTableCell>
                            </StyledTableRow>
                          )
                        )
                      ) : (
                        <tr
                          style={{
                            border: "1px solid gray",
                            textAlign: "center",
                          }}
                        >
                          <td
                            colSpan={10}
                            style={{
                              border: "1px solid gray",
                              borderCollapse: "collapse",
                            }}
                          >
                            No record found
                          </td>
                        </tr>
                      )}
                      {!!fibresEndToEnd?.total_fibre_km && (
                        <tr
                          style={{
                            textAlign: "center",
                            height: 30,
                            color: "#363636",
                          }}
                        >
                          <td colSpan={9} style={{ textAlign: "end" }}>
                            <div style={{ fontWeight: 650, fontSize: 20 }}>
                              Total Fibre km =
                            </div>
                          </td>
                          <td
                            colSpan={1}
                            style={{ textAlign: "start", color: "#363636" }}
                          >
                            <div style={{ fontWeight: 650, fontSize: 20 }}>
                              {" "}
                              {fibresEndToEnd?.total_fibre_km}
                              {"km "}
                            </div>
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* {!!fibresEndToEnd?.total_fibre_km && (
            <h1>
              {" "}
              {"Total Fibre km =" +
                " " +
                fibresEndToEnd?.total_fibre_km +
                " " +
                "km"}{" "}
            </h1>
          )} */}
              </Box>
            </Paper>
          </Box>
        </DialogContentText>
      </DialogContent>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item>
          <DialogActions style={{}} sx={{ overflow: "hidden" }}>
            <Button onClick={handleClose} variant="contained" color="error">
              Close
            </Button>
            {show_pdf && (
              <Button
                variant="contained"
                sx={{ color: "#fff" }}
                onClick={() => {
                  var temp: any[] = [];
                  for (let i = 0; i < fibresEndToEnd.fibre.length; i++) {
                    temp[i] = [
                      fibresEndToEnd.fibre[i].cable_short_code,
                      `${fibresEndToEnd.fibre[i].station_name}
                  ${fibresEndToEnd.fibre[i].fms_code}`,
                      fibresEndToEnd.fibre[i].tube_colour +
                        " - " +
                        fibresEndToEnd.fibre[i].fibre_colour,
                      `${fibresEndToEnd.fibre[i].fibre_status}
                  ${
                    !!fibresEndToEnd.fibre[i].ferule_name
                      ? fibresEndToEnd.fibre[i].ferule_name
                      : "N/A"
                  }`,
                      `${
                        fibresEndToEnd.fibre[i].service_name
                          ? fibresEndToEnd.fibre[i].service_name
                          : "N/A"
                      }
                  ${
                    !!fibresEndToEnd.fibre[i].des_service_desc
                      ? fibresEndToEnd.fibre[i].des_service_desc
                      : "N/A"
                  }`,

                      `${fibresEndToEnd.fibre[i].des_station_name}
                  ${fibresEndToEnd.fibre[i].des_fms_code}`,
                      fibresEndToEnd.fibre[i].des_tube_colour +
                        " - " +
                        fibresEndToEnd.fibre[i].des_fibre_colour,
                      `${fibresEndToEnd.fibre[i].des_fibre_status}
                  ${
                    !!fibresEndToEnd.fibre[i].des_ferule_name
                      ? fibresEndToEnd.fibre[i].des_ferule_name
                      : "N/A"
                  }`,
                      `${
                        fibresEndToEnd.fibre[i].des_service_name
                          ? fibresEndToEnd.fibre[i].des_service_name
                          : "N/A"
                      }
                  ${
                    !!fibresEndToEnd.fibre[i].des_service_desc
                      ? fibresEndToEnd.fibre[i].des_service_desc
                      : "N/A"
                  }`,
                      ` ${
                        (fibresEndToEnd.fibre[i].spliced_tube_colour || "") +
                        "-" +
                        (fibresEndToEnd.fibre[i].spliced_fibre_colour || " ")
                      }
                ${fibresEndToEnd.fibre[i].spliced_cable_short_code || " "}`,
                      `${fibresEndToEnd.fibre[i].km_distance} km`,
                    ];
                  }
                  pdfMake.vfs = pdfFonts.pdfMake.vfs;
                  var dd: any = {
                    pageOrientation: "landscape",
                    content: [
                      {
                        text: "End To End Info",
                        style: "header",
                      },
                      {
                        style: "tableExample",
                        table: {
                          headerRows: 1,
                          body: [
                            [
                              {
                                text: "Cable Short Code ",
                                style: "tableHeader",
                              },
                              {
                                text: "Station / FMS code (Source A) ",
                                style: "tableHeader",
                              },
                              {
                                text: "Tube / Fibre Color (Source A)",
                                style: "tableHeader",
                              },
                              {
                                text: "Fibre Status / Ferule Name (Source A)",
                                style: "tableHeader",
                              },
                              {
                                text: "Service (Source A)",
                                style: "tableHeader",
                              },
                              {
                                text: "Station / FMS code (Source B)",
                                style: "tableHeader",
                              },
                              {
                                text: "Tube / Fibre Color (Source B) ",
                                style: "tableHeader",
                              },
                              {
                                text: "Fibre Status / Ferule Name (Source A)",
                                style: "tableHeader",
                              },
                              {
                                text: "Service (Source B)",
                                style: "tableHeader",
                              },
                              {
                                text: "Conneted With",
                                style: "tableHeader",
                              },
                              {
                                text: "Fibre km",
                                style: "tableHeader",
                              },
                            ],

                            ...temp,
                          ],
                        },
                        layout: {
                          fillColor: function (rowIndex: any) {
                            return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                          },
                          hLineWidth: function (i: any, node: any) {
                            return i === 0 || i === node.table.body.length
                              ? 2
                              : 1;
                          },
                          vLineWidth: function (i: any, node: any) {
                            return i === 0 || i === node.table.widths.length
                              ? 2
                              : 1;
                          },
                          hLineColor: function (i: any, node: any) {
                            return i === 0 || i === node.table.body.length
                              ? "black"
                              : "gray";
                          },
                          vLineColor: function (i: any, node: any) {
                            return i === 0 || i === node.table.widths.length
                              ? "black"
                              : "gray";
                          },
                          // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                          // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                          // paddingLeft: function(i, node) { return 4; },
                          // paddingRight: function(i, node) { return 4; },
                          // paddingTop: function(i, node) { return 2; },
                          // paddingBottom: function(i, node) { return 2; },
                          // fillColor: function (rowIndex, node, columnIndex) { return null; }
                        },
                      },
                      {
                        style: "tableExample",
                        table: {
                          headerRows: 1,
                          body: [
                            [
                              {
                                text: "Total Fibre km ",
                                style: "tableHeader",
                              },
                              {
                                text: `${fibresEndToEnd?.total_fibre_km} km`,
                                style: "tableHeader",
                              },
                            ],
                          ],
                        },
                      },
                    ],
                    styles: {
                      header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                        color: "#9d56a2",
                      },
                      subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5],
                      },
                      tableExample: {
                        margin: [0, 5, 0, 15],
                        fontSize: 12,
                      },
                      tableHeader: {
                        bold: true,
                        fontSize: 12,
                        color: "white",
                        fillColor: "#035779",
                      },
                    },
                    defaultStyle: {
                      // alignment: 'justify'
                    },
                  };

                  pdfMake.createPdf(dd).open();
                }}
              >
                Generate PDF
              </Button>
            )}
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    cable_details: state.cable.cable_det,
    fibresEndToEnd: state.report.end_to_end_report,
  };
};
const mapDispatchToProps = {
  CableDetailLoadAction,
  removeCableDetailLoadAction,
  EndToEndFibreTraceAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(EndToEndDialog);
interface EndToEndProps {
  EndToEndFibreTraceAction?: any;
  fibresEndToEnd: any;
  isOpen: boolean;
  setOpen: any;
  data?: any;
  show_pdf?: any;
  GetEndToEndAction?: any;
  impl_id?: number;
  CableDetailLoadAction?: any;
  cable_details?: CableDetails;
  Destdetail?: DestinationCableInfo;
  Sourcedetail?: SourceCableInfo;
  removeCableDetailLoadAction?: any;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
