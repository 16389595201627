import { Box, Collapse, Grid, IconButton, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react'
import { Tube } from '../../../models/cableMasterModel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
function TubeTable({columns,row,index}: TubeTableProps) {
    const [open, setOpen] = React.useState(false);
    const columns2: any[] = [
        "No",
        "Fibre Color",
        "Fibre Type"
      ];
  return (
    <>
<StyledTableRow
hover
role="checkbox"
tabIndex={-1}
key={row.tube_id}
>


<StyledTableCell align="left" key={columns[0]}>
<IconButton
      aria-label="expand row"
      size="small"
      onClick={() => setOpen(!open)}
    >
      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
</StyledTableCell>
    <StyledTableCell2 align="left" key={columns2[0]}>
                    {index + 1}
        </StyledTableCell2>
<StyledTableCell align="left" key={columns[1]}>
  <Grid container gap={1} direction={'row'}>
  <Box style={{border:'1px solid #000', height: '20px', width: '20px' ,backgroundColor: row.tube_colour_code}}></Box>{row.tube_colour}
  </Grid>
</StyledTableCell>
<StyledTableCell align="left" key={columns[4]}>
{row.no_of_fibres}
</StyledTableCell>
</StyledTableRow>
<StyledTableRow>
    {
        !!row.fibres && row.fibres.length > 0 && 
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4} >
<Collapse in={open} timeout="auto" unmountOnExit>
<Box sx={{margin: 1 , paddingLeft: 30 }}>
  {/* <Typography style={{fontSize: 16, fontWeight: 'bold'}} gutterBottom component="div">
      Fibre Detail
  </Typography> */}
  <TableContainer sx={{ maxHeight: 480 }}>
    <Table  size="small" stickyHeader aria-label="sticky table" style={{ tableLayout: "fixed" }}>
      <TableHead>
        <TableRow>
          {columns2.map((column) => (
            <StyledTableCell2 align="left" key={column}>
              {column}
            </StyledTableCell2>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
          {
              !!row.fibres && row.fibres.map((fibre: any, index: number)=> (
                <TableRow>
                    <StyledTableCell2 align="left" key={columns2[0]}>
                    {index + 1}
                    </StyledTableCell2>
                    <StyledTableCell2 align="left" key={columns2[1]}>
                    <Grid container gap={1} direction={'row'}>
                        <Box style={{border:'1px solid #000',height: '20px', width: '20px' ,backgroundColor: fibre.fibre_colour_code}}></Box> {fibre.fibre_colour}
                    </Grid>
                    </StyledTableCell2>
                    <StyledTableCell2 align="left" key={columns2[2]}>
                    {fibre.fibre_type}
                    </StyledTableCell2>
                </TableRow>
              ))
          }
      </TableBody>
      </Table>
  </TableContainer>
</Box>
</Collapse>
</StyledTableCell>
    }
</StyledTableRow>
</>
  )
}

export default TubeTable
interface TubeTableProps {
    columns: string[];
    row: Tube;
    index: number;
}
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#00AAEE",
      color: theme.palette.common.white,
      padding: 10,
      paddingLeft: 15,
      fontWeight: 600,
      fontSize: 17
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 8,
      paddingLeft: 15,
      backgroundColor: '#e8e3e3'
    },
  }));
  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#c99c00", 
      color: theme.palette.common.white,
      padding: 2,
      paddingLeft: 15,
      fontWeight: 600,
      fontSize: 16
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
      padding: 8,
      paddingLeft: 14,
      backgroundColor: '#e3dada'
    },
  }));
  
  const StyledTableRow2 = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));