import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CableList } from "../../../models/cableModel";
import { StoreState } from "../../../models/reduxModels";
import {
  DeleteCableAction,
  GetAllCableAction,
  FmsWiseCable,
} from "../../../Stores/actions/cableAction";
import CableMainView from "./CableMainView";
import { showFmsWiseCableAction } from "../../../Stores/actions/cableAction";
import { FMSList } from "../../../models/fmsModel";
import ChooseFMSPopOver from "../ChooseFMSPopOver";
import { useHistory } from "react-router-dom";
import { UserDetails } from "../../../models/userModels";
function CableMain({
  GetAllCableAction,
  cable_list,
  DeleteCableAction,
  filter,
  showFmsWiseCableAction,
  fms_list,
  user,
}: CableProps) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [filters, SetFilters] = useState<number>(0);
  const [open, onClose] = useState<boolean>(false);
  const [cab, Setcab] = useState<CableList | undefined>();

  useEffect(() => {
    if (!!filter) {
      SetFilters(+filter.fms_id);
      showFmsWiseCableAction(+filter.fms_id);
    } else {
      GetAllCableAction();
      SetFilters(0);
    }
  }, []);

  const Delete = (props: number) => {
    DeleteCableAction({
      payload: props,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  const handleFilterFmsWise = (data: number) => {
    if (data) showFmsWiseCableAction(data);
    else GetAllCableAction();
  };
  const CablePortDetail = (data: CableList) => {
    
    if(!!filter){
      history.push(
        `/cable-service/cable-port-main/${data.cable_detail_id}/${filter.fms_id}`
      );
    }else{
      Setcab(data);
      onClose(true);
    }
    
  };
  const Submit = (data: any) => {
    history.push(
      `/cable-service/cable-port-main/${cab?.cable_detail_id}/${data.fms_id}`
    );
  };
  return (
    <>
      <ChooseFMSPopOver
        fms_list={fms_list}
        open={open}
        onClose={onClose}
        cab={cab}
        Submit={Submit}
      />
      <CableMainView
        Delete={Delete}
        user={user}
        cable_list={cable_list}
        fms_list={[
          {
            fms_id: 0,
            fms_name: "ALL FMS",
            fms_code: "",
            fms_type: "",
            fms_installation_name: "",
            fms_installation_type: "",
            station_id: 0,
          },
          ...fms_list,
        ]}
        filters={filters}
        SetFilters={SetFilters}
        handleFilterFmsWise={handleFilterFmsWise}
        CablePortDetail={CablePortDetail}
      />
    </>
  );
}
const mapStateToProps = (state: StoreState) => {
  return {
    cable_list: state.cable.cable_list,
    filter: state.cable.has_filter,
    fms_list: state.cable.fms_list,
    user: state.user.userDetails,

  };
};
const mapDispatchToProps = {
  GetAllCableAction,
  DeleteCableAction,
  showFmsWiseCableAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CableMain);
interface CableProps {
  GetAllCableAction?: any;
  cable_list: CableList[];
  DeleteCableAction?: any;
  filter?: any;
  showFmsWiseCableAction?: any;
  fms_list: FMSList[];
  user?: UserDetails;
}
