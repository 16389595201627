import * as React from "react";
import "./SideNav.css";
import ArticleIcon from "@mui/icons-material/Article";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import HistoryIcon from "@mui/icons-material/History";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import WifiFindTwoToneIcon from "@mui/icons-material/WifiFindTwoTone";
import MailIcon from "@mui/icons-material/Mail";
import MonetizationOnSharpIcon from "@mui/icons-material/MonetizationOnSharp";
import BlurLinearIcon from "@mui/icons-material/BlurLinear";
import AddIcon from "@mui/icons-material/Add";
import {
  Collapse,
  Drawer,
  Icon,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { connect } from "react-redux";
import { StoreState } from "../../models/reduxModels";
import { UserDetails } from "../../models/userModels";
import { useEffect, useState } from "react";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CableIcon from "@mui/icons-material/Cable";
import SettingsInputCompositeIcon from "@mui/icons-material/SettingsInputComposite";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import SettingsInputAntennaTwoToneIcon from "@mui/icons-material/SettingsInputAntennaTwoTone";
import LeaderboardTwoToneIcon from "@mui/icons-material/LeaderboardTwoTone";
import LanIcon from "@mui/icons-material/Lan";
import { Link, useHistory, useLocation } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { FiltreUpdate2SuccessAction } from "../../Stores/actions/fmsLocationAction";
import { FiltreUpdateSuccessAction } from "../../Stores/actions/stationAction";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import { updateFMSToLocation } from "../../Stores/actions/fmsAction";
import { FmsWiseCable } from "../../Stores/actions/cableAction";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ReportIcon from "@mui/icons-material/Report";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
const SideNav = ({
  open,
  setOpen,
  userDetail,
  FiltreUpdate2SuccessAction,
  FiltreUpdateSuccessAction,
  updateFMSToLocation,
  FmsWiseCable,
  user,
}: SideNavProps) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const matchesmdTheme = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslgTheme = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    if (matchesmdTheme) {
      setOpen(false);
    } else if (matcheslgTheme) {
      setOpen(true);
    }
  }, [matchesmdTheme, matcheslgTheme]);

  const NavigateToUrl = (url: any) => {
    if (url == "/zone") {
      FiltreUpdateSuccessAction(undefined);
      updateFMSToLocation(undefined);
      FmsWiseCable(undefined);
    }
    if (url == "/station") {
      //FiltreUpdateSuccessAction(undefined);
      updateFMSToLocation(undefined);
      FmsWiseCable(undefined);
    } else if (url == "/fms-location") {
      //FiltreUpdate2SuccessAction(undefined);
      updateFMSToLocation(undefined);
      FmsWiseCable(undefined);
      //FiltreUpdateSuccessAction(undefined);
    } else if (url == "/fms") {
      // FiltreUpdateSuccessAction(undefined);
      // updateFMSToLocation(undefined);
      FmsWiseCable(undefined);
    } else if (url == "/cable") {
      // FiltreUpdateSuccessAction(undefined);
      // updateFMSToLocation(undefined);
      // FmsWiseCable(undefined);
    } else if (url == "/cable-master" || url == "/service-master") {
      FiltreUpdateSuccessAction(undefined);
      updateFMSToLocation(undefined);
      FmsWiseCable(undefined);
    }

    history.push(url);
  };
  const [open1, setOpen1] = useState(true);

  const handleClick = () => {
    setOpen1(!open1);
  };
  const [path, SetPath] = useState<string>("dashboard");
  const [navItemOne, SetNavItemOne] = useState<SideNavItem[]>([
    {
      key: 1,
      Text: "Zone",
      icon: <LocationOnIcon />,
      url: "/zone",
      hasPermission: true,
    },
    {
      key: 2,
      Text: "Station",
      icon: <CorporateFareIcon />,
      url: "/station",
      hasPermission: true,
    },
    {
      key: 3,
      Text: "FMS Location",
      icon: <HomeWorkIcon />,
      url: "/fms-location",
      hasPermission: true,
    },
    {
      key: 4,
      Text: "FMS",
      icon: <AccountTreeIcon />,
      url: "/fms",
      hasPermission: true,
    },
    {
      key: 5,
      Text: "Cable",

      //   icon: <CableIcon />,
      icon: <SettingsInputCompositeIcon />,
      url: "/cable",
      hasPermission: true,
    },
    {
      key: 6,
      Text: "Service",
      icon: <HomeRepairServiceIcon />,
      url: "/service-master",
      hasPermission: true,
    },
    {
      key: 12,
      Text: "Bulk Upload",
      icon: <AddIcon />,
      url: "/bulk-upload",
      hasPermission: true,
    },
  ]);
  const [navItemTwo, SetNavItemTwo] = useState<SideNavItem[]>([
    // {
    //   key: 1,
    //   Text: "Asset addition/ Delection",
    //   icon: <SettingsInputAntennaTwoToneIcon />,
    //   url: "/connectivity",
    //   hasPermission: true,
    // },
    // {
    //   key: 2,
    //   Text: "Service Tracing",
    //   icon: <MiscellaneousServicesIcon />,
    //   url: "/service-tracing",
    //   hasPermission: true,
    // },
    // {
    //   key: 3,
    //   Text: "Service Occupancy",
    //   icon: <ManageAccountsIcon />,
    //   url: "/service-ocupency",
    //   hasPermission: true,
    // },
    {
      key: 4,
      Text: "Activity History",
      //   icon: <ReportIcon />,
      icon: <HistoryIcon />,
      url: "/report",
      hasPermission: true,
    },
    {
      key: 5,
      Text: "Asset Value",
      //   icon: <MonetizationOnSharpIcon />,
      icon: <CurrencyRupeeIcon />,
      url: "/assets",
      hasPermission: true,
    },
    {
      key: 6,
      Text: "Redundant Route",
      icon: <ForkRightIcon />,
      url: "/redudant-route",
      hasPermission: true,
    },
    {
      key: 7,
      Text: "Damaged Fibre",
      icon: <BrokenImageIcon />,
      url: "/damagefibre-report",
      hasPermission: true,
    },
    {
      key: 8,
      Text: "Cable Utilization",
      icon: <BlurLinearIcon />,
      url: "/utilization-report",
      hasPermission: true,
    },
    {
      key: 9,
      Text: "Service Wise Search ",
      icon: <WifiFindTwoToneIcon />,
      url: "/service-query-report",
      hasPermission: true,
    },
    {
      key: 10,
      Text: "Cable Wise Search ",
      icon: <QueryStatsIcon />,
      url: "/cable-query-report",
      hasPermission: true,
    },
    {
      key: 11,
      Text: "End To End",
      icon: <CableIcon />,
      url: "/end-to-end-report",
      hasPermission: true,
    },
  ]);
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "center",
  }));
  useEffect(() => {
    SetPath("/" + location.pathname.split("/")[1]);
  }, [location.pathname]);
  return (
    <>
      {!!userDetail && !!userDetail.userid && (
        <Drawer
          sx={{
            width: 270,

            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 270,
              boxSizing: "border-box",
            },
          }}
          PaperProps={{ style: { height: "calc(100vh - 195px)", top: 145 } }}
          // PaperProps={{ sx: {height:{sm:"77.5vh",lg:"77.1vh",xs:"77vh",md:"77.6vh"},top:{lg:132,md:132,sm:135,xs:139}}}}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <div className="container">
            <DrawerHeader>
              <Typography
                style={{ color: "#ffff", fontSize: 14, fontWeight: 600 }}
              >
                OPTICAL FIBRE MANAGEMENT
              </Typography>
              {/* <IconButton onClick={() => setOpen(false)} style={{color:'#fff',position: 'absolute', right: 5}}>
                <ChevronLeftIcon />
              </IconButton> */}
            </DrawerHeader>
            <div className="dividertop">
              {/* <Divider style={{color:"#4ab006"}}/> */}

              {/* <List>
              <ListItem button onClick={() => NavigateToUrl("/dashboard")}
                style={{backgroundColor: path == '/dashboard'?"rgb(30 35 42 / 90%)":"#2b333ee6",}}>
                <ListItemIcon style={{color: path == '/dashboard'?'#00aaee':"#80758d",}}>
                  <Icon >dashboard</Icon>
                </ListItemIcon>
                <ListItemText  primary={<Typography  style={{color: path == '/dashboard'?'#00aaee':"#80758d", fontSize: 18,fontWeight: 700,}}>{"DASHBOARD"}</Typography>} />
                <div className="submenu" style={{backgroundColor: path == '/dashboard'?'#00aaee':"#2b333e",}}></div>
              </ListItem>
            </List> */}
              {/* <Divider /> */}
            </div>

            <List>
              {navItemOne.map((item, index) => (
                <ListItem
                  button
                  key={item.key}
                  onClick={() => NavigateToUrl(item.url)}
                  //   style={{padding:0,}}
                  style={{
                    backgroundColor:
                      path == item.url ? "rgb(30 35 42 / 90%)" : "#2b333ee6",
                  }}
                >
                  <ListItemIcon
                    style={{ color: path == item.url ? "#00aaee" : "#80758d" }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        style={{
                          color: path == item.url ? "#00aaee" : "#80758d",
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        {item.Text}
                      </Typography>
                    }
                  />
                  <div
                    className="submenu"
                    style={{
                      backgroundColor: path == item.url ? "#00aaee" : "#2b333e",
                    }}
                  ></div>
                </ListItem>
              ))}
            </List>
            <Divider />

            {user?.roleType !== "R" && user?.roleType !== "FW" && (
              <div className="dividertop">
                <List>
                  <ListItem
                    style={{ backgroundColor: "rgb(30 35 42 / 90%)" }}
                    onClick={handleClick}
                  >
                    <LeaderboardTwoToneIcon
                      style={{
                        color:
                          path == "/dashboard"
                            ? "rgb(162 124 165)"
                            : "rgb(162 124 165)",
                      }}
                    />
                    <ListItemText
                      primary={
                        <Typography
                          style={{
                            color:
                              path == "/dashboards"
                                ? "#00aaee"
                                : "rgb(162 124 165)",
                            fontSize: 16,
                            fontWeight: 700,
                            marginLeft: 30,
                          }}
                        >
                          {"Reports"}
                        </Typography>
                      }
                    />
                    {open1 ? (
                      <ExpandLess style={{ color: "#ffff" }} />
                    ) : (
                      <ExpandMore style={{ color: "#ffff" }} />
                    )}
                  </ListItem>
                </List>
              </div>
            )}
            {/* Report Generation menu  */}

            {user?.roleType !== "R" && user?.roleType !== "FW" && (
              <Collapse in={open1} timeout="auto" unmountOnExit>
                <List style={{ paddingTop: 0 }}>
                  {navItemTwo.map((item, index) => (
                    <ListItem
                      button
                      key={item.key}
                      onClick={() => NavigateToUrl(item.url)}
                      style={{
                        backgroundColor:
                          path == item.url
                            ? "rgb(30 35 42 / 90%)"
                            : "#2b333ee6",
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color: path == item.url ? "#00aaee" : "#80758d",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            style={{
                              color: path == item.url ? "#00aaee" : "#80758d",
                              fontSize: 16,
                              fontWeight: 500,
                            }}
                          >
                            {item.Text}
                          </Typography>
                        }
                      />
                      <div
                        className="submenu"
                        style={{
                          backgroundColor:
                            path == item.url ? "#00aaee" : "#2b333e",
                        }}
                      ></div>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        </Drawer>
      )}
    </>
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    userDetail: state.user.userDetails,
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  FiltreUpdateSuccessAction,
  FiltreUpdate2SuccessAction,
  updateFMSToLocation,
  FmsWiseCable,
};
export default connect(mapStateToProps, mapDispatchToProps)(SideNav);

interface SideNavProps {
  open?: boolean;
  setOpen?: any;
  userDetail?: UserDetails;
  FiltreUpdateSuccessAction?: any;
  FiltreUpdate2SuccessAction?: any;
  updateFMSToLocation?: any;
  FmsWiseCable?: any;
  user?: UserDetails;
}

interface SideNavItem {
  url: string;
  icon: any;
  Text: string;
  hasPermission?: boolean;
  key: number;
}
