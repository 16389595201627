import React, { useEffect } from "react";
import { StoreState } from "../../../models/reduxModels";
import { ServiceList } from "../../../models/serviceMasterModel";
import {
  GetAllFibreByServiceAction,
  getAllServiceAction,
  removeGetAllFibreByServiceAction,
} from "../../../Stores/actions/connectivityAction";
import { connect } from "react-redux";
import ServiceTable from "./ServiceTable";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  TablePagination,
  Fab,
  styled,
  TableCell,
  tableCellClasses,
  MenuItem,
  useTheme,
  useMediaQuery,
  Tooltip,
  Card,
  Typography,
} from "@mui/material";
import Autocompletes from "../../../components/Autocompletes";
import { FibreList } from "../../../models/connectivityModel";

const ServiceTracingMain = ({
  getAllServiceAction,
  service_list,
  fibre_list,
  GetAllFibreByServiceAction,
  removeGetAllFibreByServiceAction,
}: ServiceTracingProps) => {
  useEffect(() => {
    getAllServiceAction();
    return () => removeGetAllFibreByServiceAction();
  }, []);
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm();
  const { errors } = formState;

  const onServiceChange = (data: number) => {
    GetAllFibreByServiceAction(data);
  };

  return (
    <Card sx={{ marginTop: "10px" }}>
      <Box>
        <Paper elevation={3} sx={{ padding: "2rem 4rem" }}>
          <form>
            <Grid container justifyItems="center" alignItems="center">
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: 550 }}>Select Service</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={"service_id"}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      hasInfo={false}
                      infoEvent={() => {}}
                      option={service_list}
                      keys={"service_id"}
                      label={"service_name"}
                      onBlur={onBlur}
                      onChange={(value: any) => (
                        onChange(value), onServiceChange(+value)
                      )}
                      placeholder={"Choose Service"}
                      value={value}
                      errors={errors["service_id"]}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
        {!!fibre_list && fibre_list.length > 0 && (
          <Paper
            elevation={3}
            sx={{ padding: "0.7rem 1rem", marginTop: "1rem" }}
          >
            <ServiceTable fibreList={fibre_list} />
          </Paper>
        )}
      </Box>
    </Card>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    service_list: state.connectivity.service_list,
    fibre_list: state.connectivity.fibre_list,
  };
};

const mapDispatchToProps = {
  getAllServiceAction,
  GetAllFibreByServiceAction,
  removeGetAllFibreByServiceAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceTracingMain);
interface ServiceTracingProps {
  getAllServiceAction?: any;
  fibre_list: FibreList[];
  service_list: ServiceList[];
  GetAllFibreByServiceAction?: any;
  removeGetAllFibreByServiceAction?: any;
}
