import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FilterListIcon from "@mui/icons-material/FilterList";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  TablePagination,
  Fab,
  styled,
  TableCell,
  tableCellClasses,
  MenuItem,
  useTheme,
  useMediaQuery,
  Tooltip,
  Card,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import Autocompletes from "../../components/Autocompletes";
import { ConnectivityList } from "../../models/connectivityModel";
import colorGenerator from "../../Common/utility/colorGenerator";

const TableData = ({
  cableList,
  getCableFibreAction,
  selectedFMS,
  setSelectedFMSId,
}: TableDataProps) => {
  const [expanded2, setExpanded2] = useState<string[]>([
    "panel1",
    "panel2",
    "panel3",
  ]);
  // const [expanded2, setExpanded2] = useState<string | false>("panel2");
  const TColumn: any[] = ["Cable Short Code", "Cable color", "Cable Strength"];

  // const TData: any[] = [
  //     "Cab11",
  //     "red",
  //     "234 mt",
  //   ];
  const showOtherInfo = () => {
    // let sendFMS:number=selectedFMS===cableList.source_fms ?cableList.destination_fms:cableList.source_fms
    // setSelectedFMSId(sendFMS)
    // if(!selectedFMS) alert("sorry")
    // console.log("selectedFMS",selectedFMS,sendFMS)

    getCableFibreAction(selectedFMS);
  };
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      let newExpd: string[] = [];
      if (newExpanded) {
        newExpd = [...expanded2, panel];
      } else {
        newExpd = expanded2.filter((expd: string) => expd !== panel);
      }

      setExpanded2(newExpd);
    };
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm();
  const { errors } = formState;
  return (
    <Box>
      <table
        style={{
          border: "1px solid gray",
          width: "100%",
          borderCollapse: "collapse",
          marginTop: "1rem",
        }}
      >
        <thead>
          <tr style={{ border: "1px solid gray" }}>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Cable Short Code
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Cable Color{" "}
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Cable Strength{" "}
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Cable Length{" "}
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Cable Type
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Tube Color
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Fibre Color
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Fibre Type
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Fibre Status
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Terminated Status
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Spliced With
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Patch With
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Terminated FDB
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Adt. Position
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Adt. Type
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Ferrule Name
            </th>
            <th
              style={{ border: "1px solid gray", borderCollapse: "collapse" }}
            >
              Remarks
            </th>
          </tr>
          {/* //       if(Cab.association_fms_id===selectedFMS) {
                        //       return (
                              
                        //       <tr key={index} style={{backgroundColor:`${bgColor}`}}>
                        //         <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.association_tube_colour?Cab.tube_colour: "NA"}</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.association_fibre_colour?Cab.fibre_colour:"NA"}</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.association_fibre_type ?Cab.association_fibre_type: "NA"}</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.association_fibre_status?Cab.association_fibre_status:"NA"}</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>NA</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>NA</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>NA</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>
                        //            {!!Cab.association_adapter_position ? 
                        //             Cab.association_adapter_position: 
                        //             "NA" }
                        //           </td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>
                        //            {!!Cab.association_adapter_type ?
                        //           Cab.association_adapter_type:"NA"}
                        //          </td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{
                        //            !!Cab.association_ferule_name ?Cab.association_ferule_name:"NA"
                        //         }</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>NA</td>
                        //       </tr>

                        //     )
                        //   }else{
                        //     return(
                        //       <tr key={index} style={{backgroundColor:`${bgColor}`}}>
                        //         <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.tube_colour ?Cab.tube_colour: "NA"}</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.fibre_colour?Cab.fibre_colour:"NA"}</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.fibre_type ?Cab.fibre_type: "NA"}</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.fibre_status?Cab.fibre_status:"NA"}</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.service_type?Cab.service_type:"NA"}</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{
                        //           Cab.fibre_status!=="TERMINATED" &&
                        //           !!Cab.service_type && Cab.service_type!=="PATCHING" && !!Cab.spliced_fibre_code ?Cab.spliced_fibre_code: "NA"}</td>
                                 
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.fibre_status && 
                        //          Cab.fibre_status==="TERMINATED" &&
                        //           !!Cab.service_type && Cab.service_type==="PATCHING" && Cab.spliced_fibre_code ? Cab.spliced_fibre_code: "NA"}</td>
                        //           <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.fdb_fms_code ?Cab.fdb_fms_code: "NA" }</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.adapter_position ?Cab.adapter_position: "NA" }</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.adapter_type ?Cab.adapter_type:"NA" }</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.ferule_name?Cab.ferule_name:"NA"}</td>
                        //          <td style={{border:"1px solid gray",textAlign:"center"}}>{!!Cab.remarks?Cab.remarks:"NA"}</td>
                        //       </tr>
                        //     )}
                        //   }) */}
        </thead>
        <tbody>
          {!!cableList &&
            cableList.map((Cab: ConnectivityList, index: number) => {
              let bgColor: string = colorGenerator(
                String(Cab.cable_detail_id),
                1,
                250
              );
              return (
                <tr key={index} style={{ backgroundColor: `${bgColor}` }}>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.cable_short_code ? Cab.cable_short_code : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.cable_colour ? Cab.cable_colour : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.cable_strength ? Cab.cable_strength + "F" : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.cable_actual_length
                      ? Cab.cable_actual_length + " mt"
                      : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.cable_type ? Cab.cable_type : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.tube_colour ? Cab.tube_colour : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.fibre_colour ? Cab.fibre_colour : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.fibre_type ? Cab.fibre_type : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.fibre_status ? Cab.fibre_status : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.service_type ? Cab.service_type : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {Cab.fibre_status !== "TERMINATED" &&
                    !!Cab.service_type &&
                    Cab.service_type !== "PATCHING" &&
                    !!Cab.spliced_fibre_code
                      ? Cab.spliced_fibre_code
                      : "NA"}
                  </td>

                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.fibre_status &&
                    Cab.fibre_status === "TERMINATED" &&
                    !!Cab.service_type &&
                    Cab.service_type === "PATCHING" &&
                    Cab.spliced_fibre_code
                      ? Cab.spliced_fibre_code
                      : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.fdb_fms_code ? Cab.fdb_fms_code : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.adapter_position ? Cab.adapter_position : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.adapter_type ? Cab.adapter_type : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.ferule_name ? Cab.ferule_name : "NA"}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {!!Cab.remarks ? Cab.remarks : "NA"}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Box>
  );
};

export default TableData;
interface TableDataProps {
  cableList: ConnectivityList[];
  getCableFibreAction?: any;
  selectedFMS: number | null;
  setSelectedFMSId?: any;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: 17,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
