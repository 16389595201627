import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import React from "react";
import colorGenerator from "../../../Common/utility/colorGenerator";
import { FMSList, JointInfo } from "../../../models/fmsModel";
import "./jointInfo.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
function JointInfoView({ jointinfo, single_fms }: JointViewProps) {
  const history = useHistory();
  const GoBack = () => {
    history.push("/fms/fms-main");
  };
  return (
    <Card sx={{}}>
      <CardHeader
        style={{ backgroundColor: "#202a37", color: "#fff", height: 14 }}
        titleTypographyProps={{
          fontWeight: 500,
          fontSize: "1.3rem",
        }}
        title={
          <Grid container direction={"row"} alignItems={"center"}>
            <Grid>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => GoBack()}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid>
              <Typography>{`Joint info of ${single_fms?.fms_code}`}</Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent style={{ padding: 0 }}>
        <table id="customers">
          <tr>
            <th>Cable Short Code</th>
            <th>Tube Color</th>
            <th>Fibre Color</th>
            <th>Fibre Type</th>
            <th>Fibre Status</th>
            <th>Spliced Status</th>
            <th>Service Name</th>
            <th>Spliced Cable Short Code</th>
            <th>Spliced Tube Color</th>
            <th>Spliced Fibre Color</th>
            <th>Spliced Fibre Type</th>
            <th>Spliced Fibre Status</th>
            <th>Spliced Fibre Spliced Status</th>
            <th>Spliced Service Name</th>
          </tr>
          {jointinfo.map((item, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: colorGenerator(
                  String(item.cable_detail_id),
                  120,
                  220
                ),
              }}
            >
              <td>{!!item.cable_short_code ? item.cable_short_code : "NA"}</td>
              <td>{!!item.tube_colour ? item.tube_colour : "NA"}</td>
              <td>{!!item.fibre_colour ? item.fibre_colour : "NA"}</td>
              <td>{!!item.fibre_type ? item.fibre_type : "NA"}</td>
              <td>{!!item.fibre_status ? item.fibre_status : "NA"}</td>
              <td>{!!item.spliced_status ? item.spliced_status : "NA"}</td>
              <td>{!!item.service_name ? item.service_name : "NA"}</td>
              <td>
                {!!item.splice_cable_short_code
                  ? item.splice_cable_short_code
                  : "NA"}
              </td>
              <td>
                {!!item.splice_tube_colour ? item.splice_tube_colour : "NA"}
              </td>
              <td>
                {!!item.splice_fibre_colour ? item.splice_fibre_colour : "NA"}
              </td>
              <td>
                {!!item.splice_fibre_type ? item.splice_fibre_type : "NA"}
              </td>
              <td>
                {!!item.splice_fibre_status ? item.splice_fibre_status : "NA"}
              </td>
              <td>
                {!!item.splice_spliced_status
                  ? item.splice_spliced_status
                  : "NA"}
              </td>
              <td>
                {!!item.splice_service_name ? item.splice_service_name : "NA"}
              </td>
            </tr>
          ))}
        </table>
      </CardContent>
    </Card>
  );
}

export default JointInfoView;
interface JointViewProps {
  jointinfo: JointInfo[];
  single_fms?: FMSList;
}
