import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Box,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../components/Autocompletes";
import { PatchFibre } from "../../../models/cableModel";
import { FMSList } from "../../../models/fmsModel";

function PatchCableModal({
  onClose,
  open,
  fmsId,
  getFMS,
  FDB3,
  GetFibre,
  patch_fibre,
  onSubmit,
}: PatchMod) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset,
  } = useForm();
  const { errors } = formState;
  const onSubmits = (props: any) => {
    onSubmit({
      impl_id: props.fibre_impl_id,
      lable:
        FDB3.find((m) => m.fms_id == props.fms_id)?.fms_code +
        "-" +
        patch_fibre.find((m) => m.fibre_impl_id == props.fibre_impl_id)
          ?.tube_colour +
        "-" +
        patch_fibre.find((m) => m.fibre_impl_id == props.fibre_impl_id)
          ?.fibre_colour +
        "-" +
        patch_fibre.find((m) => m.fibre_impl_id == props.fibre_impl_id)
          ?.ferule_name,
    });
    onClose(false);
  };
  useEffect(() => {
    if (open) {
      getFMS(fmsId);
      reset();
    }
  }, [open]);
  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={() => onClose(false)}>
      <DialogTitle
        style={{
          backgroundColor: "#00AAEE",
          textAlign: "center",
          color: "#fff",
        }}
      >
        Select Patch Fibre
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmits)}>
          <Grid
            container
            justifyContent="center"
            marginTop={2}
            alignItems={"center"}
          >
            <Grid item xs={3}>
              <Typography sx={{ fontWeight: 550 }}>Select FMS</Typography>
            </Grid>
            <Grid item xs={1}>
              :
            </Grid>
            <Grid item xs={7}>
              <Controller
                control={control}
                name={"fms_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={FDB3}
                    keys={"fms_id"}
                    label={"fms_code"}
                    extras={"location_name"}
                    onBlur={onBlur}
                    onChange={(val: number) => (
                      onChange(val),
                      GetFibre(
                        +val,
                        FDB3.find((m) => m.fms_id == +val)?.fms_type
                      )
                    )}
                    value={value}
                    placeholder={"FMS"}
                    errors={errors["fms_id"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            marginTop={2}
            alignItems={"center"}
          >
            <Grid item xs={3}>
              <Typography style={{ fontWeight: 550 }}>Fibre</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Controller
                control={control}
                name={"fibre_impl_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    style={{ width: "100%", margin: "5px" }}
                    type="text"
                    variant="outlined"
                    size="small"
                    select
                    onBlur={onBlur}
                    onChange={(val: any) => onChange(val)}
                    value={value}
                    name="fibre_impl_id"
                    defaultValue={""}
                    error={!!errors["fibre_impl_id"]}
                    label={
                      "Fibre (Cable Code - Tube Color - Fibre Color - Adt. Pos - Farul)"
                    }
                  >
                    {!!patch_fibre &&
                      patch_fibre?.map(
                        (item: any, index: number) =>
                          !!item.tube_colour_code &&
                          !!item.fibre_colour_code && (
                            <MenuItem key={index} value={item.fibre_impl_id}>
                              <Grid
                                container
                                gap={1}
                                direction={"row"}
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <Typography>{item.cable_short_code}</Typography>
                                <Typography>{item.tube_colour}</Typography>
                                <Typography>
                                  {item.fibre_colour + " "}
                                  {!!item.adapter_position &&
                                    item.adapter_position > 0 &&
                                    item.adapter_position}{" "}
                                  {item.ferule_name}
                                </Typography>
                              </Grid>
                            </MenuItem>
                          )
                      )}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            marginTop={2}
            alignItems={"center"}
          >
            <Grid item>
              <Button
                color={"error"}
                onClick={() => onClose(false)}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{
                  marginRight: 10,
                }}
              >
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSubmit(onSubmits)}
                color={"success"}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{
                  marginRight: 10,
                }}
              >
                Choose
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default PatchCableModal;
interface PatchMod {
  fmsId?: number;
  open: boolean;
  onClose: any;
  getFMS?: any;
  FDB3: FMSList[];
  GetFibre?: any;
  patch_fibre: PatchFibre[];
  onSubmit?: any;
}
