import React, { useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  TablePagination,
  Fab,
  styled,
  TableCell,
  tableCellClasses,
  MenuItem,
  useTheme,
  useMediaQuery,
  Tooltip,
  Card,
  Typography,
  Checkbox,
} from "@mui/material";
import { FibreList } from "../../../models/connectivityModel";
import { useHistory } from "react-router-dom";

const ServiceTable = ({ fibreList }: ServiceTableProps) => {
  

  const history = useHistory();
  const [SfibreChecked, setSFibreChecked] = useState<Boolean>(false);
  const [DfibreChecked, setDFibreChecked] = useState<Boolean>(false);
  const [fibreId, setFibreId] = useState<{ SourceFibre: any; DestiFibre: any }>(
    { SourceFibre: null, DestiFibre: null }
  );
  const [checkedBox, setCheckedBox] = useState<{
    SourceFibre: boolean;
    DestiFibre: boolean;
  }>({ SourceFibre: false, DestiFibre: false });

  const handleFibreChange = (data: number) => {
    if (
      fibreId.SourceFibre &&
      checkedBox.SourceFibre &&
      fibreId.SourceFibre != data
    ) {
      if (checkedBox.DestiFibre) {
        setFibreId({
          ...fibreId,
          DestiFibre: null,
        });
      } else {
        setFibreId({
          ...fibreId,
          DestiFibre: data,
        });
      }
      setCheckedBox({
        ...checkedBox,
        DestiFibre: !checkedBox.DestiFibre,
      });
    } else if (fibreId.SourceFibre != data) {
      setFibreId({
        ...fibreId,
        SourceFibre: data,
      });
      setCheckedBox({
        ...checkedBox,
        SourceFibre: !checkedBox.SourceFibre,
      });
    } else {
      setFibreId({
        ...fibreId,
        SourceFibre: null,
      });
      setCheckedBox({
        ...checkedBox,
        SourceFibre: !checkedBox.SourceFibre,
      });
    }
  };

  const TColumn: any[] = [
    "Select",
    "FMS Code",
    "Station Name",
    "Location Name",
    "Tube Color",
    "Fibre Color",
  ];

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setChecked(event.target.checked);
  //   };
  const checkBoxDisabled = (data: number) => {
    if (fibreId.SourceFibre && fibreId.DestiFibre) {
      if (data != fibreId.SourceFibre && data != fibreId.DestiFibre)
        return true;
      else return false;
    }
    return false;
  };
  const showTracing = () => {
    history.push(
      `/service-tracing/service-tracing-flow/${fibreId.SourceFibre}/${fibreId.DestiFibre}`
    );
  };

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {TColumn.map((column: string) => (
                <StyledTableCell align="left" key={column}>
                  {column}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fibreList?.map((Fibre: FibreList, index: number) => (
              <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
                <StyledTableCell align="left" key={TColumn[0]}>
                  {checkBoxDisabled(Fibre.fibre_impl_id) ? (
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      onChange={() => handleFibreChange(Fibre.fibre_impl_id)}
                      disabled={true}
                    />
                  ) : (
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      onChange={() => handleFibreChange(Fibre.fibre_impl_id)}
                      disabled={false}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell align="left" key={TColumn[1]}>
                  {Fibre.fms_code}
                </StyledTableCell>
                <StyledTableCell align="left" key={TColumn[2]}>
                  {Fibre.station_name}
                </StyledTableCell>
                <StyledTableCell align="left" key={TColumn[3]}>
                  {Fibre.location_name}
                </StyledTableCell>
                <StyledTableCell align="left" key={TColumn[4]}>
                  {Fibre.tube_colour}
                </StyledTableCell>
                <StyledTableCell align="left" key={TColumn[5]}>
                  {Fibre.fibre_colour}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {!!fibreList && fibreList.length > 0 && (
          <Button
            variant="contained"
            sx={{ color: "#fff" }}
            onClick={() => showTracing()}
            disabled={
              !!fibreId.SourceFibre && !!fibreId.DestiFibre ? false : true
            }
          >
            Trace
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ServiceTable;

interface ServiceTableProps {
  fibreList: FibreList[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: 17,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
