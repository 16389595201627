import React,{useEffect} from 'react'
import ConnectivityMain from "./ConnectivityMain"

// import { 
//   GetAllStations
// } from "../../Stores/actions/stationAction"
// import { 
//   GetOnlyAllLocation
// } from "../../Stores/actions/fmsLocationAction"
// import{ 
//   GetOnlyFMS
// } from "../../Stores/actions/fmsAction"
import {connect} from "react-redux"
import { StoreState } from '../../models/reduxModels'
import { ZoneList } from '../../models/zoneModels'
import { StationList } from '../../models/stationModel'
import { FMSLocationList } from '../../models/fmsLocationModel'
import { FMSList } from '../../models/fmsModel'
import {
  ConnectivityFilterAction,
  getCableFibreAction,
  removeCableList
} from "../../Stores/actions/connectivityAction"
import { ConnectivityList } from '../../models/connectivityModel'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
const Connectivity = ({
  ConnectivityFilterAction,
  allZone,
  allStation,
  allLocation,
  getCableFibreAction,
  allFMS,
  cable_list,
  removeCableList
}:ConnectivityProps) => {
  useEffect(()=>{
    ConnectivityFilterAction()
    
  },[])
  const { path, url } = useRouteMatch();  
    return (
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={()=><Redirect to={`${path}/connectivity-main`} />}
        />
        <Route
          exact
          path={`${path}/connectivity-main`}
          render={() =>
            <ConnectivityMain
                allZone={allZone}
                allStation={allStation}
                allLocation={allLocation}
                allFMS={allFMS}
                getCableFibreAction={getCableFibreAction}
                cable_list={cable_list}
                removeCableList={removeCableList}
            />
          }
        />
      </Switch>
    
  )
}
const mapStateToProps = (state: StoreState) => {
  return{ 
    allZone:state.connectivity.zone_list,
    allStation:state.connectivity.station_list,
    allLocation:state.connectivity.location_list,
    allFMS:state.connectivity.fms_list,
    cable_list:state.connectivity.cable_list

  }
}
const mapDispatchToProps={
  ConnectivityFilterAction,
  getCableFibreAction,
  removeCableList
}
export default connect(mapStateToProps, mapDispatchToProps)(Connectivity)
interface ConnectivityProps{
  ConnectivityFilterAction?:any;
  allZone:ZoneList[];
  allStation:StationList[];
  allLocation:FMSLocationList[];
  allFMS:FMSList[];
  getCableFibreAction?:any;
  cable_list:ConnectivityList[]
  removeCableList?:any;

}