import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { StoreState } from "../../../models/reduxModels";
import { UserDetails } from "../../../models/userModels";
import { ChangePasswordAction } from "../../../Stores/actions/userAction";
import ProfileMainView from "./ProfileMainView";

function ProfileMain({ user, ChangePasswordAction }: ReportMainProps) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const submitActions = (props: any) => {
    let payload: any = {
      data: {
        userid: user?.userid,
        old_password: props.old_password,
        new_password: props.new_password,
      },
      enqueueSnackbar: enqueueSnackbar,
      history: history,
    };
    ChangePasswordAction(payload);
  };
  return <ProfileMainView user={user} submitAc={submitActions} />;
}

const mapStateToProps = (state: StoreState) => {
  return {
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  ChangePasswordAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileMain);

interface ReportMainProps {
  user?: UserDetails;
  ChangePasswordAction?: any;
}
