import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ReportMain from "../Report/ReportMain";
import RedudantRouteMain from "./RedudantRouteMain";

function RedudantRoute() {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <Redirect to={`${path}/redudant-main`} />}
      />
      <Route
        exact
        path={`${path}/redudant-main`}
        render={() => <RedudantRouteMain />}
      />
    </Switch>
  );
}

export default RedudantRoute;
