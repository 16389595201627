import { DomainMain } from "../../models/domainModel";
import { DomainActionTypes } from "../actions/domainAction";
import { ZoneActionTypes } from "../actions/zoneActions";
import InitialState from "./initialState";

const initialState: DomainMain = InitialState.domain;

export default function DomainReducer(
  state: DomainMain = initialState,
  action: any
) {
  switch (action.type) {
    case DomainActionTypes.Domain_Types_Success_Action:
      switch (action.payload.key) {
        case "station_type":
          return { ...state, station_type: action.payload.data };
        case "fms_type":
          return { ...state, fms_type: action.payload.data };
        case "installation_type":
          return { ...state, installation_type: action.payload.data };
        case "cable_type":
          return { ...state, cable_type: action.payload.data };
        case "cable_strength":
          return { ...state, cable_strength: action.payload.data };
        case "fibre_status":
          return { ...state, fibre_status: action.payload.data };
        case "fibre_type":
          return { ...state, fibre_type: action.payload.data };
        case "terminated_status":
          return { ...state, terminated_status: action.payload.data };
        case "adapter_type":
          return { ...state, adapter_type: action.payload.data };
        case "cable_make":
          return { ...state, cable_make: action.payload.data };
        case "transaction_type":
          return { ...state, transaction_type: action.payload.data };
        case "service_type":
          return { ...state, service_type: action.payload.data };
        default:
          return state;
      }
    default:
      return state;
  }
}
