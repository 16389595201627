import { connect } from "react-redux";
import React, { useEffect } from "react";
import { CableList } from "../../models/cableModel";
import { FMSLocationList } from "../../models/fmsLocationModel";
import { FMSList } from "../../models/fmsModel";
import { StoreState } from "../../models/reduxModels";
import { StationList } from "../../models/stationModel";
import { ZoneList } from "../../models/zoneModels";
import { showFmsWiseCableAction } from "../../Stores/actions/cableAction";
import { GetFMSLocationWiseAction } from "../../Stores/actions/fmsAction";
import { getStationLocation } from "../../Stores/actions/fmsLocationAction";
import {
  CableWiseReportAction,
  CableWiseReportClear,
  GetDamageFibreLoad,
  GetUtilzCable,
} from "../../Stores/actions/ReportAction";
import { getZoneStations } from "../../Stores/actions/stationAction";

import { CableReport, UtilizeCable } from "../../models/ReportModel";
import CableWiseReportView from "./CableWiseReportView";

function CableWiseReport({
  allzone,
  getZoneStations,
  allstation,
  getStationLocation,
  allfmslocation,
  GetFMSLocationWiseAction,
  allFMS,
  showFmsWiseCableAction,
  cable_list,
  GetDamageFibreLoad,
  CableWiseReportAction,
  cableReport,
  CableWiseReportClear,
}: CableWiseReportProps) {
  useEffect(() => {
    // CableWiseReport();
    GetDamageFibreLoad();
    CableWiseReportClear();
  }, []);
  const Filters = (props: any) => {
    CableWiseReportAction(props);
  };
  const clearFilter = () => {
    // CableWiseReport();
    GetDamageFibreLoad();
  };
  return (
    <CableWiseReportView
      allzone={allzone}
      getZoneStations={getZoneStations}
      allstation={allstation}
      getStationLocation={getStationLocation}
      allfmslocation={allfmslocation}
      GetFMSLocationWiseAction={(id: number) =>
        GetFMSLocationWiseAction({ location_id: +id })
      }
      allFMS={allFMS}
      showFmsWiseCableAction={showFmsWiseCableAction}
      cable_list={cable_list}
      clear={clearFilter}
      Filters={Filters}
      cableReport={cableReport}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    cableReport: state.report.cable_report,
    allzone: state.zone.zone_list,
    allstation: state.station.station_list,
    cable_list: state.cable.cable_list,
    allFMS: state.fms.fms_list,
    allfmslocation: state.fmslocation.fmslocation_list,
  };
};
const mapDispatchToProps = {
  getZoneStations,
  getStationLocation,
  GetFMSLocationWiseAction,
  showFmsWiseCableAction,
  CableWiseReportAction,
  GetDamageFibreLoad,
  CableWiseReportClear,
};
export default connect(mapStateToProps, mapDispatchToProps)(CableWiseReport);
interface CableWiseReportProps {
  allzone: ZoneList[];
  getZoneStations?: any;
  allstation: StationList[];
  getStationLocation?: any;
  allfmslocation: FMSLocationList[];
  GetFMSLocationWiseAction?: any;
  allFMS: FMSList[];
  showFmsWiseCableAction?: any;
  cable_list: any[];
  GetDamageFibreLoad?: any;
  CableWiseReportAction?: any;
  cableReport: CableReport[];
  CableWiseReportClear?: any;
}
