import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StationList } from "../../../models/stationModel";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { FibreMater } from "../../../models/cableMasterModel";
import CreateIcon from "@mui/icons-material/Create";
import "./FibreMainView.css";
import { DeleteButton } from "../../../components/Button";
function FibreMainView({
  allfibre,
  Delete,
  FilterData,
  filters,
}: FibreMasterViewProps) {
  const history = useHistory();
  const columns: any[] = [
    // "Fibre Code",
    "Fibre Color",
    "Fibre Type",
    "Action",
  ];
  const [open, setOpen] = useState<boolean>(false);
  const [id, SetId] = useState<number>(-1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState<FibreMater[]>([]);
  const [searched, setSearched] = useState<string>("");
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const theme = useTheme();
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  const matchesmdTheme = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslgTheme = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    if (matchesmdTheme) {
      setIsLargeScreen(false);
    } else if (matcheslgTheme) {
      setIsLargeScreen(true);
    }
  }, [matchesmdTheme, matcheslgTheme]);
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!allfibre
      ? allfibre.filter((row: FibreMater) => {
          return (
            row.fibre_code.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row.fibre_colour
              .toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row.fibre_type.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  useEffect(() => {
    if (!!allfibre) {
      cancelSearch();
    }
  }, [allfibre]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const del = () => {
    setOpen(false);
    Delete(id);
  };
  const styles = {
    marginRight: "50px",
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Fibre?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            No
          </Button>
          <Button onClick={() => del()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          margin={1}
        >
          <Grid item xs={6}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer sx={{ maxHeight: 480 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell align="left" key={column}>
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.fibre_id}
                      >
                        {/* <StyledTableCell align="left" key={columns[0]}>
                          {row.fibre_code}
                        </StyledTableCell> */}
                        <StyledTableCell align="left" key={columns[1]}>
                          <Grid container gap={1} direction={"row"}>
                            <Box
                              style={{
                                border: "1px solid #000",
                                // boxShadow: '0 0 6px' ,
                                height: "20px",
                                width: "20px",
                                backgroundColor: row.fibre_colour_code,
                              }}
                            ></Box>
                            {row.fibre_colour}
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[2]}>
                          {row.fibre_type}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[3]}>
                          {!isLargeScreen ? (
                            <Tooltip title="Edit" arrow>
                              <EditIcon
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  history.push(
                                    `/cable-master/fibre-edit/${row.fibre_id}`
                                  )
                                }
                              />
                            </Tooltip>
                          ) : (
                            <Button
                              onClick={() =>
                                history.push(
                                  `/cable-master/fibre-edit/${row.fibre_id}`
                                )
                              }
                              variant="contained"
                              color="primary"
                              size="small"
                              startIcon={<EditIcon />}
                              style={{ color: "#fff" }}
                            >
                              Edit
                            </Button>
                          )}

                          {/* <button className="edit"
                             onClick={() =>
                              history.push(
                                `/cable-master/fibre-edit/${row.fibre_id}`
                              )
                            }>
                              <CreateIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>
                                <span className="level">Edit</span>
                            
                          </button> */}

                          {/* <button className="delete" onClick={() => (
                              SetId(row.fibre_id), setOpen(true)
                            )}>
                          <DeleteIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>
                           
                            <span className="level">Delete</span>
                              
                          </button> */}

                          {!isLargeScreen ? (
                            <Tooltip title="Delete" arrow>
                              <DeleteIcon
                                color="error"
                                sx={{ cursor: "pointer" }}
                                onClick={() => (
                                  SetId(row.fibre_id), setOpen(true)
                                )}
                              />
                            </Tooltip>
                          ) : (
                            <DeleteButton
                              onClick={() => (
                                SetId(row.fibre_id), setOpen(true)
                              )}
                              style={{ marginLeft: 10 }}
                              variant="contained"
                              color="error"
                              size="small"
                              startIcon={<DeleteIcon />}
                            >
                              Delete
                            </DeleteButton>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={styles}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={allfibre.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Fab
          sx={fabStyle}
          aria-label={"Add"}
          color={"primary"}
          onClick={() => history.push("/cable-master/fibre-add")}
          style={{ marginBottom: 15 }}
        >
          {<AddIcon style={{ color: "#ffff" }} />}
        </Fab>
      </Paper>
    </>
  );
}

export default FibreMainView;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0a3648",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 400,
    fontSize: 17,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const fabStyle = {
  position: "absolute",
  bottom: 40,
  right: 16,
};

interface FibreMasterViewProps {
  allfibre: FibreMater[];
  Delete?: any;
  FilterData?: any;
  filters?: number;
}
