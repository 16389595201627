import {
  CableDetail,
  CableDetails,
  CableList,
  fibrebycablePayload,
} from "../../models/cableModel";
import {
  CableDelete,
  CableDetailCreate,
  CableDetailGetById,
  CableDetailService,
  CableGetAll,
  CableUpdate,
  CableUpdateService,
  FMSPortGet,
  FMSPortMapping,
  ShowFMSWiseCable,
  FibreCreateLoad,
  fibrebycablefms,
  GetAvailablePort,
  getAllFMSbyStationIdService,
  getFdbByFms,
  FDBFMSFab,
  getAllFDBByFMS
} from "../../Services/Services";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { UserLogoutSuccess } from "./userAction";
import { GetAllFMS } from "../../Services/Services";
import { FMSList } from "../../models/fmsModel";

export enum CableActionTypes {
  Get_All_Cable_Success_Action = "[CABLE] Get All Cable Success Action",
  Cable_Create_Success_Action = "[CABLE] Cable Create Success Action",
  Cable_Detail_Get_By_Id_Load_Success_Action = "[CABLE] Cable Detail Get By Id Load Success Action",
  Cable_Update_Success_Action = "[CABLE] Cable Update Success Action",
  Cable_Delete_Success_Action = "[CABLE] Cable Delete Success Action",
  Show_FMS_Wise_Cable = "[CABLE] Show_FMS_Wise_Cable",
  Collect_FMS_Record_for_filter = "[CABLE] Collect_FMS_Record_for_filter",

  Cable_Detail_Load_Success_Action = "[CABLE] Cable Detail Load Success Action",
  Cable_Service_Update_Success_Action = "[CABLE] Cable Service Update Success Action",
  Cable_Detail_Remove_Success_Action="[CABLE] Cable_Detail_Remove_Success_Action",

  Fms_Port_Detail_Load_Success_Action = "[CABLE] Fms Port Detail Load Success Action",

  FMS_Port_Update_Success_Action = "[CABLE] FMS Port Update Success Action",
  Cable_color_get_success = "[Cable] Cable_color_get_success",
  Fibrebycablefms_get_success = "[Cable] Fibrebycablefms_get_success",
  Get_Available_Port_Success_Action = "[Cable] Get Available Port Success Action",
  Get_ALL_FMS_BY_STATION_ID_ACTION = "[Cable]  Get_ALL_SOURCE_FMS_BY_STATION_ID_ACTION",
  Get_All_Cable_By_FMS = "[Cable]  Get_All_Cable_By_FMS",

  Get_Fdb_By_Fms_Success = "[CABLE] Get Fdb By Fms Success",
  Get_Fdb2_By_Fms_Success = "[CABLE] Get Fdb2 By Fms Success",
  Get_Fdb4_By_Fms_Success = "[CABLE] Get Fdb4 By Fms Success",
  Get_Fdb_Fibre_Success = "[CABLE] Get Fdb Fibre Success",
  
}
// Get All Cables action
export const GetAllCableAction = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading Fibre. Please Wait...",
      })
    );
    return Promise.all([CableGetAll(), GetAllFMS()])
      .then(async (response) => {
        if (response[0].status != 200) {
          dispatch(ApiCallErrorAction(response[0].data));
        } else if (response[1].status != 200) {
          dispatch(ApiCallErrorAction(response[1].data));
        } else {
          dispatch(
            GetAllCableDetailSuccessAction({
              cable_list: response[0].data,
              fms_list: response[1].data,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllCableDetailSuccessAction = (data: any) => {
  return { type: CableActionTypes.Get_All_Cable_Success_Action, payload: data };
};

export const CableCreateAction = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Cable. Please Wait...",
      })
    );
    return CableDetailCreate(data.payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(CableCreateSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CableCreateSuccess = (data: CableDetails) => {
  return { type: CableActionTypes.Cable_Create_Success_Action, payload: data };
};

export const CableDetailGetByIdAction = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Cable. Please Wait...",
      })
    );
    return CableDetailGetById(data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(CableDetailGetByIdSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CableDetailGetByIdSuccessAction = (data: any) => {
  return {
    type: CableActionTypes.Cable_Detail_Get_By_Id_Load_Success_Action,
    payload: data,
  };
};
export const CableUpdateAction = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Cable. Please Wait...",
      })
    );
    return CableUpdate(data.payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          await data.enqueueSnackbar("Cable Successfully Updated!", {
            variant: "success",
          });
          dispatch(CableUpdateeSuccess());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CableUpdateeSuccess = () => {
  return { type: CableActionTypes.Cable_Update_Success_Action };
};
export const DeleteCableAction = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Deleting Cable Please Wait...",
      })
    );
    return CableDelete(data.payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          await data.enqueueSnackbar("Deleted Successfully!", {
            variant: "success",
          });
          dispatch(DeleteCableSuccess());
          dispatch(GetAllCableAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const DeleteCableSuccess = () => {
  return { type: CableActionTypes.Cable_Delete_Success_Action };
};

// ////////   show fms wise cable/////
const showFmsWiseCable = (payload: any) => {
  return { type: CableActionTypes.Show_FMS_Wise_Cable, payload: payload };
};
export const showFmsWiseCableAction = (data: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "cable data loading Please Wait...",
      })
    );
    return Promise.all([GetAllFMS(), ShowFMSWiseCable(data)])
      .then(async (response) => {
        if (response[0].status != 200) {
          dispatch(ApiCallErrorAction(response[0].data));
        } else if (response[1].status != 200) {
          dispatch(ApiCallErrorAction(response[1].data));
        } else {
          dispatch(
            showFmsWiseCable({
              fms_list: response[0].data,
              cable_list: response[1].data,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

// show fms wise cable
export const FmsWiseCable = (data: FMSList | undefined) => {
  return {
    type: CableActionTypes.Collect_FMS_Record_for_filter,
    payload: data,
  };
};

export const CableDetailLoadAction = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading Cable. Please Wait...",
      })
    );
    return CableDetailService(data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(CableDetailLoadSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CableDetailLoadSuccessAction = (data: CableDetails) => {
  return {
    type: CableActionTypes.Cable_Detail_Load_Success_Action,
    payload: data,
  };
};

export const CableServiceUpdateAction = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Fibre Status. Please Wait...",
      })
    );
    return CableUpdateService(data.payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // data.history.replace("/cable");
          await data.enqueueSnackbar("Cable Successfully Updated!", {
            variant: "success",
          });
          dispatch(CableServiceUpdateeSuccess());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CableServiceUpdateeSuccess = () => {
  return { type: CableActionTypes.Cable_Service_Update_Success_Action };
};
export const FmsPortDetails = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading Fibre. Please Wait...",
      })
    );
    return FMSPortGet(data.payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(data));
        } else {
          dispatch(FMsPortSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};
export const FMsPortSuccessAction = (data: CableDetail) => {
  return {
    type: CableActionTypes.Fms_Port_Detail_Load_Success_Action,
    payload: data,
  };
};

export const FMSPortUpdateAction = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating FMS Port. Please Wait...",
      })
    );
    return FMSPortMapping(data.payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          await data.enqueueSnackbar("FMS Port Successfully Updated!", {
            variant: "success",
          });
          dispatch(
            FmsPortDetails({
              payload: {
                cable_detail_id: data.payload.cable_detail_id,
                fms_id: data.payload.fms_id,
              },
            })
          );
          dispatch(FMSPortUpdateSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const FMSPortUpdateSuccessAction = () => {
  return { type: CableActionTypes.FMS_Port_Update_Success_Action };
};

//   get all cable color    Actions

const getCableColorSuccess = (data: any) => {
  return { type: CableActionTypes.Cable_color_get_success, payload: data };
};

export const getAllCableColor = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating FMS Port. Please Wait...",
      })
    );
    return FibreCreateLoad()
      .then((response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(getCableColorSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

//  fibrebycablefms

const fibreByCableFmsSuccess = (data: any) => {
  return { type: CableActionTypes.Fibrebycablefms_get_success, payload: data };
};

export const fibreByCableFmsAction = (data: fibrebycablePayload) => {
  return (dispatch: any, getState: any) => {
    return fibrebycablefms(data)
      .then((response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(fibreByCableFmsSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};
export const getAllCaleColor = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating FMS Port. Please Wait...",
      })
    );
    return FibreCreateLoad()
      .then((response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(getCableColorSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAvailablePorts = (data: number, type: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Getting Port. Please Wait...",
      })
    );
    return GetAvailablePort(data, type)
      .then((response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(PortGettingSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};
const PortGettingSuccessAction = (data: any) => {
  return {
    type: CableActionTypes.Get_Available_Port_Success_Action,
    payload: data,
  };
};

// ////////////////////////////
const getAllSFMSByStationIDSuccess = (data: any) => {
  return {
    type: CableActionTypes.Get_ALL_FMS_BY_STATION_ID_ACTION,
    payload: data,
  };
};

export const getAllFMSbyStationIdAction = (data: number) => (
  dispatch: any,
  getState: any
) => {
  getAllFMSbyStationIdService(data)
    .then((response) => {
      if (response.status != 200) dispatch(ApiCallErrorAction(response.data));
      else dispatch(getAllSFMSByStationIDSuccess(response.data));
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Please Login again to continue.",
          })
        );
        dispatch(UserLogoutSuccess());
      } else if (error.response.status === 500) {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: error?.response?.data?.message,
          })
        );
      } else {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Error encountered please try again later",
          })
        );
      }
    });
};

// ////   simple station by fms

const showFmsWiseCable2 = (payload: any) => {
  return { type: CableActionTypes.Get_All_Cable_By_FMS, payload: payload };
};

export const showFmsWiseCableAction2 = (data: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "cable data loading Please Wait...",
      })
    );
    return ShowFMSWiseCable(data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(showFmsWiseCable2(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

const GetFDBByFMSSuccess = (payload: any) => {
  return { type: CableActionTypes.Get_Fdb_By_Fms_Success, payload: payload };
};

export const GetFDBByFMS = (data: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "cable data loading Please Wait...",
      })
    );
    return getAllFDBByFMS(data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetFDBByFMSSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

const GetFDB2ByFMSSuccess = (payload: any) => {
  return { type: CableActionTypes.Get_Fdb2_By_Fms_Success, payload: payload };
};

export const GetFDB2ByFMS = (data: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "cable data loading Please Wait...",
      })
    );
    return getAllFDBByFMS(data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetFDB2ByFMSSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetFDB4ByFMS = (data: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "FMS data loading Please Wait...",
      })
    );
    return getFdbByFms(data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetFDB4ByFMSSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};
const GetFDB4ByFMSSuccess = (payload: any) => {
  return { type: CableActionTypes.Get_Fdb4_By_Fms_Success, payload: payload };
};

export const GetFDBFibre = (data: number, type: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "FMS Fibre loading. Please Wait...",
      })
    );
    return FDBFMSFab(data, type)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetFDBFibreSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};
const GetFDBFibreSuccess = (payload: any) => {
  return { type: CableActionTypes.Get_Fdb_Fibre_Success, payload: payload };
};


export const removeCableDetailLoadAction=()=>(dispatch:any,getState:any)=>{
  dispatch({
    type:CableActionTypes.Cable_Detail_Remove_Success_Action
  })
}