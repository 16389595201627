import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { CableMater, ColorCode, TubeMater } from '../../../models/cableMasterModel'
import { StoreState } from '../../../models/reduxModels'
import { CableCreate, FibreCreateLoads, GetAllTube, GetCableDetails, UpdateCable } from '../../../Stores/actions/cableMasterActions'
import EditCableView from './EditCableView'

function EditCable({alltube,color,CableCreate,FibreCreateLoads,GetAllTube,GetCableDetails,cable_deetail,UpdateCable}: EditCableProps) {
    let { id }: any = useParams();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(()=> {
        FibreCreateLoads()
        GetAllTube()
        GetCableDetails(id)
    },[])
    const submitData=(payload: any)=> {
        UpdateCable({
            payload: { 
                cable_id: cable_deetail?.cable_id,
                cable_desc: payload.cable_desc,
                cable_strength: payload.cable_strength,
                cable_colour: cable_deetail?.cable_colour,
                created_at: cable_deetail?.created_at,
                created_by: cable_deetail?.created_by,
                updated_at: cable_deetail?.updated_at,
                updated_by: cable_deetail?.updated_by,
                cable_colour_code:  payload.cable_colour_code,
                no_of_tubes: cable_deetail?.no_of_tubes,
                cable_type:  payload.cable_type,
                // cable_name:  payload.cable_name,
                cable_make: payload.cable_make,
                cable_code: cable_deetail?.cable_code
             },
            history: history,
            enqueueSnackbar: enqueueSnackbar,
          });
    }
  return (
    <EditCableView submitData={submitData} cable_deetail={cable_deetail} color={color} alltube={alltube} />
  )
}
const mapStateToProps = (state: StoreState) => {
    return {
        color: state.cable_master.color_code,
        alltube: state.cable_master.tube_master,
        cable_deetail: state.cable_master.cable_detail
    };
  };
  const mapDispatchToProps = {
    FibreCreateLoads,
    GetAllTube,
    CableCreate,
    GetCableDetails,
    UpdateCable
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditCable);
  interface EditCableProps {
    FibreCreateLoads?: any;
    color: ColorCode[];
    GetAllTube?: any;
    alltube: TubeMater[];
    CableCreate?: any;
    GetCableDetails?: any;
    cable_deetail?:CableMater;
    UpdateCable?: any;
  }