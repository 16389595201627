import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { CableMater, CableMsterList } from '../../../models/cableMasterModel'
import { StoreState } from '../../../models/reduxModels'
import { DeleteCables, GetAllCableMaster, GetCableDetails} from '../../../Stores/actions/cableMasterActions'
import CableMainView from './CableMainView'

function CableMain({GetAllCableMaster,master_cable_list,GetCableDetails,cable_deetail,DeleteCables}: CableMainProps) {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(()=>{
    GetAllCableMaster()
  },[])
  const OpenRow=(id: number)=> {
    GetCableDetails(id)
  }
  const Delete=(id: number)=>{
    DeleteCables({payload: id,
      enqueueSnackbar: enqueueSnackbar
    })
  }
  return (
    <CableMainView Delete={Delete} allcable={master_cable_list} OpenRow={OpenRow} cable_deetail={cable_deetail} />
  )
}

const mapStateToProps = (state: StoreState) => {
  return {
    master_cable_list: state.cable_master.cable_master_list,
    cable_deetail: state.cable_master.cable_detail
  };
};
const mapDispatchToProps = {
  GetAllCableMaster,
  GetCableDetails,
  DeleteCables
};

export default connect(mapStateToProps, mapDispatchToProps)(CableMain);
interface CableMainProps {
  GetAllCableMaster?: any;
  master_cable_list: CableMsterList[];
  GetCableDetails?: any;
  cable_deetail?:CableMater;
  DeleteCables?: any;
}