import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import {
  showFmsWiseCableAction2,
  fibreByCableFmsAction,
  getAllFMSbyStationIdAction,
} from "../../../Stores/actions/cableAction";
import {
  CableDetails,
  CableList,
  fibrebycablefmsModel,
} from "../../../models/cableModel";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../components/Autocompletes";
import { FMSList } from "../../../models/fmsModel";

const ChooseCableModal = ({
  fmsId,
  open,
  onSubmit,
  onClose,
  showFmsWiseCableAction2,
  cable_list,
  fibreByCableFmsAction,
  fibrebycablefms,
  id,
  cable_details,
  getAllFMSbyStationIdAction,
  station,
  fms_list_by_station,
  fab_id,
}: ChooseCableModalProps) => {
  const [srcStationId, setSrcStationId] = useState<number | undefined>(
    undefined
  );
  const [destStationId, setdestStationId] = useState<number | undefined>(
    undefined
  );
  const [seletedCabDetailsId, setSelectedCabDetailsId] = useState<
    number | undefined
  >(undefined);
  const [existsSrcFMSId, setExistsSrcFMSId] = useState<number | undefined>(
    undefined
  );
  const [existsDestFMSId, setExistsDestFMSId] = useState<number | undefined>(
    undefined
  );

  const { control, register, handleSubmit, formState, setValue, getValues } =
    useForm();
  const { errors } = formState;
  useEffect(() => {
    open && getAllFMSbyStationIdAction(station);
  }, [open]);
  const showAllCableByFMS = (data: number) => {
    showFmsWiseCableAction2(data);
  };
  const getFibreByCableFms = (val: number) => {
    fibreByCableFmsAction({
      cable_detail_id: val,
      fms_id: +getValues("fms_id"),
    });
  };

  const onSubmits = (props: any) => {
    let fmsCode: string | undefined = fms_list_by_station.find(
      (fms) => fms.fms_id === +props.fms_id
    )?.fms_code;
    let cableShortCode: string | undefined = cable_list.find(
      (cab: CableList) => cab.cable_detail_id === +props.cable_detail_id
    )?.cable_short_code;
    let tubeColor: string | undefined =
      fibrebycablefms?.fibreInfoOfCableFms.find(
        (fibre: any) => fibre?.fibre_impl_id === +props.fibre_impl_id
      )?.tube_colour;
    let fibreColour: string | undefined =
      fibrebycablefms?.fibreInfoOfCableFms.find(
        (fibre: any) => fibre?.fibre_impl_id === +props.fibre_impl_id
      )?.fibre_colour;
    onSubmit({
      label: `${tubeColor}-${fibreColour}-${fmsCode}-${cableShortCode}`,
      impl_id: +props.fibre_impl_id,
    });
    onClose(false);
  };
  useEffect(() => {
    setTimeout(() => {
      // !!fmsId && open && setValue("fms_id", fmsId);
      !!fmsId && open && showAllCableByFMS(+fmsId);
      // !!fmsId && open && setValue("cable_detail_id", id);
      !!fmsId && open && !!id && getFibreByCableFms(id);
    }, 200);
    !open && setValue("fms_id", "");
    !open && setValue("cable_detail_id", "");
  }, [fmsId, open]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => onClose(false)}
      maxWidth={"md"}
    >
      <DialogTitle
        style={{
          backgroundColor: "#00AAEE",
          textAlign: "center",
          color: "#fff",
        }}
      >
        Select Fibre
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmits)}>
          <Grid
            container
            justifyContent="center"
            marginTop={2}
            alignItems={"center"}
          >
            <Grid item xs={3}>
              <Typography sx={{ fontWeight: 550 }}>Select FMS</Typography>
            </Grid>
            <Grid item xs={1}>
              :
            </Grid>
            <Grid item xs={7}>
              <Controller
                control={control}
                name={"fms_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={
                      !!fms_list_by_station &&
                      fms_list_by_station.filter((m) => m.fms_type != "FDB")
                    }
                    keys={"fms_id"}
                    extras={"location_name"}
                    label={"fms_code"}
                    onBlur={onBlur}
                    onChange={(val: number) => (
                      onChange(val), showAllCableByFMS(+val)
                    )}
                    value={value}
                    errors={errors["fms_id"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            marginTop={2}
            alignItems={"center"}
          >
            <Grid item xs={3}>
              <Typography style={{ fontWeight: 550 }}>Cable</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Controller
                control={control}
                name={"cable_detail_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={cable_list}
                    keys={"cable_detail_id"}
                    label={"cable_short_code"}
                    onBlur={onBlur}
                    onChange={(val: number) => (
                      onChange(val), getFibreByCableFms(val)
                    )}
                    value={value}
                    errors={errors["cable_detail_id"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            marginTop={2}
            alignItems={"center"}
          >
            <Grid item xs={3}>
              <Typography style={{ fontWeight: 550 }}>Fibre</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Controller
                control={control}
                name={"fibre_impl_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    style={{ width: "100%", margin: "5px" }}
                    type="text"
                    variant="outlined"
                    size="small"
                    select
                    onBlur={onBlur}
                    onChange={(val: any) => onChange(val)}
                    value={value}
                    name="fibre_impl_id"
                    defaultValue={""}
                    error={!!errors["fibre_impl_id"]}
                    label={"Fibre"}
                  >
                    {!!fibrebycablefms &&
                      fibrebycablefms?.fibreInfoOfCableFms?.map(
                        (item: any, index: number) =>
                          item.fibre_impl_id != fab_id &&
                          !!item.tube_colour_code &&
                          !!item.fibre_colour_code && (
                            <MenuItem key={index} value={item.fibre_impl_id}>
                              <Grid
                                container
                                gap={1}
                                direction={"row"}
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <Box
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    backgroundColor: item.tube_colour_code,
                                  }}
                                ></Box>
                                <Typography>{item.tube_colour}</Typography>
                                {item.tube_colour && item.fibre_colour && (
                                  <Box
                                    sx={{
                                      width: "15px",
                                      height: "1px",
                                      backgroundColor: "#000",
                                    }}
                                  ></Box>
                                )}
                                <Box
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    backgroundColor: item.fibre_colour_code,
                                  }}
                                ></Box>
                                <Typography>{item.fibre_colour}</Typography>
                              </Grid>
                            </MenuItem>
                          )
                      )}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            marginTop={2}
            alignItems={"center"}
          >
            <Grid item>
              <Button
                color={"error"}
                onClick={() => onClose(false)}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{
                  marginRight: 10,
                }}
              >
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSubmit(onSubmits)}
                color={"success"}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{
                  marginRight: 10,
                }}
              >
                Choose
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    cable_list: state.cable.cable_list,
    fibrebycablefms: state.cable.fibrebycablefms,
    fms_list_by_station: state.cable.fms_list_by_station,
    cable_details: state.cable.cable_det,
  };
};
const mapDispatchToProps = {
  showFmsWiseCableAction2,
  fibreByCableFmsAction,
  getAllFMSbyStationIdAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(ChooseCableModal);

export interface ChooseCableModalProps {
  fmsId?: number;
  open: boolean;
  onClose: any;
  onSubmit: any;
  showFmsWiseCableAction2?: any;
  cable_list: CableList[] | [];
  fibreByCableFmsAction?: any;
  fibrebycablefms?: any;
  id?: number;
  getAllFMSbyStationIdAction?: any;
  cable_details?: CableDetails;
  fab_id?: number;
  station?: number;
  fms_list_by_station: FMSList[];
}

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     width:"70vw",
//   },
//   '& .MuiDialogActions-root': {
//     width:"70vw",
//   },
// }));
