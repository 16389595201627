import { StoreState } from "../../models/reduxModels";

const InitialState: StoreState = {
  loading: {
    count: 0,
    message: "",
  },
  error: {
    error: undefined,
  },
  user: {
    userDetails: undefined,
    // {
    //   email: "example@email.com",
    //   roleType: "ADMIN",
    //   userName: "Rabi Thakur",
    //   contact: "8745968874",
    //   accessToken: "sdff",
    //   userType: "sdad",
    //   userid: 2,
    // },
  },
  zone: {
    zone_list: [],
    single_zone: undefined,
  },
  service: {
    service_list: [],
    single_Service: undefined,
  },
  fmslocation: {
    fmslocation_list: [],
    single_fmslocation: undefined,
    station_list: [],
    has_filter: undefined,
  },
  station: {
    station_list: [],
    single_station: undefined,
    has_filter: undefined,
    station_list2: [],
  },
  cable_master: {
    fibre_master: [],
    color_code: [],
    tube_master: [],
    cable_master_list: [],
    cable_detail: undefined,
    tube_detail: undefined,
  },
  fms: {
    fms_list: [],
    single_fms: undefined,
    station_list: [],
    has_filter: undefined,
    fms_location_list: [],
    zone_list: [],
    station_by_zone: [],
    location_by_station: [],
    fdb_list: [],
    joint_fms: [],
    FmsSchematic: undefined,
  },

  cable: {
    cable_list: [],
    single_cable: undefined,
    has_filter: undefined,
    fms_list: [],
    cable_detail: undefined,
    fms_port_detail: undefined,
    cable_det: undefined,
    edit_flag: false,
    fibrebycablefms: [],
    Port: undefined,
    fms_list_by_station: [],
    fdb_list: [],
    fdb_list2: [],
    fdb_list4: [],
    patch_fibre: [],
  },
  cable_tree: {
    CableTree: undefined,
  },
  domain: {
    station_type: [],
    fms_type: [],
    installation_type: [],
    cable_type: [],
    cable_strength: [],
    fibre_status: [],
    fibre_type: [],
    terminated_status: [],
    adapter_type: [],
    cable_make: [],
    transaction_type: [],
    service_type: [],
  },
  cableFibre: {
    source: [],
    dest: [],
  },
  connectivity: {
    zone_list: [],
    station_list: [],
    location_list: [],
    fms_list: [],
    cable_list: [],
    fibre_list: [],
    service_list: [],
    trace_fibre_list: [],
  },
  service_occupency: {
    service_occupency_list: [],
  },
  report: {
    report_data: [],
    assets: undefined,
    damage_fibre: [],
    utilz_cable: [],
    cable_by_service: [],
    cable_report: [],
    end_to_end_report: undefined,
    fibrebycablefms: [],
    cable_report_by_service: undefined,
    BulkUploadReport: [],
  },
  cable_color: [],
};

export default InitialState;
