import {
  BulkUploadReport,
  CableDetailsbyService,
} from "../../models/ReportModel";
import {
  BulkFileUpload,
  BulkReportList,
  CableByServiceService,
  CableGetAll,
  CableWiseReportService,
  DamageFibreService,
  EndToEndFibreTraceService,
  getAllAssetVal,
  GetAllFMS,
  GetAllFMSLocation,
  GetAllStation,
  GetAllZone,
  GetFibreByFmsCableId,
  GetLocationByStationId,
  GetReportService,
  GetStationByZoneId,
  ServiceWiseCableDetailService,
  UtilzCableService,
} from "../../Services/Services";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { GetAllCableAction } from "./cableAction";
import { GetAllStations } from "./stationAction";
import { UserLogoutSuccess } from "./userAction";

export enum ReportActionTypes {
  Get_Report_data_success = "[Report] Get_Report_data_success",
  Get_Report_data_Clear = "[Report] Get_Report_data_success",
  Get_Asset_Data_Success = "[Report] Get_Asset_Data_Success",
  Get_Damage_Fibre_Get_Success = "[Report] Get Damage Fibre Get Success",
  Get_Damage_Fibre_Load_Success = "[Report] Get Damage Fibre Load Success",
  Get_Utilz_Cable_Get_Success = "[Report] Get Utilz Cable Get Success",
  Get_Utilz_Cable_Load_Success = "[Report] Get Utilz Cable Load Success",
  Get_Cable_By_Service_Success = "[Report] Get Cable By Service Success",
  Get_Report_By_Cable_Success = "[Report] Get Report By Cable Success",
  End_To_End_Fibre_Trace_Success = "[Report] End To End Fibre Trace Success",
  Get_Fibre_By_Cable_Fms = "[Report] Get Fibre By Cable Fms",
  End_To_End_Clear_Action = "[Report] End to End Clear Action",
  Cable_By_Service_Clear_Action = "[Report] Cable By Service Clear Action",
  Cable_Wise_Report_Clear_Action = "[Report] Cable Wise Report Clear Action",
  End_To_End_Get_Station_Action = "[Report] End To End Get Station",
  Service_Wise_Cable_Detail_Load_Success_Action = "[Report] Service Wise Cable Detail Load Success Action",
  Bulk_Upload_Report_Load_Success_Action = "[Report] Bulk Upload Report Load Success Action",
  Bulk_File_Upload_Success_Action = "[Report] Bulk File Upload Success Action",
  Bulk_Upload_Report_Load2_Success_Action = "[Report] Bulk Upload Report Load 2 Success Action",
}

export const GetAssetData = (req: any) => (dispatch: any, getState: any) => {
  dispatch(
    BeginApiCallAction({
      count: 1,
      message: "",
    })
  );
  return getAllAssetVal(req)
    .then((resp) => {
      if (resp.status !== 200) {
        dispatch(ApiCallErrorAction(resp.data));
      } else {
        dispatch({
          type: ReportActionTypes.Get_Asset_Data_Success,
          payload: resp.data,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        // showToast("Please Login again to continue.");
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Please Login again to continue.",
          })
        );
        dispatch(UserLogoutSuccess());
      } else if (error.response.status === 500) {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: error?.response?.data?.message,
          })
        );
      } else {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Error encountered please try again later",
          })
        );
      }
    });
};

export const GetReportData =
  (
    transaction_type: string,
    transaction_id: number,
    event_name: string | null,
    start_date: any,
    end_date: any
  ) =>
  (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "",
      })
    );
    return GetReportService(
      transaction_type,
      transaction_id,
      event_name,
      start_date,
      end_date
    )
      .then((resp) => {
        if (resp.status != 200) {
          dispatch(ApiCallErrorAction(resp.data));
        } else {
          dispatch({
            type: ReportActionTypes.Get_Report_data_success,
            payload: resp.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // showToast("Please Login again to continue.");
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const GetDamageFibre = (req: any) => (dispatch: any, getState: any) => {
  dispatch(
    BeginApiCallAction({
      count: 1,
      message: "",
    })
  );
  return DamageFibreService(req)
    .then((resp) => {
      if (resp.status !== 200) {
        dispatch(ApiCallErrorAction(resp.data));
      } else {
        dispatch({
          type: ReportActionTypes.Get_Damage_Fibre_Get_Success,
          payload: resp.data,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        // showToast("Please Login again to continue.");
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Please Login again to continue.",
          })
        );
        dispatch(UserLogoutSuccess());
      } else if (error.response.status === 500) {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: error?.response?.data?.message,
          })
        );
      } else {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Error encountered please try again later",
          })
        );
      }
    });
};

export const GetDamageFibreLoad =
  (req: any) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "",
      })
    );
    return Promise.all([
      GetAllZone(),
      GetAllStation(),
      GetAllFMSLocation(),
      GetAllFMS(),
      CableGetAll(),
    ])
      .then((resp: any) => {
        if (resp[0].status !== 200) {
          dispatch(ApiCallErrorAction(resp[0].data));
        } else if (resp[1].status !== 200) {
          dispatch(ApiCallErrorAction(resp[1].data));
        } else if (resp[2].status !== 200) {
          dispatch(ApiCallErrorAction(resp[2].data));
        } else if (resp[3].status !== 200) {
          dispatch(ApiCallErrorAction(resp[3].data));
        } else if (resp[4].status !== 200) {
          dispatch(ApiCallErrorAction(resp[4].data));
        } else {
          dispatch({
            type: ReportActionTypes.Get_Damage_Fibre_Load_Success,
            payload: resp,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // showToast("Please Login again to continue.");
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const GetUtilzCable = (req: any) => (dispatch: any, getState: any) => {
  dispatch(
    BeginApiCallAction({
      count: 1,
      message: "",
    })
  );
  return UtilzCableService(req)
    .then((resp) => {
      if (resp.status !== 200) {
        dispatch(ApiCallErrorAction(resp.data));
      } else {
        dispatch({
          type: ReportActionTypes.Get_Utilz_Cable_Get_Success,
          payload: resp.data,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        // showToast("Please Login again to continue.");
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Please Login again to continue.",
          })
        );
        dispatch(UserLogoutSuccess());
      } else if (error.response.status === 500) {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: error?.response?.data?.message,
          })
        );
      } else {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Error encountered please try again later",
          })
        );
      }
    });
};
export const GetCableByService =
  (req: any) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "",
      })
    );
    return CableByServiceService(req)
      .then((resp) => {
        if (resp.status !== 200) {
          dispatch(ApiCallErrorAction(resp.data));
        } else {
          dispatch({
            type: ReportActionTypes.Get_Cable_By_Service_Success,
            payload: resp.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // showToast("Please Login again to continue.");
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
export const GetCableByServiceClear = () => {
  return { type: ReportActionTypes.Cable_By_Service_Clear_Action };
};
export const CableWiseReportClear = () => {
  return { type: ReportActionTypes.Cable_Wise_Report_Clear_Action };
};

export const CableWiseReportAction =
  (req: any) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "",
      })
    );
    return CableWiseReportService(req)
      .then((resp) => {
        if (resp.status !== 200) {
          dispatch(ApiCallErrorAction(resp.data));
        } else {
          dispatch({
            type: ReportActionTypes.Get_Report_By_Cable_Success,
            payload: resp.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // showToast("Please Login again to continue.");
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
export const EndToEndFibreTraceAction =
  (req: any) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "",
      })
    );
    return EndToEndFibreTraceService(req)
      .then((resp) => {
        if (resp.status !== 200) {
          dispatch(ApiCallErrorAction(resp.data));
        } else {
          dispatch({
            type: ReportActionTypes.End_To_End_Fibre_Trace_Success,
            payload: resp.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // showToast("Please Login again to continue.");
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const ReportClearAction = () => {
  return { type: ReportActionTypes.Get_Report_data_Clear };
};

export const GetFibreByCableFms =
  (req: any) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "",
      })
    );
    return GetFibreByFmsCableId(req)
      .then((resp) => {
        if (resp.status !== 200) {
          dispatch(ApiCallErrorAction(resp.data));
        } else {
          dispatch({
            type: ReportActionTypes.Get_Fibre_By_Cable_Fms,
            payload: resp.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // showToast("Please Login again to continue.");
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const EndtoEndClearAction = () => {
  return { type: ReportActionTypes.End_To_End_Clear_Action };
};

export const EndToEndGetStation =
  (req: any, key: any) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "",
      })
    );
    return GetStationByZoneId(req)
      .then((resp) => {
        if (resp.status !== 200) {
          dispatch(ApiCallErrorAction(resp.data));
        } else {
          dispatch({
            type: ReportActionTypes.End_To_End_Get_Station_Action,
            payload: { data: resp.data, key: key },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // showToast("Please Login again to continue.");
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const ServiceWiseCableDetailLoadAction = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading Cable. Please Wait...",
      })
    );
    return ServiceWiseCableDetailService(data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(ServiceWiseCableDetailLoadSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const ServiceWiseCableDetailLoadSuccessAction = (
  data: CableDetailsbyService
) => {
  return {
    type: ReportActionTypes.Service_Wise_Cable_Detail_Load_Success_Action,
    payload: data,
  };
};

export const BulkReportListLoadAction = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading BulkUpload Report. Please Wait...",
      })
    );
    return BulkReportList()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(BulkUploadReportLoadSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const BulkUploadReportLoadSuccessAction = (data: BulkUploadReport[]) => {
  return {
    type: ReportActionTypes.Bulk_Upload_Report_Load_Success_Action,
    payload: data,
  };
};

export const BulkFileUploadAction = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Uploading File. Please Wait...",
      })
    );
    return BulkFileUpload(data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(BulkReportListLoadAction());
          dispatch(BulkFileUploadSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const BulkFileUploadSuccessAction = () => {
  return {
    type: ReportActionTypes.Bulk_File_Upload_Success_Action,
  };
};

export const BulkReportList2LoadAction = () => {
  return (dispatch: any, getState: any) => {
    return BulkReportList()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(BulkUploadReportLoad2SuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const BulkUploadReportLoad2SuccessAction = (
  data: BulkUploadReport[]
) => {
  return {
    type: ReportActionTypes.Bulk_Upload_Report_Load2_Success_Action,
    payload: data,
  };
};
