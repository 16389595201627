import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { StoreState } from "../../../models/reduxModels";
import { StationList } from "../../../models/stationModel";
import { UserDetails } from "../../../models/userModels";
import { ZoneList } from "../../../models/zoneModels";
import { FiltreUpdate2SuccessAction } from "../../../Stores/actions/fmsLocationAction";
import {
  DeleteStations,
  FiltreUpdateSuccessAction,
  GetAllStations,
  getZoneStations,
} from "../../../Stores/actions/stationAction";
import StationMainView from "./StationMainView";

const StationMain = ({
  allstation,
  GetAllStations,
  allzone,
  DeleteStations,
  getZoneStations,
  filter,
  FiltreUpdate2SuccessAction,
  user,
}: StationProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [filters, SetFilter] = useState<number>(0);
  useEffect(() => {
    if (!!filter) {
      SetFilter(filter.zone_id);
      getZoneStations(+filter.zone_id);
    } else {
      GetAllStations();
      SetFilter(0);
    }
    FiltreUpdate2SuccessAction(undefined);
  }, []);
  const Delete = (data: number) => {
    DeleteStations({
      payload: data,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  const FilterData = (data: number) => {
    if (data != null) {
      const zone = allzone.find((m) => m.zone_id == +data);
      SetFilter(data);
      FiltreUpdate2SuccessAction(zone);
      getZoneStations(+data);
    }
  };

  const gotoStation = (data: StationList) => {
    FiltreUpdate2SuccessAction(data);
    history.push(`/fms-location/fmslocation-main`);
  };
  return (
    <StationMainView
      allstation={allstation}
      allzone={[
        {
          zone_id: 0,
          zone_name: "All Zone",
          zone_code: "ABC",
          zone_desc: "",
        },
        ...allzone,
      ]}
      Delete={Delete}
      user={user}
      FilterData={FilterData}
      filters={filters}
      gotoStation={gotoStation}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    allstation: state.station.station_list,
    allzone: state.zone.zone_list,
    filter: state.station.has_filter,
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  GetAllStations,
  DeleteStations,
  getZoneStations,
  FiltreUpdate2SuccessAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(StationMain);
interface StationProps {
  GetAllStations?: any;
  allstation: StationList[];
  allzone: ZoneList[];
  DeleteStations?: any;
  getZoneStations?: any;
  filter?: ZoneList | undefined;
  FiltreUpdate2SuccessAction?: any;
  user?: UserDetails;
}
