// import * as React from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from '@mui/material/Typography';
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DomainList } from "../../../models/domainModel";
import ChooseCableModal from "./ChooseCableModal";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import { UserDetails } from "../../../models/userModels";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Fab,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Switch,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import "./CableAddView.css";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Autocompletes from "../../../components/Autocompletes";
import {
  CableDetails,
  CableList,
  PatchFibre,
} from "../../../models/cableModel";
import FmsFindpopOver from "../FMsFindPopover";
import SearchIcon from "@mui/icons-material/Search";
import CableTreeDialog from "../../../components/CableTreeDialog";
import FibreDetailCard from "./FibreDetailCard";
import {
  CableDuplicateCheck,
  GetCableTypetemplate,
} from "../../../Services/Services";
import CheckIcon from "@mui/icons-material/Check";
import { FMSList } from "../../../models/fmsModel";
import { useSnackbar } from "notistack";
function CableAddView({
  submitData,
  cableTypes,
  fibreStatus,
  terminatedStatus,
  fibreTypes,
  cableMake,
  adapterType,
  allservice,
  cableColor,
  cable_det,
  edit_flag,
  cableUpdate,
  UpdateFibre,
  selectedFMS,
  FDB1,
  FDB,
  getFMS,
  FDB3,
  GetFibre,
  patch_fibre,
  userDetails,
}: CableAddViewProps) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [FmsA, SetFMSA] = useState<any>();
  const [FmsB, SetFMSB] = useState<any>();
  const [isCableTreeOpen, setCableTreeOpen] = useState<boolean>(false);
  const [cableDetails, setCableDetails] = useState<any>();
  // const [fmsAdup, setfmsAdup] = useState<string>("");
  // const [fmsBdup, setfmsBdup] = useState<string>("");
  // const [shortCode, setShortCode] = useState<string>("");
  // const [ModalOpen, setModalOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [buttomData, SetbuttomData] = useState<boolean>(false);
  const [update, SetUpdate] = useState<boolean>(false);
  const [dublicate, SetDublicate] = useState<boolean>(false);
  const [isBothEndNotSame, setIsBothEndNotSame] = useState<boolean>(false);
  // const [otherFibre, setOtherFibre] = useState<number | undefined>();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm();
  const generateCabShortCode = () => {
    let code: any = "";
    if (!!getValues("cable_type"))
      code = getValues("cable_type").split("F")[0] + "F";
    if (!!getValues("second_cable_type")) {
      code = code + "-" + getValues("second_cable_type").split("F")[0] + "F";
    }
    if (!!FmsA) {
      code = code + "-" + FmsA.station_code;
    }
    if (!!FmsB) {
      code = code + "-" + FmsB.station_code;
    }
    setValue("cable_short_code_readable", code);
  };
  useEffect(() => {
    generateCabShortCode();
  }, [FmsA, FmsB]);
  const handleBothEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!!cable_det) return;
    setIsBothEndNotSame(event.target.checked);
    setValue("second_cable_type", "");
    setValue("second_cable_colour", "");
    setValue("second_cable_make", "");
    setValue("second_no_of_fibre", "");
    setValue("second_cable_fibre_type", "");
  };

  const { errors } = formState;

  const onSubmit = (props: any) => {
    if (dublicate) {
      let newShortCode =
        props.cable_short_code_readable + "-" + props.cable_short_code_manual;
      if (!!cable_det && !!update && !!cable_det.cableInfo) {
        SetUpdate(false);
        cableUpdate({
          ...cable_det.cableInfo,
          cable_strength: +props.no_of_fibre,
          cable_short_code: newShortCode,
          asymmetric_flag: isBothEndNotSame ? 1 : 0,
          cable_actual_length: +props.cable_actual_length,
          source_fms:
            !!FmsA && !!FmsA.fms_id
              ? FmsA.fms_id
              : cable_det.cableInfo.source_fms,
          destination_fms:
            !!FmsB && !!FmsB.fms_id
              ? FmsB.fms_id
              : cable_det.cableInfo.destination_fms,
          cable_colour: props.cable_color,
          cable_make: props.cable_make,
          cable_type: props.cable_type,
          cost_per_unit: props.cost_per_unit,
          second_cable_type: isBothEndNotSame ? props.second_cable_type : null,
          second_cable_colour: isBothEndNotSame
            ? props.second_cable_colour
            : null,
          second_cable_make: isBothEndNotSame ? props.second_cable_make : null,
          second_cable_strength: isBothEndNotSame
            ? props.second_no_of_fibre
            : null,
          cable_fibre_type: props.cable_fibre_type,
          second_cable_fibre_type: isBothEndNotSame
            ? props.second_cable_fibre_type
            : null,
        });
      } else {
        submitData({
          cable_short_code: newShortCode,
          cable_actual_length: +props.cable_actual_length,
          source_fms: FmsA.fms_id,
          destination_fms: FmsB.fms_id,
          cable_strength: +props.no_of_fibre,
          cable_colour: props.cable_color,
          cable_make: props.cable_make,
          cable_type: props.cable_type,
          asymmetric_flag: isBothEndNotSame ? 1 : 0,
          cost_per_unit: props.cost_per_unit,
          second_cable_type: isBothEndNotSame ? props.second_cable_type : null,
          second_cable_colour: isBothEndNotSame
            ? props.second_cable_colour
            : null,
          second_cable_make: isBothEndNotSame ? props.second_cable_make : null,
          second_cable_strength: isBothEndNotSame
            ? props.second_no_of_fibre
            : null,
          cable_fibre_type: props.cable_fibre_type,
          second_cable_fibre_type: isBothEndNotSame
            ? props.second_cable_fibre_type
            : null,
          default_fibre_status_des: props.default_fibre_status_des,
          default_fibre_status_sor: props.default_fibre_status_sor,
        });
      }
    } else {
      SetDublicate(false);
      setError(
        "cable_short_code_manual",
        { type: "focus" },
        { shouldFocus: true }
      );
    }
  };
  const handleCableDetailsDialog = (cab_id: any) => {
    try {
      GetCableTypetemplate(cab_id)
        .then((response) => {
          if (!!response && response.status == 200 && !!response.data) {
            setCableDetails({
              ...JSON.parse(response.data.cable_template),
              cable_type: cab_id,
            });
            setCableTreeOpen(true);
          }
        })
        .catch((err) => {});
    } catch (err) {}
  };
  const SourceAChoose = async (data: any) => {
    setOpen(false);
    if (!!FmsB && FmsB.fms_id == data.fms_id) {
      await enqueueSnackbar(
        "Source FMS A and Source FMS B must be different.",
        {
          variant: "warning",
        }
      );
      setValue("source_fms", "");
      SetFMSA(undefined);
    } else {
      setValue("source_fms", data.fms_name);
      SetFMSA(data);
      generateCabShortCode();
    }
  };
  const SourceBChoose = async (data: any) => {
    setOpen1(false);
    if (!!FmsA && FmsA.fms_id == data.fms_id) {
      await enqueueSnackbar(
        "Source FMS A and Source FMS B must be different.",
        {
          variant: "warning",
        }
      );
      setValue("destination_fms", "");
      SetFMSB(undefined);
    } else {
      setValue("destination_fms", data.fms_name);
      SetFMSB(data);
      generateCabShortCode();
    }
  };
  useEffect(() => {
    if (edit_flag) {
      setExpanded("panel2");
    } else {
      setExpanded("panel1");
    }
  }, [edit_flag]);
  useEffect(() => {
    if (!!cable_det && !!cable_det.cableInfo) {
      // setValue("no_of_fibre", cable_det.cableInfo.cable_strength.toString());
      // setValue("cable_color", cable_det.cableInfo.cable_colour);
      setValue("cable_actual_length", cable_det.cableInfo.cable_actual_length);
      setValue("cable_type", cable_det.cableInfo.cable_type);
      setValue("cable_make", cable_det.cableInfo.cable_make);
      setValue("cable_make", cable_det.cableInfo.cable_make);
      let code: string[] = cable_det.cableInfo.cable_short_code.split("-");
      setValue("cable_short_code_manual", code[code.length - 1]);
      setValue(
        "cable_short_code_readable",
        code.slice(0, code.length - 1).join("-")
      );
      setValue("source_fms", cable_det.cableInfo.source_fms_code);
      setValue("destination_fms", cable_det.cableInfo.destination_fms_code);
      setValue("cost_per_unit", cable_det.cableInfo.cost_per_unit);
      !!cable_det.cableInfo.second_cable_strength && setIsBothEndNotSame(true);
      !!cable_det.cableInfo.second_cable_type &&
        setValue("second_cable_type", cable_det.cableInfo.second_cable_type);
      !!cable_det.cableInfo.second_cable_colour &&
        setValue(
          "second_cable_colour",
          cable_det.cableInfo.second_cable_colour
        );
      !!cable_det.cableInfo.second_cable_strength &&
        setValue(
          "second_no_of_fibre",
          cable_det.cableInfo.second_cable_strength
        );
      !!cable_det.cableInfo.second_cable_make &&
        setValue("second_cable_make", cable_det.cableInfo.second_cable_make);
      !!cable_det.cableInfo.cable_fibre_type &&
        setValue("cable_fibre_type", cable_det.cableInfo.cable_fibre_type);
      !!cable_det.cableInfo.second_cable_fibre_type &&
        setValue(
          "second_cable_fibre_type",
          cable_det.cableInfo.second_cable_fibre_type
        );
      SetDublicate(true);
    }
  }, [cable_det]);

  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const CodeCheck = (data: string) => {
    if ((!!cable_det && update) || !cable_det) {
      try {
        let code = getValues("cable_short_code_readable") + "-" + data;
        if (
          !cable_det ||
          (!!cable_det && cable_det.cableInfo?.cable_short_code != code)
        ) {
          CableDuplicateCheck(code)
            .then((response) => {
              if (response.data.response) {
                SetDublicate(false);
                setError(
                  "cable_short_code_manual",
                  { type: "focus" },
                  { shouldFocus: true }
                );
              } else {
                SetDublicate(true);
                clearErrors("cable_short_code_manual");
              }
            })
            .catch((error) => {
              SetDublicate(false);
              setError(
                "cable_short_code_manual",
                { type: "focus" },
                { shouldFocus: true }
              );
            });
        } else {
          SetDublicate(true);
          clearErrors("cable_short_code_manual");
        }
      } catch (err) {
        SetDublicate(false);
        setError(
          "cable_short_code_manual",
          { type: "focus" },
          { shouldFocus: true }
        );
      }
    }
  };
  useEffect(() => {
    if (!!selectedFMS) {
      reset();
      setValue("source_fms", selectedFMS?.fms_code);
      SetFMSA({
        zone_id: selectedFMS.zone_id,
        station_id: selectedFMS?.station_id,
        location_id: selectedFMS?.location_id,
        fms_id: selectedFMS?.fms_id,
        zone_name: "",
        station_name: selectedFMS.station_name,
        location_name: selectedFMS.location_name,
        fms_name: selectedFMS.fms_code,
        station_code: selectedFMS.station_code,
      });
    }
  }, []);
  return (
    <Card sx={{ marginTop: "10px" }}>
      <FmsFindpopOver
        type={1}
        onClose={setOpen}
        open={open}
        onSubmits={SourceAChoose}
      />
      <FmsFindpopOver
        onClose={setOpen1}
        open={open1}
        onSubmits={SourceBChoose}
        type={2}
      />
      <Dialog
        open={isCableTreeOpen}
        onClose={setCableTreeOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ maxHeight: "80%", overflowY: "scroll" }}
      >
        <DialogTitle id="alert-dialog-title">
          {cableDetails?.cable_type}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!!cableDetails?.definition &&
              cableDetails?.definition.map((m: any) => (
                <Typography justifyContent={"center"} textAlign={"justify"}>
                  {m.tube_color} - {m.fibre_color}
                </Typography>
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCableTreeOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange1("panel1")}
        >
          <AccordionSummary
            style={{ backgroundColor: "#202a37", color: "#fff", height: 14 }}
            expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={{ fontWeight: 500, fontSize: "1.3rem" }}>
              ADD CABLE
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, marginTop: "20px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex">
                <Grid container justifyContent="center" alignItems={"center"}>
                  <Grid item xs={4} sx={{ display: "flex" }}>
                    <Typography style={{ fontWeight: 550 }}>
                      Cable Actual Length (meter)
                    </Typography>
                    {/* <Box sx={{ color: "red" }}>*</Box> */}
                  </Grid>
                  <Grid item xs={1}>
                    <Typography style={{ fontWeight: 550 }}>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      disabled={!!cable_det && !update}
                      {...register("cable_actual_length", { required: false })}
                      style={{ width: "100%", margin: "5px" }}
                      type="text"
                      variant="outlined"
                      size="small"
                      name="cable_actual_length"
                      placeholder="Cable Actual Length"
                      error={!!errors["cable_actual_length"]}
                    />
                  </Grid>
                </Grid>

                <Grid container justifyContent="center" alignItems={"center"}>
                  <Grid item xs={4} sx={{ display: "flex" }}>
                    <Typography style={{ fontWeight: 550 }}>
                      Cable Cost/ Unit
                    </Typography>
                    {/* <Box sx={{ color: "red" }}>*</Box> */}
                    <Box>
                      {" "}
                      <div style={{ fontWeight: 550 }}>(₹)</div>
                    </Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography style={{ fontWeight: 550 }}>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Controller
                      control={control}
                      name={"cost_per_unit"}
                      defaultValue={""}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          disabled={!!cable_det && !update}
                          style={{ width: "100%", margin: "5px" }}
                          type="number"
                          placeholder="Cost/ Unit"
                          variant="outlined"
                          size="small"
                          name="cost_per_unit"
                          value={value}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          error={!!errors["cost_per_unit"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container justifyContent="center" alignItems={"center"}>
                    <Grid item xs={4} sx={{ display: "flex" }}>
                      <Typography style={{ fontWeight: 550 }}>
                        Cable Make
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography style={{ fontWeight: 550 }}>:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        {...register("cable_make", { required: false })}
                        disabled={!!cable_det && !update}
                        style={{ width: "100%", margin: "5px" }}
                        type="text"
                        variant="outlined"
                        size="small"
                        name="cable_make"
                        placeholder="Cable Make"
                        error={!!errors["cable_make"]}
                      />
                    </Grid>
                  </Grid> */}
              </div>
              {/* <div className="flex">
                <Grid container justifyContent="center" alignItems={"center"}>
                  <Grid item xs={4} sx={{ display: "flex" }}>
                    <Typography style={{ fontWeight: 550 }}>
                      Number of Fibre{" "}
                    </Typography>
                    <Box sx={{ color: "red" }}>*</Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography style={{ fontWeight: 550 }}>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Controller
                      control={control}
                      name={"no_of_fibre"}
                      defaultValue={""}
                      rules={{ required: true, max: 50 }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          disabled={!!cable_det}
                          style={{ width: "100%", margin: "5px" }}
                          type="number"
                          placeholder="No of fibre"
                          variant="outlined"
                          size="small"
                          name="no_of_fibre"
                          value={value}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), generateCabShortCode()
                          )}
                          error={!!errors["no_of_fibre"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems={"center"}>
                  <Grid item xs={4} sx={{ display: "flex" }}>
                    <Typography style={{ fontWeight: 550 }}>
                      Cable Color
                    </Typography>
                    <Box sx={{ color: "red" }}>*</Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography style={{ fontWeight: 550 }}>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      {...register("cable_color", { required: true })}
                      style={{ width: "100%", margin: "5px" }}
                      disabled={!!cable_det && !update}
                      type="text"
                      variant="outlined"
                      size="small"
                      name="cable_color"
                      placeholder="Cable Color"
                      error={!!errors["cable_color"]}
                    />
                  </Grid>
                </Grid>
              </div> */}

              <div className="flex">
                <Grid container justifyContent="center" alignItems={"center"}>
                  <Grid item xs={4} sx={{ display: "flex" }}>
                    <Typography style={{ fontWeight: 550 }}>
                      Cable Type
                    </Typography>
                    <Box sx={{ color: "red" }}>*</Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography style={{ fontWeight: 550 }}>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Controller
                      control={control}
                      name={"cable_type"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={true}
                          infoEvent={handleCableDetailsDialog}
                          option={cableTypes}
                          keys={"domain_value"}
                          disable={!!cable_det}
                          label={"domain_value"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), generateCabShortCode()
                          )}
                          placeholder={"Cable Type"}
                          value={value}
                          errors={errors["cable_type"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid container justifyContent="center" alignItems={"center"}>
                  <Grid item xs={4} sx={{ display: "flex" }}>
                    <Typography style={{ fontWeight: 550 }}>
                      Cable Make
                    </Typography>
                    {/* <Box sx={{ color: "red" }}>*</Box> */}
                  </Grid>
                  <Grid item xs={1}>
                    <Typography style={{ fontWeight: 550 }}>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Controller
                      control={control}
                      name={"cable_make"}
                      defaultValue={""}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          infoEvent={handleCableDetailsDialog}
                          option={cableMake}
                          keys={"domain_value"}
                          disable={!!cable_det && !update}
                          label={"domain_value"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), generateCabShortCode()
                          )}
                          placeholder={"Cable Make"}
                          value={value}
                          errors={errors["cable_make"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>

              <div className="flex">
                <Grid container justifyContent="center" alignItems={"center"}>
                  <Grid item xs={4} sx={{ display: "flex" }}>
                    <Typography style={{ fontWeight: 550 }}>
                      Source FMS A
                    </Typography>
                    <Box sx={{ color: "red" }}>*</Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography style={{ fontWeight: 550 }}>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      onClick={() => !(!!cable_det && !update) && setOpen(true)}
                      inputProps={{ readOnly: true }}
                      {...register("source_fms", { required: true })}
                      size="small"
                      disabled={!!cable_det && !update}
                      style={{ width: "100%", margin: "5px" }}
                      placeholder="Source FMS A"
                      id="fullWidth"
                      InputProps={{
                        endAdornment: (
                          <IconButton color="primary">
                            <SearchIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems={"center"}>
                  <Grid item xs={4} sx={{ display: "flex" }}>
                    <Typography style={{ fontWeight: 550 }}>
                      Source FMS B
                    </Typography>
                    <Box sx={{ color: "red" }}>*</Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography style={{ fontWeight: 550 }}>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      onClick={() =>
                        !(!!cable_det && !update) && setOpen1(true)
                      }
                      inputProps={{ readOnly: true }}
                      disabled={!!cable_det && !update}
                      {...register("destination_fms", { required: true })}
                      size="small"
                      sx={{ width: "100%", margin: "5px" }}
                      placeholder="Source FMS B"
                      id="fullWidth"
                      InputProps={{
                        endAdornment: (
                          <IconButton color="primary">
                            <SearchIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              {!cable_det && (
                <div className="flex">
                  <Grid container justifyContent="center" alignItems={"center"}>
                    <Grid item xs={4} sx={{ display: "flex" }}>
                      <Typography style={{ fontWeight: 550 }}>
                        Default Fibre Status (FMS A)
                      </Typography>
                      <Box sx={{ color: "red" }}>*</Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography style={{ fontWeight: 550 }}>:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Controller
                        control={control}
                        name={"default_fibre_status_sor"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            infoEvent={handleCableDetailsDialog}
                            option={fibreStatus?.filter(
                              (m: any) =>
                                m.domain_value == "COILED" ||
                                m.domain_value == "TERMINATED"
                            )}
                            keys={"domain_value"}
                            disable={!!cable_det}
                            label={"domain_value"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value), generateCabShortCode()
                            )}
                            placeholder={"Default Fibre Status (FMS A)"}
                            value={value}
                            errors={errors["default_fibre_status_sor"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="center" alignItems={"center"}>
                    <Grid item xs={4} sx={{ display: "flex" }}>
                      <Typography style={{ fontWeight: 550 }}>
                        Default Fibre Status (FMS B)
                      </Typography>
                      <Box sx={{ color: "red" }}>*</Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography style={{ fontWeight: 550 }}>:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Controller
                        control={control}
                        name={"default_fibre_status_des"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            infoEvent={handleCableDetailsDialog}
                            option={fibreStatus?.filter(
                              (m: any) =>
                                m.domain_value == "COILED" ||
                                m.domain_value == "TERMINATED"
                            )}
                            keys={"domain_value"}
                            disable={!!cable_det}
                            label={"domain_value"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value), generateCabShortCode()
                            )}
                            placeholder={"Default Fibre Status (FMS B)"}
                            value={value}
                            errors={errors["default_fibre_status_des"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
              <div className="flex">
                {!cable_det && (
                  <Grid container justifyContent="center" alignItems={"center"}>
                    <Grid item xs={4} sx={{ display: "flex" }}>
                      <Typography style={{ fontWeight: 550 }}>
                        Cable Fibre Type
                      </Typography>
                      {/* <Box sx={{ color: "red" }}>*</Box> */}
                    </Grid>
                    <Grid item xs={1}>
                      <Typography style={{ fontWeight: 550 }}>:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Controller
                        control={control}
                        name={"cable_fibre_type"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            option={fibreTypes}
                            keys={"domain_value"}
                            disable={!!cable_det}
                            label={"domain_value"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Cable Fibre Type"}
                            value={value}
                            errors={errors["cable_fibre_type"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid container justifyContent="start" alignItems={"center"}>
                  <Box
                    sx={{
                      display: "flex",
                      marginLeft: "3.5rem",
                      alignItems: "center",
                    }}
                  >
                    <Switch
                      checked={isBothEndNotSame}
                      onChange={handleBothEndChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography sx={{ fontWeight: 550 }}>
                      Is Asymmetric ?
                    </Typography>
                  </Box>
                </Grid>
              </div>

              {/* when both end not same  */}
              {isBothEndNotSame && (
                <>
                  <Paper
                    style={{
                      width: "90%",
                      margin: "0.8rem auto 0.9rem",
                      boxShadow:
                        "1px 1px 3px 2px rgba(0,0,0,0.4),-1px -1px 3px 2px rgba(0,0,0,0.4)",
                      paddingBottom: "0.5rem",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#00aaee",
                        padding: "0.08rem 0",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          margin: "0.5rem auto",
                          color: "#fff",
                        }}
                        variant="h6"
                      >
                        Other End Cable Info ( FMS B )
                      </Typography>
                    </Box>
                    <div className="flex">
                      {/* <Grid
                        container
                        justifyContent="center"
                        alignItems={"center"}
                      >
                        <Grid item xs={4} sx={{ display: "flex" }}>
                          <Typography style={{ fontWeight: 550 }}>
                            Number of Fibre{" "}
                          </Typography>
                          <Box sx={{ color: "red" }}>*</Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography style={{ fontWeight: 550 }}>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Controller
                            control={control}
                            name={"second_no_of_fibre"}
                            defaultValue={""}
                            rules={{ required: true, max: 50 }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <TextField
                                disabled={!!cable_det}
                                style={{ width: "100%", margin: "5px" }}
                                type="number"
                                placeholder="No of fibre"
                                variant="outlined"
                                size="small"
                                name="second_no_of_fibre"
                                value={value}
                                onBlur={onBlur}
                                onChange={(value: any) => (
                                  onChange(value), generateCabShortCode()
                                )}
                                error={!!errors["second_no_of_fibre"]}
                              />
                            )}
                          />
                        </Grid>
                      </Grid> */}
                      {/* <Grid
                        container
                        justifyContent="center"
                        alignItems={"center"}
                      >
                        <Grid item xs={4} sx={{ display: "flex" }}>
                          <Typography style={{ fontWeight: 550 }}>
                            Cable Color
                          </Typography>
                          <Box sx={{ color: "red" }}>*</Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography style={{ fontWeight: 550 }}>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            {...register("second_cable_colour", {
                              required: true,
                            })}
                            style={{ width: "100%", margin: "5px" }}
                            disabled={!!cable_det}
                            type="text"
                            variant="outlined"
                            size="small"
                            name="second_cable_colour"
                            placeholder="Cable Color"
                            error={!!errors["second_cable_colour"]}
                          />
                        </Grid>
                      </Grid> */}
                    </div>
                    <div className="flex">
                      <Grid
                        container
                        justifyContent="center"
                        alignItems={"center"}
                      >
                        <Grid item xs={4} sx={{ display: "flex" }}>
                          <Typography style={{ fontWeight: 550 }}>
                            Cable Type
                          </Typography>
                          <Box sx={{ color: "red" }}>*</Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography style={{ fontWeight: 550 }}>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Controller
                            control={control}
                            name={"second_cable_type"}
                            defaultValue={""}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                hasInfo={true}
                                infoEvent={handleCableDetailsDialog}
                                option={cableTypes}
                                keys={"domain_value"}
                                disable={!!cable_det}
                                label={"domain_value"}
                                onBlur={onBlur}
                                onChange={(value: any) => (
                                  onChange(value), generateCabShortCode()
                                )}
                                placeholder={"Cable Type"}
                                value={value}
                                errors={errors["second_cable_type"]}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems={"center"}
                      >
                        <Grid item xs={4} sx={{ display: "flex" }}>
                          <Typography style={{ fontWeight: 550 }}>
                            Cable Make
                          </Typography>
                          {/* <Box sx={{ color: "red" }}>*</Box> */}
                        </Grid>
                        <Grid item xs={1}>
                          <Typography style={{ fontWeight: 550 }}>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Controller
                            control={control}
                            name={"second_cable_make"}
                            defaultValue={""}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                hasInfo={false}
                                infoEvent={handleCableDetailsDialog}
                                option={cableMake}
                                keys={"domain_value"}
                                disable={!!cable_det && !update}
                                label={"domain_value"}
                                onBlur={onBlur}
                                onChange={(value: any) => (
                                  onChange(value), generateCabShortCode()
                                )}
                                placeholder={"Cable Make"}
                                value={value}
                                errors={errors["second_cable_make"]}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div className="flex">
                      {!cable_det && (
                        <Grid
                          container
                          justifyContent="center"
                          alignItems={"center"}
                        >
                          <Grid item xs={4} sx={{ display: "flex" }}>
                            <Typography style={{ fontWeight: 550 }}>
                              Cable Fibre Type
                            </Typography>
                            {/* <Box sx={{ color: "red" }}>*</Box> */}
                          </Grid>
                          <Grid item xs={1}>
                            <Typography style={{ fontWeight: 550 }}>
                              :
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Controller
                              control={control}
                              name={"second_cable_fibre_type"}
                              defaultValue={""}
                              rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  hasInfo={false}
                                  infoEvent={handleCableDetailsDialog}
                                  option={fibreTypes}
                                  keys={"domain_value"}
                                  disable={!!cable_det}
                                  label={"domain_value"}
                                  onBlur={onBlur}
                                  onChange={(value: any) => onChange(value)}
                                  placeholder={"Cable Fibre Type"}
                                  value={value}
                                  errors={errors["second_cable_fibre_type"]}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        container
                        justifyContent="center"
                        alignItems={"center"}
                      ></Grid>
                    </div>
                  </Paper>
                </>
              )}

              <div className="flex" style={{ marginBottom: 40 }}>
                <Grid container justifyContent="center" alignItems={"center"}>
                  <Grid item xs={2} sx={{ display: "flex" }}>
                    <Typography style={{ fontWeight: 550 }}>
                      Short Code
                    </Typography>
                    <Box sx={{ color: "red" }}>*</Box>
                  </Grid>
                  <Grid item xs={0.5}>
                    <Typography style={{ fontWeight: 550 }}>:</Typography>
                  </Grid>
                  <Grid item xs={5.5}>
                    <TextField
                      {...register("cable_short_code_readable", {
                        required: true,
                      })}
                      inputProps={{ readOnly: true }}
                      style={{ width: "100%", margin: "0 0 0 5px" }}
                      type="text"
                      disabled={!!cable_det}
                      variant="outlined"
                      size="small"
                      name="cable_short_code_readable"
                      placeholder="Cable Short Code"
                      error={!!errors["cable_short_code_readable"]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name={"cable_short_code_manual"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          style={{ width: "100%", margin: "0px 13px 0 6px" }}
                          type="text"
                          onChange={(Value: any) => (
                            onChange(Value.target.value),
                            CodeCheck(Value.target.value)
                          )}
                          value={value}
                          onBlur={onBlur}
                          variant="outlined"
                          disabled={!!cable_det}
                          size="small"
                          name="cable_short_code_manual"
                          placeholder="Type your Code"
                          error={!!errors["cable_short_code_manual"]}
                        />
                      )}
                    />
                  </Grid>
                  <Grid style={{ marginRight: dublicate ? -30 : 0 }}>
                    {dublicate && (
                      <CheckIcon
                        style={{ marginLeft: 2, fontSize: 30, color: "green" }}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>

              <Grid container justifyContent="center" alignItems={"center"}>
                <Grid item>
                  {!cable_det && (
                    <Button
                      type="submit"
                      style={{ color: "#fff" }}
                      variant="contained"
                      sx={{ mt: 3, mb: 2, mx: 2 }}
                    >
                      Next
                    </Button>
                  )}

                  {!!cable_det &&
                    !update &&
                    !!userDetails &&
                    userDetails.roleType === "RW" && (
                      <Fab
                        sx={fabStyle}
                        aria-label={"Add"}
                        color={"primary"}
                        onClick={() => SetUpdate(true)}
                        style={{ marginBottom: -25 }}
                      >
                        {<CreateIcon style={{ color: "#ffff" }} />}
                      </Fab>
                    )}
                  {!!cable_det && !!update && (
                    <Button
                      style={{ color: "#fff" }}
                      onClick={handleSubmit(onSubmit)}
                      variant="contained"
                      sx={{ mt: 3, mb: 2, mx: 2 }}
                    >
                      Save
                    </Button>
                  )}
                  {!!cable_det && (
                    <Button
                      style={{ color: "#fff" }}
                      variant="contained"
                      onClick={() => (setExpanded("panel2"), SetUpdate(false))}
                      sx={{ mt: 3, mb: 2, mx: 2 }}
                    >
                      Next
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange1("panel2")}
          disabled={!cable_det}
        >
          <AccordionSummary
            style={{ backgroundColor: "#035779", color: "#fff", height: 14 }}
            expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={{ fontWeight: 500, fontSize: "1.3rem" }}>
              FIBRE DETAIL
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, marginTop: "20px" }}>
            <div className="source">
              <h4>
                Source A{" -"}
                {!!cable_det && cable_det?.sourceCableInfo[0].station_name}(
                {!!cable_det && cable_det?.cableInfo?.source_fms_code}-
                {!!cable_det && cable_det?.cableInfo?.source_fms_type})
              </h4>
              <h4>
                Source B{" -"}
                {!!cable_det && cable_det?.destinationCableInfo[0].station_name}
                ({!!cable_det && cable_det?.cableInfo?.destination_fms_code}-
                {!!cable_det && cable_det?.cableInfo?.destination_fms_type})
              </h4>
            </div>
            {!!cable_det?.destinationCableInfo &&
              [
                ...Array(
                  cable_det?.destinationCableInfo.length >
                    cable_det?.sourceCableInfo.length
                    ? cable_det?.destinationCableInfo.length
                    : cable_det?.sourceCableInfo.length
                ),
              ].map((item, index) => (
                <FibreDetailCard
                  cableType={cable_det.cableInfo?.cable_type}
                  SecondCableType={cable_det.cableInfo?.second_cable_type}
                  key={index}
                  UpdateFibre={UpdateFibre}
                  Destdetail={
                    cable_det?.destinationCableInfo.length >= index + 1
                      ? cable_det.destinationCableInfo[index]
                      : undefined
                  }
                  Sourcedetail={
                    cable_det?.sourceCableInfo.length >= index + 1
                      ? cable_det.sourceCableInfo[index]
                      : undefined
                  }
                  index={index}
                  item={item}
                  fibreStatus={fibreStatus}
                  terminatedStatus={terminatedStatus}
                  fibreTypes={fibreTypes}
                  adapterType={adapterType}
                  allservice={allservice}
                  cableColor={cableColor}
                  FDB1={FDB1}
                  FDB={FDB}
                  getFMS={getFMS}
                  FDB3={FDB3}
                  GetFibre={GetFibre}
                  patch_fibre={patch_fibre}
                />
              ))}
          </AccordionDetails>
        </Accordion>
      </div>
      <Grid container justifyContent="center" alignItems={"center"}>
        <Grid item>
          <Button
            onClick={() => history.push(`/cable`)}
            style={{
              color: "#fff",
              marginRight: 10,
              background: "#C1B4B3",
            }}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

export default CableAddView;
interface CableAddViewProps {
  submitData?: any;
  cableTypes?: any;
  fibreStatus?: any;
  terminatedStatus?: any;
  fibreTypes?: any;
  cableMake?: any;
  adapterType?: any;
  allservice?: any;
  cableColor?: any;
  cable_det?: CableDetails;
  edit_flag?: boolean;
  cableUpdate?: any;
  UpdateFibre?: any;
  selectedFMS?: FMSList;
  FDB: FMSList[];
  FDB1: FMSList[];
  getFMS?: any;
  FDB3: FMSList[];
  GetFibre?: any;
  patch_fibre: PatchFibre[];
  userDetails?: UserDetails;
}

const fabStyle = {
  position: "absolute",
  bottom: 40,
  right: 16,
};
