import React, { useEffect } from "react";
import { connect } from "react-redux";
import { CableList } from "../../models/cableModel";
import { FMSLocationList } from "../../models/fmsLocationModel";
import { FMSList } from "../../models/fmsModel";
import { StoreState } from "../../models/reduxModels";
import { DamageFibre } from "../../models/ReportModel";
import { StationList } from "../../models/stationModel";
import { ZoneList } from "../../models/zoneModels";
import {
  GetAllCableAction,
  showFmsWiseCableAction,
} from "../../Stores/actions/cableAction";
import {
  GetALLFMS,
  GetFMSLocationWiseAction,
} from "../../Stores/actions/fmsAction";
import {
  GetAllFMSLocations,
  getStationLocation,
} from "../../Stores/actions/fmsLocationAction";
import {
  GetDamageFibre,
  GetDamageFibreLoad,
} from "../../Stores/actions/ReportAction";
import {
  GetAllStations,
  getZoneStations,
} from "../../Stores/actions/stationAction";
import { GetAllZones } from "../../Stores/actions/zoneActions";
import DamageFibreView from "./DamageFibreView";

function DamageFibreReport({
  damageFibre,
  GetDamageFibre,
  allstation,
  allzone,
  cable_list,
  allFMS,
  allfmslocation,
  getZoneStations,
  getStationLocation,
  GetFMSLocationWiseAction,
  showFmsWiseCableAction,
  GetDamageFibreLoad,
}: DamageProps) {
  useEffect(() => {
    GetDamageFibre();
    GetDamageFibreLoad();
  }, []);
  const Filters = (props: any) => {
    GetDamageFibre(props);
  };
  const clearFilter = () => {
    GetDamageFibre();
    GetDamageFibreLoad();
  };
  return (
    <DamageFibreView
      allstation={allstation}
      allzone={allzone}
      cable_list={cable_list}
      allfmslocation={allfmslocation}
      allFMS={allFMS}
      getZoneStations={getZoneStations}
      getStationLocation={getStationLocation}
      GetFMSLocationWiseAction={(id: number) =>
        GetFMSLocationWiseAction({ location_id: +id })
      }
      showFmsWiseCableAction={showFmsWiseCableAction}
      Filters={Filters}
      damageFibre={damageFibre}
      clear={clearFilter}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    damageFibre: state.report.damage_fibre,
    allzone: state.zone.zone_list,
    allstation: state.station.station_list,
    cable_list: state.cable.cable_list,
    allFMS: state.fms.fms_list,
    allfmslocation: state.fmslocation.fmslocation_list,
  };
};
const mapDispatchToProps = {
  GetDamageFibre,
  getZoneStations,
  getStationLocation,
  GetFMSLocationWiseAction,
  showFmsWiseCableAction,
  GetDamageFibreLoad,
};
export default connect(mapStateToProps, mapDispatchToProps)(DamageFibreReport);
interface DamageProps {
  GetDamageFibre?: any;
  damageFibre: DamageFibre[];
  allzone: ZoneList[];
  allstation: StationList[];
  cable_list: CableList[];
  GetAllFMSLocations?: any;
  allFMS: FMSList[];
  allfmslocation: FMSLocationList[];
  getZoneStations?: any;
  getStationLocation?: any;
  GetFMSLocationWiseAction?: any;
  showFmsWiseCableAction?: any;
  GetDamageFibreLoad?: any;
}
