import {CableTreeMain} from "../../models/CableTreeModel"
import {CableTreeTypes} from "../actions/cableTreeAction"
import InitialState from "./initialState"
const initialState:CableTreeMain=InitialState.cable_tree
export default function CableTreeReducer(
  state:CableTreeMain=initialState,
  action:any
){
  switch(action.type){
    case CableTreeTypes.Get_Cable_Tree_Success_Action:
      return{
        ...state,
        CableTree:action.payload
      }
    default:return state
  }
}
