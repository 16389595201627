import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Box,
  Button,
  OutlinedInput,
  Select,
  Chip,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Autocompletes from "../../../components/Autocompletes";
import {
  ColorCode,
  FibreMater,
  TubeMater,
} from "../../../models/cableMasterModel";

function CableAddView({
  color,
  alltube,
  submitData,
  cabletype,
  cablestrength,
}: CableAddViewProps) {
  const history = useHistory();

  // handle selected tube

  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setFocus,
    setError,
    watch,
  } = useForm();
  const { errors } = formState;

  const cbType: any[] = [
    {
      key: "UG",
      value: "UG",
    },
    {
      key: "Aerial",
      value: "Aerial",
    },
    {
      key: "OPGW",
      value: "OPGW",
    },
    {
      key: "ADSS",
      value: "ADSS",
    },
  ];
  const cbStrength: any[] = [
    {
      key: "6F",
      value: "6F",
    },
    {
      key: "12F",
      value: "12F",
    },
    {
      key: "18F",
      value: "18F",
    },
    {
      key: "24F",
      value: "24F",
    },
    {
      key: "48F",
      value: "48F",
    },
  ];
  const noTube: any[] = [
    { key: "1", value: "1" },
    {
      key: "2",
      value: "2",
    },
    {
      key: "3",
      value: "3",
    },
    {
      key: "4",
      value: "4",
    },
    {
      key: "5",
      value: "5",
    },
    {
      key: "6",
      value: "6",
    },
    {
      key: "7",
      value: "7",
    },
    {
      key: "8",
      value: "8",
    },
    {
      key: "9",
      value: "9",
    },
    {
      key: "10",
      value: "10",
    },
  ];

  const onSubmit = (props: any) => {
    if (!!props.no_of_tubes && +props.no_of_tubes !== props.tubes.length) {
      setError("tubes", { type: "custom", message: "custom message" });
    } else {
      submitData(props);
    }
  };
  const tubeNoSelected = () => {
    setValue("tubes", []);
  };

  const disableDat = (tube: TubeMater) => {
    let flag: boolean = false;
    if (!!getValues("tubes")) {
      if (+getValues("tubes").length >= +getValues("no_of_tubes")) {
        flag = true;
      }
      let t: TubeMater = getValues("tubes").find((m: any) => m == tube.tube_id);
      if (!!t) {
        flag = false;
      }
    }
    return flag;
  };
  const CreateCode = () => {
    let type: string = getValues("cable_type");
    let color: string = "";
    let count: number = 0;
    let tube: number[] = getValues("tubes");
    tube.forEach((m) => {
      let temp: TubeMater | undefined = alltube.find((n) => m == n.tube_id);
      if (!!temp) {
        color = color + temp.tube_colour[0];
        count = count + temp.no_of_fibres;
      }
    });

    setValue("cable_code", type + "-" + color + "-" + count + "F");
  };

  return (
    <Card sx={{ marginTop: "10px" }}>
      <CardHeader
        style={{ backgroundColor: "#202a37", color: "#fff", height: 14 }}
        titleTypographyProps={{ fontWeight: 500, fontSize: "1.3rem" }}
        title="Add Cable"
      />
      <CardContent style={{ padding: 0 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4}>
              <Typography style={{fontWeight: 550}}>Cable Name</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{fontWeight: 550}}>:</Typography>
            </Grid>
            <Grid item xs={5}>
            <TextField
                {...register("cable_name", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="cable_name"
                placeholder="Cable Name"
                error={!!errors["cable_name"]}
              />
          </Grid>
      </Grid> */}
          <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            style={{ marginTop: 22 }}
          >
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Cable Type</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Controller
                control={control}
                name={"cable_type"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={cabletype}
                    keys={"domain_value"}
                    label={"domain_value"}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"Cable Type"}
                    value={value}
                    errors={errors["cable_type"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Cable Make</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("cable_make", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="cable_make"
                placeholder="Cable Make"
                error={!!errors["cable_make"]}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>
                Cable Strength
              </Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Controller
                control={control}
                name={"cable_strength"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={cablestrength}
                    keys={"domain_value"}
                    label={"domain_value"}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"Cable Strength"}
                    value={value}
                    errors={errors["cable_strength"]}
                  />
                )}
              />
              {/* <TextField
                {...register("cable_strength", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                select
                defaultValue={""}
                name="cable_strength"
                placeholder="Cable Strength"
                error={!!errors["cable_strength"]}
              >
                  <MenuItem key={'6F'} value={'6F'}>
                    6F
                  </MenuItem>
                  <MenuItem key={'12F'} value={'12F'}>
                    12F
                  </MenuItem>
                  <MenuItem key={'18F'} value={'18F'}>
                    18F
                  </MenuItem>
                  <MenuItem key={'24F'} value={'24F'}>
                    24F
                  </MenuItem>
                  <MenuItem key={'48F'} value={'48F'}>
                    48F
                  </MenuItem>
              </TextField> */}
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Cable Color</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>

            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("cable_colour_code", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                select
                name="cable_colour_code"
                placeholder="Cable Color"
                defaultValue={""}
                error={!!errors["cable_colour_code"]}
              >
                {!!color &&
                  color.map((item) => (
                    <MenuItem
                      key={item.colour_master_id}
                      value={item.colour_code}
                    >
                      <Grid container gap={1} direction={"row"}>
                        <Box
                          style={{
                            height: "20px",
                            width: "20px",
                            backgroundColor: item.colour_code,
                          }}
                        ></Box>
                        {item.colour_name}
                      </Grid>
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>No Of Tube</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Controller
                control={control}
                name={"no_of_tubes"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={noTube}
                    keys={"key"}
                    label={"value"}
                    onBlur={onBlur}
                    onChange={(value: any) => (
                      onChange(value), tubeNoSelected()
                    )}
                    placeholder={"No Of Tubes"}
                    value={value}
                    errors={!!errors["no_of_tubes"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Tubes</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Controller
                control={control}
                name={"tubes"}
                defaultValue={[]}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Select
                    size="small"
                    style={{ width: "100%", margin: "5px" }}
                    variant="outlined"
                    error={!!errors["tubes"]}
                    multiple
                    defaultValue={[]}
                    onBlur={onBlur}
                    value={value}
                    onChange={(event) => (
                      onChange(event.target.value), CreateCode()
                    )}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value: any) => {
                          let item: TubeMater | undefined = alltube.find(
                            (m) => +m.tube_id == +value
                          );
                          return !!item ? (
                            <Chip
                              style={{
                                backgroundColor: item.tube_colour_code,
                                color: "#ffff",
                              }}
                              key={item.tube_id}
                              label={`${item.tube_colour}(${item.tube_code})`}
                            />
                          ) : (
                            <></>
                          );
                        })}
                      </Box>
                    )}
                    MenuProps={{
                      sx: {
                        "&& .Mui-selected": {
                          backgroundColor: "#CCEBF2",
                        },
                      },
                    }}
                  >
                    {alltube.map((tube) => (
                      <MenuItem
                        disabled={disableDat(tube)}
                        key={tube.tube_id}
                        value={tube.tube_id}
                      >
                        <Grid container gap={1} direction={"row"}>
                          <Box
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: tube.tube_colour_code,
                            }}
                          ></Box>
                          {tube.tube_colour}({tube.tube_code})
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Short Code</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("cable_code", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                disabled
                name="cable_code"
                placeholder="Cable Code"
                error={!!errors["cable_code"]}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Description</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("cable_desc", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                multiline
                rows={4}
                variant="outlined"
                name={"cable_desc"}
                placeholder="Description"
                error={!!errors["cable_desc"]}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item>
              <Button
                onClick={() => history.push(`/cable-master/cable-main`)}
                style={{
                  color: "#fff",
                  marginRight: 10,
                  background: "#C1B4B3",
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Back
              </Button>

              <Button
                onClick={() => history.push(`/cable-master/tube-add`)}
                style={{
                  color: "#fff",
                  marginRight: 10,
                  // background: "#C1B4B3",
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Add Tube
              </Button>

              <Button
                type="submit"
                style={{ color: "#fff" }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

export default CableAddView;
interface CableAddViewProps {
  color: ColorCode[];
  alltube: TubeMater[];
  submitData?: any;
  cabletype?: any;
  cablestrength?: any;
}
