import Axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { baseServiceUrl, environment } from "../../environment";
import { StoreState } from "../../models/reduxModels";
import { BulkUploadReport } from "../../models/ReportModel";
import { UserDetails } from "../../models/userModels";
import { ReportDownload } from "../../Services/Services";
import {
  BulkFileUploadAction,
  BulkReportList2LoadAction,
  BulkReportListLoadAction,
} from "../../Stores/actions/ReportAction";
import BulkuploadView from "./bulkupload";
import Bulkupload from "./bulkupload";
const FileDownload = require("js-file-download");
function BulkUpload({
  BulkReportListLoadAction,
  uploaddata,
  user,
  BulkFileUploadAction,
  BulkReportList2LoadAction,
}: BulkUploadProps) {
  const { enqueueSnackbar } = useSnackbar();
  let timmer: any;
  useEffect(() => {
    BulkReportListLoadAction();
  }, []);
  useEffect(() => {
    if (
      !!uploaddata &&
      uploaddata.length > 0 &&
      !!uploaddata.find((m) => m.status == 1)
    ) {
      timmer = setTimeout(() => {
        BulkReportList2LoadAction();
      }, 5000);
    }
    return () => {
      if (!!timmer) {
        clearTimeout(timmer);
      }
    };
  }, [uploaddata]);
  const uploadFileEvent = (data: any) => {
    BulkFileUploadAction(data);
  };
  const ReportDownloadEvent = (id: number) => {
    Axios({
      url: baseServiceUrl + environment.url.DownloadReportUrl,
      method: "POST",
      responseType: "blob", // Important
      headers: {
        Authorization: "Bearer " + user?.accessToken,
      },
      data: {
        bulk_upload_detail_id: id,
      },
    })
      .then((response) => {
        FileDownload(response.data, "report.xlsx");
      })
      .catch((error) => {
        enqueueSnackbar("Something wrong!", {
          variant: "error",
        });
      });
  };
  return (
    <div>
      <BulkuploadView
        uploadFileEvent={uploadFileEvent}
        uploaddata={uploaddata}
        user={user}
        ReportDownloadEvent={ReportDownloadEvent}
      />
    </div>
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    uploaddata: state.report.BulkUploadReport,
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  BulkReportListLoadAction,
  BulkFileUploadAction,
  BulkReportList2LoadAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkUpload);

interface BulkUploadProps {
  BulkReportListLoadAction?: any;
  user?: UserDetails;
  uploaddata: BulkUploadReport[];
  BulkFileUploadAction?: any;
  BulkReportList2LoadAction?: any;
}
