import { PropaneSharp } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Autocompletes from "../../../components/Autocompletes";
import { ZoneList } from "../../../models/zoneModels";

function StationAddView({
  submitData,
  allzone,
  filter,
  DomainTypes,
  stationtype,
}: StationAddViewProps) {
  const history = useHistory();
  const { control, register, handleSubmit, formState, setValue } = useForm();
  const { errors } = formState;
  const stType: any[] = [
    {
      key: "S/S",
      value: "S/S",
    },
    {
      key: "R/S",
      value: "R/S",
    },
    {
      key: "G/S",
      value: "G/S",
    },
    {
      key: "D/S",
      value: "D/S",
    },
    {
      key: "ARMU",
      value: "ARMU",
    },
  ];
  const onSubmit = (props: any) => {
    submitData({
      lattitude: +props.lattitude,
      longitude: +props.longitude,
      //   station_address: props.station_address,
      station_code: props.station_code,
      station_name: props.station_name,
      station_type: props.station_type,
      zone_id: props.zone_id,
      addressline1: props.addressline1,
      addressline2: props.addressline2,
      city: props.city,
      state: props.state,
      pin: props.pin,
      zone_name: props.zone_name,
    });
  };
  useEffect(() => {
    if (!!filter) {
      setValue("zone_id", filter.zone_id);
    }
  }, [filter]);
  useEffect(() => {
    if (true) {
      setValue("state", "West Bengal");
    }
  }, []);

  return (
    <Card sx={{ marginTop: "10px" }}>
      <CardHeader
        style={{ backgroundColor: "#202a37", color: "#fff", height: 14 }}
        titleTypographyProps={{ fontWeight: 500, fontSize: "1.3rem" }}
        title="Add Station"
      />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            paddingTop={2}
            justifyContent="center"
            alignItems={"center"}
          >
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Station Short Code</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("station_code", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="station_code"
                placeholder="Station Code"
                error={!!errors["station_code"]}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Station Name</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("station_name", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="station_name"
                placeholder="Station Name"
                error={!!errors["station_name"]}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Station Type</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Controller
                control={control}
                name={"station_type"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={stationtype}
                    keys={"domain_value"}
                    label={"domain_value"}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"Station Type"}
                    value={value}
                    errors={errors["station_type"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Zone</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Controller
                control={control}
                name={"zone_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={allzone}
                    keys={"zone_id"}
                    label={"zone_name"}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"Zone"}
                    value={value}
                    errors={errors["zone_id"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Latitude</Typography>
              {/* <Box sx={{ color: "red" }}>*</Box> */}
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("lattitude", { required: false })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="lattitude"
                placeholder="Lattitude"
                error={!!errors["lattitude"]}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Longitude</Typography>
              {/* <Box sx={{ color: "red" }}>*</Box> */}
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("longitude", { required: false })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="longitude"
                placeholder="Longitude"
                error={!!errors["longitude"]}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Addressline 1</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("addressline1", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="addressline1"
                placeholder="Addressline 1"
                error={!!errors["addressline1"]}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Addressline 2</Typography>
              {/* <Box sx={{color:"red"}}>*</Box>  */}
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("addressline2", { required: false })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="addressline2"
                placeholder="Addressline 2"
                error={!!errors["addressline2"]}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>City</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("city", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="city"
                placeholder="City"
                error={!!errors["city"]}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>State</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("state", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="state"
                placeholder="State"
                error={!!errors["state"]}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Pin</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("pin", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="pin"
                placeholder="Pin"
                error={!!errors["pin"]}
              />
            </Grid>
          </Grid>
          {/* <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4}>
              <Typography style={{ fontWeight: 550 }}>Zone Name</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("zone_name", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="zone_name"
                placeholder="Zone Name"
                error={!!errors["zone_name"]}
              />
            </Grid>
          </Grid> */}
          {/* <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4}>
              <Typography style={{ fontWeight: 550 }}>
                Station Address
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("station_address", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                multiline
                rows={4}
                variant="outlined"
                name={"station_address"}
                placeholder="Station Address"
                error={!!errors["station_address"]}
              />
            </Grid>
          </Grid> */}
          <Grid container justifyContent="center" alignItems={"center"}>
            <Button
              onClick={() => history.push(`/station`)}
              style={{
                color: "#fff",
                marginRight: 10,
                background: "#C1B4B3",
              }}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Back
            </Button>
            <Grid item>
              <Button
                type="submit"
                style={{ color: "#fff" }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

export default StationAddView;

interface StationAddViewProps {
  submitData?: any;
  allzone: ZoneList[];
  filter?: ZoneList;
  DomainTypes?: any;
  stationtype?: any;
}
