import React, { useState, useEffect } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Position,
  MiniMap,
  Background,
  Controls,
} from "react-flow-renderer";
import { TraceFibreList } from "../../../models/traceFibreModel";
import { Handle } from "react-flow-renderer";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";

const TracingFlow = ({ NodeData, EdgeData }: TracingFlowProps) => {
  const [nodes, setNodes, onNodesChange] = useNodesState<any[]>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<any[]>([]);
  const nodeTypes = {
    customNode: Node,
  };
  useEffect(() => {
    if (!!NodeData) {
      setNodes(NodeData);
    }
    if (!!EdgeData) {
      setEdges(EdgeData);
    }
  
  }, [NodeData, EdgeData]);

  return (
    <Grid container height={"100%"} width={"100%"}>
      <Grid item height={"100%"} width={"100%"}>
        <Grid style={{ position: "absolute", zIndex: 999 }}>
          <Button
            variant="contained"
            sx={{ color: "#fff" }}
            
          >
            Generate PDF
          </Button>
        </Grid>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          fitView
          attributionPosition="top-center"
          style={{ justifyContent: "center", margin: "0 auto" }}
          zoomOnScroll={true}
          defaultZoom={0.2}
          maxZoom={2}
          snapToGrid={true}
          nodeTypes={nodeTypes}
        >
          <MiniMap
            style={{ border: "1px solid #00AAEE", backgroundColor: "#55BFE6" }}
          />
          <Controls showInteractive={false} />
          <Background />
        </ReactFlow>
      </Grid>
    </Grid>
  );
};

export default TracingFlow;
interface positionType {
  x: number;
  y: number;
}
const Node = ({ data, selected }: any) => {
  




  return (
    <Card
      variant="outlined"
      style={{ padding: 0, minWidth: 400, borderRadius: 5 }}
    >
      <Handle type="target" position={Position.Top} />
      <CardContent style={{ padding: 0 }}>
        <Grid container flexDirection={"column"} justifyContent={"center"}>
          <Grid item style={{ backgroundColor: "#00AAEE" }}>
            <Grid container justifyContent={"center"}>
              <Grid item>
                {((!!data.data.source_cable_code &&
                  !data.data.des_cable_code &&
                  data.index != 0) ||
                  (!!data.data.source_cable_code &&
                    !!data.data.des_cable_code)) && (
                  <Typography>
                    {" "}
                    {data.data.source_cable_code}({data.data.source_tube_colour}{" "}
                    - {data.data.source_fibre_colour})
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ padding: 30 }}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={3}>
                <Typography>Station</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>: </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{data.data.station_code}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"center"}>
              <Grid item xs={3}>
                <Typography>Location</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>: </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{data.data.location_name}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"center"}>
              <Grid item xs={3}>
                <Typography>FMS</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>: </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{data.data.fms_code}</Typography>
              </Grid>
            </Grid>
            {!!data.data.service_name && (
              <Grid container justifyContent={"center"}>
                <Grid item xs={3}>
                  <Typography>Service</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>: </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{data.data.service_name}</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item style={{ backgroundColor: "#00AAEE" }}>
            <Grid container justifyContent={"center"}>
              <Grid item>
                {!data.data.des_cable_code &&
                  !!data.data.source_cable_code &&
                  data.index == 0 && (
                    <Typography>
                      {" "}
                      {data.data.source_cable_code}(
                      {data.data.source_tube_colour} -{" "}
                      {data.data.source_fibre_colour})
                    </Typography>
                  )}
                {!!data.data.des_cable_code && !!data.data.source_cable_code && (
                  <Typography>
                    {" "}
                    {data.data.des_cable_code}({data.data.des_tube_colour} -{" "}
                    {data.data.des_fibre_colour})
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Handle type="source" position={Position.Bottom} />
    </Card>
  );
};

interface TracingFlowProps {
  NodeData: any[];
  EdgeData: any[];
}
