import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { StoreState } from "../models/reduxModels";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Stack,
  Slide,
  Grid,
  Paper,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  styled,
  TableCell,
} from "@mui/material";

const SingleCableReport = ({
  isOpen,
  setOpen,
  cable_report,

  GetCableTreeAction,
  cable_id,

  removeCableDetailLoadAction,
}: SingleCableReportProps) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
      style={{ padding: 0 }}
    >
      {!!cable_report && (
        <>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              textAlign: "center",
              backgroundColor: "#065c7e",
              color: "#fff",
              fontSize: "1.4rem",
              padding: 0.5,
            }}
          >
            {cable_report?.cableInfo?.cable_short_code
              ? cable_report.cableInfo.cable_short_code
              : "N/A"}
          </DialogTitle>

          <h2>Source (A)</h2>

          {/* <TableContainer sx={{ marginTop: "1.4rem" }}> */}
          <Table
            stickyHeader
            style={{ border: "1px solid #035779", marginBottom: 30 }}
          >
            <TableHead>
              <TableRow>
                {/* <StyledTableCell align="justify">sfsdfds</StyledTableCell> */}
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Tube Color
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Fibre Color
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Fibre type
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Fibre Status
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Service Status
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Service Name
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Spliced With
                </StyledTableCell>

                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Patch With
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Terminated FDB
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Adt. Position
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Adt.Type
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Ferrule Name
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Remarks
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!cable_report.sourceCableInfo &&
                cable_report.sourceCableInfo.map((row: any, index: number) => {
                  return (
                    <>
                      <StyledTableRow hover role="checkbox" tabIndex={-1}>
                        <StyledTableCell align="left">
                          {row.tube_colour}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.fibre_colour}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.fibre_type}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.fibre_status}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.service_type || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.service_name ? row.service_name : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.spliced_fibre_code || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.spliced_fibre_code || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.fdb_fms_code || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.adapter_position ? row.adapter_position : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.adapter_type ? row.adapter_type : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.ferule_name ? row.ferule_name : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.remarks ? row.remarks : "N/A"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
          {/* </TableContainer> */}
          <h2>Source (B)</h2>

          {/* <TableContainer sx={{ marginTop: "1.4rem" }}> */}
          <Table stickyHeader style={{ border: "1px solid #035779" }}>
            <TableHead>
              <TableRow>
                {/* <StyledTableCell align="justify">sfsdfds</StyledTableCell> */}
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Tube Color
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Fibre Color
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Fibre type
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Fibre Status
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Service Status
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Service Name
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Spliced With
                </StyledTableCell>

                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Patch With
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Terminated FDB
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Adt. Position
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Adt.Type
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Ferrule Name
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    // border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  Remarks
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!cable_report.destinationCableInfo &&
                cable_report.destinationCableInfo.map(
                  (row: any, index: number) => {
                    return (
                      <>
                        <StyledTableRow hover role="checkbox" tabIndex={-1}>
                          <StyledTableCell align="left">
                            {row.tube_colour}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.fibre_colour}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.fibre_type}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.fibre_status}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.service_type || "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.service_name ? row.service_name : "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.spliced_fibre_code || "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.spliced_fibre_code || "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.fdb_fms_code || "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.adapter_position
                              ? row.adapter_position
                              : "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.adapter_type ? row.adapter_type : "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.ferule_name ? row.ferule_name : "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.remarks ? row.remarks : "N/A"}
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    );
                  }
                )}
            </TableBody>
          </Table>
          {/* </TableContainer> */}

          {/* <div>{JSON.parse(cable_report.transaction_data)}</div> */}
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item>
              <DialogActions style={{}} sx={{ overflow: "hidden" }}>
                <Button onClick={handleClose} variant="contained" color="error">
                  Close
                </Button>
                <Button
                  variant="contained"
                  sx={{ color: "#fff" }}
                  onClick={() => {
                    var temp: any[] = [];
                    var temp1: any[] = [];
                    for (
                      let i = 0;
                      i < cable_report.sourceCableInfo.length;
                      i++
                    ) {
                      temp[i] = [
                        ` ${cable_report.sourceCableInfo[i].tube_colour} 
                      ${cable_report.sourceCableInfo[i].fibre_colour}`,
                        cable_report.sourceCableInfo[i].fibre_type,
                        cable_report.sourceCableInfo[i].fibre_status,
                        cable_report.sourceCableInfo[i].service_type || " N/A",
                        cable_report.sourceCableInfo[i].service_name || " N/A",
                        cable_report.sourceCableInfo[i].spliced_fibre_code ||
                          " N/A",
                        cable_report.sourceCableInfo[i].spliced_fibre_code ||
                          " N/A",
                        cable_report.sourceCableInfo[i].fdb_fms_code || " N/A",
                        cable_report.sourceCableInfo[i].adapter_position ||
                          " N/A",
                        cable_report.sourceCableInfo[i].adapter_type || " N/A",
                        cable_report.sourceCableInfo[i].ferule_name || " N/A",
                        cable_report.sourceCableInfo[i].remarks || " N/A",
                      ];
                    }
                    for (
                      let j = 0;
                      j < cable_report.destinationCableInfo.length;
                      j++
                    ) {
                      temp1[j] = [
                        ` ${cable_report.destinationCableInfo[j].tube_colour} 
                        ${cable_report.destinationCableInfo[j].fibre_colour}`,
                        cable_report.destinationCableInfo[j].fibre_type,
                        cable_report.destinationCableInfo[j].fibre_status,
                        cable_report.destinationCableInfo[j].service_type ||
                          " N/A",
                        cable_report.destinationCableInfo[j].service_name ||
                          " N/A",
                        cable_report.destinationCableInfo[j]
                          .spliced_fibre_code || " N/A",
                        cable_report.destinationCableInfo[j]
                          .spliced_fibre_code || " N/A",
                        cable_report.destinationCableInfo[j].fdb_fms_code ||
                          " N/A",
                        cable_report.destinationCableInfo[j].adapter_position ||
                          " N/A",
                        cable_report.destinationCableInfo[j].adapter_type ||
                          " N/A",
                        cable_report.destinationCableInfo[j].ferule_name ||
                          " N/A",
                        cable_report.destinationCableInfo[j].remarks || " N/A",
                      ];
                    }
                    pdfMake.vfs = pdfFonts.pdfMake.vfs;
                    var dd: any = {
                      pageOrientation: "landscape",
                      content: [
                        {
                          text:
                            (cable_report?.cableInfo?.cable_short_code
                              ? cable_report.cableInfo.cable_short_code
                              : "N/A") +
                            " " +
                            "Cable Report",
                          style: "header",
                        },
                        {
                          text: `Source A`,
                          style: "subheader",
                        },
                        {
                          style: "tableExample",
                          table: {
                            headerRows: 1,
                            body: [
                              [
                                {
                                  text: "Tube Fibre Color ",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Fibre type",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Fibre Status",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Service Status",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Service Name",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Spliced With ",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Patch With",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Terminated FDB",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Adt. Position",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Adt.Type",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Ferrule Name",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Remarks",
                                  style: "tableHeader",
                                },
                              ],

                              ...temp,
                            ],
                          },
                          layout: {
                            fillColor: function (rowIndex: any) {
                              return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                            },
                            hLineWidth: function (i: any, node: any) {
                              return i === 0 || i === node.table.body.length
                                ? 2
                                : 1;
                            },
                            vLineWidth: function (i: any, node: any) {
                              return i === 0 || i === node.table.widths.length
                                ? 2
                                : 1;
                            },
                            hLineColor: function (i: any, node: any) {
                              return i === 0 || i === node.table.body.length
                                ? "black"
                                : "gray";
                            },
                            vLineColor: function (i: any, node: any) {
                              return i === 0 || i === node.table.widths.length
                                ? "black"
                                : "gray";
                            },
                          },
                        },
                        {
                          text: `Source B`,
                          style: "subheader",
                        },
                        {
                          style: "tableExample",
                          table: {
                            headerRows: 1,
                            body: [
                              [
                                {
                                  text: "Tube Fibre Color ",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Fibre type",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Fibre Status",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Service Status",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Service Name",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Spliced With ",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Patch With",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Terminated FDB",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Adt. Position",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Adt.Type",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Ferrule Name",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Remarks",
                                  style: "tableHeader",
                                },
                              ],

                              ...temp1,
                            ],
                          },
                          layout: {
                            fillColor: function (rowIndex: any) {
                              return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                            },
                            hLineWidth: function (i: any, node: any) {
                              return i === 0 || i === node.table.body.length
                                ? 2
                                : 1;
                            },
                            vLineWidth: function (i: any, node: any) {
                              return i === 0 || i === node.table.widths.length
                                ? 2
                                : 1;
                            },
                            hLineColor: function (i: any, node: any) {
                              return i === 0 || i === node.table.body.length
                                ? "black"
                                : "gray";
                            },
                            vLineColor: function (i: any, node: any) {
                              return i === 0 || i === node.table.widths.length
                                ? "black"
                                : "gray";
                            },
                          },
                        },
                      ],
                      styles: {
                        header: {
                          fontSize: 18,
                          bold: true,
                          margin: [0, 0, 0, 10],
                          // color: "#353535",
                          color: "#9d56a2",
                        },
                        subheader: {
                          fontSize: 16,
                          bold: true,
                          margin: [0, 10, 0, 5],
                        },
                        tableExample: {
                          margin: [0, 5, 0, 15],
                        },
                        tableHeader: {
                          bold: true,
                          fontSize: 13,
                          // color: "black",
                          color: "white",
                          fillColor: "#035779",
                        },
                      },
                      defaultStyle: {
                        // alignment: 'justify'
                      },
                    };

                    pdfMake.createPdf(dd).open();
                  }}
                >
                  Generate Pdf
                </Button>
              </DialogActions>
            </Grid>
          </Grid>

          {/* <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item>
          <DialogActions style={{}} sx={{ overflow: "hidden" }}></DialogActions>
        </Grid>
      </Grid> */}
        </>
      )}
    </Dialog>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 550,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const mapStateToProps = (state: StoreState) => {
  return {
    cable_details: state.cable.cable_det,
  };
};
const mapDispatchToProps = {
  //   CableDetailLoadAction,
  //   removeCableDetailLoadAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleCableReport);
interface SingleCableReportProps {
  isOpen: boolean;
  setOpen: any;
  data?: any;
  GetCableTreeAction?: any;
  cable_id?: number;
  cable_report?: any;

  removeCableDetailLoadAction?: any;
}
// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement;
//   },
//   ref: React.Ref<unknown>
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
