import { FMSMain } from "../../models/fmsModel";
import InitialState from "./initialState";
import { FMSActionTypes } from "../actions/fmsAction";
import { ReportActionTypes } from "../actions/ReportAction";
// import { StationActionTypes } from "../actions/stationAction";
// import {FMSLocationActionTypes} from "../actions/fmsLocationAction"

const initialState: FMSMain = InitialState.fms;
export default function FMSReducer(state: FMSMain = initialState, action: any) {
  switch (action.type) {
    case FMSActionTypes.FMS_GetAll_Success:
      return {
        ...state,
        fms_list: action.payload.fms_list,
        fms_location_list: action.payload.fms_location_list,
        FmsSchematic: undefined
      };
    case FMSActionTypes.Get_Only_FMS_Action_Success:
      return {
        ...state,
        fms_list: action.payload,
      };
    case FMSActionTypes.Get_FMS_By_Id_Success_Action:
      return {
        ...state,
        single_fms: action.payload,
      };
    case FMSActionTypes.Update_FMS_Success_Action:
      return {
        ...state,
      };
    case FMSActionTypes.FMS_Delete_Success_Action:
      return {
        ...state,
        has_filter: undefined,
      };
    case FMSActionTypes.FMS_Add_Request_Action:
      return {
        ...state,
        zone_list: action.payload.zone_list,
      };
    case FMSActionTypes.Get_Station_By_Zone_Action:
      return {
        ...state,
        station_by_zone: action.payload.station_by_zone,
      };
    case FMSActionTypes.Get_Location_By_Station_Action:
      return {
        ...state,
        location_by_station: action.payload.location_by_station,
      };
    case FMSActionTypes.FMS_Add_Success_Action:
      return {
        ...state,
      };
    case FMSActionTypes.SHOW_FMS_LOCATION_WISE:
      return {
        ...state,
        fms_list: action.payload.filter_data,
        fms_location_list: action.payload.fms_location_list,
      };
    case FMSActionTypes.GET_FILTERED_DATA_FROM_LOCATION:
      return {
        ...state,
        has_filter: action.payload,
      };
    case FMSActionTypes.Get_All_FDB_By_FMS_ACTION_SUCCESS:
      return {
        ...state,
        fdb_list: action.payload,
      };
    case FMSActionTypes.Clear_All_FDB_By_FMS_ACTION_SUCCESS:
      return {
        ...state,
        fdb_list: [],
      };
    case FMSActionTypes.Get_Joint_Info_Success_Action:
      return { ...state, joint_fms: action.payload };
    case ReportActionTypes.Get_Damage_Fibre_Load_Success:
      return {
        ...state,
        fms_list: action.payload[3].data,
      };
    case FMSActionTypes.Fms_Schematic_Get_Success_Action:
      return { ...state, FmsSchematic: action.payload };
    default:
      return state;
  }
}
