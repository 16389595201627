import { Button, ButtonProps, styled } from "@mui/material";

export const DeleteButton = styled(Button)<ButtonProps>(({ }) => ({
    color: '#ffff',
    backgroundColor: '#da4040',
    '&:hover': {
      backgroundColor: '#d80a0a',
    },
    margin: 5
  }))

export const InfoButton = styled(Button)<ButtonProps>(({ }) => ({
    color: '#ffff',
    backgroundColor: '#60d32b',
    '&:hover': {
      backgroundColor: '#46b811',
    },
    margin: 5
  }))
export const DetailButton = styled(Button)<ButtonProps>(({ }) => ({
    color: '#ffff',
    backgroundColor: '#9d56a2',
    '&:hover': {
      backgroundColor: '#59255c',
    },
    margin: 5
  }))
export const DetailButton2 = styled(Button)<ButtonProps>(({ }) => ({
    color: '#ffff',
    backgroundColor: '#035779',
    '&:hover': {
      backgroundColor: '#073142',
    },
    margin: 5
  }))