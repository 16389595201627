import { connect } from "react-redux";
import React, { useEffect } from "react";

import { StoreState } from "../../models/reduxModels";

import EndToEndFibreReportView from "./EndToEndFibreReportView";
import {
  EndtoEndClearAction,
  EndToEndFibreTraceAction,
  EndToEndGetStation,
  GetCableByService,
  GetDamageFibreLoad,
  GetFibreByCableFms,
} from "../../Stores/actions/ReportAction";
import { ZoneList } from "../../models/zoneModels";
import { StationList } from "../../models/stationModel";
import { getZoneStations } from "../../Stores/actions/stationAction";
import { getStationLocation } from "../../Stores/actions/fmsLocationAction";
import { showFmsWiseCableAction } from "../../Stores/actions/cableAction";
import { GetFMSLocationWiseAction } from "../../Stores/actions/fmsAction";
import { FMSList } from "../../models/fmsModel";
import { FMSLocationList } from "../../models/fmsLocationModel";
import { fibrebycablefms } from "../../models/ReportModel";
import { GetAllZones } from "../../Stores/actions/zoneActions";

function EndToEndReport({
  EndToEndFibreTraceAction,
  fibresEndToEnd,
  allzone,
  allstation,
  getZoneStations,
  getStationLocation,
  allFMS,
  allfmslocation,
  cable_list,
  showFmsWiseCableAction,
  GetDamageFibreLoad,
  GetFibreByCableFms,
  fibre,
  allstation2,
  EndtoEndClearAction,
  GetFMSLocationWiseAction,
  EndToEndGetStation,
}: EndToEndReportProps) {
  useEffect(() => {
    GetAllZones();
  }, []);
  const Filters = (props: any) => {
    EndToEndFibreTraceAction(props);
  };

  const getFibre = (props: any) => {
    GetFibreByCableFms(props);
  };
  return (
    <EndToEndFibreReportView
      getZoneStations={EndToEndGetStation}
      Filters={Filters}
      fibresEndToEnd={fibresEndToEnd}
      allzone={allzone}
      allstation={allstation}
      allstation2={allstation2}
      getStationLocation={getStationLocation}
      allFMS={allFMS}
      getFibre={getFibre}
      allfmslocation={allfmslocation}
      GetFMSLocationWiseAction={(id: number) =>
        GetFMSLocationWiseAction({ location_id: +id })
      }
      showFmsWiseCableAction={showFmsWiseCableAction}
      cable_list={cable_list}
      fibre={fibre}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    fibresEndToEnd: state.report.end_to_end_report,
    allzone: state.zone.zone_list,
    allstation: state.station.station_list,
    allFMS: state.fms.fms_list,
    allfmslocation: state.fmslocation.fmslocation_list,
    cable_list: state.cable.cable_list,
    fibre: state.report.fibrebycablefms,
    allstation2: state.station.station_list2,
  };
};
const mapDispatchToProps = {
  EndToEndFibreTraceAction,
  GetCableByService,
  getZoneStations,
  getStationLocation,
  GetDamageFibreLoad,
  EndtoEndClearAction,

  GetFMSLocationWiseAction,
  showFmsWiseCableAction,
  GetFibreByCableFms,
  EndToEndGetStation,
  GetAllZones,
};
export default connect(mapStateToProps, mapDispatchToProps)(EndToEndReport);
interface EndToEndReportProps {
  EndToEndFibreTraceAction?: any;
  fibresEndToEnd: any;
  allzone: ZoneList[];
  allstation: StationList[];
  allstation2: StationList[];
  getZoneStations?: any;
  getStationLocation?: any;
  allFMS: FMSList[];
  cable_list: any[];
  allfmslocation: FMSLocationList[];
  showFmsWiseCableAction?: any;
  GetDamageFibreLoad?: any;
  GetFibreByCableFms?: any;
  fibre: fibrebycablefms[];
  EndtoEndClearAction?: any;
  GetFMSLocationWiseAction?: any;
  EndToEndGetStation?: any;
  GetAllZones?: any;
}
