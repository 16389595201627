import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { TubeMater } from "../../../models/cableMasterModel";
import { StoreState } from "../../../models/reduxModels";
import {
  DeleteTubes,
  GetAllTube,
  GetTubeDetails,
} from "../../../Stores/actions/cableMasterActions";

// import { GetAllTube } from '../../../Stores/actions/tubeMasterAction';
import TubeMainView from "./TubeMainView";

function TubeMain({
  GetAllTube,
  alltube,
  DeleteTubes,
  GetTubeDetails,
  tube_deetail,
}: TubeMainProps) {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    GetAllTube();
  }, []);
  const OpenRow = (id: number) => {
    GetTubeDetails(id);
  };
  const Delete = (data: number) => {
    DeleteTubes({
      payload: data,
      enqueueSnackbar: enqueueSnackbar,
    });
  };

  return (
    <TubeMainView
      alltube={alltube}
      Delete={Delete}
      OpenRow={OpenRow}
      tube_deetail={tube_deetail}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    alltube: state.cable_master.tube_master,
    tube_deetail: state.cable_master.tube_detail,
  };
};

const mapDispatchToProps = {
  GetAllTube,
  DeleteTubes,
  GetTubeDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(TubeMain);
interface TubeMainProps {
  GetAllTube?: any;
  alltube: TubeMater[];
  DeleteTubes?: any;
  GetTubeDetails?: any;
  tube_deetail?: TubeMater;
}
