import React, { useEffect, useState } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import { connect } from "react-redux";

import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Position,
} from "react-flow-renderer";
import { StoreState } from "../../../models/reduxModels";
import { GetTraceFibreAction } from "../../../Stores/actions/connectivityAction";
import { useParams } from "react-router-dom";
import { TraceFibreList } from "../../../models/traceFibreModel";
import { Handle } from "react-flow-renderer";
import TracingFlow from "./TracingFlow";

const ServiceTracingMain = ({
  GetTraceFibreAction,
  tracing_fibre_list,
}: ServiceTracingMainProps) => {
  const initialNodes2: initialNodesTypes[] = [];
  const initialEdges2: initialEdgesType[] = [];
  const { sid, did }: any = useParams();
  useEffect(() => {
    if (!!sid && !!did) GetTraceFibreAction(+sid, +did);
  }, [sid, did]);
  const [initialNodeData, setInitialNodeData] = useState<initialNodesTypes[]>(
    []
  );
  const [initialNodeEdge, setInitialNodeEdge] = useState<initialEdgesType[]>(
    []
  );

  useEffect(() => {
    tracing_fibre_list.forEach((tracingList: TraceFibreList, index: number) => {
      if (index === 0) {
        initialNodes2.push({
          id: `${index}`,
          sourcePosition: "bottom",
          data: { data: tracingList, index: index },
          type: "customNode",
          position: { x: 0, y: index * 300 + 300 },
        });
      } else {
        initialNodes2.push({
          id: `${index}`,
          sourcePosition: "top",
          targetPosition: "bottom",
          data: { data: tracingList, index: index },
          type: "customNode",
          position: { x: 0, y: index * 300 + 300 },
        });
      }

      initialEdges2.push({
        id: `FMS-edge${index}`,
        source: `${index}`,
        target: `${index + 1}`,
        animated: true,
        type: "smoothstep",
        style: {
          strokeWidth: 6,
          stroke: "#00AAEE",
        },
      });
    });
    setInitialNodeData(initialNodes2);
    setInitialNodeEdge(initialEdges2);
  }, [tracing_fibre_list]);

  const nodeTypes = {
    customNode: Node,
  };

 

  return (
    <>
      <TracingFlow NodeData={initialNodeData} EdgeData={initialNodeEdge} />
    </>
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    tracing_fibre_list: state.connectivity.trace_fibre_list,
  };
};
const mapDispatchToProps = {
  GetTraceFibreAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceTracingMain);
interface ServiceTracingMainProps {
  GetTraceFibreAction?: any;
  tracing_fibre_list: TraceFibreList[];
}

interface positionType {
  x: number;
  y: number;
}
interface initialNodesTypes {
  id: string;
  sourcePosition: string;
  targetPosition?: string;
  data: any;
  position: positionType;
  style?: object;
  type?: any;
}
interface initialEdgesType {
  id: string;
  source?: string;
  type?: string;
  target?: string;
  animated?: boolean;
  label?: string;
  style?: any;
}
