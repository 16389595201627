import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  CableCreates,
  ColorCode,
  FibreMater,
  TubeMater,
} from "../../../models/cableMasterModel";
import { DomainList } from "../../../models/domainModel";
import { StoreState } from "../../../models/reduxModels";
import {
  CableCreate,
  FibreCreateLoads,
  GetAllTube,
  GetCableDetails,
} from "../../../Stores/actions/cableMasterActions";
import { DomainTypes } from "../../../Stores/actions/domainAction";
import CableAddView from "./CableAddView";

function AddCable({
  color,
  FibreCreateLoads,
  alltube,
  GetAllTube,
  CableCreate,
  cabletype,
  cablestrength,
  DomainTypes,
}: AddCableProps) {
  useEffect(() => {
    DomainTypes("cable_type");
    DomainTypes("cable_strength");
  }, []);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    FibreCreateLoads();
    GetAllTube();
  }, []);
  const submitData = (props: any) => {
    let cab: CableCreates = {
      cable_code: props.cable_code,
      cable_colour_code: props.cable_colour_code,
      cable_desc: props.cable_desc,
      cable_make: props.cable_make,
      // cable_name: props.cable_name,
      cable_strength: props.cable_strength,
      cable_type: props.cable_type,
      no_of_tubes: +props.no_of_tubes,
      tubes: props.tubes,
    };
    CableCreate({
      payload: cab,
      history: history,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
    <CableAddView
      submitData={submitData}
      color={color}
      alltube={alltube}
      cabletype={cabletype}
      cablestrength={cablestrength}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    color: state.cable_master.color_code,
    alltube: state.cable_master.tube_master,
    cabletype: state.domain.cable_type,
    cablestrength: state.domain.cable_strength,
  };
};
const mapDispatchToProps = {
  FibreCreateLoads,
  GetAllTube,
  CableCreate,
  DomainTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCable);
interface AddCableProps {
  FibreCreateLoads?: any;
  color: ColorCode[];
  GetAllTube?: any;
  alltube: TubeMater[];
  CableCreate?: any;
  DomainTypes?: any;
  cabletype?: DomainList[];
  cablestrength?: DomainList[];
}
