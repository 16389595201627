import React from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import AssetMain from "./AssetMain"
const Assets = () => {
    const {path,url}=useRouteMatch()
  return (
   <Switch>
       <Route 
            exact
            path={`${path}`}
            render={()=><Redirect to={`${path}/assets-main`}/>}
       />
       <Route 
            exact
            path={`${path}/assets-main`}
            render={()=><AssetMain/>}
       />
       
   </Switch>
  )
}

export default Assets