
import { DomainList } from "../../models/domainModel";
import {
  DomainType,
  
} from "../../Services/Services";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { UserLogoutSuccess } from "./userAction";
export enum DomainActionTypes {
  Domain_Types_Success_Action = "[Domain] Domain Type Success Action",
}

export const DomainTypes = (inputData: string) => {
  
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "",
        })
      );
      return DomainType({domain_type:inputData})
        .then((response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            dispatch(DomainTypesSuccess(response.data,inputData));
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // showToast("Please Login again to continue.");
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
          else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
          }
        });
    };
  };
  export const DomainTypesSuccess = (dataVal: any,keyVal:string) => {
    return {
      type: DomainActionTypes.Domain_Types_Success_Action,
      payload:{key:keyVal,data:dataVal},
      
    };
  };