import { useState, useEffect } from "react";
import { CableList } from "../../../models/cableModel";
import { DomainList } from "../../../models/domainModel";
import { FMSList } from "../../../models/fmsModel";
import ReportFMSTable from "./ReportFMSTable";
import ReportCablesTable from "./ReportCablesTable";
import { useSnackbar } from "notistack";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  TablePagination,
  Fab,
  styled,
  TableCell,
  tableCellClasses,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
  Tooltip,
  Card,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Controller, useForm, useWatch } from "react-hook-form";
import Autocompletes from "../../../components/Autocompletes";
import { ReportData } from "../../../models/ReportModel";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { ServiceList } from "../../../models/serviceMasterModel";
import { StationList } from "../../../models/stationModel";
import StationTable from "./StationTable";
import ServiceTable from "./ServiceTable";
const ReportMainView = ({
  domainList,
  fmsList,
  cableList,
  GetReportData,
  reportData,
  allservice,
  allstation,
  ReportClearAction,
}: ReportMainViewProps) => {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [transactionType, setTransactionType] = useState<string>("");
  const [showTable, setShowTable] = useState<boolean>(false);
  const [selectedCode, setSelectedCode] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm();
  useEffect(() => {
    setValue("transaction_type", "FMS");
    setTransactionType("FMS");
    setValue("event_name", "All");
  }, []);
  const { errors } = formState;
  const [FabSatA, SetFabSatA] = useState<any | undefined>();
  const [FabSatB, SetFabSatB] = useState<any | undefined>();

  const ShowReportData = async (props: any) => {
    ReportClearAction();
    GetReportData(
      props.transaction_type,
      props.transaction_type == "FMS"
        ? !!props.fms_id
          ? props.fms_id
          : null
        : props.transaction_type == "Cables"
        ? !!props.cable_detail_id
          ? props.cable_detail_id
          : null
        : props.transaction_type == "Station"
        ? !!props.station_id
          ? props.station_id
          : null
        : !!props.service_id
        ? props.service_id
        : null,
      props.event_name != "All" ? props.event_name : null,
      props.start_date,
      props.end_date
    );
    setShowTable(true);
  };
  const Events: any[] = [
    { Keys: "Add", value: "ADD" },
    { Keys: "Update", value: "UPDATE" },
    { Keys: "Delete", value: "DELETE" },
    { Keys: "All", value: "ALL" },
  ];
  useEffect(() => {
    setValue("cable_detail_id", null);
    setValue("fms_id", null);
  }, [transactionType]);
  useEffect(() => {
    let start_date: any = new Date();
    setValue("end_date", start_date);
    let end_date: any = new Date(start_date).getTime() - 7776000000;
    setValue("start_date", new Date(end_date));
    SetFabSatA(end_date);
    SetFabSatB(start_date);
  }, []);
  const generatePdf = () => {
    if (transactionType === "FMS") {
      var temp: any[] = [];
      for (let i = 0; i < reportData.length; i++) {
        temp[i] = [
          JSON.parse(reportData[i].transaction_data).fms_code,
          reportData[i].event_name,
          JSON.parse(reportData[i].transaction_data).fms_type,
          JSON.parse(reportData[i].transaction_data).fms_installation_name,
          JSON.parse(reportData[i].transaction_data).fms_installation_type,

          moment(reportData[i].updated_at).format("DD/MM/YYYY  hh:mm:ss a"),
          !!reportData[i].user_name ? reportData[i].user_name : "NA",
        ];
      }
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      var dd: any = {
        pageOrientation: "landscape",
        content: [
          { text: "FMS Log History", style: "header" },
          {
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*", "*", "*", "*"],
              body: [
                [
                  { text: "FMS Code ", style: "tableHeader" },
                  { text: "Event Type ", style: "tableHeader" },
                  { text: "FMS Type ", style: "tableHeader" },
                  {
                    text: "Installation Name ",
                    style: "tableHeader",
                  },
                  {
                    text: "Installation Type ",
                    style: "tableHeader",
                  },
                  { text: "Updated At ", style: "tableHeader" },
                  { text: "Updated By ", style: "tableHeader" },
                ],

                ...temp,
              ],
            },
            layout: {
              fillColor: function (rowIndex: any) {
                return rowIndex % 2 === 0 ? "#CCCCCC" : null;
              },
              hLineWidth: function (i: any, node: any) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function (i: any, node: any) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              hLineColor: function (i: any, node: any) {
                return i === 0 || i === node.table.body.length
                  ? "black"
                  : "gray";
              },
              vLineColor: function (i: any, node: any) {
                return i === 0 || i === node.table.widths.length
                  ? "black"
                  : "gray";
              },
              // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
              // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
              // paddingLeft: function(i, node) { return 4; },
              // paddingRight: function(i, node) { return 4; },
              // paddingTop: function(i, node) { return 2; },
              // paddingBottom: function(i, node) { return 2; },
              // fillColor: function (rowIndex, node, columnIndex) { return null; }
            },
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
            color: "#9d56a2",
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5],
          },
          tableExample: {
            margin: [0, 5, 0, 15],
            fontSize: 12,
          },
          tableHeader: {
            bold: true,
            fontSize: 12,
            color: "white",
            fillColor: "#035779",
          },
        },
        defaultStyle: {},
      };
    } else if (transactionType === "Cables") {
      var temp: any[] = [];
      for (let i = 0; i < reportData.length; i++) {
        temp[i] = [
          //   console.log(
          //     JSON.parse(reportData[0].transaction_data)
          //       .cable_strength
          //   ),
          !!JSON.parse(reportData[i].transaction_data)?.cableInfo
            ? JSON.parse(reportData[i].transaction_data).cableInfo
                ?.cable_short_code
            : `N/A`,
          !!JSON.parse(reportData[i].transaction_data)?.cableInfo &&
          !!JSON.parse(reportData[i].transaction_data)?.cableInfo
            ?.source_fms_code?.source_fms_code
            ? JSON.parse(reportData[i].transaction_data).cableInfo
                ?.source_fms_code
            : `N/A`,
          !!JSON.parse(reportData[i].transaction_data)?.sourceCableInfo &&
          JSON.parse(reportData[i].transaction_data)?.sourceCableInfo.length >
            0 &&
          JSON.parse(reportData[i].transaction_data).sourceCableInfo[0]
            ?.station_name
            ? JSON.parse(reportData[i].transaction_data).sourceCableInfo[0]
                ?.station_name
            : `N/A`,
          !!JSON.parse(reportData[i].transaction_data)?.cableInfo
            ?.destination_fms_code
            ? JSON.parse(reportData[i].transaction_data).cableInfo
                ?.destination_fms_code
            : `N/A`,
          !!JSON.parse(reportData[i].transaction_data)?.destinationCableInfo &&
          JSON.parse(reportData[i].transaction_data)?.destinationCableInfo
            .length > 0 &&
          !!JSON.parse(reportData[i].transaction_data)?.destinationCableInfo[0]
            .station_name
            ? JSON.parse(reportData[i].transaction_data).destinationCableInfo[0]
                ?.station_name
            : `N/A`,
          !!JSON.parse(reportData[i].transaction_data)?.cableInfo?.cable_type
            ? JSON.parse(reportData[i].transaction_data).cableInfo?.cable_type
            : `N/A`,
          !!JSON.parse(reportData[i].transaction_data)?.cableInfo
            ?.cable_actual_length
            ? `${
                JSON.parse(reportData[i].transaction_data).cableInfo
                  ?.cable_actual_length
              } mt`
            : `N/A`,
          //
          //   ?
          //       JSON.parse(reportData[i].transaction_data).cableInfo
          //         ?.cable_actual_length

          //   : `N/A`,
          !!reportData[i].updated_at
            ? moment(reportData[i].updated_at).format("DD/MM/YYYY  hh:mm:ss a")
            : "NA",

          !!reportData[i].user_name ? reportData[i].user_name : "NA",
        ];
      }
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      var dd: any = {
        pageOrientation: "landscape",
        content: [
          { text: "Cable Log History", style: "header" },
          {
            style: "tableExample",
            widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*"],
            table: {
              headerRows: 1,
              body: [
                [
                  {
                    text: "Cable Short Code ",
                    style: "tableHeader",
                  },
                  {
                    text: "FMS Code (Source A)",
                    style: "tableHeader",
                  },
                  {
                    text: "Station Name (Source A) ",
                    style: "tableHeader",
                  },
                  {
                    text: "FMS Code (Source B)",
                    style: "tableHeader",
                  },
                  {
                    text: "Station Name (Source B) ",
                    style: "tableHeader",
                  },
                  {
                    text: "Cable Type ",
                    style: "tableHeader",
                  },
                  {
                    text: "Cable Length ",
                    style: "tableHeader",
                  },
                  {
                    text: "Updated At ",
                    style: "tableHeader",
                  },
                  {
                    text: "Updated By ",
                    style: "tableHeader",
                  },
                ],

                ...temp,
              ],
            },
            layout: {
              fillColor: function (rowIndex: any) {
                return rowIndex % 2 === 0 ? "#CCCCCC" : null;
              },
              hLineWidth: function (i: any, node: any) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function (i: any, node: any) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              hLineColor: function (i: any, node: any) {
                return i === 0 || i === node.table.body.length
                  ? "black"
                  : "gray";
              },
              vLineColor: function (i: any, node: any) {
                return i === 0 || i === node.table.widths.length
                  ? "black"
                  : "gray";
              },
              // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
              // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
              // paddingLeft: function(i, node) { return 4; },
              // paddingRight: function(i, node) { return 4; },
              // paddingTop: function(i, node) { return 2; },
              // paddingBottom: function(i, node) { return 2; },
              // fillColor: function (rowIndex, node, columnIndex) { return null; }
            },
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
            color: "#9d56a2",
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5],
          },
          tableExample: {
            margin: [0, 5, 0, 15],
            fontSize: 12,
          },
          tableHeader: {
            bold: true,
            fontSize: 12,
            color: "white",
            fillColor: "#035779",
          },
        },
        defaultStyle: {
          // alignment: 'justify'
        },
      };
    } else if (transactionType === "Station") {
      var temp: any[] = [];
      for (let i = 0; i < reportData.length; i++) {
        temp[i] = [
          JSON.parse(reportData[i].transaction_data).station_name,
          JSON.parse(reportData[i].transaction_data).station_code,
          JSON.parse(reportData[i].transaction_data).station_type,
          JSON.parse(reportData[i].transaction_data).longitude,
          JSON.parse(reportData[i].transaction_data).lattitude,
          // JSON.parse(reportData[i].transaction_data)
          //   .station_address,
          reportData[i].event_name,
          // reportData[i].updated_at,
          moment(reportData[i].updated_at).format("DD/MM/YYYY  hh:mm:ss a"),
          !!reportData[i].user_name ? reportData[i].user_name : "NA",
        ];
      }
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      var dd: any = {
        pageOrientation: "landscape",
        content: [
          { text: "Station Log History", style: "header" },
          {
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*", "*", "*", "*", "*"],
              body: [
                [
                  {
                    text: "Station Name ",
                    style: "tableHeader",
                  },
                  {
                    text: "Station Code ",
                    style: "tableHeader",
                  },
                  {
                    text: "Station Type ",
                    style: "tableHeader",
                  },
                  {
                    text: "Longitude ",
                    style: "tableHeader",
                  },
                  {
                    text: "Lattitude ",
                    style: "tableHeader",
                  },
                  // {
                  //   text: "Station Address ",
                  //   style: "tableHeader",
                  // },
                  { text: "Event Type ", style: "tableHeader" },
                  { text: "Updated At ", style: "tableHeader" },
                  { text: "Updated By ", style: "tableHeader" },
                ],

                ...temp,
              ],
            },
            layout: {
              fillColor: function (rowIndex: any) {
                return rowIndex % 2 === 0 ? "#CCCCCC" : null;
              },
              hLineWidth: function (i: any, node: any) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function (i: any, node: any) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              hLineColor: function (i: any, node: any) {
                return i === 0 || i === node.table.body.length
                  ? "black"
                  : "gray";
              },
              vLineColor: function (i: any, node: any) {
                return i === 0 || i === node.table.widths.length
                  ? "black"
                  : "gray";
              },
              // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
              // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
              // paddingLeft: function(i, node) { return 4; },
              // paddingRight: function(i, node) { return 4; },
              // paddingTop: function(i, node) { return 2; },
              // paddingBottom: function(i, node) { return 2; },
              // fillColor: function (rowIndex, node, columnIndex) { return null; }
            },
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
            color: "#9d56a2",
          },
          subheader: {
            fontSize: 12,
            bold: true,
            margin: [0, 10, 0, 5],
          },
          tableExample: {
            margin: [0, 5, 0, 15],
            fontSize: 12,
          },
          tableHeader: {
            bold: true,
            fontSize: 12,
            color: "white",
            fillColor: "#035779",
          },
        },
        defaultStyle: {
          // alignment: 'justify'
        },
      };
    } else if (transactionType === "Service") {
      var temp: any[] = [];
      for (let i = 0; i < reportData.length; i++) {
        temp[i] = [
          JSON.parse(reportData[i].transaction_data).service_name,
          JSON.parse(reportData[i].transaction_data).service_desc,
          reportData[i].event_name,
          moment(reportData[i].updated_at).format("DD/MM/YYYY  hh:mm:ss a"),
          !!reportData[i].user_name ? reportData[i].user_name : "NA",
        ];
      }
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      var dd: any = {
        pageOrientation: "landscape",
        content: [
          { text: "Service Log History", style: "header" },
          {
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*", "*"],
              body: [
                [
                  {
                    text: "Service Name ",
                    style: "tableHeader",
                  },
                  {
                    text: "Service Description ",
                    style: "tableHeader",
                  },
                  { text: "Event Type ", style: "tableHeader" },
                  {
                    text: "Updated At ",
                    style: "tableHeader",
                  },
                  {
                    text: "Updated By ",
                    style: "tableHeader",
                  },
                ],

                ...temp,
              ],
            },
            layout: {
              fillColor: function (rowIndex: any) {
                return rowIndex % 2 === 0 ? "#CCCCCC" : null;
              },
              hLineWidth: function (i: any, node: any) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function (i: any, node: any) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              hLineColor: function (i: any, node: any) {
                return i === 0 || i === node.table.body.length
                  ? "black"
                  : "gray";
              },
              vLineColor: function (i: any, node: any) {
                return i === 0 || i === node.table.widths.length
                  ? "black"
                  : "gray";
              },
              // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
              // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
              // paddingLeft: function(i, node) { return 4; },
              // paddingRight: function(i, node) { return 4; },
              // paddingTop: function(i, node) { return 2; },
              // paddingBottom: function(i, node) { return 2; },
              // fillColor: function (rowIndex, node, columnIndex) { return null; }
            },
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
            color: "#9d56a2",
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5],
          },
          tableExample: {
            margin: [0, 5, 0, 15],
            fontSize: 12,
          },
          tableHeader: {
            bold: true,
            fontSize: 12,
            color: "white",
            fillColor: "#035779",
          },
        },
        defaultStyle: {
          // alignment: 'justify'
        },
      };
    }

    pdfMake.createPdf(dd).open();
  };
  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange1("panel1")}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              endIcon={<FilterListIcon />}
              sx={{ color: "#fff" }}
              onClick={() => handleChange1("panel1")}
            >
              Filter
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <form>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Start Date
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"start_date"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <DesktopDatePicker
                          inputFormat="DD/MM/yyyy"
                          value={value}
                          //   onChange={onChange}
                          onChange={(value: any) => (
                            SetFabSatA(value), onChange(value)
                          )}
                          renderInput={(params: any) => (
                            <TextField
                              style={{ width: "100%", margin: "5px" }}
                              onBlur={onBlur}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      End Date
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"end_date"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <DesktopDatePicker
                          inputFormat="DD/MM/yyyy"
                          value={value}
                          //   onChange={onChange}
                          onChange={(value: any) => (
                            SetFabSatB(value), onChange(value)
                          )}
                          renderInput={(params: any) => (
                            <TextField
                              style={{ width: "100%", margin: "5px" }}
                              onBlur={onBlur}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container justifyContent="space-around" alignItems="start">
              <Grid item xs={5}>
                {new Date(FabSatB).getTime() - new Date(FabSatA).getTime() >
                  7776000000 && (
                  <h6 style={{ color: "red", fontSize: 16, margin: " 10px 0" }}>
                    Please Select date difference between 3 months*
                  </h6>
                )}
                {new Date(FabSatA).getTime() > new Date(FabSatB).getTime() && (
                  <h6 style={{ color: "red", fontSize: 16, margin: " 10px 0" }}>
                    End Date Should Always Grater Than Start Date*
                  </h6>
                )}
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>

            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                {/* grid container */}
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select Transaction Type
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"transaction_type"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          infoEvent={() => {}}
                          option={domainList}
                          keys={"domain_value"}
                          label={"domain_value"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value),
                            setTransactionType(value),
                            setShowTable(false)
                          )}
                          placeholder={"Choose FMS "}
                          value={value}
                          errors={errors["fms_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {!!transactionType && transactionType === "FMS" && (
                    <>
                      <Grid
                        item
                        xs={4}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography style={{ fontWeight: 550 }}>
                          Select FMS
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography style={{ fontWeight: 550 }}>:</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Controller
                          control={control}
                          name={"fms_id"}
                          defaultValue={""}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              infoEvent={() => {}}
                              option={fmsList}
                              keys={"fms_id"}
                              label={"fms_code"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(+value), setShowTable(false)
                              )}
                              placeholder={"Choose FMSLocation"}
                              value={value}
                              errors={errors["fms_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  {!!transactionType && transactionType === "Cables" && (
                    <>
                      <Grid
                        item
                        xs={4}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography style={{ fontWeight: 550 }}>
                          Select Cables
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography style={{ fontWeight: 550 }}>:</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Controller
                          control={control}
                          name={"cable_detail_id"}
                          defaultValue={""}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              infoEvent={() => {}}
                              option={cableList}
                              keys={"cable_detail_id"}
                              label={"cable_short_code"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(+value), setShowTable(false)
                              )}
                              placeholder={"Choose Cable"}
                              value={value}
                              errors={errors["cable_detail_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  {!!transactionType && transactionType === "Station" && (
                    <>
                      <Grid
                        item
                        xs={4}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography style={{ fontWeight: 550 }}>
                          Select Station
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography style={{ fontWeight: 550 }}>:</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Controller
                          control={control}
                          name={"station_id"}
                          defaultValue={""}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              infoEvent={() => {}}
                              option={allstation}
                              keys={"station_id"}
                              label={"station_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(+value), setShowTable(false)
                              )}
                              placeholder={"Choose Station"}
                              value={value}
                              errors={errors["station_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  {!!transactionType && transactionType === "Service" && (
                    <>
                      <Grid
                        item
                        xs={4}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography style={{ fontWeight: 550 }}>
                          Select Service
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography style={{ fontWeight: 550 }}>:</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Controller
                          control={control}
                          name={"service_id"}
                          defaultValue={""}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              infoEvent={() => {}}
                              option={allservice}
                              keys={"service_id"}
                              label={"service_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(+value), setShowTable(false)
                              )}
                              placeholder={"Choose Service"}
                              value={value}
                              errors={errors["service_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              {/* grid container */}
            </Grid>

            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select Events
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"event_name"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          infoEvent={() => {}}
                          option={Events}
                          keys={"Keys"}
                          label={"value"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Choose Event"}
                          value={value}
                          errors={errors["event_name"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                {/* {FabSatB?._d - FabSatA?._d > 7948801000 && (
                  <h6 style={{ color: "red" }}>
                    Please Select date difference between 3 months
                  </h6>
                )} */}
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end ",
                marginTop: "1.5rem",
                marginRight: "3rem",
              }}
            >
              {new Date(FabSatB).getTime() - new Date(FabSatA).getTime() <=
                7776000000 &&
                new Date(FabSatB).getTime() > new Date(FabSatA).getTime() && (
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#2e7d32",
                      margin: "0 1rem",
                    }}
                    onClick={handleSubmit(ShowReportData)}
                  >
                    Show
                  </Button>
                )}

              {!!showTable && !!reportData && !!reportData.length && (
                <Button
                  variant="contained"
                  sx={{ color: "#fff" }}
                  onClick={() => generatePdf()}
                >
                  Generate PDF
                </Button>
              )}
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>
      {showTable && transactionType === "FMS" && !!reportData && (
        <ReportFMSTable
          tableData={reportData}
          transactionTypes={transactionType}
          selectedFMSCode={selectedCode}
        />
      )}
      {showTable && transactionType === "Cables" && !!reportData && (
        <ReportCablesTable
          tableData={reportData}
          transactionTypes={transactionType}
          selectedCableCode={selectedCode}
        />
      )}
      {showTable && transactionType === "Station" && !!reportData && (
        <StationTable
          tableData={reportData}
          transactionTypes={transactionType}
        />
      )}
      {showTable && transactionType === "Service" && !!reportData && (
        <ServiceTable
          tableData={reportData}
          transactionTypes={transactionType}
        />
      )}
    </Paper>
  );
};

export default ReportMainView;
interface ReportMainViewProps {
  domainList: DomainList[];
  fmsList: FMSList[];
  cableList: CableList[];
  GetReportData?: any;
  reportData: ReportData[];
  allservice: ServiceList[];
  allstation: StationList[];
  ReportClearAction?: any;
}
