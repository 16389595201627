import { SwitchRightTwoTone } from "@mui/icons-material";
import { ReportMainModel } from "../../models/ReportModel";
import { ReportActionTypes } from "../actions/ReportAction";
import InitialState from "./initialState";

const initialState: ReportMainModel = InitialState.report;
export default function ReportReducer(
  state: ReportMainModel = initialState,
  action: any
) {
  switch (action.type) {
    case ReportActionTypes.Get_Report_data_success:
      return {
        ...state,
        report_data: action.payload,
      };
    case ReportActionTypes.Get_Asset_Data_Success:
      return {
        ...state,
        assets: action.payload,
      };
    case ReportActionTypes.Get_Damage_Fibre_Get_Success:
      return { ...state, damage_fibre: action.payload };
    case ReportActionTypes.Get_Utilz_Cable_Get_Success:
      return { ...state, utilz_cable: action.payload };
    case ReportActionTypes.Get_Cable_By_Service_Success:
      return { ...state, cable_by_service: action.payload };
    case ReportActionTypes.Get_Report_By_Cable_Success:
      return { ...state, cable_report: action.payload };
    case ReportActionTypes.End_To_End_Fibre_Trace_Success:
      return { ...state, end_to_end_report: action.payload };
    case ReportActionTypes.Get_Report_data_Clear:
      return { ...state, report_data: [] };
    case ReportActionTypes.Get_Fibre_By_Cable_Fms:
      return { ...state, fibrebycablefms: action.payload };
    case ReportActionTypes.End_To_End_Clear_Action:
      return { ...state, end_to_end_report: undefined };
    case ReportActionTypes.Cable_By_Service_Clear_Action:
      return { ...state, cable_by_service: [] };
    case ReportActionTypes.Cable_Wise_Report_Clear_Action:
      return { ...state, cable_report: [] };
    case ReportActionTypes.Service_Wise_Cable_Detail_Load_Success_Action:
      return { ...state, cable_report_by_service: action.payload };
    case ReportActionTypes.Bulk_Upload_Report_Load_Success_Action:
      return { ...state, BulkUploadReport: action.payload };
    case ReportActionTypes.Bulk_Upload_Report_Load2_Success_Action:
      return { ...state, BulkUploadReport: action.payload };
    default:
      return state;
  }
}
