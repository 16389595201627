import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CableMsterList } from "../../../models/cableMasterModel";
import {
  CableAdd,
  CableDetails,
  CableList,
  PatchFibre,
} from "../../../models/cableModel";
import { FMSList } from "../../../models/fmsModel";
import { StoreState } from "../../../models/reduxModels";
import { DomainTypes } from "../../../Stores/actions/domainAction";
import { cableColorList } from "../../../models/cableColorModel";
import {
  CableCreateAction,
  CableDetailLoadAction,
  CableDetailLoadSuccessAction,
  CableServiceUpdateAction,
  CableUpdateAction,
  GetAllCableAction,
  GetFDB2ByFMS,
  GetFDB4ByFMS,
  GetFDBByFMS,
  GetFDBFibre,
} from "../../../Stores/actions/cableAction";
//import {GetAllFDBByFMS} from "../../../Stores/actions/fmsAction"
import { GetAllCableMaster } from "../../../Stores/actions/cableMasterActions";
import { GetALLFMS } from "../../../Stores/actions/fmsAction";
import CableAddView from "./CableAddView";
import { DomainList } from "../../../models/domainModel";
import { getAllCableColor } from "../../../Stores/actions/cableAction";
import { ServiceList } from "../../../models/serviceMasterModel";
import { GetAllServices } from "../../../Stores/actions/serviceMasterAction";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Fab, Grid, IconButton } from "@mui/material";
import { UserDetails } from "../../../models/userModels";
function AddCable({
  CableCreateAction,
  DomainTypes,
  cableTypes,
  fibreStatus,
  fibreTypes,
  cableMake,
  terminatedStatus,
  adapterType,
  cableColor,
  getAllCableColor,
  GetAllServices,
  allservice,
  cable_det,
  edit_flag,
  CableDetailLoadAction,
  CableUpdateAction,
  CableServiceUpdateAction,
  selectedFMS,
  FDB1,
  FDB,
  GetFDB2ByFMS,
  GetFDBByFMS,
  FDB3,
  GetFDB4ByFMS,
  GetFDBFibre,
  patch_fibre,
  user,
  CableDetailLoadSuccessAction,
}: AddCableProps) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  let { id }: any = useParams();
  useEffect(() => {
    CableDetailLoadSuccessAction(undefined);
    GetAllServices();
    DomainTypes("cable_type");
    DomainTypes("fibre_status");
    DomainTypes("fibre_type");
    DomainTypes("cable_make");
    DomainTypes("terminated_status");
    DomainTypes("adapter_type");
    getAllCableColor();
    if (+id > 0) {
      CableDetailLoadAction(+id);
    }
  }, []);
  const submitData = (props: any) => {
    CableCreateAction({
      payload: props,
      enqueueSnackbar: enqueueSnackbar,
      history: history,
    });
  };
  const cableUpdate = (props: any) => {
    CableUpdateAction({
      payload: props,
      enqueueSnackbar: enqueueSnackbar,
      history: history,
    });
  };
  const UpdateFibre = (props: any) => {
    CableServiceUpdateAction({
      payload: props,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  useEffect(() => {
    if (
      !!cable_det &&
      !!cable_det.cableInfo &&
      !!cable_det.cableInfo.source_fms_type &&
      (cable_det.cableInfo.source_fms_type == "TJB" ||
        cable_det.cableInfo.source_fms_type == "GCO")
    ) {
      GetFDBByFMS(cable_det.cableInfo.source_fms);
    }
    if (
      !!cable_det &&
      !!cable_det.cableInfo &&
      !!cable_det.cableInfo.destination_fms_type &&
      (cable_det.cableInfo.destination_fms_type == "TJB" ||
        cable_det.cableInfo.destination_fms_type == "GCO")
    ) {
      GetFDB2ByFMS(cable_det.cableInfo.destination_fms);
    }
  }, [cable_det]);
  const getFMS = (fms_id: number) => {
    GetFDB4ByFMS(fms_id);
  };
  const GetFibre = (fms_id: number, type: string) => {
    GetFDBFibre(fms_id, type);
  };

  return (
    <>
      <Grid container justifyContent={"end"}>
        <Grid
          item
          marginTop={-5}
          marginBottom={"5px"}
          zIndex={999}
          position={"fixed"}
        >
          <Fab
            size={"small"}
            onClick={() => +id != 0 && CableDetailLoadAction(+id)}
            color="primary"
            aria-label="add"
          >
            <RefreshIcon style={{ color: "#FFFF" }} />
          </Fab>
        </Grid>
      </Grid>
      <CableAddView
        submitData={submitData}
        cableTypes={cableTypes}
        fibreStatus={fibreStatus}
        terminatedStatus={terminatedStatus}
        fibreTypes={fibreTypes}
        cableMake={cableMake}
        adapterType={adapterType}
        allservice={allservice}
        cableColor={cableColor}
        cable_det={cable_det}
        edit_flag={edit_flag}
        cableUpdate={cableUpdate}
        UpdateFibre={UpdateFibre}
        selectedFMS={selectedFMS}
        FDB={FDB}
        FDB1={FDB1}
        getFMS={getFMS}
        FDB3={FDB3}
        GetFibre={GetFibre}
        patch_fibre={patch_fibre}
        userDetails={user}
      />
    </>
  );
}
const mapStateToProps = (state: StoreState) => {
  return {
    cableTypes: state.domain.cable_type,
    fibreStatus: state.domain.fibre_status,
    fibreTypes: state.domain.fibre_type,
    cableMake: state.domain.cable_make,
    terminatedStatus: state.domain.terminated_status,
    adapterType: state.domain.adapter_type,
    cableColor: state.cable_color,
    allservice: state.service.service_list,
    cable_det: state.cable.cable_det,
    edit_flag: state.cable.edit_flag,
    selectedFMS: state.cable.has_filter,
    FDB: state.cable.fdb_list,
    FDB1: state.cable.fdb_list2,
    FDB3: state.cable.fdb_list4,
    patch_fibre: state.cable.patch_fibre,
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  CableCreateAction,
  DomainTypes,
  getAllCableColor,
  GetAllServices,
  CableDetailLoadAction,
  CableUpdateAction,
  CableServiceUpdateAction,
  GetFDBByFMS,
  GetFDB2ByFMS,
  GetFDB4ByFMS,
  GetFDBFibre,
  CableDetailLoadSuccessAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCable);
interface AddCableProps {
  CableCreateAction?: any;
  DomainTypes?: any;
  cableTypes?: any;
  fibreStatus?: any;
  fibreTypes?: any;
  cableMake?: any;
  terminatedStatus?: any;
  adapterType?: any;
  getAllCableColor?: any;
  cableColor?: cableColorList[] | [];
  GetAllServices?: any;
  allservice: ServiceList[];
  cable_det?: CableDetails;
  edit_flag?: boolean;
  CableDetailLoadAction?: any;
  CableUpdateAction?: any;
  CableServiceUpdateAction?: any;
  selectedFMS?: FMSList;
  FDB: FMSList[];
  FDB1: FMSList[];
  GetFDBByFMS?: any;
  GetFDB2ByFMS?: any;
  FDB3: FMSList[];
  GetFDB4ByFMS?: any;
  GetFDBFibre?: any;
  patch_fibre: PatchFibre[];
  user?: UserDetails;
  CableDetailLoadSuccessAction?: any;
}
