import { Avatar, Grid, IconButton } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import React from "react";
import "./Header.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { AccountCircle } from "@mui/icons-material";
import { connect } from "react-redux";
import { StoreState } from "../../models/reduxModels";
import { UserDetails } from "../../models/userModels";
import CallIcon from "@mui/icons-material/Call";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import { UserLogoutSuccess } from "../../Stores/actions/userAction";
import { useHistory } from "react-router-dom";

const Header = ({
  setOpen,
  open,
  userDetail,
  UserLogoutSuccess,
}: headerProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const LogOut = () => {
    UserLogoutSuccess();
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Profile = () => {
    history.push("/user-profile/profile-main");
    setAnchorEl(null);
  };
  return (
    <div className="header">
      <div className="flex">
        {/* {!!userDetail && (
                    
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(!open)}
                        style={{marginTop: "27px",height: "37px",}}
                    >
                        <MenuIcon />
                    </IconButton>
                   
                )} */}

        <img
          style={{ width: "13%", marginLeft: 20 }}
          src={require("../../assets/RPSG-LOGO-FINAL.png")}
        />

        {/* <h1>Optical Fibre Management System</h1> */}
        <h1>OPTICAL FIBRE MANAGEMENT SYSTEM</h1>
        <img
          style={{ width: "13%", marginRight: 20 }}
          src={require("../../assets/CESC-LOGO-FINAL.png")}
        />
        {/* {!!userDetail && (
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            style={{marginTop: "20px",}}
          >
            <AccountCircle style={{ color: "#9e56a2", fontSize: "30px", }} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>LogOut</MenuItem>
          </Menu>
        </div>
      )} */}
      </div>
      <div className="secondflex">
        {!!userDetail && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            style={{ height: "37px", color: "#fff" }}
          >
            <MenuIcon fontSize="medium" />
          </IconButton>
        )}

        {!!userDetail && (
          <div style={{ margin: 5 }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              // style={{marginTop: "20px",}}
              style={{ paddingTop: "2px", paddingRight: "2px" }}
            >
              <AccountCircle style={{ color: "#fff", fontSize: "30px" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={Profile}>Profile</MenuItem>
              <MenuItem onClick={LogOut}>LogOut</MenuItem>
            </Menu>
          </div>
        )}
      </div>
    </div>

    // <Grid
    //   container
    //   spacing={0}
    //   alignItems="center"
    //   justifyContent={"center"}
    //   className="header"
    // >
    //   <Grid
    //     xs={4}
    //     container
    //     direction="row"
    //     justifyContent="center"
    //     alignItems="center"
    //   >
    //     {!!userDetail && (
    //       <Grid container xs={3} justifyContent="flex-start">
    //         <IconButton
    //           color="inherit"
    //           aria-label="open drawer"
    //           onClick={() => setOpen(!open)}
    //         >
    //           <MenuIcon />
    //         </IconButton>
    //       </Grid>
    //     )}
    // {/* <Grid container xs={9} justifyContent="flex-start">
    //   <img
    //     style={{ width: "40%" }}
    //     src={require("../../assets/CESC-LOGO-FINAL.png")}
    //   />
    // </Grid> */}
    //   </Grid>
    //   {/* <Grid
    //     item
    //     xs={4}
    //     className="text-styles"
    //     alignItems="center"
    //     justifyContent="center"
    //   >
    //     Optical Fibre Management System
    //   </Grid> */}
    //   <Grid
    //     container
    //     item
    //     xs={4}
    //     alignItems="end"
    //     justifyContent="end"
    //     paddingRight={5}
    //   >
    //     {!!userDetail && (
    //       <div>
    //         <IconButton
    //           size="large"
    //           aria-label="account of current user"
    //           aria-controls="menu-appbar"
    //           aria-haspopup="true"
    //           onClick={handleMenu}
    //           color="inherit"
    //         >
    //           <AccountCircle style={{ color: "#ffff", fontSize: "30px" }} />
    //         </IconButton>
    //         <Menu
    //           id="menu-appbar"
    //           anchorEl={anchorEl}
    //           anchorOrigin={{
    //             vertical: "top",
    //             horizontal: "right",
    //           }}
    //           keepMounted
    //           transformOrigin={{
    //             vertical: "top",
    //             horizontal: "right",
    //           }}
    //           open={Boolean(anchorEl)}
    //           onClose={handleClose}
    //         >
    //           <MenuItem onClick={handleClose}>Profile</MenuItem>
    //           <MenuItem onClick={handleClose}>LogOut</MenuItem>
    //         </Menu>
    //       </div>
    //     )}
    //   </Grid>
    // </Grid>
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    userDetail: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  UserLogoutSuccess,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
interface headerProps {
  setOpen?: any;
  open?: boolean;
  userDetail?: UserDetails;
  UserLogoutSuccess?: any;
}
