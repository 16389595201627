import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../components/Autocompletes";
import { CableList } from "../../../models/cableModel";
import { FMSList } from "../../../models/fmsModel";
import Zone from "../../Zone";

function ChooseFMSPopOver({
  onClose,
  open,
  fms_list,
  cab,
  Submit,
}: ChooseFMsPopoverProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
  } = useForm();
  const { errors } = formState;
  const onSubmit = (props: any) => {
    Submit(props);
  };
  const [FilterFMS, SetFilterFMS] = useState<FMSList[]>([]);
  useEffect(() => {
    if (!!cab && !!fms_list) {
      SetFilterFMS(
        fms_list.filter(
          (m) => m.fms_id === cab.source_fms || m.fms_id === cab.destination_fms
        )
      );
    }
  }, [cab]);
  return (
    <Dialog fullWidth open={open} onClose={() => onClose(false)}>
      <DialogTitle
        style={{
          backgroundColor: "#202a37",
          color: "#fff",
          height: 20,
          fontWeight: 500,
          fontSize: "1.1rem",
        }}
      >
        Choose FMS
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            justifyContent="center"
            marginTop={2}
            alignItems={"center"}
          >
            <Grid item xs={3}>
              <Typography style={{ fontWeight: 550 }}>FMS</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Controller
                control={control}
                name={"fms_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={FilterFMS}
                    keys={"fms_id"}
                    label={"fms_code"}
                    onBlur={onBlur}
                    onChange={(value: any) => onChange(value)}
                    placeholder={"FMS"}
                    value={value}
                    errors={errors["fms_id"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item>
              <Button
                onClick={() => onClose(false)}
                color={"error"}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{
                  marginRight: 10,
                }}
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{ color: "#fff" }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ChooseFMSPopOver;

interface ChooseFMsPopoverProps {
  open: boolean;
  onClose?: any;
  cab?: CableList;
  fms_list: FMSList[];
  Submit?: any;
}
