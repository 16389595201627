import FilterListIcon from "@mui/icons-material/FilterList";
import { Component } from "react";
import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import Autocompletes from "../../components/Autocompletes";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  Box,
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TableCell,
  styled,
} from "@mui/material";
import { ZoneList } from "../../models/zoneModels";
import { StationList } from "../../models/stationModel";
import { FMSLocationList } from "../../models/fmsLocationModel";
import { CableList, FMSList } from "../../models/fmsModel";
import { fibrebycablefms, UtilizeCable } from "../../models/ReportModel";

function EndToEndFibreReportView({
  Filters,
  fibresEndToEnd,
  allzone,
  allstation,
  getStationLocation,
  getZoneStations,
  allfmslocation,
  GetFMSLocationWiseAction,
  allFMS,
  showFmsWiseCableAction,
  cable_list,
  getFibre,
  fibre,
  allstation2,
}: //   Filters,
//   clear,
//   utilizeCable,
EndToEndFibreReportProps) {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm();
  const stationFilter = (id: number, n: number) => {
    getZoneStations(id, n);
  };
  const { errors } = formState;
  const Filter = (props: any) => {
    Filters({
      fibre_impl_id: props.fibre_impl_id,
      destination_station_id: props.station_id2,
    });
    setExpanded(false);
  };
  const Clear = () => {
    reset();
    // clear();
  };
  return (
    <Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange1("panel1")}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              endIcon={<FilterListIcon />}
              sx={{ color: "#fff" }}
              onClick={() => handleChange1("panel1")}
            >
              Filter
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <form>
            <Grid
              container
              justifyContent={"center"}
              style={{
                padding: 5,
                marginBottom: 20,
                width: "100%",
                backgroundColor: "#035779",
              }}
            >
              <Typography style={{ color: "#ffff", fontWeight: 600 }}>
                Source A
              </Typography>
            </Grid>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>Zone</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"zone_id"}
                      defaultValue={""}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          option={allzone}
                          keys={"zone_id"}
                          label={"zone_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), !!value && stationFilter(+value, 1)
                          )}
                          placeholder={"Zone"}
                          value={value}
                          errors={errors["zone_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Station
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"station_id"}
                        defaultValue={""}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            infoEvent={() => {}}
                            option={allstation}
                            keys={"station_id"}
                            label={"station_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value),
                              !!value && getStationLocation(+value)
                            )}
                            placeholder={"Station"}
                            value={value}
                            errors={errors["station_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select Location
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"location_id"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={allfmslocation}
                          keys={"location_id"}
                          label={"location_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value),
                            !!value && GetFMSLocationWiseAction(+value)
                          )}
                          placeholder={"Select Location"}
                          value={value}
                          errors={errors["location_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select FMS
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"fms_id"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={allFMS}
                          keys={"fms_id"}
                          label={"fms_code"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value),
                            !!value && showFmsWiseCableAction(+value),
                            !!value &&
                              !!getValues("cable_detail_id") &&
                              getFibre({
                                cable_detail_id: getValues("cable_detail_id"),
                                fms_id: value,
                              })
                          )}
                          placeholder={"Select FMS"}
                          value={value}
                          errors={errors["fms_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select Cable
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"cable_detail_id"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={cable_list}
                          keys={"cable_detail_id"}
                          label={"cable_short_code"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value),
                            !!value &&
                              !!getValues("fms_id") &&
                              getFibre({
                                cable_detail_id: value,
                                fms_id: getValues("fms_id"),
                              })
                          )}
                          placeholder={"Select Cable"}
                          value={value}
                          errors={errors["cable_detail_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select Fibre
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"fibre_impl_id"}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={fibre}
                          keys={"fibre_impl_id"}
                          label={"tube_colour"}
                          extras2={"fibre_colour"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Select Fibre"}
                          value={value}
                          errors={errors["fibre_impl_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"center"}
              style={{
                padding: 5,
                marginBottom: 20,
                width: "100%",
                backgroundColor: "#035779",
              }}
            >
              <Typography style={{ color: "#ffff", fontWeight: 600 }}>
                Source B
              </Typography>
            </Grid>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>Zone</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"zone_id2"}
                      defaultValue={""}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          option={allzone}
                          keys={"zone_id"}
                          label={"zone_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), !!value && stationFilter(+value, 2)
                          )}
                          placeholder={"Zone"}
                          value={value}
                          errors={errors["zone_id2"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Station
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"station_id2"}
                        defaultValue={""}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            infoEvent={() => {}}
                            option={allstation2}
                            keys={"station_id"}
                            label={"station_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Station"}
                            value={value}
                            errors={errors["station_id2"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end ",
                marginTop: "1.5rem",
                marginRight: "3rem",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#808090",
                }}
                onClick={() => Clear()}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#2e7d32",
                  margin: "0 1rem",
                }}
                onClick={handleSubmit(Filter)}
              >
                Show
              </Button>
              {!!fibresEndToEnd &&
                !!fibresEndToEnd.fibre &&
                fibresEndToEnd.fibre.length > 0 && (
                  <Button
                    variant="contained"
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      var temp: any[] = [];
                      for (let i = 0; i < fibresEndToEnd.fibre.length; i++) {
                        temp[i] = [
                          fibresEndToEnd.fibre[i].cable_short_code,
                          `${fibresEndToEnd.fibre[i].station_name}
                        ${fibresEndToEnd.fibre[i].fms_code}`,
                          fibresEndToEnd.fibre[i].tube_colour +
                            " - " +
                            fibresEndToEnd.fibre[i].fibre_colour,
                          `${fibresEndToEnd.fibre[i].fibre_status}
                        ${
                          !!fibresEndToEnd.fibre[i].ferule_name
                            ? fibresEndToEnd.fibre[i].ferule_name
                            : "N/A"
                        }`,
                          `${
                            fibresEndToEnd.fibre[i].service_name
                              ? fibresEndToEnd.fibre[i].service_name
                              : "N/A"
                          }
                        ${
                          !!fibresEndToEnd.fibre[i].des_service_desc
                            ? fibresEndToEnd.fibre[i].des_service_desc
                            : "N/A"
                        }`,

                          `${fibresEndToEnd.fibre[i].des_station_name}
                        ${fibresEndToEnd.fibre[i].des_fms_code}`,
                          fibresEndToEnd.fibre[i].des_tube_colour +
                            " - " +
                            fibresEndToEnd.fibre[i].des_fibre_colour,
                          `${fibresEndToEnd.fibre[i].des_fibre_status}
                        ${
                          !!fibresEndToEnd.fibre[i].des_ferule_name
                            ? fibresEndToEnd.fibre[i].des_ferule_name
                            : "N/A"
                        }`,
                          `${
                            fibresEndToEnd.fibre[i].des_service_name
                              ? fibresEndToEnd.fibre[i].des_service_name
                              : "N/A"
                          }
                        ${
                          !!fibresEndToEnd.fibre[i].des_service_desc
                            ? fibresEndToEnd.fibre[i].des_service_desc
                            : "N/A"
                        }`,
                          ` ${
                            (fibresEndToEnd.fibre[i].spliced_tube_colour ||
                              "") +
                            "-" +
                            (fibresEndToEnd.fibre[i].spliced_fibre_colour ||
                              " ")
                          }
                      ${
                        fibresEndToEnd.fibre[i].spliced_cable_short_code || " "
                      }`,
                          `${fibresEndToEnd.fibre[i].km_distance} km`,
                        ];
                      }
                      pdfMake.vfs = pdfFonts.pdfMake.vfs;
                      var dd: any = {
                        pageOrientation: "landscape",
                        content: [
                          {
                            text: "End To End Fibre Trace",
                            style: "header",
                          },
                          {
                            style: "tableExample",
                            table: {
                              headerRows: 1,
                              body: [
                                [
                                  {
                                    text: "Cable Short Code ",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Station / FMS code (Source A) ",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Tube / Fibre Color (Source A)",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Fibre Status / Ferule Name (Source A)",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Service (Source A)",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Station / FMS code (Source B)",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Tube / Fibre Color (Source B) ",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Fibre Status / Ferule Name (Source A)",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Service (Source B)",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Spliced With",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Fibre km",
                                    style: "tableHeader",
                                  },
                                ],

                                ...temp,
                              ],
                            },
                            layout: {
                              fillColor: function (rowIndex: any) {
                                return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                              },
                              hLineWidth: function (i: any, node: any) {
                                return i === 0 || i === node.table.body.length
                                  ? 2
                                  : 1;
                              },
                              vLineWidth: function (i: any, node: any) {
                                return i === 0 || i === node.table.widths.length
                                  ? 2
                                  : 1;
                              },
                              hLineColor: function (i: any, node: any) {
                                return i === 0 || i === node.table.body.length
                                  ? "black"
                                  : "gray";
                              },
                              vLineColor: function (i: any, node: any) {
                                return i === 0 || i === node.table.widths.length
                                  ? "black"
                                  : "gray";
                              },
                              // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                              // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                              // paddingLeft: function(i, node) { return 4; },
                              // paddingRight: function(i, node) { return 4; },
                              // paddingTop: function(i, node) { return 2; },
                              // paddingBottom: function(i, node) { return 2; },
                              // fillColor: function (rowIndex, node, columnIndex) { return null; }
                            },
                          },
                          {
                            style: "tableExample",
                            table: {
                              headerRows: 1,
                              body: [
                                [
                                  {
                                    text: "Total Fibre km ",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: `${fibresEndToEnd?.total_fibre_km} km`,
                                    style: "tableHeader",
                                  },
                                ],
                              ],
                            },
                          },
                        ],
                        styles: {
                          header: {
                            fontSize: 18,
                            bold: true,
                            margin: [0, 0, 0, 10],
                            color: "#9d56a2",
                          },
                          subheader: {
                            fontSize: 16,
                            bold: true,
                            margin: [0, 10, 0, 5],
                          },
                          tableExample: {
                            margin: [0, 5, 0, 15],
                            fontSize: 12,
                          },
                          tableHeader: {
                            bold: true,
                            fontSize: 12,
                            color: "white",
                            fillColor: "#035779",
                          },
                        },
                        defaultStyle: {
                          // alignment: 'justify'
                        },
                      };

                      pdfMake.createPdf(dd).open();
                    }}
                  >
                    Generate PDF
                  </Button>
                )}
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>

      <Paper
        elevation={3}
        sx={{
          width: "100%",
          margin: "1rem 0",
          paddingTop: "1rem",
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box>
            <Typography
              sx={{
                color: "gray",
                textAlign: "center",
                textShadow: "0px 0px 0px #fff, 3px 3px 0px rgba(0,0,0,0.15)",
                fontWeight: 620,
              }}
              variant="h5"
            >
              {" End To End Fibre Trace"}
            </Typography>
          </Box>
          <TableContainer sx={{ marginTop: "1.4rem" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Cable Short Code
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Station / FMS code (Source A)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Tube Fibre Color (Source A)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Fibre Status / Ferule Name (Source A)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Service (Source A)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Station - FMS code (Source B)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Tube Fibre Color (Source B)
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Fibre Status / Ferule Name (Source A)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Service (Source B)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Spliced With
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Fibre km
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!fibresEndToEnd?.fibre && fibresEndToEnd.fibre.length > 0 ? (
                  fibresEndToEnd.fibre.map((tData: any, index: number) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      <StyledTableCell>
                        {tData.cable_short_code}
                      </StyledTableCell>
                      <StyledTableCell>
                        {`${tData.station_name}  `} <br />
                        {tData.fms_code}
                      </StyledTableCell>
                      <StyledTableCell>
                        {tData.tube_colour} - {tData.fibre_colour}
                      </StyledTableCell>
                      <StyledTableCell>
                        {tData.fibre_status} <br />
                        {tData.ferule_name ? tData.ferule_name : `N/A`}
                      </StyledTableCell>
                      <StyledTableCell>
                        {tData.service_name ? tData.service_name : `N/A`} <br />
                        {tData.service_desc}
                      </StyledTableCell>
                      <StyledTableCell>
                        {tData.des_station_name} <br />
                        {tData.des_fms_code}
                      </StyledTableCell>
                      <StyledTableCell>
                        {tData.des_tube_colour} - {tData.des_fibre_colour}
                      </StyledTableCell>

                      <StyledTableCell>
                        {tData.des_fibre_status} <br />
                        {tData.des_ferule_name ? tData.des_ferule_name : `N/A`}
                      </StyledTableCell>
                      <StyledTableCell>
                        {tData.des_service_name
                          ? tData.des_service_name
                          : `N/A`}{" "}
                        <br />
                        {tData.des_service_desc}
                      </StyledTableCell>
                      <StyledTableCell>
                        {tData.spliced_tube_colour} {"-"}{" "}
                        {tData.spliced_fibre_colour} <br />
                        {tData.spliced_cable_short_code}{" "}
                      </StyledTableCell>
                      <StyledTableCell>{tData.km_distance} km</StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <tr
                    style={{
                      border: "1px solid gray",
                      textAlign: "center",
                    }}
                  >
                    <td
                      colSpan={10}
                      style={{
                        border: "1px solid gray",
                        borderCollapse: "collapse",
                      }}
                    >
                      No record found
                    </td>
                  </tr>
                )}
                {!!fibresEndToEnd?.total_fibre_km && (
                  <tr
                    style={{
                      textAlign: "center",
                      height: 30,
                      color: "#363636",
                    }}
                  >
                    <td colSpan={9} style={{ textAlign: "end" }}>
                      <div style={{ fontWeight: 650, fontSize: 20 }}>
                        Total Fibre km =
                      </div>
                    </td>
                    <td
                      colSpan={1}
                      style={{ textAlign: "start", color: "#363636" }}
                    >
                      <div style={{ fontWeight: 650, fontSize: 20 }}>
                        {" "}
                        {fibresEndToEnd?.total_fibre_km}
                        {"km "}
                      </div>
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* {!!fibresEndToEnd?.total_fibre_km && (
            <h1>
              {" "}
              {"Total Fibre km =" +
                " " +
                fibresEndToEnd?.total_fibre_km +
                " " +
                "km"}{" "}
            </h1>
          )} */}
        </Box>
      </Paper>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default EndToEndFibreReportView;
interface EndToEndFibreReportProps {
  allzone: ZoneList[];
  allstation: StationList[];
  cable_list: CableList[];
  allFMS: FMSList[];
  allfmslocation: FMSLocationList[];
  getZoneStations?: any;
  getStationLocation?: any;
  GetFMSLocationWiseAction?: any;
  showFmsWiseCableAction?: any;
  Filters?: any;
  fibresEndToEnd?: any;
  getFibre?: any;
  //   clear?: any;
  //   utilizeCable: UtilizeCable[];
  fibre: fibrebycablefms[];
  allstation2: StationList[];
}
