import React, { useState, useEffect } from "react";
import { FMSList } from "../../../models/fmsModel";

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  styled,
  tableCellClasses,
  TextField,
  Grid,
  Fab,
  SxProps,
  Zoom,
  useTheme,
  Breadcrumbs,
  Link,
  Typography,
  Chip,
  emphasize,
  Button,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Tooltip,
  Box,
  Collapse,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { FMSLocationList } from "../../../models/fmsLocationModel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import {
  DeleteButton,
  InfoButton,
  DetailButton,
} from "../../../components/Button";
import PreviewIcon from "@mui/icons-material/Preview";
import { UserDetails } from "../../../models/userModels";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import Autocompletes from "../../../components/Autocompletes";

const FMSMainView = ({
  allFMS,
  Delete,
  locationList,
  SetFilters,
  filterData,
  filters,
  goToCable,
  handleFilterLocWise,
  user,
  GetAllFDBByFMS,
  fdb_list,
  ClearAllFDBByFMS,
  ShowJointinfo,
  FmsSchematicView,
}: FMSProps) => {
  const theme = useTheme();
  const columns: any[] = [
    "Show FDB",
    "FMS Name",
    "Station",
    "FMS Location",
    "Installation Position",
    "FMS Type",
    "Installation Type",
    "Actions",
  ];
  const fdbColumns: any[] = [
    "FDB Name",
    "FDB Location",
    "Installation Position",
    "FDB Type",
    "Installation Type",
    "Actions",
  ];
  const [open, setOpen] = useState<boolean>(false);
  const [delId, SetDelId] = useState<number>(-1);
  const [isFDBOpen, setIsFDBOpen] = useState<number>(0);

  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<FMSList[]>([]);
  const [searched, setSearched] = useState<string>("");
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  const matchesmdTheme = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslgTheme = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    if (matchesmdTheme) {
      setIsLargeScreen(false);
    } else if (matcheslgTheme) {
      setIsLargeScreen(true);
    }
  }, [matchesmdTheme, matcheslgTheme]);

  const requestSearch = (searchVal: string) => {
    const filteredRows = !!allFMS
      ? allFMS?.filter((row: FMSList) => {
          return (
            row?.fms_name?.toLowerCase().includes(searchVal.toLowerCase()) ||
            row?.fms_code?.toLowerCase().includes(searchVal.toLowerCase()) ||
            row?.fms_type?.toLowerCase().includes(searchVal.toLowerCase()) ||
            row?.fms_installation_name
              ?.toLowerCase()
              .includes(searchVal.toLowerCase()) ||
            row?.fms_installation_type
              ?.toLowerCase()
              .includes(searchVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  useEffect(() => {
    if (!!allFMS) cancelSearch();
  }, [allFMS]);

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };
  const styles = {
    marginRight: "50px",
  };

  const del = () => {
    setOpen(false);
    Delete(delId);
  };

  const isExpanded = (data: number) => {
    //isFDBOpen,setIsFDBOpen,

    if (data === isFDBOpen) {
      setIsFDBOpen(0);
      ClearAllFDBByFMS();
    } else {
      setIsFDBOpen(0);
      ClearAllFDBByFMS();
      setIsFDBOpen(data);
      GetAllFDBByFMS(data);
    }

    // if(!!temp){
    //   let updatedList=isFDBOpen.filter((exp:number)=>exp!==data)
    //   setIsFDBOpen(updatedList)
    //   ClearAllFDBByFMS()
    // }else{
    //   ClearAllFDBByFMS()

    //   setIsFDBOpen(data)
    //   GetAllFDBByFMS(data)
    // }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this FMS ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            No
          </Button>
          <Button onClick={() => del()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Grid container justifyContent="center" alignItems="center" margin={1}>
          <Grid item xs={2}>
            {/*  this is for sidebar  */}
          </Grid>
          <Grid item xs={5}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => {
                requestSearch(e.target.value);
                setSearched(e.target.value);
              }}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {/* <TextField
              style={{ marginLeft: "10px", width: "80%" }}
              id="outlined-select-currency"
              select
              size="small"
              placeholder="Select Location"
              defaultValue={"0"}
              value={filters}
              onChange={(data) => {
                SetFilters(data.target.value);
                handleFilterLocWise(+data.target.value);
              }}
            >
              <MenuItem value={"0"}>All Location</MenuItem>
              {!!locationList &&
                locationList.map((option: FMSLocationList) => (
                  <MenuItem value={option.location_id} key={option.location_id}>
                    {option.station_name}({option.location_name})
                  </MenuItem>
                ))}
            </TextField> */}
            <Autocompletes
              option={locationList}
              keys={"location_id"}
              label={"station_name"}
              onChange={(value: any) => (
                SetFilters(value), handleFilterLocWise(+value)
              )}
              placeholder={"Filter..."}
              value={filters}
              extras={"location_name"}
            />
          </Grid>
        </Grid>
        <TableContainer sx={{ maxHeight: 480 }}>
          <Table stickyHeader sx={{ overflowX: "hidden" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell align="justify" key={column}>
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <>
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.fms_id}
                        >
                          <StyledTableCell align="left" key={columns[0]}>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => isExpanded(row.fms_id)}
                            >
                              {isFDBOpen === row.fms_id ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[1]}>
                            {row.fms_code}
                          </StyledTableCell>

                          <StyledTableCell align="left" key={columns[2]}>
                            {row.station_code}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[3]}>
                            {row.location_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[4]}>
                            {row.fms_installation_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[5]}>
                            {row.fms_type}{" "}
                            {row.fms_type == "FODB" ||
                              (row.fms_type == "LIU" &&
                                "(" + row.port_count + "F)")}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[6]}>
                            {row.fms_installation_type}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            key={columns[7]}
                            // style={{ width: 424 }}
                          >
                            {user?.roleType !== "R" &&
                              user?.roleType !== "FW" &&
                              (!isLargeScreen ? (
                                <Tooltip title="Edit" arrow>
                                  <EditIcon
                                    color="primary"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() =>
                                      history.push(
                                        `/fms/edit-fms/${row.fms_id}/0`
                                      )
                                    }
                                  />
                                </Tooltip>
                              ) : (
                                <Button
                                  onClick={() =>
                                    history.push(
                                      `/fms/edit-fms/${row.fms_id}/0`
                                    )
                                  }
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  startIcon={<EditIcon />}
                                  style={{ color: "#fff" }}
                                >
                                  Edit
                                </Button>
                              ))}

                            {/* <button className="edit" 
                                onClick={() =>history.push(`/fms/edit-fms/${row.fms_id}`)}>
                             <CreateIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>           
                            <span className="level">Edit</span>
                          </button> */}

                            {user?.roleType !== "R" &&
                              user?.roleType !== "FW" &&
                              (!isLargeScreen ? (
                                <Tooltip title="Delete" arrow>
                                  <DeleteIcon
                                    color="error"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => (
                                      SetDelId(row.fms_id), setOpen(true)
                                    )}
                                  />
                                </Tooltip>
                              ) : (
                                <DeleteButton
                                  onClick={() => (
                                    SetDelId(row.fms_id), setOpen(true)
                                  )}
                                  style={{ marginLeft: 10 }}
                                  variant="outlined"
                                  color="error"
                                  size="small"
                                  startIcon={<DeleteIcon />}
                                >
                                  Delete
                                </DeleteButton>
                              ))}

                            {!isLargeScreen ? (
                              <Tooltip title="View All" arrow>
                                <PreviewIcon
                                  color="success"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() =>
                                    history.push(
                                      `/fms/edit-fms/${row.fms_id}/1`
                                    )
                                  }
                                />
                              </Tooltip>
                            ) : (
                              <DetailButton
                                onClick={() =>
                                  history.push(`/fms/edit-fms/${row.fms_id}/1`)
                                }
                                style={{ marginLeft: 10 }}
                                variant="outlined"
                                color="primary"
                                size="small"
                                // import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

                                startIcon={<PreviewIcon />}
                              >
                                view
                              </DetailButton>
                            )}

                            {/* <button className="delete" onClick={() => (
                              SetDelId(row.fms_id), setOpen(true)
                            )}>
                                <DeleteIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>
                                <span className="level">Delete</span>

                          </button> */}
                            {row.fms_type != "FDB" && !isLargeScreen ? (
                              <Tooltip title="Show Cable" arrow>
                                <InfoIcon
                                  color="success"
                                  onClick={() => goToCable(row)}
                                  sx={{ cursor: "pointer" }}
                                />
                              </Tooltip>
                            ) : (
                              row.fms_type != "FDB" && (
                                <InfoButton
                                  style={{ marginLeft: 10 }}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  startIcon={<InfoIcon />}
                                  onClick={() => goToCable(row)}
                                >
                                  Show Cable
                                </InfoButton>
                              )
                            )}
                            {row.fms_type == "TEE/BRANCH JOINT" &&
                            !isLargeScreen ? (
                              <Tooltip title="Show Joint Info" arrow>
                                <SettingsInputComponentIcon
                                  color="success"
                                  onClick={() => goToCable(row)}
                                  sx={{ cursor: "pointer" }}
                                />
                              </Tooltip>
                            ) : (
                              row.fms_type == "TEE/BRANCH JOINT" && (
                                <InfoButton
                                  style={{}}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  startIcon={<SettingsInputComponentIcon />}
                                  onClick={() => ShowJointinfo(row)}
                                >
                                  Show Joint Info
                                </InfoButton>
                              )
                            )}
                            {!isLargeScreen ? (
                              <Tooltip title="Schematic" arrow>
                                <InsertPhotoIcon
                                  color="success"
                                  onClick={() => FmsSchematicView(row)}
                                  sx={{ cursor: "pointer" }}
                                />
                              </Tooltip>
                            ) : (
                              <InfoButton
                                style={{}}
                                variant="outlined"
                                color="primary"
                                size="small"
                                startIcon={<InsertPhotoIcon />}
                                onClick={() => FmsSchematicView(row)}
                              >
                                Schematic
                              </InfoButton>
                            )}
                            {/* <button className="showfms">
                          <InfoIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>
                                <span className="level">Show Cable</span>
                          </button>                          
                                <span className="level">Show FMS</span>
                          </button>                           */}
                          </StyledTableCell>
                        </StyledTableRow>
                        {isFDBOpen === row.fms_id && fdb_list.length > 0 && (
                          <TableRow sx={{ border: "1px solid red" }}>
                            <TableCell
                              colSpan={7}
                              sx={{ margin: 0, padding: 0 }}
                            >
                              <Collapse
                                in={isFDBOpen === row.fms_id}
                                timeout="auto"
                                unmountOnExit
                                sx={{ margin: 0, padding: 0 }}
                              >
                                <Box
                                  sx={{ marginLeft: 6, overflowX: "hidden" }}
                                >
                                  <Table
                                    size="small"
                                    aria-label="purchases"
                                    sx={{
                                      width: "103%",
                                      margin: 0,
                                      padding: 0,
                                      overflowX: "hidden",
                                    }}
                                  >
                                    <TableHead
                                      sx={{
                                        backgroundColor: "#00aaee",
                                        margin: 0,
                                        padding: 0,
                                      }}
                                    >
                                      <TableRow sx={{ margin: 0, padding: 0 }}>
                                        {fdbColumns.map((column: string) => (
                                          <TableCell sx={{ color: "#fff" }}>
                                            {column}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {fdb_list
                                        .filter(
                                          (fdb: FMSList) =>
                                            fdb.parent_fms_id === row.fms_id
                                        )
                                        .map((fdb: FMSList) => (
                                          <StyledTableRow>
                                            <StyledTableCell>
                                              {fdb.fms_code}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              {fdb.location_name}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              {fdb.fms_installation_name}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              {fdb.fms_type}
                                              {"(" + fdb.port_count + "F)"}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              {fdb.fms_installation_type}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              align="left"
                                              key={columns[6]}
                                              style={{ width: 424 }}
                                            >
                                              {user?.roleType !== "R" &&
                                                user?.roleType !== "FW" &&
                                                (!isLargeScreen ? (
                                                  <Tooltip title="Edit" arrow>
                                                    <EditIcon
                                                      color="primary"
                                                      sx={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        history.push(
                                                          `/fms/edit-fms/${fdb.fms_id}/0`
                                                        )
                                                      }
                                                    />
                                                  </Tooltip>
                                                ) : (
                                                  <Button
                                                    onClick={() =>
                                                      history.push(
                                                        `/fms/edit-fms/${fdb.fms_id}/0`
                                                      )
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    startIcon={<EditIcon />}
                                                    style={{
                                                      color: "#fff",
                                                      height: "1.75rem",
                                                    }}
                                                  >
                                                    Edit
                                                  </Button>
                                                ))}

                                              {/* <button className="edit" 
                                onClick={() =>history.push(`/fms/edit-fms/${row.fms_id}`)}>
                             <CreateIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>           
                            <span className="level">Edit</span>
                          </button> */}

                                              {user?.roleType !== "R" &&
                                                user?.roleType !== "FW" &&
                                                (!isLargeScreen ? (
                                                  <Tooltip title="Delete" arrow>
                                                    <DeleteIcon
                                                      color="error"
                                                      sx={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => (
                                                        SetDelId(fdb.fms_id),
                                                        setOpen(true)
                                                      )}
                                                    />
                                                  </Tooltip>
                                                ) : (
                                                  <DeleteButton
                                                    onClick={() => (
                                                      SetDelId(fdb.fms_id),
                                                      setOpen(true)
                                                    )}
                                                    style={{
                                                      marginLeft: 10,
                                                      height: "1.75rem",
                                                    }}
                                                    variant="outlined"
                                                    color="error"
                                                    size="small"
                                                    startIcon={<DeleteIcon />}
                                                  >
                                                    Delete
                                                  </DeleteButton>
                                                ))}

                                              {!isLargeScreen ? (
                                                <Tooltip title="View All" arrow>
                                                  <PreviewIcon
                                                    color="success"
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() =>
                                                      history.push(
                                                        `/fms/edit-fms/${fdb.fms_id}/1`
                                                      )
                                                    }
                                                  />
                                                </Tooltip>
                                              ) : (
                                                <DetailButton
                                                  onClick={() =>
                                                    history.push(
                                                      `/fms/edit-fms/${fdb.fms_id}/1`
                                                    )
                                                  }
                                                  style={{
                                                    marginLeft: 10,
                                                    height: "1.75rem",
                                                  }}
                                                  variant="outlined"
                                                  color="primary"
                                                  size="small"
                                                  // import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

                                                  startIcon={<PreviewIcon />}
                                                >
                                                  view
                                                </DetailButton>
                                              )}

                                              {/* <button className="delete" onClick={() => (
                              SetDelId(row.fms_id), setOpen(true)
                            )}>
                                <DeleteIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>
                                <span className="level">Delete</span>

                          </button> */}

                                              {/* <button className="showfms">
                          <InfoIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>
                                <span className="level">Show Cable</span>
                          </button>                          
                                <span className="level">Show FMS</span>
                          </button>                           */}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        ))}

                                      {/* {row.history.map((historyRow) => (
                                    <TableRow key={historyRow.date}>
                                      <TableCell component="th" scope="row">
                                        {historyRow.date}
                                      </TableCell>
                                      <TableCell>{historyRow.customerId}</TableCell>
                                      <TableCell align="right">{historyRow.amount}</TableCell>
                                      <TableCell align="right">
                                        {Math.round(historyRow.amount * row.price * 100) / 100}
                                      </TableCell>
                                    </TableRow>
                                  ))} */}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={styles}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={allFMS.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {user?.roleType !== "R" && user?.roleType !== "FW" && (
          <Fab
            sx={fabStyle}
            aria-label={"Add"}
            color={"primary"}
            onClick={() => history.push("/fms/add-fms")}
            style={{ marginBottom: 15 }}
          >
            {<AddIcon style={{ color: "#ffff" }} />}
          </Fab>
        )}
      </Paper>
    </>
  );
};

export default FMSMainView;
interface FMSProps {
  Delete?: any;
  allFMS: FMSList[];
  filterData?: any;
  filters?: number;
  SetFilters?: any;
  locationList: FMSLocationList[];
  handleFilterLocWise?: any;
  goToCable?: any;
  user?: UserDetails;
  GetAllFDBByFMS?: any;
  fdb_list: FMSList[];
  ClearAllFDBByFMS?: any;
  ShowJointinfo?: any;
  FmsSchematicView?: any;
}

const fabStyle = {
  position: "absolute",
  bottom: 40,
  right: 16,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: 17,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
