import { Delete } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, IconButton, MenuItem, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { StationList } from '../../../models/stationModel';
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

import { CableMater, CableMsterList, FibreMater } from '../../../models/cableMasterModel';
import { Box } from '@mui/system';
import CableTable from './CableTable';
function CableMainView({allcable,Delete,FilterData,filters,OpenRow,cable_deetail}: CableMasterViewProps) {
  const [open, setOpen] = React.useState<number>(0);
const history = useHistory();
  const columns: any[] = [
    "",
    "Short Code",
    "Cable Color",
    "Cable Type",
    "Cable Strength",
    "Tube count",
    // "Cable Decription",
    "Action",
  ];
  const [open1, setOpen1] = useState<boolean>(false);
  const [id, SetId] = useState<number>(-1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState<CableMsterList[]>([]);
  const [searched, setSearched] = useState<string>("");
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!allcable
      ? allcable.filter((row: CableMsterList) => {
          return (
            row.cable_colour
              .toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row.cable_desc
              .toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row.cable_strength
              .toLowerCase()
              .includes(searchedVal.toLowerCase()) 
          );
        })
      : [];
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  useEffect(() => {
    if (!!allcable) {
      cancelSearch();
    }
  }, [allcable]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const del = () => {
    setOpen1(false);
    Delete(id);
  };
  const styles = {
    marginRight: "50px",
  };
  return (
    <>
      <Dialog
        open={open1}
        onClose={() => setOpen1(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Cable?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen1(false)}>
            No
          </Button>
          <Button onClick={() => del()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          margin={1}
        >
          <Grid item xs={6}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
        </Grid>
        {
          !!rows && rows.length > 0 &&
          <TableContainer>

              
          <Table stickyHeader aria-label="sticky table" >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell align="left" key={column}>
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: number) => {
                    return (
                      <CableTable index={index}  key={row.cable_id} DeleteCable={(id: number)=> (SetId(id), setOpen1(true))} cable_deetail={cable_deetail} setOpen={setOpen} open={open} row={row} columns={columns} OpenRow={OpenRow} />
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        }
        <TablePagination
          style={styles}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={allcable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Fab
          sx={fabStyle}
          aria-label={"Add"}
          color={"primary"}
          onClick={() => history.push("/cable-master/cable-add")}
          style={{ marginBottom:15 }}
        >
          {<AddIcon style={{ color: "#ffff" }} />}
        </Fab>
      </Paper>
    </>
  )
}

export default CableMainView


  const fabStyle = {
    position: "absolute",
    bottom: 40,
    right: 16,
  };

  interface CableMasterViewProps {
    allcable: CableMsterList[];
    Delete?: any;
    FilterData?: any;
    filters?: number;
    OpenRow?: any;
    cable_deetail?:CableMater;
  }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0a3648",
      color: theme.palette.common.white,
      padding: 10,
      paddingLeft: 15,
      fontWeight: 400,
      fontSize: 17
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 8,
      paddingLeft: 15,
    },
  }));