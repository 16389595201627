let shortTextFunc: (longText: string) => string;

shortTextFunc = (longText: string) => {
  let shortText: string = "";
  if (!!longText && longText.length >= 50) {
    shortText = longText.slice(0, 30);
    return (shortText += "....");
  }
  return longText;
};
export default shortTextFunc;
