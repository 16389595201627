import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../components/Autocompletes";

function ServiceAddView({ submitData, serviceType }: ServiceAddViewProps) {
  const theme = useTheme();
  const history = useHistory();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setFocus,
    setError,
    watch,
  } = useForm();
  const { errors } = formState;
  const onSubmit = (props: any) => {
    submitData({ ...props, service_type: null });
  };

  return (
    <>
      <Card sx={{ marginTop: "10px" }}>
        <CardHeader
          style={{ backgroundColor: "#202a37", color: "#fff", height: 14 }}
          titleTypographyProps={{ fontWeight: 500, fontSize: "1.3rem" }}
          title="Add Service"
        />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container justifyContent="center" alignItems={"center"}>
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  Service Name
                </Typography>
                <Box sx={{ color: "red" }}>*</Box>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("service_name", { required: true })}
                  style={{ width: "100%", margin: "5px" }}
                  type="text"
                  variant="outlined"
                  size="small"
                  name="service_name"
                  placeholder="Service Name"
                  error={!!errors["service_name"]}
                />
              </Grid>
            </Grid>

            {/* <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Service Type</Typography>
            </Grid>
            
            <Grid item xs={1}>
              <Typography style={{fontWeight: 550}}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Controller
                  control={control}
                  name={"service_type"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                     <Autocompletes
                     option={serviceType}
                     keys={"domain_value"}
                     label={"domain_value"}
                     onBlur={onBlur}
                     onChange={(value: any) => (
                       onChange(value)
                     )}
                     placeholder={"Service Type"}
                     value={value}
                     errors={errors["service_type"]}
                   />
                 )}
               />
            </Grid>
          </Grid> */}

            <Grid container justifyContent="center" alignItems={"center"}>
              {/* <Grid item xs={4}>
              <Typography style={{fontWeight: 550}}>Description</Typography>
            </Grid> */}
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>Description</Typography>
                {/* <Box sx={{color:"red"}}>*</Box>  */}
              </Grid>

              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("service_desc", { required: false })}
                  style={{ width: "100%", margin: "5px" }}
                  multiline
                  rows={4}
                  variant="outlined"
                  name={"service_desc"}
                  placeholder="Description"
                  error={!!errors["service_desc"]}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center" alignItems={"center"}>
              <Grid item>
                <Button
                  onClick={() => history.push(`/service-master`)}
                  style={{
                    color: "#fff",
                    marginRight: 10,
                    background: "#C1B4B3",
                  }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  style={{ color: "#fff" }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

export default ServiceAddView;

interface ServiceAddViewProps {
  submitData?: any;
  serviceType: any;
}
