import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Box,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Autocompletes from "../../../components/Autocompletes";
import { FMSLocationList } from "../../../models/fmsLocationModel";
import { StationList } from "../../../models/stationModel";

function FMSLocationEditView({
  submitData,
  SingleFMSLocation,
  station,
  readable,
}: ZoneEditViewProps) {
  const { control, register, handleSubmit, formState, setValue } = useForm();
  const history = useHistory();
  const [flag, Setflag] = useState<boolean>(true);
  const { errors } = formState;
  const onSubmit = (props: any) => {
    submitData(props);
  };
  const StationChange = (data: number) => {
    if (
      !!station &&
      !!station?.find(m => m.station_id == +data) &&
      station?.find(m => m.station_id == +data)?.station_type ==
        "Tee/Branch Joint"
    ) {
      Setflag(false);
    } else {
      Setflag(true);
    }
  };
  useEffect(() => {
    if (!!SingleFMSLocation) {
      setValue("building_name", SingleFMSLocation.building_name);
      setValue("floor_name", SingleFMSLocation.floor_name);
      setValue("room_name", SingleFMSLocation.room_name);
      setValue("station_id", SingleFMSLocation.station_id);
      setValue("location_name", SingleFMSLocation.location_name);

      Boolean("readable_station_data") &&
        setValue(
          "readable_station_data",
          station.find(sta => sta.station_id === SingleFMSLocation.station_id)
            ?.station_name
        );
      StationChange(SingleFMSLocation.station_id);
    }
  }, [SingleFMSLocation]);
  return (
    <>
      <Card sx={{}}>
        <CardHeader
          style={{ backgroundColor: "#202a37", color: "#fff", height: 14 }}
          titleTypographyProps={{ fontWeight: 500, fontSize: "1.3rem" }}
          title={Boolean(readable) ? "View FMS Location" : "Edit FMS Location"}
        />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <Grid container justifyContent="center" alignItems={"center"}>
          <Grid xs={10}>
          <Grid item xs={11.8}>
          <TextField
                  {...register("location_name", { required: true })}
                  style={{ width: "100%", margin: "5px" }}
                  type="text"
                  label="Location Name"
                  variant="outlined"
                  size="small"
                  name="location_name"
                  error={!!errors["location_name"]}
                />
          </Grid>
          </Grid>
          </Grid> */}

            <Grid container justifyContent="center" alignItems={"center"}>
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  Station Name
                </Typography>
                {!Boolean(readable) && <Box sx={{ color: "red" }}>*</Box>}
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                {Boolean(readable) ? (
                  <TextField
                    {...register("readable_station_data", { required: true })}
                    style={{ width: "100%", margin: "5px" }}
                    type="text"
                    variant="outlined"
                    size="small"
                    name="readable_station_data"
                    placeholder="Building Name"
                    error={!!errors["readable_station_data"]}
                    InputProps={{
                      readOnly: Boolean(readable),
                    }}
                  />
                ) : (
                  <Controller
                    control={control}
                    name={"station_id"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        option={station}
                        keys={"station_id"}
                        label={"station_name"}
                        onBlur={onBlur}
                        onChange={(value: any) => (
                          onChange(value), StationChange(value)
                        )}
                        placeholder={"Select Station"}
                        value={value}
                        errors={errors["station_id"]}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container justifyContent="center" alignItems={"center"}>
              <Grid container justifyContent="center" alignItems={"center"}>
                <Grid item xs={4} sx={{ display: "flex" }}>
                  <Typography style={{ fontWeight: 550 }}>
                    Location Name
                  </Typography>
                  {!Boolean(readable) && <Box sx={{ color: "red" }}>*</Box>}
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ fontWeight: 550 }}>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    {...register("location_name", { required: true })}
                    style={{ width: "100%", margin: "5px" }}
                    type="text"
                    variant="outlined"
                    size="small"
                    name="location_name"
                    placeholder="Location Name"
                    error={!!errors["location_name"]}
                    InputProps={{
                      readOnly: Boolean(readable),
                    }}
                  />
                </Grid>
              </Grid>

              {/* <Grid item xs={5}>
            <Grid
                  container
                  paddingTop={2}
                  justifyContent="left"
                  alignItems={"center"}
                >
                  <Grid item xs={11.5}>
                <Controller
                control={control}
                name={"station_id"}
                defaultValue={""}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    style={{ width: "100%", margin: "5px" }}
                    type="text"
                    variant="outlined"
                    size="small"
                    onBlur={onBlur}
                    value={value}
                    select
                    name="station_id"
                    onChange={(value) =>
                      onChange(value.target.value.toString())
                    }
                    label="Select Station"
                    error={!!errors["station_id"]}
                    defaultValue={""}
                  >
                   {!!station && station.map((option) => (
                  <MenuItem key={option.station_id} value={option.station_id}>
                    {option.station_name}
                  </MenuItem>
                ))}
                  </TextField>
                )}
              />
                </Grid>
                </Grid>
              </Grid> */}

              <Grid container justifyContent="center" alignItems={"center"}>
                <Grid item xs={4} sx={{ display: "flex" }}>
                  <Typography style={{ fontWeight: 550 }}>
                    Building Name
                  </Typography>
                  {/* {!Boolean(readable) && <Box sx={{ color: "red" }}>*</Box>} */}
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ fontWeight: 550 }}>:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    {...register("building_name")}
                    style={{ width: "100%", margin: "5px" }}
                    type="text"
                    variant="outlined"
                    size="small"
                    name="building_name"
                    placeholder="Building Name"
                    error={!!errors["building_name"]}
                    InputProps={{
                      readOnly: Boolean(readable),
                    }}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={5}>
              <Grid
                  container
                  paddingTop={2}
                  justifyContent="left"
                  alignItems={"center"}
                >
                  <Grid item xs={11.5}>
                <TextField
                  {...register("building_name", { required: true })}
                  style={{ width: "100%", margin: "5px" }}
                  type="text"
                  label="Building Name"
                  variant="outlined"
                  size="small"
                  name="building_name"
                  placeholder="Building Name"
                  error={!!errors["building_name"]}
                />
                  </Grid>
            </Grid>
              </Grid> */}
            </Grid>
            <Grid container justifyContent="center" alignItems={"center"}>
              {flag && (
                <Grid container justifyContent="center" alignItems={"center"}>
                  <Grid item xs={4} sx={{ display: "flex" }}>
                    <Typography style={{ fontWeight: 550 }}>
                      Floor Name
                    </Typography>
                    {/* {!Boolean(readable) && <Box sx={{ color: "red" }}>*</Box>} */}
                  </Grid>
                  <Grid item xs={1}>
                    <Typography style={{ fontWeight: 550 }}>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      {...register("floor_name")}
                      style={{ width: "100%", margin: "5px" }}
                      type="text"
                      variant="outlined"
                      size="small"
                      name="floor_name"
                      placeholder="Floor Name"
                      error={!!errors["floor_name"]}
                      InputProps={{
                        readOnly: Boolean(readable),
                      }}
                    />
                  </Grid>
                </Grid>
              )}

              {/* <Grid item xs={5}>
            <Grid
                  container
                  paddingTop={2}
                  justifyContent="left"
                  alignItems={"center"}
                >
                  <Grid item xs={11.5}>
                <TextField
                  {...register("floor_name", { required: true })}
                  style={{ width: "100%", margin: "5px" }}
                  variant="outlined"
                  name={"floor_name"}
                  label="Floor name"
                  size="small"
                  placeholder="Floor name"
                  error={!!errors["floor_name"]}
                />
                </Grid>
                </Grid>
              </Grid> */}
              {/* <Grid item xs={5}>
              <Grid
                  container
                  paddingTop={2}
                  justifyContent="left"
                  alignItems={"center"}
                >
                  <Grid item xs={11.5}>
                <TextField
                  {...register("room_name", { required: true })}
                  style={{ width: "100%", margin: "5px" }}
                  variant="outlined"
                  name={"room_name"}
                  size="small"
                  label="Room name"
                  error={!!errors["room_name"]}
                />
                </Grid>
                </Grid>
              </Grid> */}
              {flag && (
                <Grid container justifyContent="center" alignItems={"center"}>
                  <Grid item xs={4} sx={{ display: "flex" }}>
                    <Typography style={{ fontWeight: 550 }}>
                      Room Name
                    </Typography>
                    {/* {!Boolean(readable) && <Box sx={{ color: "red" }}>*</Box>} */}
                  </Grid>
                  <Grid item xs={1}>
                    <Typography style={{ fontWeight: 550 }}>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      {...register("room_name")}
                      style={{ width: "100%", margin: "5px" }}
                      type="text"
                      variant="outlined"
                      size="small"
                      name="room_name"
                      placeholder="Room Name"
                      error={!!errors["room_name"]}
                      InputProps={{
                        readOnly: Boolean(readable),
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid container justifyContent="center" alignItems={"center"}>
              <Grid item>
                <Button
                  onClick={() => history.push(`/fms-location`)}
                  style={{
                    color: "#fff",
                    marginRight: 10,
                    background: "#C1B4B3",
                  }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Back
                </Button>
                {!Boolean(readable) && (
                  <Button
                    type="submit"
                    style={{ color: "#fff" }}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Update
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

export default FMSLocationEditView;

interface ZoneEditViewProps {
  submitData?: any;
  SingleFMSLocation?: FMSLocationList;
  station: StationList[];
  readable: number;
}
