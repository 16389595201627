import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import "./ReportCablesTable.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  TablePagination,
  Fab,
  styled,
  TableCell,
  tableCellClasses,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
  Tooltip,
  Card,
  Typography,
} from "@mui/material";
import {
  ReportCablesData,
  ReportData,
  ReportTCData,
} from "../../../models/ReportModel";
import CabTables from "./CabTables";
import SourceDesti from "./SourceDesti";
import SingleCableReport from "../../../components/SingleCableReport";

const ReportCablesTable = ({
  tableData,
  transactionTypes,
  selectedCableCode,
}: ReportCablesTableProps) => {
  //   const [tabData, setTabData] = useState<ReportTCData[]>([]);
  const [fmsName, setFMSName] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<ReportTCData[]>([]);
  const [cableselect, SetCableSelect] = useState<ReportCablesData>();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [isSingleCableReportOpen, setSingleCableReportOpen] =
    useState<boolean>(false);
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };
  const styles = {
    marginRight: "50px",
  };

  useEffect(() => {
    let mainData: ReportTCData[] = [];
    let data: any;
    if (transactionTypes === "Cables") {
      !!tableData &&
        tableData.map((tData: ReportData, index: number) => {
          data = JSON.parse(tData.transaction_data);
          mainData.push({ ...tData, transaction_data: data });
        });
      setRows(mainData);
    }
  }, [tableData]);

  const theme = useTheme();
  return (
    // <Paper elevation={3} sx={{ padding: "1rem " }}>
    //   <TableContainer sx={{ marginTop: "1.4rem" }}>
    //     <Box
    //       sx={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Typography variant="h5" sx={{ color: "#00aaee" }}>
    //         TRANSACTION OF CABLE
    //       </Typography>
    //     </Box>
    //     {!!tabData &&
    //       tabData.map((tData: ReportTCData, index) => (
    //         <Box
    //           sx={{
    //             border: "1px dashed #00aaee",
    //             margin: "0.5rem 0",
    //             padding: "0.8rem",
    //           }}
    //           key={index}
    //         >
    //           <Box
    //             sx={{
    //               display: "flex",
    //               alignItems: "center",
    //               justifyContent: "center",
    //             }}
    //           >
    //             <Box sx={{ flex: "0.7" }}>
    //               <Typography sx={{ margin: "0.4rem 1rem", color: "gray" }}>
    //                 <span style={{ fontWeight: 550 }}>Event Type</span> :{" "}
    //                 <span style={{ color: "black" }}>
    //                   {!!tData.event_name ? tData.event_name : "NA"}
    //                 </span>
    //               </Typography>
    //             </Box>
    //             <Box
    //               sx={{
    //                 margin: "0.4rem 0",
    //                 display: "flex",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //                 color: "gray",
    //                 flex: "0.3",
    //               }}
    //             >
    //               <Typography sx={{ margin: "0 0.4rem" }}>
    //                 Updated At :{" "}
    //               </Typography>
    //               <Typography>
    //                 {moment(tData.updated_at).format("DD/MM/YYYY hh:mm:ss a")}
    //               </Typography>
    //             </Box>
    //           </Box>
    //           <CabTables
    //             tableData={
    //               !!tData?.transaction_data?.findCable
    //                 ? tData?.transaction_data?.findCable
    //                 : tData?.transaction_data?.findCable
    //             }
    //             key={Math.random()}
    //           />
    //           {/* <SourceDesti
    //             tableData={tData.transaction_data.sourceCableInfo}
    //             type="Source A"
    //             key={Math.random()}
    //           />
    //           <SourceDesti
    //             tableData={tData.transaction_data.destinationCableInfo}
    //             type="Source B"
    //             key={index}
    //           /> */}
    //         </Box>
    //       ))}
    //   </TableContainer>
    // </Paper>

    <Paper
      elevation={3}
      sx={{
        width: "100%",
        paddingTop: "1rem ",
      }}
    >
      {/* <Box sx={{ width: "100%", height: "100%" }}> */}
      <Box>
        <Typography
          sx={{
            color: "gray",
            textAlign: "center",
            fontWeight: 620,
            textShadow: "0px 0px 0px #fff, 3px 3px 0px rgba(0,0,0,0.15)",
          }}
          variant="h5"
        >
          {"TRANSACTION OF CABLE"}
        </Typography>
      </Box>
      <TableContainer sx={{ marginTop: "1.4rem" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {/* <StyledTableCell align="justify">sfsdfds</StyledTableCell> */}
              <StyledTableCell
                style={{
                  // border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                Cable Short Code
              </StyledTableCell>
              <StyledTableCell
                style={{
                  // border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                FMS Code(Source A)
              </StyledTableCell>
              <StyledTableCell
                style={{
                  // border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                Station Name(Source A)
              </StyledTableCell>
              <StyledTableCell
                style={{
                  // border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                FMS Code(Source B)
              </StyledTableCell>
              <StyledTableCell
                style={{
                  // border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                Station Name(Source B)
              </StyledTableCell>
              <StyledTableCell
                style={{
                  // border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                Cable Type
              </StyledTableCell>
              <StyledTableCell
                style={{
                  // border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                Cable Length
              </StyledTableCell>
              <StyledTableCell
                style={{
                  // border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                Updated At
              </StyledTableCell>
              <StyledTableCell
                style={{
                  // border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                Updated By
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <SingleCableReport
              isOpen={isSingleCableReportOpen}
              setOpen={setSingleCableReportOpen}
              cable_report={cableselect}
            />
            {!!rows &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      <StyledTableCell
                        align="left"
                        //   style={{ color: "red", cursor: "pointer",  }}
                        className="cable_code"
                        onClick={() => {
                          SetCableSelect(row.transaction_data);

                          setSingleCableReportOpen(true);
                        }}
                      >
                        {row.transaction_data?.cableInfo?.cable_short_code
                          ? row.transaction_data?.cableInfo?.cable_short_code
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.transaction_data?.cableInfo?.source_fms_code}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {
                          row.transaction_data?.sourceCableInfo[index]
                            ?.station_name
                        }
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.transaction_data?.cableInfo?.destination_fms_code}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {
                          row.transaction_data?.destinationCableInfo[index]
                            ?.station_name
                        }
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.transaction_data?.cableInfo?.cable_type}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.transaction_data?.cableInfo?.cable_actual_length
                          ? `${row.transaction_data?.cableInfo?.cable_actual_length} mt`
                          : `N/A`}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {moment(row.updated_at).format(
                          "DD/MM/YYYY  hh:mm:ss a"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.user_name}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={styles}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* <table
          style={{
            border: "1px solid gray",
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "1rem",
          }}
        >
          <thead>
            <tr
              style={{
                border: "1px solid gray",
                backgroundColor: "#2B333E",
                color: "#fff",
              }}
            >
              <th
                style={{
                  border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                Cable Short Code
              </th>
              <th
                style={{
                  border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                FMS Code(A)
              </th>
              <th
                style={{
                  border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                Station Name(A)
              </th>
              <th
                style={{
                  border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                FMS Code(B)
              </th>
              <th
                style={{
                  border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                Station Name(B)
              </th>
              <th
                style={{
                  border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                Cable Type
              </th>
              <th
                style={{
                  border: "1px solid gray",
                  borderCollapse: "collapse",
                }}
              >
                Cable Length
              </th>
            </tr>
          </thead>
          <tbody>
            {!!tabData && tabData.length > 0 ? (
              tabData?.map((tData: ReportTCData, index: number) => (
                <tr
                  style={{
                    border: "1px solid gray",
                    textAlign: "center",
                  }}
                  key={index}
                >
                  <td
                    style={{
                      border: "1px solid gray",
                      borderCollapse: "collapse",
                    }}
                  >
                    {tData?.transaction_data?.cableInfo?.cable_short_code}
                  </td>
                  <td
                    style={{
                      border: "1px solid gray",
                      borderCollapse: "collapse",
                    }}
                  >
                    {tData?.transaction_data?.cableInfo?.source_fms_code}
                  </td>
                  <td
                    style={{
                      border: "1px solid gray",
                      borderCollapse: "collapse",
                    }}
                  >
                    {tData?.transaction_data?.cableInfo?.source_fms_name}
                  </td>
                  <td
                    style={{
                      border: "1px solid gray",
                      borderCollapse: "collapse",
                    }}
                  >
                    {tData?.transaction_data?.cableInfo?.destination_fms_code}
                  </td>
                  <td
                    style={{
                      border: "1px solid gray",
                      borderCollapse: "collapse",
                    }}
                  >
                    {tData?.transaction_data?.cableInfo?.destination_fms_name}
                  </td>
                  <td
                    style={{
                      border: "1px solid gray",
                      borderCollapse: "collapse",
                    }}
                  >
                    {tData?.transaction_data?.cableInfo?.cable_type}
                  </td>
                  <td
                    style={{
                      border: "1px solid gray",
                      borderCollapse: "collapse",
                    }}
                  >
                    {tData?.transaction_data?.cableInfo?.cable_actual_length}
                  </td>
                </tr>
              ))
            ) : (
              <tr
                style={{
                  border: "1px solid gray",
                  textAlign: "center",
                }}
              >
                <td
                  colSpan={8}
                  style={{
                    border: "1px solid gray",
                    borderCollapse: "collapse",
                  }}
                >
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table> */}
      {/* </Box> */}
    </Paper>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default ReportCablesTable;
interface ReportCablesTableProps {
  tableData: ReportData[];
  transactionTypes: string;
  selectedCableCode: string;
}
