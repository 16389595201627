import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Autocompletes from "../../../components/Autocompletes";
import { ColorCode, FibreMater } from "../../../models/cableMasterModel";

function AddTubeView({ color, allfibre, submitData }: AddFibreProps) {
  const [tubeLength, setTubeLength] = useState<number>(1);
  const history = useHistory();
  const handleTubeChange = (value: string) => {
    setTubeLength(+value);
  };
  const noFibre: any[] = [
    { key: "1", value: "1" },
    {
      key: "2",
      value: "2",
    },
    {
      key: "3",
      value: "3",
    },
    {
      key: "4",
      value: "4",
    },
    {
      key: "5",
      value: "5",
    },
    {
      key: "6",
      value: "6",
    },
    {
      key: "7",
      value: "7",
    },
    {
      key: "8",
      value: "8",
    },
    {
      key: "9",
      value: "9",
    },
    {
      key: "10",
      value: "10",
    },
  ];

  const {
    register,
    control,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
  } = useForm();
  const { errors } = formState;

  const onSubmit = (props: any) => {
    if (!!props.no_of_fibres) {
      if (+props.no_of_fibres !== props.fibres.length) {
        setError("tubes", { type: "custom", message: "custom message" });
      } else {
        submitData(props);
      }
    } else setError("tubes", { type: "custom", message: "custom message" });
  };

  const fibreNoSelected = () => {
    setValue("fibres", []);
  };
  const disableDat = (fibre: FibreMater) => {
    let flag: boolean = false;
    if (!!getValues("fibres")) {
      if (+getValues("fibres").length >= +getValues("no_of_fibres")) {
        flag = true;
      }
      let t: FibreMater = getValues("fibres").find(
        (m: any) => m == fibre.fibre_id
      );
      if (!!t) {
        flag = false;
      }
    }
    return flag;
  };

  return (
    <Card sx={{ marginTop: "10px" }}>
      <CardHeader
        style={{ backgroundColor: "#202a37", color: "#fff", height: 14 }}
        titleTypographyProps={{ fontWeight: 500, fontSize: "1.3rem" }}
        title="Add Tube"
      />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Tube Code</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("tube_code", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="tube_code"
                placeholder="Tube Code"
                error={!!errors["tube_code"]}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>
                Tube colour code
              </Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("tube_colour_code", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                select
                name="tube_colour_code"
                defaultValue={""}
                placeholder="tube colour code"
                error={!!errors["tube_colour_code"]}
              >
                {!!color &&
                  color.map((item) => (
                    <MenuItem
                      key={item.colour_master_id}
                      value={item.colour_code}
                    >
                      <Grid container gap={1} direction={"row"}>
                        <Box
                          style={{
                            border: "1px solid #000",
                            height: "20px",
                            width: "20px",
                            backgroundColor: item.colour_code,
                          }}
                        ></Box>
                        {item.colour_name}
                      </Grid>
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>No Of Fibres</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Controller
                control={control}
                name={"no_of_fibres"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={noFibre}
                    keys={"key"}
                    label={"value"}
                    onBlur={onBlur}
                    onChange={(value: any) => (
                      fibreNoSelected(),
                      onChange(value),
                      handleTubeChange(value)
                    )}
                    placeholder={"No Of Fibre"}
                    value={value}
                    errors={errors["no_of_fibres"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 550 }}>Fibres</Typography>
              <Box sx={{ color: "red" }}>*</Box>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Controller
                control={control}
                name={"fibres"}
                defaultValue={[]}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Select
                    error={!!errors["fibres"]}
                    size="small"
                    style={{ width: "100%", margin: "5px" }}
                    variant="outlined"
                    multiple
                    onBlur={onBlur}
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value: any) => {
                          let item: FibreMater | undefined = allfibre.find(
                            (m) => +m.fibre_id == +value
                          );
                          return !!item ? (
                            <Chip
                              style={{
                                backgroundColor: item.fibre_colour_code,
                                color: "#ffff",
                                border: "0.5px solid gray",
                              }}
                              key={item.fibre_id}
                              label={
                                <div
                                  style={{
                                    backgroundColor: "#d4d5d6",
                                    borderRadius: 5,
                                  }}
                                >
                                  <Typography
                                    style={{ color: "#000000" }}
                                    fontSize={14}
                                  >
                                    {item.fibre_colour}({item.fibre_code})
                                  </Typography>
                                </div>
                              }
                            />
                          ) : (
                            <></>
                          );
                        })}
                      </Box>
                    )}
                    MenuProps={{
                      sx: {
                        "&& .Mui-selected": {
                          backgroundColor: "#CCEBF2",
                        },
                      },
                    }}
                  >
                    {allfibre.map((fibre) => (
                      <MenuItem
                        disabled={disableDat(fibre)}
                        key={fibre.fibre_id}
                        value={fibre.fibre_id}
                      >
                        <Grid container gap={1} direction={"row"}>
                          <Box
                            style={{
                              border: "1px solid #000",
                              height: "20px",
                              width: "20px",
                              backgroundColor: fibre.fibre_colour_code,
                            }}
                          ></Box>
                          {fibre.fibre_colour}({fibre.fibre_code})
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item>
              <Button
                onClick={() => history.push(`/cable-master/tube-main`)}
                style={{
                  color: "#fff",
                  marginRight: 10,
                  background: "#C1B4B3",
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Back
              </Button>

              <Button
                onClick={() => history.push(`/cable-master/fibre-add`)}
                style={{
                  color: "#fff",
                  marginRight: 10,
                  // background: "#C1B4B3",
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Add Fibre
              </Button>

              <Button
                type="submit"
                style={{ color: "#fff" }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

export default AddTubeView;
interface AddFibreProps {
  color: ColorCode[];
  allfibre: FibreMater[];
  submitData?: any;
}
