import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { FMSList, JointInfo } from "../../../models/fmsModel";
import { StoreState } from "../../../models/reduxModels";
import { GetJointInfo } from "../../../Stores/actions/fmsAction";
import JointInfoView from "./JointInfoView";

function JointInfos({ jointinfo, GetJointInfo, allFMS }: jointProps) {
  let { id }: any = useParams();
  useEffect(() => {
    GetJointInfo(+id);
  }, []);
  return (
    <JointInfoView
      jointinfo={jointinfo}
      single_fms={allFMS.find((m) => m.fms_id === +id)}
    />
  );
}

const mapDispatchToProps = {
  GetJointInfo,
};

const mapStateToProps = (state: StoreState) => {
  return {
    jointinfo: state.fms.joint_fms,
    allFMS: state.fms.fms_list,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(JointInfos);
interface jointProps {
  jointinfo: JointInfo[];
  GetJointInfo?: any;
  allFMS: FMSList[];
}
