import {
  Box,
  Grid,
  MenuItem,
  TextField,
  IconButton,
  Switch,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../components/Autocompletes";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import ChooseCableModal from "./ChooseCableModal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import { CableList, PatchFibre, PortDetail } from "../../../models/cableModel";
import {
  DestinationCableInfo,
  SourceCableInfo,
} from "../../../models/cableModel";
import { height } from "@mui/system";
import { GetAvailablePorts } from "../../../Stores/actions/cableAction";
import "./CableAddView.css";
import { FMSList } from "../../../models/fmsModel";
import { DomainList } from "../../../models/domainModel";
import PatchCableModal from "./PatchCableModal";
import { useSnackbar } from "notistack";
function FibreDetailCard({
  index,
  item,
  fibreStatus,
  terminatedStatus,
  fibreTypes,
  adapterType,
  allservice,
  cableColor,
  Destdetail,
  Sourcedetail,
  UpdateFibre,
  cable_list,
  GetAvailablePorts,
  Port,
  FDB1,
  FDB,
  getFMS,
  FDB3,
  GetFibre,
  patch_fibre,
  cableType,
  SecondCableType,
}: FibreDetailProps) {
  const { control, register, handleSubmit, formState, setValue, getValues } =
    useForm();
  const { errors } = formState;
  const { enqueueSnackbar } = useSnackbar();
  const [FabSatA, SetFabSatA] = useState<string | undefined>();
  const [FabSatB, SetFabSatB] = useState<string | undefined>();
  const [TerSatA, TerFabSatA] = useState<string | undefined>();
  const [TerSatB, TerFabSatB] = useState<string | undefined>();
  const [StubeColor, setSTubeColor] = useState<string | undefined>();
  const [SfibreColor, setSFibreColor] = useState<string | undefined>();
  const [TtubeColor, setTTubeColor] = useState<string | undefined>();
  const [TfibreColor, setTFibreColor] = useState<string | undefined>();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [cabSFModalOpen, setCabSFModalOpen] = useState<boolean>(false);
  const [cabDFModalOpen, setCabDFModalOpen] = useState<boolean>(false);
  const [PatchA, SetPathA] = useState<boolean>(false);
  const [PatchB, SetPatchB] = useState<boolean>(false);

  const [SFibreDetails, setSFibreDetails] = useState<number | undefined>(
    undefined
  );
  const [DFibreDetails, setDFibreDetails] = useState<number | undefined>(
    undefined
  );

  const [SPFibreDetails, setSPFibreDetails] = useState<number | undefined>(
    undefined
  );
  const [DPFibreDetails, setDPFibreDetails] = useState<number | undefined>(
    undefined
  );
  const [PatchFlagA, SetPatchFlagA] = useState<boolean>(false);
  const [PatchFlagB, SetPatchFlagB] = useState<boolean>(false);
  const timer = React.useRef<number>();
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };
  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const showAllPort = async (data: number, type: number) => {
    if (type == 1) {
      if (Sourcedetail?.fms_type != "FODB" && Sourcedetail?.fms_type != "LIU") {
        if (!!getValues("fdb_fms_id")) {
          GetAvailablePorts(+getValues("fdb_fms_id"), Sourcedetail?.fms_type);
        } else {
          await enqueueSnackbar("Terminated FDB Not Selected!", {
            variant: "warning",
          });
          setValue("adaptor_position", null);
        }
      } else {
        GetAvailablePorts(data, Sourcedetail?.fms_type);
      }
    } else {
      if (Destdetail?.fms_type != "FODB" && Destdetail?.fms_type != "LIU") {
        if (!!getValues("fdb_fms_id2")) {
          GetAvailablePorts(+getValues("fdb_fms_id2"), Destdetail?.fms_type);
        } else {
          await enqueueSnackbar("Terminated FDB Not Selected!", {
            variant: "warning",
          });
          setValue("adaptor_position2", null);
        }
      } else {
        GetAvailablePorts(data, Destdetail?.fms_type);
      }
    }
  };

  useEffect(() => {
    if (!!Sourcedetail?.cable_detail_id) {
      if (
        !!Sourcedetail?.fibre_status &&
        Sourcedetail.fibre_status == "SPLICED"
      ) {
        setValue("fibre_count", Sourcedetail.spliced_fibre_code);
      } else if (
        !!Sourcedetail?.fibre_status &&
        Sourcedetail.fibre_status == "TERMINATED" &&
        !!Sourcedetail.spliced_fibre_code
      ) {
        SetPatchFlagA(true);
        setValue("patching", Sourcedetail.spliced_fibre_code);
      }
    }

    if (!!Sourcedetail?.tube_colour_id) {
      setValue("tube_color", Sourcedetail?.tube_colour_id);
      setSTubeColor(Sourcedetail?.tube_colour);
    }
    if (!!Sourcedetail?.fibre_colour_id) {
      setValue("fibre_color", Sourcedetail?.fibre_colour_id);
      setSFibreColor(Sourcedetail?.fibre_colour);
    }
    if (!!Sourcedetail?.fibre_type) {
      setValue("fibre_type", Sourcedetail?.fibre_type);
    }
    if (!!Sourcedetail?.fibre_status) {
      SetFabSatA(Sourcedetail?.fibre_status);
      setValue("fibre_status", Sourcedetail?.fibre_status);
    }
    if (!!Sourcedetail?.service_type) {
      TerFabSatA(Sourcedetail?.service_type);
      setValue("terminated_status", Sourcedetail?.service_type);
    }
    if (!!Sourcedetail?.adapter_type) {
      setValue("adaptor_type", Sourcedetail?.adapter_type);
    }
    if (!!Sourcedetail?.service_id) {
      setValue("service_id", Sourcedetail?.service_id);
    }
    if (!!Sourcedetail?.remarks) {
      setValue("remarks", Sourcedetail?.remarks);
    }
    if (!!Sourcedetail?.ferule_name) {
      setValue("farul_name", Sourcedetail?.ferule_name);
    }
    if (!!Sourcedetail?.spliced_status) {
      setValue("spliced_status", Sourcedetail?.spliced_status);
    }
    if (!!Sourcedetail?.fdb_fms_id) {
      setTimeout(() => {
        setValue("fdb_fms_id", Sourcedetail?.fdb_fms_id);
      }, 500);
    }
    if (!!Sourcedetail?.adapter_position) {
      setValue("adaptor_position", Sourcedetail?.adapter_position);
    }
    if (!!Sourcedetail?.service_desc) {
      setValue("service_desc", Sourcedetail?.service_desc);
    }
    if (!!Sourcedetail?.service_impl_type) {
      setValue("service_impl_type", Sourcedetail?.service_impl_type);
    }
  }, [Sourcedetail, FDB, fibreStatus, terminatedStatus]);
  useEffect(() => {
    if (!!Destdetail?.tube_colour_id) {
      setValue("tube_color2", Destdetail?.tube_colour_id);
      setTTubeColor(Destdetail?.tube_colour);
    }
    if (!!Destdetail?.cable_detail_id) {
      if (!!Destdetail?.fibre_status && Destdetail.fibre_status == "SPLICED") {
        setValue("fibre_count2", Destdetail.spliced_fibre_code);
      } else if (
        !!Destdetail?.fibre_status &&
        Destdetail.fibre_status == "TERMINATED" &&
        !!Destdetail.spliced_fibre_code
      ) {
        SetPatchFlagB(true);
        setValue("patching2", Destdetail.spliced_fibre_code);
      }
    }
    if (!!Destdetail?.fibre_colour_id) {
      setValue("fibre_color2", Destdetail?.fibre_colour_id);
      setTFibreColor(Destdetail?.fibre_colour);
    }
    if (!!Destdetail?.fibre_type) {
      setValue("fibre_type2", Destdetail?.fibre_type);
    }
    if (!!Destdetail?.spliced_status) {
      setValue("spliced_status2", Destdetail?.spliced_status);
    }
    if (!!Destdetail?.fibre_status) {
      SetFabSatB(Destdetail?.fibre_status);
      setValue("fibre_status2", Destdetail?.fibre_status);
    }
    if (!!Destdetail?.service_type) {
      TerFabSatB(Destdetail?.service_type);
      setValue("terminated_status2", Destdetail?.service_type);
    }
    if (!!Destdetail?.adapter_type) {
      setValue("adaptor_type2", Destdetail?.adapter_type);
    }

    if (!!Destdetail?.service_id) {
      setValue("service_id2", Destdetail?.service_id);
    }
    if (!!Destdetail?.remarks) {
      setValue("remarks2", Destdetail?.remarks);
    }
    if (!!Destdetail?.ferule_name) {
      setValue("farul_name2", Destdetail?.ferule_name);
    }
    if (!!Destdetail?.fdb_fms_id) {
      setTimeout(() => {
        setValue("fdb_fms_id2", Destdetail?.fdb_fms_id);
      }, 500);
    }
    if (!!Destdetail?.adapter_position) {
      setValue("adaptor_position2", Destdetail?.adapter_position);
    }
    if (!!Destdetail?.service_desc) {
      setValue("service_desc2", Destdetail?.service_desc);
    }
    if (!!Destdetail?.service_impl_type) {
      setValue("service_impl_type2", Destdetail?.service_impl_type);
    }
  }, [Destdetail, FDB1, fibreStatus, terminatedStatus]);

  // color filter   ////  //////
  // useEffect(()=>{
  //   let fTempColor,sTempColor;
  // //   const [filteredAColor,setFilteredAColor]=useState<any[]>([])
  // // const [filteredBColor,setFilteredBColor]=useState<any[]>([])
  //   if(!!cableType && !!SecondCableType && cableType===SecondCableType){
  //     fTempColor=cableColor.filter((cable:any)=> cable.cable_type===cableType)
  //     setFilteredAColor(fTempColor)
  //   }else if(!!cableType && cableType!==SecondCableType){
  //     fTempColor=cableColor.filter((cable:any)=> cable.cable_type===cableType)
  //     sTempColor=cableColor.filter((cable:any)=> cable.cable_type===SecondCableType)
  //     setFilteredAColor(fTempColor)
  //     setFilteredBColor(sTempColor)
  //   }else{
  //     fTempColor=cableColor.filter((cable:any)=> cable.cable_type===cableType)
  //     setFilteredAColor(fTempColor)
  //   }
  // },[cableType,SecondCableType])

  const SetSourceFibreDetailsReq = (data: any) => {
    setValue("fibre_count", data.label);
    setSFibreDetails(data.impl_id);
  };
  const SetDestFibreDetailsReq = (data: any) => {
    setValue("fibre_count2", data.label);
    setDFibreDetails(data.impl_id);
  };
  const onSubmit = (props: any) => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
    UpdateFibre({
      sourceCableInfo: !!Sourcedetail
        ? {
            ...Sourcedetail,
            splice_impl_id: SFibreDetails,
            tube_colour_id: props.tube_color ? +props.tube_color : null,
            fibre_colour_id: !!props.fibre_color ? +props.fibre_color : null,
            tube_colour: !!+props.tube_color
              ? cableColor.find(
                  (m: any) => +m.colour_master_id == +props.tube_color
                ).colour_name
              : null,
            fibre_colour: !!props.fibre_color
              ? cableColor.find(
                  (m: any) => +m.colour_master_id == +props.fibre_color
                ).colour_name
              : null,
            fibre_type: props.fibre_type,
            fibre_status: props.fibre_status,
            adapter_position:
              props.fibre_status == "TERMINATED"
                ? +props.adaptor_position
                : null,
            adapter_type:
              props.fibre_status == "TERMINATED" ? props.adaptor_type : null,
            ferule_name: props.farul_name,
            service_id:
              (props.fibre_status == "TERMINATED" ||
                props.fibre_status == "SPLICED") &&
              props.terminated_status == "IN-SERVICE"
                ? props.service_id
                : null,
            service_type:
              props.fibre_status == "TERMINATED" ||
              props.fibre_status == "SPLICED"
                ? props.terminated_status
                : null,
            remarks: props.fibre_status == "DAMAGED" ? props.remarks : null,
            spliced_fibre_code:
              props.fibre_status == "SPLICED"
                ? getValues("fibre_count")
                : props.fibre_status == "TERMINATED" && PatchFlagA
                ? getValues("patching")
                : null,
            spliced_fibre_impl_id:
              !!props.fibre_status && props.fibre_status == "SPLICED"
                ? !!SFibreDetails
                  ? SFibreDetails
                  : Sourcedetail?.spliced_fibre_impl_id
                : !!props.fibre_status &&
                  props.fibre_status == "TERMINATED" &&
                  PatchFlagA
                ? SPFibreDetails
                : null,
            fdb_fms_id:
              props.fibre_status == "TERMINATED" &&
              Sourcedetail?.fms_type != "FODB"
                ? props.fdb_fms_id
                : null,
            fdb_fms_code:
              props.fibre_status == "TERMINATED" &&
              Sourcedetail?.fms_type != "FODB"
                ? FDB?.find((m) => m.fms_id == +props.fdb_fms_id)?.fms_code
                : null,
            spliced_status:
              props.fibre_status == "SPLICED" && !!props.spliced_status
                ? props.spliced_status
                : null,
            service_impl_type:
              (props.fibre_status == "TERMINATED" ||
                props.fibre_status == "SPLICED") &&
              props.terminated_status == "IN-SERVICE"
                ? props.service_impl_type
                : null,
            service_desc:
              (props.fibre_status == "TERMINATED" ||
                props.fibre_status == "SPLICED") &&
              props.terminated_status == "IN-SERVICE"
                ? props.service_desc
                : null,
          }
        : null,
      destinationCableInfo: !!Destdetail
        ? {
            ...Destdetail,
            splice_impl_id: DFibreDetails,
            tube_colour_id: !!props.tube_color2 ? +props.tube_color2 : null,
            fibre_colour_id: !!props.fibre_color2 ? +props.fibre_color2 : null,
            tube_colour: props.tube_color2
              ? cableColor.find(
                  (m: any) => +m.colour_master_id == +props.tube_color2
                ).colour_name
              : null,
            fibre_colour: !!props.fibre_color2
              ? cableColor.find(
                  (m: any) => +m.colour_master_id == +props.fibre_color2
                ).colour_name
              : null,
            fibre_type: props.fibre_type2,
            fibre_status: props.fibre_status2,
            adapter_position:
              props.fibre_status2 == "TERMINATED"
                ? +props.adaptor_position2
                : null,
            adapter_type:
              props.fibre_status2 == "TERMINATED" ? props.adaptor_type2 : null,
            ferule_name: props.farul_name2,
            service_id:
              (props.fibre_status2 == "TERMINATED" ||
                props.fibre_status2 == "SPLICED") &&
              props.terminated_status2 == "IN-SERVICE"
                ? props.service_id2
                : null,
            service_type:
              props.fibre_status2 == "TERMINATED" ||
              props.fibre_status2 == "SPLICED"
                ? props.terminated_status2
                : null,
            remarks: props.fibre_status2 == "DAMAGED" ? props.remarks2 : null,
            spliced_fibre_code:
              props.fibre_status2 == "SPLICED"
                ? getValues("fibre_count2")
                : props.fibre_status2 == "TERMINATED" && PatchFlagB
                ? getValues("patching2")
                : null,
            spliced_fibre_impl_id:
              !!props.fibre_status2 && props.fibre_status2 == "SPLICED"
                ? !!DFibreDetails
                  ? DFibreDetails
                  : Destdetail?.spliced_fibre_impl_id
                : !!props.fibre_status2 &&
                  props.fibre_status2 == "TERMINATED" &&
                  PatchFlagB
                ? DPFibreDetails
                : null,
            fdb_fms_id:
              props.fibre_status2 == "TERMINATED" &&
              Destdetail?.fms_type != "FODB"
                ? props.fdb_fms_id2
                : null,
            fdb_fms_code:
              props.fibre_status2 == "TERMINATED" &&
              Destdetail?.fms_type != "FODB"
                ? FDB1?.find((m) => m.fms_id == +props.fdb_fms_id2)?.fms_code
                : null,
            spliced_status:
              props.fibre_status2 == "SPLICED" && !!props.spliced_status2
                ? props.spliced_status2
                : null,
            service_impl_type:
              (props.fibre_status2 == "TERMINATED" ||
                props.fibre_status2 == "SPLICED") &&
              props.terminated_status2 == "IN-SERVICE"
                ? props.service_impl_type2
                : null,
            service_desc:
              (props.fibre_status2 == "TERMINATED" ||
                props.fibre_status2 == "SPLICED") &&
              props.terminated_status2 == "IN-SERVICE"
                ? props.service_desc2
                : null,
          }
        : null,
    });
  };
  const OnSubmitSP = (data: any) => {
    setSPFibreDetails(data.impl_id);
    setValue("patching", data.lable);
  };
  const OnSubmitDP = (data: any) => {
    setDPFibreDetails(data.impl_id);
    setValue("patching2", data.lable);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h2>
          Fibre #{index + 1}{" "}
          {!!Sourcedetail?.tube_colour &&
            !!Sourcedetail.fibre_colour &&
            Sourcedetail.tube_colour + "-" + Sourcedetail.fibre_colour + " >> "}
          {!!Destdetail?.tube_colour &&
            !!Destdetail.fibre_colour &&
            Destdetail.tube_colour + "-" + Destdetail.fibre_colour}
        </h2>
      </AccordionSummary>

      <AccordionDetails>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ChooseCableModal
            fmsId={Sourcedetail?.fms_id}
            station={Sourcedetail?.station_id}
            open={cabSFModalOpen}
            onClose={setCabSFModalOpen}
            onSubmit={SetSourceFibreDetailsReq}
            id={Sourcedetail?.cable_detail_id}
            fab_id={Sourcedetail?.fibre_impl_id}
          />
          <ChooseCableModal
            fmsId={Destdetail?.fms_id}
            open={cabDFModalOpen}
            station={Destdetail?.station_id}
            onClose={setCabDFModalOpen}
            onSubmit={SetDestFibreDetailsReq}
            id={Destdetail?.cable_detail_id}
            fab_id={Destdetail?.fibre_impl_id}
          />
          <PatchCableModal
            onClose={SetPathA}
            open={PatchA}
            fmsId={Sourcedetail?.fms_id}
            getFMS={getFMS}
            FDB3={FDB3}
            GetFibre={GetFibre}
            patch_fibre={patch_fibre.filter(
              (m) => m.fibre_impl_id != Sourcedetail?.fibre_impl_id
            )}
            onSubmit={OnSubmitSP}
          />
          <PatchCableModal
            onClose={SetPatchB}
            open={PatchB}
            fmsId={Destdetail?.fms_id}
            getFMS={getFMS}
            FDB3={FDB3}
            GetFibre={GetFibre}
            patch_fibre={patch_fibre.filter(
              (m) => m.fibre_impl_id != Destdetail?.fibre_impl_id
            )}
            onSubmit={OnSubmitDP}
          />
          <Grid container>
            <Grid item xs={0.5}></Grid>

            <Grid item xs={5.2}>
              {!!Sourcedetail && (
                <div className="fibre">
                  <h3>
                    Fibre #{index + 1}{" "}
                    {!!Sourcedetail?.tube_colour &&
                      !!Sourcedetail?.fibre_colour &&
                      " || " +
                        Sourcedetail?.tube_colour +
                        " - " +
                        Sourcedetail?.fibre_colour}
                  </h3>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    padding={"20px"}
                  >
                    <Grid
                      item
                      xs={
                        !!StubeColor &&
                        StubeColor != "" &&
                        !!SfibreColor &&
                        SfibreColor != ""
                          ? 3.9
                          : 5
                      }
                    >
                      <Controller
                        control={control}
                        name={"tube_color"}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            style={{ width: "100%", margin: "5px" }}
                            type="text"
                            variant="outlined"
                            size="small"
                            select
                            name="tube_color"
                            onBlur={onBlur}
                            value={value}
                            onChange={(value) => (
                              onChange(value.target.value),
                              setSTubeColor(value.target.value)
                            )}
                            defaultValue={""}
                            error={!!errors["tube_color"]}
                            label={"Tube Color"}
                          >
                            {!!cableColor &&
                              cableColor
                                .filter(
                                  (m: any) =>
                                    m.cable_type === cableType &&
                                    (m.selection_type === 1 ||
                                      m.selection_type === 3)
                                )
                                .map((item: any) => (
                                  <MenuItem
                                    key={item.colour_master_id}
                                    value={item.colour_master_id}
                                  >
                                    <Grid container gap={1} direction={"row"}>
                                      <Box
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          backgroundColor: item.colour_code,
                                        }}
                                      ></Box>
                                      {item.colour_name}
                                    </Grid>
                                  </MenuItem>
                                ))}
                          </TextField>
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={
                        !!StubeColor &&
                        StubeColor != "" &&
                        !!SfibreColor &&
                        SfibreColor != ""
                          ? 3.9
                          : 5
                      }
                    >
                      <Controller
                        control={control}
                        name={"fibre_color"}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            style={{ width: "100%", margin: "5px" }}
                            type="text"
                            variant="outlined"
                            size="small"
                            onBlur={onBlur}
                            value={value}
                            onChange={(value) => (
                              onChange(value.target.value),
                              setSFibreColor(value.target.value)
                            )}
                            select
                            name="fibre_color"
                            defaultValue={""}
                            error={!!errors["fibre_color"]}
                            label={"Fibre Color"}
                          >
                            {!!cableColor &&
                              cableColor
                                .filter(
                                  (m: any) =>
                                    m.cable_type === cableType &&
                                    (m.selection_type === 2 ||
                                      m.selection_type === 3)
                                )
                                .map((item: any) => (
                                  <MenuItem
                                    key={item.colour_master_id}
                                    value={item.colour_master_id}
                                  >
                                    <Grid container gap={1} direction={"row"}>
                                      <Box
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          backgroundColor: item.colour_code,
                                        }}
                                      ></Box>
                                      {item.colour_name}
                                    </Grid>
                                  </MenuItem>
                                ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    {!!StubeColor &&
                    StubeColor != "" &&
                    !!SfibreColor &&
                    SfibreColor != "" ? (
                      <>
                        <Grid item xs={3.9}>
                          <Controller
                            control={control}
                            name={"fibre_type"}
                            defaultValue={""}
                            // rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                // hasInfo={true}
                                option={fibreTypes}
                                keys={"domain_value"}
                                label={"domain_value"}
                                onBlur={onBlur}
                                onChange={(value: any) => onChange(value)}
                                placeholder={"Fibre Type"}
                                value={value}
                                errors={errors["fibre_type"]}
                                textLabel={"Fibre Type"}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={5.8}>
                          <Controller
                            control={control}
                            name={"fibre_status"}
                            defaultValue={""}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                option={fibreStatus}
                                keys={"domain_value"}
                                label={"domain_value"}
                                onBlur={onBlur}
                                onChange={(value: string) => (
                                  SetFabSatA(value), onChange(value)
                                )}
                                placeholder={"Fibre"}
                                value={value}
                                errors={errors["fibre_status"]}
                                textLabel={"Fibre Status"}
                              />
                            )}
                          />
                        </Grid>
                        {FabSatA == "TERMINATED" &&
                          (Sourcedetail?.fms_type == "GCO" ||
                            Sourcedetail?.fms_type == "TJB") && (
                            <Grid item xs={5.8}>
                              <Controller
                                control={control}
                                name={"fdb_fms_id"}
                                defaultValue={""}
                                // rules={{ required: true }}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <Autocompletes
                                    option={FDB}
                                    keys={"fms_id"}
                                    label={"fms_code"}
                                    onBlur={onBlur}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Select Terminated FDB"}
                                    value={value}
                                    errors={errors["fdb_fms_id"]}
                                    textLabel={"Select Terminated FDB"}
                                  />
                                )}
                              />
                            </Grid>
                          )}
                        {FabSatA == "TERMINATED" && (
                          <Grid item xs={5.8}>
                            <Controller
                              control={control}
                              name={"adaptor_type"}
                              defaultValue={""}
                              // rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  option={adapterType}
                                  keys={"domain_value"}
                                  label={"domain_value"}
                                  onBlur={onBlur}
                                  onChange={(value: any) => onChange(value)}
                                  placeholder={"Adapter Type"}
                                  value={value}
                                  errors={errors["adaptor_type"]}
                                  textLabel={"Adapter Type"}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {FabSatA == "TERMINATED" && (
                          <Grid item xs={5.8}>
                            <Controller
                              control={control}
                              name={"adaptor_position"}
                              defaultValue={""}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <TextField
                                  {...register("adaptor_position")}
                                  style={{ width: "100%", margin: "5px" }}
                                  type="text"
                                  className="select-class"
                                  select
                                  onFocus={() =>
                                    !!Sourcedetail &&
                                    Sourcedetail.fms_id &&
                                    showAllPort(Sourcedetail.fms_id, 1)
                                  }
                                  variant="outlined"
                                  size="small"
                                  name="adaptor_position"
                                  placeholder="Adapter Position"
                                  error={!!errors["adaptor_position"]}
                                  label="Adapter Position"
                                  onChange={(val) => onChange(val.target.value)}
                                  value={value}
                                >
                                  {!!Sourcedetail &&
                                    Sourcedetail.adapter_position && (
                                      <MenuItem
                                        value={Sourcedetail.adapter_position}
                                        key={Sourcedetail.adapter_position}
                                      >
                                        {Sourcedetail.adapter_position}
                                      </MenuItem>
                                    )}
                                  {!!Port &&
                                    Port.port_list.map((item: number) => (
                                      <MenuItem value={item} key={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                </TextField>
                              )}
                            />
                          </Grid>
                        )}
                        {FabSatA == "SPLICED" && (
                          <Grid item xs={5.8}>
                            <Controller
                              control={control}
                              name={"spliced_status"}
                              defaultValue={""}
                              rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  option={[
                                    {
                                      domain_value: "Cord Cable",
                                    },
                                    {
                                      domain_value: "Direct Spliced",
                                    },
                                  ]}
                                  keys={"domain_value"}
                                  label={"domain_value"}
                                  onBlur={onBlur}
                                  onChange={(value: any) => onChange(value)}
                                  placeholder={"Splice Status"}
                                  value={value}
                                  errors={errors["spliced_status"]}
                                  textLabel={"Splice Status"}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {FabSatA == "SPLICED" && (
                          <Grid item xs={12}>
                            <TextField
                              {...register("fibre_count", {
                                required: FabSatA === "SPLICED" ? true : false,
                              })}
                              style={{ width: "100%", margin: "5px" }}
                              type="text"
                              variant="outlined"
                              size="small"
                              name="fibre_count"
                              placeholder="Fibre Details"
                              error={!!errors["fibre_count"]}
                              onClick={() => setCabSFModalOpen(true)}
                              label="Spliced With"
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <IconButton color="primary">
                                    <SearchIcon />
                                  </IconButton>
                                ),
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={5.8}>
                          <TextField
                            {...register(
                              "farul_name"
                              // { required: true }
                            )}
                            style={{ width: "100%", margin: "5px" }}
                            type="text"
                            variant="outlined"
                            size="small"
                            name="farul_name"
                            placeholder="Ferrule Name"
                            error={!!errors["farul_name"]}
                            label="Ferrule Name"
                          />
                        </Grid>
                        {(FabSatA == "TERMINATED" || FabSatA == "SPLICED") && (
                          <Grid item xs={5.8}>
                            <Controller
                              control={control}
                              name={"terminated_status"}
                              defaultValue={""}
                              rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  option={
                                    FabSatA == "TERMINATED"
                                      ? terminatedStatus
                                      : terminatedStatus?.filter(
                                          (m) => m.domain_value != "PATCHING"
                                        )
                                  }
                                  keys={"domain_value"}
                                  label={"domain_value"}
                                  onBlur={onBlur}
                                  onChange={(value: any) => (
                                    TerFabSatA(value),
                                    onChange(value),
                                    value == "IN-SERVICE"
                                      ? setValue("service_impl_type", "IT")
                                      : null
                                  )}
                                  placeholder={"Service Status"}
                                  value={value}
                                  errors={errors["terminated_status"]}
                                  textLabel={"Service Status"}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {(FabSatA == "TERMINATED" || FabSatA == "SPLICED") &&
                          (TerSatA == "IN-SERVICE" ||
                            TerSatA == "PATCHING") && (
                            <Grid item xs={5.8}>
                              <Controller
                                control={control}
                                name={"service_id"}
                                defaultValue={""}
                                // rules={{ required: true }}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <Autocompletes
                                    // hasInfo={true}
                                    option={allservice}
                                    keys={"service_id"}
                                    label={"service_name"}
                                    onBlur={onBlur}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Service Name"}
                                    value={value}
                                    errors={errors["service_id"]}
                                    textLabel={"Service Name"}
                                  />
                                )}
                              />
                            </Grid>
                          )}
                        {(FabSatA == "TERMINATED" || FabSatA == "SPLICED") &&
                          (TerSatA == "IN-SERVICE" ||
                            TerSatA == "PATCHING") && (
                            <Grid item xs={5.8}>
                              <Grid
                                container
                                direction={"row"}
                                justifyContent={"space-around"}
                                alignItems={"center"}
                              >
                                <Grid>
                                  <Typography>Service Type :</Typography>
                                </Grid>
                                <Grid>
                                  <Controller
                                    control={control}
                                    name={"service_impl_type"}
                                    defaultValue={""}
                                    // rules={{ required: true }}
                                    render={({
                                      field: { onBlur, value, onChange },
                                    }) => (
                                      <RadioGroup
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        style={{
                                          justifyContent: "center",
                                          alignItems: "start",
                                        }}
                                        onChange={(value) =>
                                          onChange(value.target.value)
                                        }
                                        value={value}
                                      >
                                        <FormControlLabel
                                          value="IT"
                                          control={<Radio size="small" />}
                                          label="IT"
                                        />
                                        <FormControlLabel
                                          value="OT"
                                          control={<Radio size="small" />}
                                          label="OT"
                                        />
                                      </RadioGroup>
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        {(FabSatA == "TERMINATED" || FabSatA == "SPLICED") &&
                          (TerSatA == "IN-SERVICE" ||
                            TerSatA == "PATCHING") && (
                            <Grid item xs={5.8}>
                              <TextField
                                {...register(
                                  "service_desc"
                                  // { required: true }
                                )}
                                style={{ width: "100%", margin: "5px" }}
                                type="text"
                                variant="outlined"
                                size="small"
                                name="service_desc"
                                placeholder="Service Description"
                                error={!!errors["service_desc"]}
                                label="Service Description"
                              />
                            </Grid>
                          )}
                        {FabSatA == "TERMINATED" && (
                          <Grid item xs={5.8}>
                            <FormControlLabel
                              style={{ margin: "1px" }}
                              control={
                                <Switch
                                  checked={PatchFlagA}
                                  onChange={(bal: any) =>
                                    SetPatchFlagA(!PatchFlagA)
                                  }
                                />
                              }
                              label="Is Patched?"
                            />
                          </Grid>
                        )}
                        {PatchFlagA && FabSatA == "TERMINATED" && (
                          <Grid item xs={12}>
                            <TextField
                              {...register("patching", {
                                required:
                                  FabSatA === "TERMINATED" &&
                                  TerSatA == "PATCHING"
                                    ? true
                                    : false,
                              })}
                              style={{ width: "100%", margin: "5px" }}
                              type="text"
                              variant="outlined"
                              size="small"
                              placeholder="Patching With"
                              name="patching"
                              error={!!errors["patching"]}
                              onClick={() => SetPathA(true)}
                              label="Patching With"
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <IconButton color="primary">
                                    <SearchIcon />
                                  </IconButton>
                                ),
                              }}
                            />
                          </Grid>
                        )}
                        {FabSatA == "DAMAGED" && (
                          <Grid item xs={12}>
                            <TextField
                              {...register(
                                "remarks"
                                //  { required: true }
                              )}
                              style={{ width: "100%", margin: "5px" }}
                              type="text"
                              variant="outlined"
                              size="small"
                              multiline={true}
                              name="remarks"
                              placeholder="Remarks"
                              error={!!errors["remarks"]}
                              label="Remarks"
                            />
                          </Grid>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </div>
              )}
              {!Sourcedetail && (
                <div className="fibre">
                  <h3>There is no Fibre !</h3>
                  <h1 style={{ color: "#4b90a9" }}>Asymmetric</h1>
                </div>
              )}
            </Grid>
            <Grid
              xs={0.5}
              container
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                <DoubleArrowIcon style={{ fontSize: 30, color: "#6eb8d3" }} />
              </Grid>
            </Grid>
            <Grid item xs={5.2}>
              {!!Destdetail && (
                <div className="fibre">
                  <h3>
                    Fibre #{index + 1}{" "}
                    {!!Destdetail?.tube_colour &&
                      !!Destdetail?.fibre_colour &&
                      " || " +
                        Destdetail?.tube_colour +
                        " - " +
                        Destdetail?.fibre_colour}
                  </h3>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    padding={"20px"}
                  >
                    <Grid
                      item
                      xs={
                        !!TtubeColor &&
                        TtubeColor != "" &&
                        !!TfibreColor &&
                        TfibreColor != ""
                          ? 3.9
                          : 5
                      }
                    >
                      <Controller
                        control={control}
                        name={"tube_color2"}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            style={{ width: "100%", margin: "5px" }}
                            type="text"
                            variant="outlined"
                            size="small"
                            select
                            name="tube_color2"
                            onBlur={onBlur}
                            value={value}
                            onChange={(value) => (
                              onChange(value.target.value),
                              setTTubeColor(value.target.value)
                            )}
                            defaultValue={""}
                            error={!!errors["tube_color2"]}
                            label={"Tube Color"}
                          >
                            {!!cableColor &&
                              cableColor
                                .filter((m: any) =>
                                  !!SecondCableType
                                    ? m.cable_type === SecondCableType &&
                                      (m.selection_type === 1 ||
                                        m.selection_type === 3)
                                    : m.cable_type === cableType &&
                                      (m.selection_type === 1 ||
                                        m.selection_type === 3)
                                )
                                .map((item: any) => (
                                  <MenuItem
                                    key={item.colour_master_id}
                                    value={item.colour_master_id}
                                  >
                                    <Grid container gap={1} direction={"row"}>
                                      <Box
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          backgroundColor: item.colour_code,
                                        }}
                                      ></Box>
                                      {item.colour_name}
                                    </Grid>
                                  </MenuItem>
                                ))}
                          </TextField>
                        )}
                      />
                    </Grid>

                    {/*  desti fibre data */}

                    <Grid
                      item
                      xs={
                        !!TtubeColor &&
                        TtubeColor != "" &&
                        !!TfibreColor &&
                        TfibreColor != ""
                          ? 3.9
                          : 5
                      }
                    >
                      <Controller
                        control={control}
                        name={"fibre_color2"}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            style={{ width: "100%", margin: "5px" }}
                            type="text"
                            variant="outlined"
                            size="small"
                            onBlur={onBlur}
                            value={value}
                            onChange={(value) => (
                              onChange(value.target.value),
                              setTFibreColor(value.target.value)
                            )}
                            select
                            name="fibre_color2"
                            defaultValue={""}
                            error={!!errors["fibre_color2"]}
                            label={"Fibre Color"}
                          >
                            {!!cableColor &&
                              cableColor
                                .filter((m: any) =>
                                  !!SecondCableType
                                    ? m.cable_type === SecondCableType &&
                                      (m.selection_type === 2 ||
                                        m.selection_type === 3)
                                    : m.cable_type === cableType &&
                                      (m.selection_type === 2 ||
                                        m.selection_type === 3)
                                )
                                .map((item: any) => (
                                  <MenuItem
                                    key={item.colour_master_id}
                                    value={item.colour_master_id}
                                  >
                                    <Grid container gap={1} direction={"row"}>
                                      <Box
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          backgroundColor: item.colour_code,
                                        }}
                                      ></Box>
                                      {item.colour_name}
                                    </Grid>
                                  </MenuItem>
                                ))}
                          </TextField>
                        )}
                      />
                    </Grid>

                    {!!TtubeColor &&
                    TtubeColor != "" &&
                    !!TfibreColor &&
                    TfibreColor != "" ? (
                      <>
                        <Grid item xs={3.9}>
                          <Controller
                            control={control}
                            name={"fibre_type2"}
                            defaultValue={""}
                            // rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                // hasInfo={true}
                                option={fibreTypes}
                                keys={"domain_value"}
                                label={"domain_value"}
                                onBlur={onBlur}
                                onChange={(value: any) => onChange(value)}
                                placeholder={"Fibre Type"}
                                value={value}
                                errors={errors["fibre_type2"]}
                                textLabel={"Fibre Type"}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={5.8}>
                          <Controller
                            control={control}
                            name={"fibre_status2"}
                            defaultValue={""}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                option={fibreStatus}
                                keys={"domain_value"}
                                label={"domain_value"}
                                onBlur={onBlur}
                                onChange={(value: string) => (
                                  SetFabSatB(value), onChange(value)
                                )}
                                placeholder={"Fibre"}
                                value={value}
                                errors={errors["fibre_status2"]}
                                textLabel={"Fibre Status"}
                              />
                            )}
                          />
                        </Grid>
                        {FabSatB == "TERMINATED" &&
                          (Destdetail?.fms_type == "GCO" ||
                            Destdetail?.fms_type == "TJB") && (
                            <Grid item xs={5.8}>
                              <Controller
                                control={control}
                                name={"fdb_fms_id2"}
                                defaultValue={""}
                                // rules={{ required: true }}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <Autocompletes
                                    option={FDB1}
                                    keys={"fms_id"}
                                    label={"fms_code"}
                                    onBlur={onBlur}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Select Terminated FDB"}
                                    value={value}
                                    errors={errors["fdb_fms_id2"]}
                                    textLabel={"Select Terminated FDB"}
                                  />
                                )}
                              />
                            </Grid>
                          )}
                        {FabSatB == "TERMINATED" && (
                          <Grid item xs={5.8}>
                            <Controller
                              control={control}
                              name={"adaptor_type2"}
                              defaultValue={""}
                              // rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  option={adapterType}
                                  keys={"domain_value"}
                                  label={"domain_value"}
                                  onBlur={onBlur}
                                  onChange={(value: any) => onChange(value)}
                                  placeholder={"Adapter Type"}
                                  value={value}
                                  errors={errors["adaptor_type2"]}
                                  textLabel={"Adapter Type"}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {FabSatB == "TERMINATED" && (
                          <Grid item xs={5.8}>
                            <Controller
                              control={control}
                              name={"adaptor_position2"}
                              defaultValue={""}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <TextField
                                  {...register(
                                    "adaptor_position2"
                                    //  { required: true }
                                  )}
                                  style={{ width: "100%", margin: "5px" }}
                                  type="text"
                                  className="select-class"
                                  select
                                  onFocus={() =>
                                    !!Destdetail &&
                                    !!Destdetail.fms_id &&
                                    showAllPort(Destdetail.fms_id, 2)
                                  }
                                  variant="outlined"
                                  size="small"
                                  name="adaptor_position2"
                                  placeholder="Adapter Position"
                                  error={!!errors["adaptor_position2"]}
                                  label="Adapter Position"
                                  onChange={(val) => onChange(val.target.value)}
                                  value={value}
                                >
                                  {!!Destdetail &&
                                    !!Destdetail.adapter_position && (
                                      <MenuItem
                                        value={Destdetail.adapter_position}
                                        key={Destdetail.adapter_position}
                                      >
                                        {Destdetail.adapter_position}
                                      </MenuItem>
                                    )}
                                  {!!Port &&
                                    Port.port_list.map((item: number) => (
                                      <MenuItem value={item} key={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                </TextField>
                              )}
                            />
                          </Grid>
                        )}
                        {FabSatB == "SPLICED" && (
                          <Grid item xs={5.8}>
                            <Controller
                              control={control}
                              name={"spliced_status2"}
                              defaultValue={""}
                              rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  option={[
                                    {
                                      domain_value: "Cord Cable",
                                    },
                                    {
                                      domain_value: "Direct Spliced",
                                    },
                                  ]}
                                  keys={"domain_value"}
                                  label={"domain_value"}
                                  onBlur={onBlur}
                                  onChange={(value: any) => onChange(value)}
                                  placeholder={"Splice Status"}
                                  value={value}
                                  errors={errors["spliced_status2"]}
                                  textLabel={"Splice Status"}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {FabSatB == "SPLICED" && (
                          <Grid item xs={12}>
                            <TextField
                              {...register("fibre_count2", {
                                required: FabSatB === "SPLICED" ? true : false,
                              })}
                              style={{ width: "100%", margin: "5px" }}
                              type="text"
                              variant="outlined"
                              size="small"
                              name="fibre_count2"
                              placeholder="Fibre Details"
                              onClick={() => setCabDFModalOpen(true)}
                              error={!!errors["fibre_count2"]}
                              label="Spliced With"
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <IconButton color="primary">
                                    <SearchIcon />
                                  </IconButton>
                                ),
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={5.8}>
                          <TextField
                            {...register(
                              "farul_name2"
                              //  { required: true }
                            )}
                            style={{ width: "100%", margin: "5px" }}
                            type="text"
                            variant="outlined"
                            size="small"
                            name="farul_name2"
                            placeholder="Ferrule Name"
                            error={!!errors["farul_name2"]}
                            label="Ferrule Name"
                          />
                        </Grid>
                        {(FabSatB == "TERMINATED" || FabSatB == "SPLICED") && (
                          <Grid item xs={5.8}>
                            <Controller
                              control={control}
                              name={"terminated_status2"}
                              defaultValue={""}
                              // rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  option={
                                    FabSatB == "TERMINATED"
                                      ? terminatedStatus
                                      : terminatedStatus?.filter(
                                          (m) => m.domain_value != "PATCHING"
                                        )
                                  }
                                  keys={"domain_value"}
                                  label={"domain_value"}
                                  onBlur={onBlur}
                                  onChange={(value: any) => (
                                    TerFabSatB(value),
                                    onChange(value),
                                    value == "IN-SERVICE"
                                      ? setValue("service_impl_type2", "IT")
                                      : null
                                  )}
                                  placeholder={"Service Status"}
                                  value={value}
                                  errors={errors["terminated_status2"]}
                                  textLabel={"Service Status"}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {(FabSatB == "TERMINATED" || FabSatB == "SPLICED") &&
                          (TerSatB == "IN-SERVICE" ||
                            TerSatB == "PATCHING") && (
                            <Grid item xs={5.8}>
                              <Controller
                                control={control}
                                name={"service_id2"}
                                defaultValue={""}
                                // rules={{ required: true }}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <Autocompletes
                                    // hasInfo={true}
                                    option={allservice}
                                    keys={"service_id"}
                                    label={"service_name"}
                                    onBlur={onBlur}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Service Name"}
                                    value={value}
                                    errors={errors["service_id2"]}
                                    textLabel={"Service Name"}
                                  />
                                )}
                              />
                            </Grid>
                          )}
                        {(FabSatB == "TERMINATED" || FabSatB == "SPLICED") &&
                          (TerSatB == "IN-SERVICE" ||
                            TerSatB == "PATCHING") && (
                            <Grid item xs={5.8}>
                              <Controller
                                control={control}
                                name={"service_impl_type2"}
                                defaultValue={""}
                                // rules={{ required: true }}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    style={{
                                      justifyContent: "space-around",
                                      alignItems: "center",
                                    }}
                                    onChange={(value) =>
                                      onChange(value.target.value)
                                    }
                                    value={value}
                                  >
                                    <Typography>Service Type :</Typography>
                                    <FormControlLabel
                                      value="IT"
                                      control={<Radio size="small" />}
                                      label="IT"
                                    />
                                    <FormControlLabel
                                      value="OT"
                                      control={<Radio size="small" />}
                                      label="OT"
                                    />
                                  </RadioGroup>
                                )}
                              />
                            </Grid>
                          )}
                        {(FabSatB == "TERMINATED" || FabSatB == "SPLICED") &&
                          (TerSatB == "IN-SERVICE" ||
                            TerSatB == "PATCHING") && (
                            <Grid item xs={5.8}>
                              <TextField
                                {...register(
                                  "service_desc2"
                                  // { required: true }
                                )}
                                style={{ width: "100%", margin: "5px" }}
                                type="text"
                                variant="outlined"
                                size="small"
                                name="service_desc2"
                                placeholder="Service Description"
                                error={!!errors["service_desc2"]}
                                label="Service Description"
                              />
                            </Grid>
                          )}
                        {FabSatB == "TERMINATED" && (
                          <Grid item xs={5.8}>
                            <FormControlLabel
                              style={{ margin: "1px" }}
                              control={
                                <Switch
                                  checked={PatchFlagB}
                                  onChange={(bal: any) =>
                                    SetPatchFlagB(!PatchFlagB)
                                  }
                                />
                              }
                              label="Is Patched?"
                            />
                          </Grid>
                        )}
                        {PatchFlagB && FabSatB == "TERMINATED" && (
                          <Grid item xs={12}>
                            <TextField
                              {...register("patching2", {
                                required:
                                  FabSatB === "TERMINATED" &&
                                  TerSatB == "PATCHING"
                                    ? true
                                    : false,
                              })}
                              style={{ width: "100%", margin: "5px" }}
                              type="text"
                              variant="outlined"
                              size="small"
                              name="patching2"
                              placeholder="Patching With"
                              onClick={() => SetPatchB(true)}
                              error={!!errors["patching2"]}
                              label="Patching With"
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <IconButton color="primary">
                                    <SearchIcon />
                                  </IconButton>
                                ),
                              }}
                            />
                          </Grid>
                        )}
                        {FabSatB == "DAMAGED" && (
                          <Grid item xs={12}>
                            <TextField
                              {...register(
                                "remarks2"
                                //  { required: true }
                              )}
                              style={{ width: "100%", margin: "5px" }}
                              type="text"
                              variant="outlined"
                              size="small"
                              multiline={true}
                              name="remarks2"
                              placeholder="Remarks"
                              error={!!errors["remarks2"]}
                              label="Remarks"
                            />
                          </Grid>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </div>
              )}
              {!Destdetail && (
                <div className="fibre">
                  <h3>There is no Fibre !</h3>
                  <h1 style={{ color: "#4b90a9" }}>Asymmetric</h1>
                </div>
              )}
            </Grid>
            <Grid item xs={0.3}>
              <div style={{ marginTop: 126 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ m: 1, position: "relative" }}>
                    <Fab
                      aria-label="save"
                      color="primary"
                      sx={buttonSx}
                      type="submit"
                      style={{ width: 40, height: 40 }}
                    >
                      {success ? (
                        <CheckIcon style={{ color: "#fff" }} />
                      ) : (
                        <SaveIcon style={{ color: "#fff" }} />
                      )}
                    </Fab>
                    {loading && (
                      <CircularProgress
                        size={53}
                        sx={{
                          color: green[500],
                          position: "absolute",
                          top: -6,
                          left: -6,
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
        </form>
      </AccordionDetails>
    </Accordion>
  );
}
const mapStateToProps = (state: StoreState) => {
  return {
    cable_list: state.cable.cable_list,
    Port: state.cable.Port,
  };
};
const mapDispatchToProps = {
  GetAvailablePorts,
};

export default connect(mapStateToProps, mapDispatchToProps)(FibreDetailCard);

interface FibreDetailProps {
  index: number;
  item?: any;
  fibreStatus?: any;
  terminatedStatus: any[];
  fibreTypes?: any;
  adapterType?: any;
  allservice?: any;
  cableColor?: any;
  Destdetail?: DestinationCableInfo;
  Sourcedetail?: SourceCableInfo;
  UpdateFibre?: any;
  cable_list?: CableList[];
  GetAvailablePorts?: any;
  Port?: PortDetail;
  FDB: FMSList[];
  FDB1: FMSList[];
  getFMS?: any;
  FDB3: FMSList[];
  GetFibre?: any;
  patch_fibre: PatchFibre[];
  cableType?: string;
  SecondCableType?: string;
}
