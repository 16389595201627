import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CableList } from "../../../models/cableModel";
import { DomainList } from "../../../models/domainModel";
import { FMSList } from "../../../models/fmsModel";
import { StoreState } from "../../../models/reduxModels";
import { ReportData } from "../../../models/ReportModel";
import { ServiceList } from "../../../models/serviceMasterModel";
import { StationList } from "../../../models/stationModel";
import { GetAllCableAction } from "../../../Stores/actions/cableAction";
import { DomainTypes } from "../../../Stores/actions/domainAction";
import { GetReportData, ReportClearAction } from "../../../Stores/actions/ReportAction";
import { GetAllServices } from "../../../Stores/actions/serviceMasterAction";
import { GetAllStations } from "../../../Stores/actions/stationAction";
import ReportMainView from "./ReportMainView";

const ReportMain = ({
  GetAllCableAction,
  DomainTypes,
  GetReportData,
  fms_list,
  cable_list,
  domain,
  reportData,
  GetAllServices,
  allservice,
  GetAllStations,
  allstation,
  ReportClearAction
}: ReportMainProps) => {
  useEffect(() => {
    DomainTypes("transaction_type");
    GetAllCableAction();
    GetAllServices();
    GetAllStations();
  }, []);

  return (
    <ReportMainView
      domainList={domain}
      fmsList={fms_list}
      cableList={cable_list}
      GetReportData={GetReportData}
      reportData={reportData}
      allservice={allservice}
      allstation={allstation}
      ReportClearAction={()=> ReportClearAction()}
    />
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    fms_list: state.cable.fms_list,
    cable_list: state.cable.cable_list,
    domain: state.domain.transaction_type,
    reportData: state.report.report_data,
    allservice: state.service.service_list,
    allstation: state.station.station_list,
  };
};
const mapDispatchToProps = {
  GetAllCableAction,
  DomainTypes,
  GetReportData,
  GetAllServices,
  GetAllStations,
  ReportClearAction
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportMain);

interface ReportMainProps {
  GetAllCableAction?: any;
  DomainTypes?: any;
  GetReportData?: any;
  fms_list: FMSList[];
  cable_list: CableList[];
  domain: DomainList[];
  reportData: ReportData[];
  GetAllServices?: any;
  allservice: ServiceList[];
  GetAllStations?: any;
  allstation: StationList[];
  ReportClearAction?: any;
}
