import { cableDetailMain } from "../../models/cableModel";
import { CableActionTypes } from "../actions/cableAction";
import { ReportActionTypes } from "../actions/ReportAction";
import InitialState from "./initialState";

const initialState: cableDetailMain = InitialState.cable;

export default function CableReducer(
  state: cableDetailMain = initialState,
  action: any
) {
  switch (action.type) {
    case CableActionTypes.Get_All_Cable_Success_Action:
      return {
        ...state,
        cable_list: action.payload.cable_list,
        fms_list: action.payload.fms_list,
        edit_flag: false,
        cable_det: undefined,
      };
    case CableActionTypes.Cable_Detail_Get_By_Id_Load_Success_Action:
      return { ...state, single_cable: action.payload };
    case CableActionTypes.Show_FMS_Wise_Cable:
      return {
        ...state,
        cable_list: action.payload.cable_list,
        fms_list: action.payload.fms_list,
      };
    case CableActionTypes.Collect_FMS_Record_for_filter:
      return {
        ...state,
        has_filter: action.payload,
        edit_flag: false,
        cable_det: undefined,
      };
    case CableActionTypes.Cable_Detail_Load_Success_Action:
      return { ...state, cable_det: action.payload, edit_flag: false };
    case CableActionTypes.Cable_Detail_Remove_Success_Action:
      return { ...state, cable_det: undefined, edit_flag: false };
    case CableActionTypes.Fms_Port_Detail_Load_Success_Action:
      return { ...state, fms_port_detail: action.payload };
    case CableActionTypes.Cable_Create_Success_Action:
      return { ...state, cable_det: action.payload, edit_flag: true };
    case CableActionTypes.Get_ALL_FMS_BY_STATION_ID_ACTION:
      return { ...state, fms_list_by_station: action.payload, cable_list: [] };
    case CableActionTypes.Fibrebycablefms_get_success:
      return { ...state, fibrebycablefms: action.payload };
    case CableActionTypes.Get_Available_Port_Success_Action:
      return { ...state, Port: action.payload };
    case CableActionTypes.Get_All_Cable_By_FMS:
      return {
        ...state,
        cable_list: action.payload,
      };
    case CableActionTypes.Get_Fdb_By_Fms_Success:
      return { ...state, fdb_list: action.payload };
    case CableActionTypes.Get_Fdb2_By_Fms_Success:
      return { ...state, fdb_list2: action.payload };
    case CableActionTypes.Get_Fdb4_By_Fms_Success:
      return { ...state, fdb_list4: action.payload };
    case CableActionTypes.Get_Fdb_Fibre_Success:
      return { ...state, patch_fibre: action.payload };
    case ReportActionTypes.Get_Damage_Fibre_Load_Success:
      return {
        ...state,
        cable_list: action.payload[4].data,
      };
    default:
      return state;
  }
}
