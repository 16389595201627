import React from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  TablePagination,
  Fab,
  styled,
  TableCell,
  tableCellClasses,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
  Tooltip,
  Card,
  Typography,
} from "@mui/material";
import { AssetCableList } from "../../../models/ReportModel";
const TabData = ({ tabData, caption, totalCost }: TabDataProps) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box>
        <Typography
          sx={{
            color: "gray",
            textAlign: "center",
            textShadow: "0px 0px 0px #fff, 3px 3px 0px rgba(0,0,0,0.15)",
            fontWeight: 620,
          }}
          variant="h5"
        >
          {caption}
        </Typography>
      </Box>
      <TableContainer sx={{ marginTop: "1.4rem" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                Cable Short Code
              </StyledTableCell>
              {/* <StyledTableCell style={{ borderCollapse: "collapse" }}>
                Cable Color{" "}
              </StyledTableCell> */}
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                Cable Make
              </StyledTableCell>
              {/* <StyledTableCell
              style={{  borderCollapse: "collapse" }}
            >
              Source FMS Code
            </StyledTableCell>
            <StyledTableCell
              style={{  borderCollapse: "collapse" }}
            >
              Desti. FMS Code
            </StyledTableCell> */}
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                Cable Strength{" "}
              </StyledTableCell>
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                Cable Length{" "}
              </StyledTableCell>
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                Cable Type
              </StyledTableCell>
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                Cost Per Unit (₹)
              </StyledTableCell>
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                Total Cost (₹)
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tabData?.map((tData: AssetCableList, index: number) => (
              <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.cable_short_code ? tData.cable_short_code : "NA"}
                </StyledTableCell>
                {/* <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.cable_colour ? tData.cable_colour : "NA"}
                </StyledTableCell> */}
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.cable_make ? tData.cable_make : "NA"}
                </StyledTableCell>
                {/* <td
                style={{  borderCollapse: "collapse" }}
              >
                {tData.source_fms_code ? tData.source_fms_code : "NA"}
              </td>
              <td
                style={{  borderCollapse: "collapse" }}
              >
                {tData.destination_fms_code ? tData.destination_fms_code : "NA"}
              </td> */}
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.cable_strength ? tData.cable_strength + "F" : "NA"}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.cable_actual_length
                    ? tData.cable_actual_length + " mt"
                    : tData.cable_actual_length}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.cable_type ? tData.cable_type : "NA"}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.cost_per_unit ? tData.cost_per_unit : "NA"}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.total_cost ? tData.total_cost : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {tabData?.length > 0 && (
              <tr style={{ textAlign: "center", height: 30 }}>
                <td colSpan={6} style={{ textAlign: "end" }}>
                  <Typography
                    sx={{
                      fontWeight: 680,
                      color: "#363636",
                      marginRight: 1,
                      fontSize: 20,
                    }}
                  >
                    Total Cost =
                  </Typography>
                </td>
                <td
                  colSpan={1}
                  style={{ textAlign: "start", color: "#363636" }}
                >
                  <div style={{ fontWeight: 680, fontSize: 20 }}>
                    {" "}
                    ₹ {totalCost}{" "}
                  </div>
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default TabData;
interface TabDataProps {
  tabData: AssetCableList[];
  caption: string;
  totalCost?: number;
}
