import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import { useSnackbar } from "notistack";
import { FMSList } from "../../../models/fmsModel";
import { FMSLocationList } from "../../../models/fmsLocationModel";
import { useHistory } from "react-router-dom";
import {
  DeleteFMSAction,
  AddFMSAction,
  GetALLFMS,
  GetFMSByIdAction,
  GetFMSLocationWiseAction,
  GetAllFDBByFMS,
  ClearAllFDBByFMS,
} from "../../../Stores/actions/fmsAction";
import { FmsWiseCable } from "../../../Stores/actions/cableAction";
import FMSMainView from "./FMSMainView";
import { UserDetails } from "../../../models/userModels";

const FMSMain = ({
  allFMS,
  GetALLFMS,
  DeleteFMSAction,
  FmsWiseCable,
  GetFMSByIdAction,
  AddFMSAction,
  filter,
  location_list,
  GetFMSLocationWiseAction,
  user,
  GetAllFDBByFMS,
  fdb_list,
  ClearAllFDBByFMS,
}: FMSProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [filters, SetFilters] = useState<number>(0);
  const history = useHistory();

  useEffect(() => {
    if (!!filter) {
      SetFilters(+filter.location_id);
      GetFMSLocationWiseAction({ location_id: +filter.location_id });
    } else {
      GetALLFMS();
      SetFilters(0);
    }
  }, []);
  const onDelete = (data: number) => {
    DeleteFMSAction({
      payload: data,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  const handleFilterLocWise = (data: number) => {
    if (data) GetFMSLocationWiseAction({ location_id: data });
    else GetALLFMS();
  };
  const goToCable = (data: FMSList) => {
    FmsWiseCable(data);
    history.push("/cable/cable-main");
  };
  const ShowJointinfo = (data: FMSList) => {
    history.push(`/fms/joint-infos/${data.fms_id}`);
  };
  const FmsSchematicView = (data: FMSList) => {
    history.push(`/fms/fms-schematic/${data.fms_id}`);
  };
  return (
    <FMSMainView
      allFMS={allFMS}
      Delete={onDelete}
      locationList={location_list}
      filters={filters}
      SetFilters={SetFilters}
      handleFilterLocWise={handleFilterLocWise}
      goToCable={goToCable}
      user={user}
      GetAllFDBByFMS={GetAllFDBByFMS}
      fdb_list={fdb_list}
      ClearAllFDBByFMS={ClearAllFDBByFMS}
      ShowJointinfo={ShowJointinfo}
      FmsSchematicView={FmsSchematicView}
    />
  );
};

const mapDispatchToProps = {
  DeleteFMSAction,
  AddFMSAction,
  GetALLFMS,
  GetFMSByIdAction,
  GetFMSLocationWiseAction,
  FmsWiseCable,
  GetAllFDBByFMS,
  ClearAllFDBByFMS,
};

const mapStateToProps = (state: StoreState) => {
  return {
    allFMS: state.fms.fms_list,
    location_list: state.fms.fms_location_list,
    filter: state.fms.has_filter,
    user: state.user.userDetails,
    fdb_list: state.fms.fdb_list,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FMSMain);
interface FMSProps {
  allFMS: FMSList[];
  GetALLFMS?: any;
  DeleteFMSAction?: any;
  GetFMSByIdAction?: any;
  AddFMSAction?: any;
  filter?: any;
  GetFMSLocationWiseAction?: any;
  location_list: FMSLocationList[];
  FmsWiseCable?: any;
  user?: UserDetails;
  GetAllFDBByFMS?: any;
  fdb_list: FMSList[];
  ClearAllFDBByFMS?: any;
}
