import React from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  TablePagination,
  Fab,
  styled,
  TableCell,
  tableCellClasses,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
  Tooltip,
  Card,
  Typography,
} from "@mui/material";
import { AssetFMSList } from "../../../models/ReportModel";
const TabData = ({ tabData, caption, totalCost }: TabDataProps) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box>
        <Typography
          sx={{
            color: "gray",
            textAlign: "center",
            textShadow: "0px 0px 0px #fff, 3px 3px 0px rgba(0,0,0,0.15)",
            fontWeight: 620,
          }}
          variant="h5"
        >
          {caption}
        </Typography>
      </Box>
      <TableContainer sx={{ marginTop: "1.4rem" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                FMS Code
              </StyledTableCell>
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                FMS Type{" "}
              </StyledTableCell>
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                FMS Installation Name
              </StyledTableCell>
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                FMS Installation Type
              </StyledTableCell>
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                Station
              </StyledTableCell>
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                FMS Location
              </StyledTableCell>
              <StyledTableCell style={{ borderCollapse: "collapse" }}>
                FMS Cost (₹){" "}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tabData?.map((tData: AssetFMSList, index: number) => (
              <StyledTableRow
                // style={{  textAlign: "center" }}
                // key={index}
                hover
                role="checkbox"
                tabIndex={-1}
                key={index}
              >
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.fms_code ? tData.fms_code : "NA"}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.fms_type ? tData.fms_type : "NA"}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.fms_installation_name
                    ? tData.fms_installation_name
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.fms_installation_type
                    ? tData.fms_installation_type
                    : "NA"}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.station_code ? tData.station_code : "NA"}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.location_name ? tData.location_name : "NA"}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  {tData.fms_cost ? tData.fms_cost : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {tabData?.length > 0 && (
              <tr style={{ textAlign: "center" }}>
                <td colSpan={6} style={{ textAlign: "end" }}>
                  <Typography
                    sx={{ fontWeight: 680, marginRight: 1, fontSize: 20 }}
                  >
                    Total Cost =
                  </Typography>
                </td>
                <td colSpan={1} style={{ textAlign: "start" }}>
                  <div style={{ fontWeight: 680, fontSize: 20 }}>
                    {" "}
                    ₹ {totalCost}{" "}
                  </div>
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default TabData;
interface TabDataProps {
  tabData: AssetFMSList[];
  caption: string;
  totalCost?: number;
}
