import { AppBar, styled, Tab, Tabs } from '@mui/material';
// import { color } from '@mui/system';
import React, { useEffect } from 'react'
import { Link, Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import AddCable from './AddCable';
import AddFibre from './AddFibre';
import AddTube from './AddTube';
import CableMain from './CableMain';
import EditCable from './EditCable';
import EditFibre from './EditFibre';
import EditTube from './EditTube';
import FibreMain from './FibreMain';
import TubeMain from './TubeMain';

function CableMaster() {
const { path, url } = useRouteMatch();
const history = useHistory();
const location = useLocation()
const routes = [
"/cable-master/cable-main",
"/cable-master/tube-main", 
"/cable-master/fibre-main",
 "/cable-master/tube-add",
 "/cable-master/fibre-add",
 "/cable-master/cable-add",
 "/cable-master/fibre-edit",
 "/cable-master/cable-edit",
 "/cable-master/tube-edit",
];
  return (
    <div >
        <AppBar position="static" style={{marginTop: -15, backgroundColor:'#035779'}} >
            <AntTabs
            variant="fullWidth"
              value={
                history.location.pathname !== "/"
                ?"/"+ history.location.pathname.split("/")[1]+"/"+
                history.location.pathname.split("/")[2]
                : false
              }
            >
              <AntTab
                value={history.location.pathname == routes[5] ? 
                  routes[5] : 
                  history.location.pathname== routes[0]?
                  routes[0]:
                  routes[7]
                }
                label="CABLE MASTER"
                component={Link}
                to={routes[0]}
              />
              <AntTab
                value={history.location.pathname == routes[3] ? 
                  routes[3] : 
                  history.location.pathname== routes[1]?
                  routes[1]:
                  routes[8]}
                label="TUBE MASTER"
                component={Link}
                to={routes[1]}
              />
              <AntTab
                value={history.location.pathname == routes[4] ? 
                  routes[4] : 
                  history.location.pathname== routes[2]?
                  routes[2]:
                  routes[6]}
                label="FIBRE MASTER"
                component={Link}
                to={routes[2]}
              />
            </AntTabs>
            </AppBar>

      <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <Redirect to={`${path}/cable-main`} />}
      />
        <Route path={`${path}/cable-main`} component={CableMain} />
        <Route path={`${path}/tube-main`} component={TubeMain} />
        <Route path={`${path}/tube-add`} component={AddTube} />
        <Route path={`${path}/fibre-main`} component={FibreMain} />
        <Route path={`${path}/fibre-add`} component={AddFibre} />
        <Route path={`${path}/fibre-edit/:id`} component={EditFibre} />
        <Route path={`${path}/tube-edit/:id`} component={EditTube} />
        <Route path={`${path}/cable-add`} component={AddCable} />
        <Route path={`${path}/cable-edit/:id`} component={EditCable} />
      </Switch>
  </div>
  )
}

export default CableMaster


const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
      backgroundColor: '#ffff',
    },
  });
  
  const AntTab = styled((props: any) => <Tab disableRipple {...props} />)(
    ({ theme }: any) => ({
      textTransform: 'none',
      minWidth: 0,
      [theme.breakpoints.up('sm')]: {
        minWidth: 0,
      },
      fontWeight: 500,
      marginRight: theme.spacing(1),
      color: '#CEEFFC',
      fontSize: 16,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#ffff',
        opacity: 1,
      },
      '&.Mui-selected': {
        color: '#ffff',
        fontWeight: 700,
      },
      '&.Mui-focusVisible': {
        backgroundColor: '#ffff',
      },
    }),
  );