import {cableColorList} from "../../models/cableColorModel"
import {CableActionTypes} from "../../Stores/actions/cableAction"
import InitialState from "../reducers/initialState"

const initialState:cableColorList[]|[]=InitialState.cable_color


export default function CableColorReducer(
    state:cableColorList[]|[]=initialState,
    action:any
    ){
        switch(action.type){
            case CableActionTypes.Cable_color_get_success:
                return action.payload
            default: return state       
        }
    }




