import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StationList } from "../../../models/stationModel";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { TubeMater } from "../../../models/cableMasterModel";
import CreateIcon from "@mui/icons-material/Create";
import TubeTable from "./TubeTable";

function TubeMainView({
  alltube,
  Delete,
  FilterData,
  filters,
  OpenRow,
  tube_deetail,
}: TubeMasterViewProps) {
  const [open, setOpen] = React.useState<number>(0);
  const [id, SetId] = useState<number>(-1);
  const history = useHistory();
  const columns: any[] = [
    "",
    "Short Code",
    "Tube Color Code",
    "Fibre Count",
    "Action",
  ];
  const [open1, setOpen1] = useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState<TubeMater[]>([]);
  const [searched, setSearched] = useState<string>("");
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!alltube
      ? alltube.filter((row: TubeMater) => {
          return (
            row.tube_code.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row.tube_colour.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row.tube_colour_code.toString().includes(searchedVal.toString()) ||
            row.no_of_fibres.toString().includes(searchedVal.toString()) ||
            row.tube_id.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  useEffect(() => {
    if (!!alltube) {
      cancelSearch();
    }
  }, [alltube]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const del = () => {
    setOpen1(false);
    Delete(id);
  };
  const styles = {
    marginRight: "50px",
  };
  const ddt = (e: number) => {
    setOpen1(true);
    SetId(+e);
  };
  return (
    <>
      <Dialog
        open={open1}
        onClose={() => setOpen1(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Tube?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen1(false)}>
            No
          </Button>
          <Button onClick={() => del()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          margin={1}
        >
          <Grid item xs={6}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
        </Grid>
        {!!rows && rows.length > 0 && (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              {/* style={{ tableLayout: "fixed" }} */}
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledTableCell key={column} align="left">
                      {column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!rows &&
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TubeTable
                          key={row.tube_id}
                          tube_deetail={tube_deetail}
                          setOpen={setOpen}
                          open={open}
                          row={row}
                          columns={columns}
                          OpenRow={OpenRow}
                          Delete={ddt}
                        />
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          style={styles}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={alltube.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Fab
          sx={fabStyle}
          aria-label={"Add"}
          color={"primary"}
          onClick={() => history.push("/cable-master/tube-add")}
          style={{ marginBottom: 15 }}
        >
          {<AddIcon style={{ color: "#ffff" }} />}
        </Fab>
      </Paper>
    </>
  );
}

export default TubeMainView;

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//       backgroundColor: "#00AAEE",
//       color: theme.palette.common.white,
//       padding: 10,
//       paddingLeft: 15,
//       fontWeight: 600,
//       fontSize: 17
//     },
//     [`&.${tableCellClasses.body}`]: {
//       fontSize: 14,
//       padding: 8,
//       paddingLeft: 15,
//     },
//   }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const fabStyle = {
  position: "absolute",
  bottom: 40,
  right: 16,
};

interface TubeMasterViewProps {
  alltube: TubeMater[];
  Delete?: any;
  FilterData?: any;
  filters?: number;
  OpenRow?: any;
  tube_deetail?: TubeMater;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0a3648",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 400,
    fontSize: 17,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));
