import { ErrorModel } from "../../models/errorModels";
import { FMSLocationList } from "../../models/fmsLocationModel";

import {
    DeleteFMSLocation,
  FMSLocationAddService,
  FMSLocationEdit,
  GetAllFMSLocation,
  GetAllStation,
  GetFMSLocationById,
  GetLocationByStationId,
 
} from "../../Services/Services";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { UserLogoutSuccess } from "./userAction";
export enum FMSLocationActionTypes {
  FMSLocation_GetAll_Success = "[FMSLOCATION] FMSLocation GetAll Success",
  FMSLocation_Add_Success_Action = "[FMSLOCATION] FMSLocation Add Success Action",
  FMSLocation_Delete_Success_Action = "[FMSLOCATION] FMSLocation Delete Success Action",
  Get_FMSLocation_By_Id_Success_Action = "[FMSLOCATION] Get FMSLocation By Id Success Action",
  Update_FMSLocation_Success_Action = "[FMSLOCATION] Update FMSLocation Success Action",
  Get_FMSLocation_By_Station_Success_Action='[FMSLOCATION] Get FMSLocation By Station Success Action',
  Filter2_Update_Success_Action='[FMSLOCATION] Filter2 Update Success Action',
  Get_Only_FMSLocation_Success_Action="[FMSLOCATION] Get Only FMSLocation Success Action"
}
export const GetOnlyAllLocation=()=>(dispatch:any,getState:any)=>{
    return GetAllFMSLocation().then(resp=>{
      if(resp.status!=200){
        dispatch(ApiCallErrorAction(resp.data));
      }else{
        dispatch({
          type:FMSLocationActionTypes.Get_Only_FMSLocation_Success_Action,
          payload:resp.data
        })
      }
    }) .catch((error) => {
      if (error.response.status === 401) {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Please Login again to continue.",
          })
        );
        dispatch(UserLogoutSuccess());
      }
      else if (error.response.status === 500) {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: error?.response?.data?.message,
          })
        );
      }
      else{
      dispatch(
        ApiCallErrorAction({
          errorCode: "",
          message: "Error encountered please try again later",
        })
      );
    }
  });
}
export const GetAllFMSLocations = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading FMSLocation Please Wait...",
      })
    );
    return Promise.all([GetAllFMSLocation(),GetAllStation()])
      .then((response) => {
        if (response[0].status != 200) {
          dispatch(ApiCallErrorAction(response[0].data));
        }else if (response[1].status != 200) {
          dispatch(ApiCallErrorAction(response[0].data));
        } else {
          dispatch(GetAllFMSLocationSuccess({
            fms_location: response[0].data,
            station: response[1].data
          }));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        }
        else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } 
        else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllFMSLocationSuccess = (data: any) => {
  return { type: FMSLocationActionTypes.FMSLocation_GetAll_Success, payload: data };
};

export const AddFMSLocations = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Adding FMSLocation Please Wait...",
      })
    );
    return FMSLocationAddService(data.payload)
      .then(async (response) => {
        if (!!(<ErrorModel>response.data.Errors)) {
          dispatch(ApiCallErrorAction(response.data.Errors));
        } else {
          data.history.replace("/fms-location");
          await data.enqueueSnackbar("FMSLocation Successfully Added!", {
            variant: "success",
          });
          dispatch(AddFMSLocationSuccess());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        }
        else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
        else{
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Error encountered please try again later",
          })
        );
      }
      });
  };
};

export const AddFMSLocationSuccess = () => {
  return { type: FMSLocationActionTypes.FMSLocation_Add_Success_Action };
};

export const DeleteFMSLocations = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Deleting FMSLocation Please Wait...",
      })
    );
    return DeleteFMSLocation(data.payload)
      .then(async (response) => {
        if (!!(<ErrorModel>response.data.Errors)) {
          dispatch(ApiCallErrorAction(response.data.Errors));
        } else {
          await data.enqueueSnackbar("Deleted Successfully!", {
            variant: "success",
          });
          dispatch(DeleteFMSLocationSuccess());
          dispatch(GetAllFMSLocations());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        }
        else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
        else{
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Error encountered please try again later",
          })
        );
      }
      });
  };
};

export const DeleteFMSLocationSuccess = () => {
  return { type: FMSLocationActionTypes.FMSLocation_Delete_Success_Action };
};

export const GetFMSLocationsByIds = (data: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading FMSLocation Please Wait...",
      })
    );
    return GetFMSLocationById(data)
      .then((response) => {
        if (!!(<ErrorModel>response.data.Errors)) {
          dispatch(ApiCallErrorAction(response.data.Errors));
        } else {
          dispatch(GetFMSLocationByIdSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // showToast("Please Login again to continue.");
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        }
        else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
        else{
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Error encountered please try again later",
          })
        );
      }
      });
  };
};
export const GetFMSLocationByIdSuccess = (payload: FMSLocationList) => {
  return {
    type: FMSLocationActionTypes.Get_FMSLocation_By_Id_Success_Action,
    payload: payload,
  };
};

export const UpdateFMSLocations = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating FMSLocation Please Wait...",
      })
    );
    return FMSLocationEdit(data.payload)
      .then(async (response) => {
        if (!!(<ErrorModel>response.data.Errors)) {
          dispatch(ApiCallErrorAction(response.data.Errors));
        } else {
          data.history.push("/fms-location");
          await data.enqueueSnackbar("FMSLocation Successfully Updated!", {
            variant: "success",
          });
          dispatch(UpdateFMSLocationSuccess());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        }
        else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
         else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const UpdateFMSLocationSuccess = () => {
  return { type: FMSLocationActionTypes.Update_FMSLocation_Success_Action };
};

export const getStationLocation= (data: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading Location Please Wait...",
      })
    );
    return Promise.all([GetLocationByStationId(data),GetAllStation()])
      .then(async (response) => {
        if (response[0].status != 200) {
          dispatch(ApiCallErrorAction(response[0].data.Errors));
        }else if(response[1].status!=200){
          dispatch(ApiCallErrorAction(response[1].data.Errors));
        } else {
          dispatch(UpdateStationLocationSuccess({ 
            locationByStation:response[0].data,
            station:response[1].data
          }));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        }
        else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
         else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};
export const UpdateStationLocationSuccess = (data:any) => {
  return { type: FMSLocationActionTypes.Get_FMSLocation_By_Station_Success_Action, payload:data };
};
export const FiltreUpdate2SuccessAction = (data: number | undefined) => {
  return { type: FMSLocationActionTypes.Filter2_Update_Success_Action, payload: data };
};