import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CreateIcon from "@mui/icons-material/Create";
import { CableMater, CableMsterList } from "../../../models/cableMasterModel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TubeTable from "./TubeTable";
import { useHistory } from "react-router-dom";
import { DeleteButton } from "../../../components/Button";
function CableTable({
  columns,
  row,
  index,
  OpenRow,
  open,
  setOpen,
  cable_deetail,
  DeleteCable,
}: cableTableProps) {
  const history = useHistory();
  const OpenTube = (id: number) => {
    if (id == open) {
      setOpen(0);
    } else {
      setOpen(id);
      OpenRow(id);
    }
  };
  const theme = useTheme();
  const columns2: any[] = ["", "No", "Tube Color", "No Of Fibre"];
  const [isLargeScreen,setIsLargeScreen]=useState<boolean>(false)
  const matchesmdTheme = useMediaQuery(theme.breakpoints.down('md'));
  const matcheslgTheme = useMediaQuery(theme.breakpoints.up('md'));
  useEffect(()=>{
    if(matchesmdTheme){
      setIsLargeScreen(false)
    }else if(matcheslgTheme){
      setIsLargeScreen(true)
    }
  },[matchesmdTheme,matcheslgTheme])
  return (
    <>
      <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.cable_id}>
        <StyledTableCell align="left" key={columns[0]}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => OpenTube(row.cable_id)}
          >
            {open == row.cable_id ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align="left" key={columns[2]}>
          {row.cable_code}
        </StyledTableCell>
        <StyledTableCell align="left" key={columns[1]}>
          <Grid container gap={1} direction={"row"}>
            <Box
              style={{
                border:'1px solid #000',
                height: "20px",
                width: "20px",
                backgroundColor: row.cable_colour_code,
              }}
            ></Box>
            {row.cable_colour}
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="left" key={columns[7]}>
          {row.cable_type}
        </StyledTableCell>
        <StyledTableCell align="left" key={columns[3]}>
          {row.cable_strength}
        </StyledTableCell>
        <StyledTableCell align="left" key={columns[4]}>
          {row.no_of_tubes}
        </StyledTableCell>
        {/* <StyledTableCell align="left" key={columns[5]}>
      {row.cable_desc}
    </StyledTableCell> */}
        <StyledTableCell align="left" key={columns[5]}>
          {/* <button className="edit"
       onClick={() => history.push(`/cable-master/cable-edit/${row.cable_id}`)}
        >
          <CreateIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>
            <span className="level">Edit</span>
        
      </button> */}


      
                            {
                            !isLargeScreen ? (
                              <Tooltip title="Edit" arrow>
                                  
                                  <EditIcon 
                                    color="primary"
                                    sx={{cursor:"pointer"}}
                                    onClick={() =>
                                      history.push(`/cable-master/cable-edit/${row.cable_id}`)
                                    }
                                  />

                                </Tooltip>
                            ):(
                              <Button
                              onClick={() =>
                                history.push(`/cable-master/cable-edit/${row.cable_id}`)
                              }
                              variant="contained"
                              color="primary"
                              size="small"
                              startIcon={<EditIcon />}
                              style={{ color: "#fff" }}
                            >
                              Edit
                            </Button>
                            )
                          }



                            {
                            !isLargeScreen ? (
                              <Tooltip title="Delete" arrow>
                                 
                                  <DeleteIcon
                                    color="error"
                                    sx={{cursor:"pointer"}}
                                    onClick={() => DeleteCable(row.cable_id)}
                                  />
                              </Tooltip>

                            ):(
                              <DeleteButton
                              onClick={() => DeleteCable(row.cable_id)}
                              style={{ marginLeft: 10 }}
                              variant="contained"
                              color="error"
                              size="small"
                              startIcon={<DeleteIcon />}
                            >
                              Delete
                            </DeleteButton>

                            )
                          }
      
          

          

          {/* <button className="delete"
      onClick={()=> DeleteCable(row.cable_id)}
        >
      <DeleteIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>
       
        <span className="level">Delete</span>
          
      </button> */}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#F5F5F5",
          }}
          colSpan={7}
        >
          <Collapse in={open == row.cable_id} timeout="auto" unmountOnExit>
            {!!cable_deetail &&
              !!cable_deetail?.tubes &&
              cable_deetail.tubes.length > 0 && (
                <Box sx={{ margin: 1, paddingLeft: 30 }}>
                  {/* <Typography style={{fontSize: 18, fontWeight: 'bold'}} gutterBottom component="div">
              Tube Detail
          </Typography> */}
                  <TableContainer>
                    <Table size="small" stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns2.map((column) => (
                            <StyledTableCell2 align="left" key={column}>
                              {column}
                            </StyledTableCell2>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cable_deetail.tubes.map((tube: any, index: number) => (
                          <TubeTable
                            row={tube}
                            columns={columns2}
                            index={index}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}

export default CableTable;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00AAEE",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: 17,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
interface cableTableProps {
  columns: string[];
  row: CableMsterList;
  OpenRow?: any;
  setOpen?: any;
  open?: number;
  cable_deetail?: CableMater;
  DeleteCable?: any;
  index: number;
}

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#A23C84",
    color: theme.palette.common.white,
    padding: 6,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: 15,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 6,
    paddingLeft: 15,
    backgroundColor: "#F5F5F5",
  },
}));

const StyledTableRow2 = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
