import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import Autocompletes from "../../../components/Autocompletes";
import { FMSLocationList } from "../../../models/fmsLocationModel";
import { FMSList } from "../../../models/fmsModel";
import { StoreState } from "../../../models/reduxModels";
import { StationList } from "../../../models/stationModel";
import { ZoneList } from "../../../models/zoneModels";
import {
  GetAllZone,
  GetFMSByLocation,
  GetLocationByStationId,
  GetStationByZoneId,
} from "../../../Services/Services";

function FmsFindpopOver({
  onClose,
  open,
  onSubmits,
  selectedZone,
  selectedStation,
  selectedLocation,
  selectedFMS,
  type,
}: FmsFindPopoverProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
  } = useForm();
  const { errors } = formState;
  const [Zone, SetZone] = useState<ZoneList[]>([]);
  const [Station, SetStation] = useState<StationList[]>([]);
  const [FMSLocation, SetFMSLocation] = useState<FMSLocationList[]>([]);
  const [FMS, SetFMS] = useState<FMSList[]>([]);

  useEffect(() => {
    if (open) {
      try {
        GetAllZone()
          .then((data) => {
            if (data.status == 200 && !!data.data) {
              SetZone(data.data);
            }
          })
          .catch((err) => {
            //todo
          });
      } catch (err) {
        //todo
      }
    }
  }, [open]);
  const SelectZone = (data: number) => {
    setValue("station_id", null);
    setValue("location_id", null);
    setValue("fms_id", null);
    try {
      GetStationByZoneId(+data)
        .then((data) => {
          if (data.status == 200 && !!data.data) {
            SetStation(data.data);
          }
        })
        .catch((err) => {
          //todo
        });
    } catch (err) {
      //todo
    }
  };
  const SelectStation = (data: number) => {
    setValue("location_id", null);
    setValue("fms_id", null);
    try {
      GetLocationByStationId(+data)
        .then((data) => {
          if (data.status == 200 && !!data.data) {
            SetFMSLocation(data.data);
          }
        })
        .catch((err) => {
          //todo
        });
    } catch (err) {
      //todo
    }
  };
  const LocationSelected = (data: number) => {
    setValue("fms_id", null);
    try {
      GetFMSByLocation({ location_id: +data })
        .then((data) => {
          if (data.status == 200 && !!data.data) {
            SetFMS(data.data);
          }
        })
        .catch((err) => {
          //todo
        });
    } catch (err) {
      //todo
    }
  };

  const onSubmit = (props: any) => {
    onSubmits({
      zone_id: props.zone_id,
      station_id: props.station_id,
      location_id: props.location_id,
      fms_id: props.fms_id,
      zone_name: Zone.find((m) => m.zone_id == +props.zone_id)?.zone_name,
      station_name: Station.find((m) => m.station_id == +props.station_id)
        ?.station_name,
      location_name: FMSLocation.find(
        (m) => m.location_id == +props.location_id
      )?.location_name,
      fms_name: FMS.find((m) => m.fms_id == +props.fms_id)?.fms_code,
      station_code: Station.find((m) => m.station_id == +props.station_id)
        ?.station_code,
    });
  };
  useEffect(() => {
    if (type == 1) {
      if (!!selectedZone) {
        setValue(
          "zone_id",
          Zone.find((zne: ZoneList) => zne.zone_id === +selectedZone.zone_id)
            ?.zone_id
        );
      }
      if (!!selectedStation && !!selectedZone) {
        SelectZone(selectedZone.zone_id);
        setValue(
          "station_id",
          Station.find(
            (sta: StationList) => sta.station_id === +selectedStation.station_id
          )?.station_id
        );
      }
      if (!!selectedLocation && !!selectedStation) {
        SelectStation(selectedStation.station_id);
        setValue(
          "location_id",
          FMSLocation.find(
            (Loc: FMSLocationList) =>
              Loc.location_id === +selectedLocation.location_id
          )?.location_id
        );
      }
      if (!!selectedFMS && !!selectedLocation) {
        LocationSelected(selectedLocation.location_id);
        setValue(
          "fms_id",
          FMS.find((FMS: FMSList) => FMS.fms_id === +selectedFMS.fms_id)?.fms_id
        );
      }
    }
  }, [selectedZone, selectedStation, selectedLocation, selectedFMS, Zone]);
  return (
    <Dialog fullWidth open={open} onClose={() => onClose(false)}>
      <DialogTitle style={{ backgroundColor: "#00AAEE", color: "#fff" }}>
        Select FMS
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            justifyContent="center"
            marginTop={2}
            alignItems={"center"}
          >
            <Grid item xs={3}>
              <Typography style={{ fontWeight: 550 }}>Zone</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Controller
                control={control}
                name={"zone_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={Zone}
                    keys={"zone_id"}
                    label={"zone_name"}
                    onBlur={onBlur}
                    onChange={(value: any) => (
                      onChange(value), SelectZone(value)
                    )}
                    placeholder={"Zone"}
                    value={value}
                    errors={errors["zone_id"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={3}>
              <Typography style={{ fontWeight: 550 }}>Station</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Controller
                control={control}
                name={"station_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={Station}
                    keys={"station_id"}
                    label={"station_name"}
                    onBlur={onBlur}
                    onChange={(value: any) => (
                      onChange(value), SelectStation(value)
                    )}
                    placeholder={"Station"}
                    value={value}
                    errors={errors["station_id"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={3}>
              <Typography style={{ fontWeight: 550 }}>FMS Location</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Controller
                control={control}
                name={"location_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={FMSLocation}
                    keys={"location_id"}
                    label={"location_name"}
                    onBlur={onBlur}
                    onChange={(value: any) => (
                      onChange(+value), LocationSelected(+value)
                    )}
                    placeholder={"FMS Location"}
                    value={value}
                    errors={errors["location_id"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={3}>
              <Typography style={{ fontWeight: 550 }}>FMS</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Controller
                control={control}
                name={"fms_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={FMS?.filter((m) => m.fms_type != "FDB")}
                    keys={"fms_id"}
                    label={"fms_code"}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"FMS"}
                    value={value}
                    errors={errors["fms_id"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item>
              <Button
                onClick={() => onClose(false)}
                color={"error"}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{
                  marginRight: 10,
                }}
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{ color: "#fff" }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Choose
              </Button>
            </Grid>
          </Grid>
        </form>
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
        ></Box>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    selectedZone: state.station.has_filter,
    selectedStation: state.fmslocation.has_filter,
    selectedLocation: state.fms.has_filter,
    selectedFMS: state.cable.has_filter,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FmsFindpopOver);
//export default FmsFindpopOver
interface FmsFindPopoverProps {
  open: boolean;
  onClose?: any;
  onSubmits?: any;
  selectedZone?: ZoneList;
  selectedStation?: StationList;
  selectedLocation?: FMSLocationList;
  selectedFMS?: FMSList;
  type?: number;
}
