import { combineReducers } from "redux";
import CableMasterReducer from "./cableMasterReducer";
import CableReducer from "./cableReducer";
import ErrorReducer from "./errorReducer";
import FMSLocationReducer from "./fmsLocationReducer";
import LoadingReducer from "./loadingReducer";
import StationReducer from "./stationReducer";
import UserReducer from "./userReducer";
import ZoneReducer from "./zoneReducer";
import FMSReducer from "./fmsReducer"
import ServiceMasterReducer from "./serviceMasterReducer";
import CableTreeReducer from "./cableTreeReducer"
import DomainReducer from "./domainReducer"
import CableColorReducer from "./cableColorReducer"
import ConnectivityReducer from "./connectivityReducer";
import ServiceOccupencyMasterReducer from "./serviceOccupencyReducer";
import ReportReducer from "./ReportReducer";
const rootReducer = combineReducers({
  loading: LoadingReducer,
  error: ErrorReducer,
  user: UserReducer,
  zone: ZoneReducer,
  station: StationReducer,
  cable_master: CableMasterReducer,
  fmslocation: FMSLocationReducer,
  fms:FMSReducer,
  cable: CableReducer,
  service: ServiceMasterReducer,
  cable_tree:CableTreeReducer,
  domain:DomainReducer,
  cable_color:CableColorReducer,
  connectivity:ConnectivityReducer,
  service_occupency:ServiceOccupencyMasterReducer,
  report:ReportReducer
});

export default rootReducer;
