import React from 'react'
import {Redirect,Route,Switch,useRouteMatch} from "react-router-dom"
import SchematicMain from "./SchematicMain"

const Schematic = () => {
  const {path,url}=useRouteMatch()

  return (
    <Switch>
      <Route 
        exact
        path={`${path}`}
        render={()=><Redirect to={`${path}/schematic-main`} />}
      />
      <Route
        exact 
        path={`${path}/schematic-main`}
        render={()=><SchematicMain/>}
      />
    </Switch>
  )
}

export default Schematic