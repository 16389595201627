import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Autocompletes from "../../../components/Autocompletes";
import { ColorCode } from "../../../models/cableMasterModel";

function AddFibreView({ color, submitData }: AddFibreProps) {
  const history = useHistory();
  const { control, register, handleSubmit, formState, setValue } = useForm();
  const { errors } = formState;
  const onSubmit = (props: any) => {
    submitData(props);
  };
  const fbType: any[] = [
    {
      key: "SM",
      value: "SM",
    },
    {
      key: "MM",
      value: "MM",
    },
  ];
  return (
    <Card sx={{ marginTop: "10px" }}>
      <CardHeader
        style={{ backgroundColor: "#202a37", color: "#fff", height: 14 }}
        titleTypographyProps={{ fontWeight: 500, fontSize: "1.3rem" }}
        title="Add Fibre"
      />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center" alignItems={"center"}>
          <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Fibre Code</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
            {/* <Grid item xs={4}>
              <Typography style={{ fontWeight: 550 }}>Fibre Code</Typography>
            </Grid> */}
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("fibre_code", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="fibre_code"
                placeholder="Fibre Code"
                error={!!errors["fibre_code"]}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems={"center"}>
            {/* <Grid item xs={4}>
              <Typography style={{ fontWeight: 550 }}>Fibre Type</Typography>
            </Grid> */}
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Fibre Type</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Controller
                control={control}
                name={"fibre_type"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={fbType}
                    keys={"key"}
                    label={"value"}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"Fibre Type"}
                    value={value}
                    errors={errors["fibre_type"]}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems={"center"}></Grid>
          <Grid container justifyContent="center" alignItems={"center"}>
            {/* <Grid item xs={4}>
              <Typography style={{ fontWeight: 550 }}>Fibre Color</Typography>
            </Grid> */}
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Fibre Color</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 550 }}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("fibre_colour_code", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                select
                name="fibre_colour_code"
                placeholder="Fibre Color"
                defaultValue={""}
                error={!!errors["fibre_colour_code"]}
              >
                {!!color &&
                  color.map((item) => (
                    <MenuItem
                      key={item.colour_master_id}
                      value={item.colour_code}
                    >
                      <Grid container gap={1} direction={"row"}>
                        <Box
                          style={{
                            height: "20px",
                            width: "20px",
                            backgroundColor: item.colour_code,
                          }}
                        ></Box>
                        {item.colour_name}
                      </Grid>
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item>
              <Button
                onClick={() => history.push(`/cable-master/fibre-main`)}
                style={{
                  color: "#fff",
                  marginRight: 10,
                  background: "#C1B4B3",
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Back
              </Button>
              <Button
                type="submit"
                style={{ color: "#fff" }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

export default AddFibreView;
interface AddFibreProps {
  color: ColorCode[];
  submitData?: any;
}
