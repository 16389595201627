import React from 'react'
import ServiceTracingFlow from './ServiceTracingFlow'
import ServiceTracingMain from './ServiceTracingMain'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
const ServiceTracing = () => {
  const {path,url}=useRouteMatch()
  
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={()=><Redirect to={`${path}/service-tracing-main`} />}
      />
      <Route 
        exact 
        path={`${path}/service-tracing-main`} 
        render={()=>   <ServiceTracingMain/>} 
      />
      <Route 
        exact 
        path={`${path}/service-tracing-flow/:sid/:did`} 
        render={()=>   <ServiceTracingFlow/>} 
      />
    </Switch>
    
  )
}

export default ServiceTracing