import { ServiceMain } from "../../models/serviceMasterModel";
import { ServiceActionTypes } from "../actions/serviceMasterAction";
import InitialState from "./initialState";

const initialState: ServiceMain = InitialState.service;

export default function ServiceMasterReducer(
  state: ServiceMain = initialState,
  action: any
) {
  switch (action.type) {
    case ServiceActionTypes.Service_GetAll_Success:
      return { ...state, service_list: action.payload };
      case ServiceActionTypes.Get_Service_By_Id_Success_Action:
        return { ...state, single_Service: action.payload };  
    default:
      return state;
  }
}