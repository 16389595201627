import { FMSLocationMain } from "../../models/fmsLocationModel";
import { GetAllFMSLocations } from "../actions/fmsLocationAction";
import InitialState from "./initialState";
import { FMSLocationActionTypes } from "../actions/fmsLocationAction";
import { StationActionTypes } from "../actions/stationAction";
import { CableMasterActionTypes } from "../actions/cableMasterActions";
import { ReportActionTypes } from "../actions/ReportAction";

const initialState: FMSLocationMain = InitialState.fmslocation;
export default function FMSLocationReducer(
  state: FMSLocationMain = initialState,
  action: any
) {
  const { type, payload } = action;
  switch (type) {
    case FMSLocationActionTypes.FMSLocation_GetAll_Success:
      return {
        ...state,
        fmslocation_list: payload.fms_location,
        station_list: payload.station,
      };
    case FMSLocationActionTypes.Get_FMSLocation_By_Id_Success_Action:
      return {
        ...state,
        single_fmslocation: payload,
      };
    case FMSLocationActionTypes.Get_Only_FMSLocation_Success_Action:
      return {
        ...state,
        fmslocation_list: payload,
      };
    case FMSLocationActionTypes.Get_FMSLocation_By_Station_Success_Action:
      return {
        ...state,
        fmslocation_list: action.payload.locationByStation,
        station_list: action.payload.station,
      };
    case FMSLocationActionTypes.Filter2_Update_Success_Action:
      return { ...state, has_filter: action.payload };
    case StationActionTypes.Filter_Update_Success_Action:
      return { ...state, has_filter: undefined };
    case CableMasterActionTypes.Get_All_Fibre_Master_Success_Action:
      return { ...state, has_filter: undefined };
    case ReportActionTypes.Get_Damage_Fibre_Load_Success:
      return {
        ...state,
        fmslocation_list: action.payload[2].data,
      };
    default:
      return state;
  }
}
