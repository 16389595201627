import React from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import AddCable from './AddCable';
import CableMain from './CableMain'
import EditCable from './EditCable';

function Cable() {
const { path, url } = useRouteMatch();
  return (
    <Switch>
    <Route
      exact
      path={`${path}`}
      render={() => <Redirect to={`${path}/cable-main`} />}
    />
    <Route exact path={`${path}/cable-main`} render={() => <CableMain />} />
    <Route exact path={`${path}/add-cable/:id`} render={() => <AddCable />} />
    <Route path={`${path}/edit-cable/:id`} render={() => <EditCable />} />
  </Switch>
  )
}

export default Cable