import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CreateIcon from "@mui/icons-material/Create";
import {
  CableMater,
  CableMsterList,
  TubeMater,
} from "../../../models/cableMasterModel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useHistory } from "react-router-dom";
import { DeleteButton } from "../../../components/Button";
// import TubeTable from './TubeTable';
function TubeTable({
  columns,
  row,
  Delete,
  OpenRow,
  open,
  setOpen,
  tube_deetail,
}: tubeTableProps) {
  const history = useHistory();
  const OpenTube = (id: number) => {
    if (id == open) {
      setOpen(0);
    } else {
      setOpen(id);
      OpenRow(id);
    }
  };
  const theme = useTheme();
  const [isLargeScreen,setIsLargeScreen]=useState<boolean>(false)
  const matchesmdTheme = useMediaQuery(theme.breakpoints.down('md'));
  const matcheslgTheme = useMediaQuery(theme.breakpoints.up('md'));
  useEffect(()=>{
    if(matchesmdTheme){
      setIsLargeScreen(false)
    }else if(matcheslgTheme){
      setIsLargeScreen(true)
    }
  },[matchesmdTheme,matcheslgTheme])
  const columns2: any[] = ["No", "Fibre Color", "Fibre Type"];
  return (
    <>
      <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.tube_id}>
        <StyledTableCell align="left" key={columns[0]}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => OpenTube(row.tube_id)}
          >
            {open == row.tube_id ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align="left" key={columns[1]}>
          {row.tube_code}
        </StyledTableCell>
        <StyledTableCell align="left" key={columns[2]}>
          <Grid container gap={1} direction={"row"}>
            <Box
              style={{
                border:'1px solid #000',
                // boxShadow: '0 0 6px' ,
                height: "20px",
                width: "20px",
                backgroundColor: row.tube_colour_code,
              }}
            ></Box>
            {row.tube_colour}
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="left" key={columns[3]}>
          {row.no_of_fibres}
        </StyledTableCell>
        <StyledTableCell align="left" key={columns[4]}>
          {/* <button className="edit"
      onClick={() => history.push(`/cable-master/tube-edit/${row.tube_id}`)}
        >
          <CreateIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>
            <span className="level">Edit</span>
        
      </button> */}




                        {
                            !isLargeScreen ? (
                              <Tooltip title="Edit" arrow>
                                  
                                  <EditIcon 
                                    color="primary"
                                    sx={{cursor:"pointer"}}
                                    onClick={() =>
                                      history.push(`/cable-master/tube-edit/${row.tube_id}`)
                                    }
                                  />

                                </Tooltip>
                            ):(
                              <Button
                              onClick={() =>
                                history.push(`/cable-master/tube-edit/${row.tube_id}`)
                              }
                              variant="contained"
                              color="primary"
                              size="small"
                              startIcon={<EditIcon />}
                              style={{ color: "#fff" }}
                            >
                              Edit
                            </Button>
                            )
                          }

          

          {/* <button className="delete"
      onClick={() => (
        Delete(row.tube_id))}
      >
      <DeleteIcon style={{fontSize:17,padding:5,color: "rgb(255, 255, 255)",position: "relative", right: 4, }}/>
       
        <span className="level">Delete</span>
          
      </button> */}



{
                            !isLargeScreen ? (
                              <Tooltip title="Delete" arrow>
                                 
                                  <DeleteIcon
                                    color="error"
                                    sx={{cursor:"pointer"}}
                                    onClick={() => Delete(row.tube_id)}
                                  />
                              </Tooltip>

                            ):(
                              <DeleteButton
                            onClick={() => Delete(row.tube_id)}
                            style={{ marginLeft: 10 }}
                            variant="contained"
                            color="error"
                            size="small"
                            startIcon={<DeleteIcon />}
                          >
                            Delete
                          </DeleteButton>

                            )
                          }
          
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={5}
        >
          <Collapse in={open == row.tube_id} timeout="auto" unmountOnExit>
            {!!tube_deetail &&
              !!tube_deetail?.fibres &&
              tube_deetail.fibres.length > 0 && (
                <Box sx={{ margin: 1, paddingLeft: 30 }}>
                  {/* <Typography variant="h6" gutterBottom component="div">
              Fibre Detail
          </Typography> */}
                  <TableContainer>
                    <Table
                      size="small"
                      stickyHeader
                      aria-label="sticky table"
                      style={{ tableLayout: "fixed" }}
                    >
                      <TableHead>
                        <TableRow>
                          {columns2.map((column) => (
                            <StyledTableCell2 align="left" key={column}>
                              {column}
                            </StyledTableCell2>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!!tube_deetail?.fibres &&
                          tube_deetail?.fibres.map(
                            (fibre: any, index: number) => (
                              <TableRow>
                                <StyledTableCell2
                                  align="left"
                                  key={columns2[0]}
                                >
                                  {index + 1}
                                </StyledTableCell2>
                                <StyledTableCell2
                                  align="left"
                                  key={columns2[1]}
                                >
                                  <Grid container gap={1} direction={"row"}>
                                    <Box
                                      style={{
                                        border:'1px solid #000',
                                        height: "20px",
                                        width: "20px",
                                        backgroundColor:
                                          fibre.fibre_colour_code,
                                      }}
                                    ></Box>{" "}
                                    {fibre.fibre_colour}
                                  </Grid>
                                </StyledTableCell2>
                                <StyledTableCell2
                                  align="left"
                                  key={columns2[2]}
                                >
                                  {fibre.fibre_type}
                                </StyledTableCell2>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}

export default TubeTable;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00AAEE",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: 17,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
    backgroundColor: "#f5f5f5",
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#c99c00",
    color: theme.palette.common.white,
    padding: 2,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    padding: 8,
    paddingLeft: 14,
    backgroundColor: "#e3dada",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
interface tubeTableProps {
  columns: string[];
  row: TubeMater;
  OpenRow?: any;
  setOpen?: any;
  open?: number;
  tube_deetail?: TubeMater;
  Delete?: any;
}
