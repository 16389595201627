import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { DomainList } from "../../../models/domainModel";
import { StoreState } from "../../../models/reduxModels";
import { StationList } from "../../../models/stationModel";
import { ZoneList } from "../../../models/zoneModels";
import { DomainTypes } from "../../../Stores/actions/domainAction";
import {
  GetStationByIds,
  UpdateStations,
} from "../../../Stores/actions/stationAction";
import StationEditView from "./EditStation";

function EditStation({
  allzone,
  GetStationByIds,
  SingleStation,
  UpdateStations,
  DomainTypes,
  stationtype,
}: AddZoneProps) {
  let { id, readable }: any = useParams();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const submitData = (data: any) => {
    UpdateStations({
      payload: { ...SingleStation, ...data },
      history: history,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  useEffect(() => {
    DomainTypes("station_type");
    GetStationByIds(+id);
  }, []);
  return (
    <StationEditView
      submitData={submitData}
      allzone={allzone}
      SingleStation={SingleStation}
      readable={+readable}
      stationtype={stationtype}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    allzone: state.zone.zone_list,
    SingleStation: state.station.single_station,
    stationtype: state.domain.station_type,
  };
};
const mapDispatchToProps = {
  GetStationByIds,
  UpdateStations,
  DomainTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStation);
interface AddZoneProps {
  allzone: ZoneList[];
  GetStationByIds?: any;
  SingleStation?: StationList;
  UpdateStations?: any;
  DomainTypes?: any;
  stationtype?: DomainList[];
}
