import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { UserDetails } from "../../models/userModels";

const UploadFileModal = ({
  open,
  onClose,
  user,
  uploadFileEvent,
}: UploadFileModalProps) => {
  const [file, Setfile] = useState<any>();
  const fileHandleEvent = (event: any) => {
    const file: any = event.target.files[0];
    Setfile(file);
  };
  const uploadFile = () => {
    if (!!user) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user_id", user?.userid);
      uploadFileEvent(formData);
      onClose(false);
    }
  };
  useEffect(() => {
    if (open) {
      Setfile(undefined);
    }
  }, [open]);
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => onClose(false)}
      maxWidth={"md"}
    >
      <DialogTitle
        style={{
          backgroundColor: "#00AAEE",
          textAlign: "center",
          color: "#fff",
        }}
      >
        Upload Bulk Data
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          marginTop={2}
          alignItems={"center"}
        >
          <Grid item xs={4}>
            <Typography sx={{ fontWeight: 550 }}>
              Download Sample Template
            </Typography>
          </Grid>
          <Grid item xs={1}>
            :
          </Grid>
          <Grid item xs={6}>
            <a
              href={require("../../assets/optical_fibre_templateV3.xlsx")}
              download="Rishabh's Resume"
              target="_blank"
            >
              <CloudDownloadIcon color="primary" sx={{ cursor: "pointer" }} />
            </a>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          marginTop={2}
          alignItems={"center"}
        >
          <Grid item xs={4}>
            <Typography sx={{ fontWeight: 550 }}>Upload File</Typography>
          </Grid>
          <Grid item xs={1}>
            :
          </Grid>
          <Grid item xs={6}>
            <input
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={fileHandleEvent}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          marginTop={2}
          alignItems={"center"}
        >
          <Grid item>
            <Button
              color={"error"}
              onClick={() => onClose(false)}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={{
                marginRight: 10,
              }}
            >
              Close
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => uploadFile()}
              color={"success"}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={{
                marginRight: 10,
              }}
            >
              Upload
            </Button>
          </Grid>
          
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UploadFileModal;
interface UploadFileModalProps {
  open: boolean;
  onClose?: any;
  user?: any;
  uploadFileEvent?: any;
}
