import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ColorCode, FibreMater, TubeMater } from '../../../models/cableMasterModel';
import { StoreState } from '../../../models/reduxModels';
import { FibreCreateLoads, GetTubeDetails, TubeCreate, TubeCreateLoads, UpdateTube,  } from '../../../Stores/actions/cableMasterActions';
import EditTubeView from './EditTubeView'
import { useSnackbar } from "notistack";

function EditTube({allfibre,color,FibreCreateLoads,TubeCreateLoads,GetTubeDetails,tube_deetail,UpdateTube}: EditTubeProps) {
    let { id }: any = useParams();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(()=> {
        FibreCreateLoads()
        TubeCreateLoads()
        GetTubeDetails(id)
    },[])

    const submitData=(payload: any)=> {
        UpdateTube({
            payload: { 
                tube_id: tube_deetail?.tube_id,
                tube_code: tube_deetail?.tube_code,
                tube_colour_code: payload.tube_colour_code,
                no_of_fibres: tube_deetail?.no_of_fibres,
               
             },
            history: history,
            enqueueSnackbar: enqueueSnackbar,
          });
    }
    // const submitData = (data: any) => {
    //     UpdateFibre({
    //       payload: { ...allfibre.find(m=> m.fibre_id == +id), ...data },
    //       history: history,
    //       enqueueSnackbar: enqueueSnackbar,
    //     });
    //   };
  return (
   <EditTubeView  color={color} tube_deetail={tube_deetail} submitData={submitData}  allfibre={allfibre} />
  )
}

const mapStateToProps = (state: StoreState) => {
    return {
        color: state.cable_master.color_code,
        allfibre: state.cable_master.fibre_master,
        tube_deetail: state.cable_master.tube_detail
    };
  };
  const mapDispatchToProps = {
    FibreCreateLoads,
    TubeCreateLoads,
    GetTubeDetails,
    TubeCreate,
    UpdateTube,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditTube);
  interface EditTubeProps {
    FibreCreateLoads?: any;
    color: ColorCode[];
    TubeCreateLoads?:  any;
    allfibre: FibreMater[];
    TubeCreate?: any;
    GetTubeDetails?: any;
    tube_deetail?:TubeMater;
    UpdateTube?: any;
  }