import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { StoreState } from "../../../models/reduxModels";
import { ZoneList } from "../../../models/zoneModels";
import {
  AddZones,
  //   GetServicesByIds,
  UpdateZones,
} from "../../../Stores/actions/zoneActions";
// import ZoneEditView from "./EditZoneView";
import { useSnackbar } from "notistack";
import ServiceEditView from "./EditServiceView";
import {
  GetServicesByIds,
  UpdateServices,
} from "../../../Stores/actions/serviceMasterAction";
import { ServiceList } from "../../../models/serviceMasterModel";
import { DomainTypes } from "../../../Stores/actions/domainAction";
function EditService({
  GetServicesByIds,
  SingleService,
  UpdateServices,
  DomainTypes,
  serviceType
}: EditServiceProps) {
  let { id, readable }: any = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const submitData = (data: any) => {
    let payload: ServiceList = JSON.parse(JSON.stringify(SingleService));
    UpdateServices({
      payload: { ...payload, ...data },
      history: history,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  useEffect(() => {
    GetServicesByIds(+id);
  }, []);
  useEffect(()=>{
    DomainTypes("service_type")
  },[])
  return (
    <ServiceEditView
      submitData={submitData}
      SingleService={SingleService}
      readable={+readable}
      serviceType={serviceType}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    SingleService: state.service.single_Service,
    serviceType:state.domain.service_type
  };
};
const mapDispatchToProps = {
  GetServicesByIds,
  UpdateServices,
  DomainTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(EditService);
interface EditServiceProps {
  GetServicesByIds?: any;
  SingleService?: ServiceList;
  UpdateServices?: any;
  DomainTypes?:any;
  serviceType:any;
}
