import { savePDF } from "@progress/kendo-react-pdf";

class DocService {
  createPdf = (html: any) => {
    savePDF(html, {
      paperSize: "A2",
      fileName: "cable-report.pdf",
      landscape: true,
    });
  };
}

const Doc = new DocService();
export default Doc;
