import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Zone from "./Views/Zone";
import Assets from "./Views/Assets";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Login from "./Views/Login";
import Dashboard from "./Views/Dashboard";
import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  styled,
  Theme,
  ThemeProvider,
} from "@mui/material";
import CescLightTheme from "./themes/CescLightTheme";
import "./App.css";
import SideNav from "./Common/SideNav";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Path from "./Views/Path";
import Loading from "./Views/Loading/Loading";
import { SnackbarProvider } from "notistack";
import { StoreState } from "./models/reduxModels";
import { connect } from "react-redux";
import { ErrorModel } from "./models/errorModels";
import ErrorToster from "./Views/ErrorToster";
import Station from "./Views/Station";
import FMSLocation from "./Views/FMSLocation";
import CableMaster from "./Views/CableMaster";
import FMS from "./Views/FMS";
import Cable from "./Views/Cable";
import Connectivity from "./Views/Connectivity";
//import CableService from "./Views/CableService";
import ServiceMaster from "./Views/ServiceMaster";
import ServiceTracing from "./Views/ServiceTracing";
import ServiceOcupency from "./Views/Service-Ocupency";
import Report from "./Views/Report";
import RedudantRoute from "./Views/RedudantRoute";
import Schematic from "./Views/Schematic";
import DamageFibreReport from "./Views/DamageFibreReport";
import Profile from "./Views/Profile";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CableUtilizationReport from "./Views/CableUtilizationReport";
import ServiceWiseReport from "./Views/ServiceWiseReport";
import CableWiseReport from "./Views/CableWiseReport";
import EndToEndReport from "./Views/EndToEndFibreReport";
import ServiceWiseCableInfo from "./Views/ServiceWiseReport/ServiceWiseCableInfo";
import BulkUpload from "./Views/BulkUpload";
function App({ error }: Appprops) {
  const [open, setOpen] = useState(true);
  const Main = styled("main", {
    shouldForwardProp: (prop) => prop !== "open",
  })<{
    open?: boolean;
  }>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${270}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }));

  return (
    <ThemeProvider theme={CescLightTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SnackbarProvider maxSnack={3}>
          <ErrorToster />
          <div>
            <Header open={open} setOpen={setOpen} />
          </div>
          <div className="Containers">
            <Loading />
            <SideNav open={open} setOpen={setOpen} />
            <div
              style={
                !open
                  ? { marginLeft: -270, padding: 20 }
                  : { marginLeft: 0, padding: 20 }
              }
              className="body-class"
            >
              <div style={{ position: "fixed", top: 147 }}>
                <Path />
              </div>
              <div style={{ marginTop: 30 }}></div>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() =>
                    false ? (
                      <Redirect to="/dashboard" />
                    ) : (
                      <Redirect to="/login" />
                    )
                  }
                />
                <Route path="/login" render={() => <Login />} />
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/zone" component={Zone} />
                <PrivateRoute path="/station" component={Station} />
                <PrivateRoute path="/fms-location" component={FMSLocation} />
                <PrivateRoute path="/fms" component={FMS} />
                <PrivateRoute path="/cable-master" component={CableMaster} />
                <PrivateRoute
                  path="/service-master"
                  component={ServiceMaster}
                />
                <PrivateRoute path="/cable" component={Cable} />
                <PrivateRoute path="/connectivity" component={Connectivity} />
                <PrivateRoute path="/report" component={Report} />
                <PrivateRoute path="/assets" component={Assets} />
                <PrivateRoute path="/schematic" component={Schematic} />

                <PrivateRoute
                  path="/service-tracing"
                  component={ServiceTracing}
                />
                <PrivateRoute
                  path="/service-ocupency"
                  component={ServiceOcupency}
                />
                <PrivateRoute
                  path="/redudant-route"
                  component={RedudantRoute}
                />
                {/* <PrivateRoute path="/cable-master" component={CableService} /> */}
                <PrivateRoute
                  path="/damagefibre-report"
                  component={DamageFibreReport}
                />
                <PrivateRoute
                  path="/utilization-report"
                  component={CableUtilizationReport}
                />
                <PrivateRoute
                  path="/service-query-report/:id/:readable"
                  component={ServiceWiseCableInfo}
                />
                <PrivateRoute
                  path="/service-query-report"
                  component={ServiceWiseReport}
                />
                <PrivateRoute
                  path="/cable-query-report"
                  component={CableWiseReport}
                />
                <PrivateRoute
                  path="/end-to-end-report"
                  component={EndToEndReport}
                />
                {/* Bulk Upload */}
                <PrivateRoute
                  path="/bulk-upload"
                  component={BulkUpload}
                />
                <PrivateRoute path="/user-profile" component={Profile} />
              </Switch>
            </div>
          </div>
          <div>
            <Footer />
          </div>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
const mapStateToProps = (state: StoreState) => {
  return {};
};
export default connect(mapStateToProps)(App);
interface Appprops {
  error?: ErrorModel;
}
