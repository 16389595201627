import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useSnackbar } from "notistack";
import TableData from "./TableData";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  TablePagination,
  Fab,
  styled,
  TableCell,
  tableCellClasses,
  MenuItem,
  useTheme,
  useMediaQuery,
  Tooltip,
  Card,
  Typography,
} from "@mui/material";
import Doc from "../../Common/ConvertToPDF/DocService";
import ConvertToPDF from "../../Common/ConvertToPDF";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import Autocompletes from "../../components/Autocompletes";
import { ZoneList } from "../../models/zoneModels";
import { StationList } from "../../models/stationModel";
import { FMSLocationList } from "../../models/fmsLocationModel";
import { FMSList } from "../../models/fmsModel";
import { ConnectivityList } from "../../models/connectivityModel";
const ConnectivityMain = ({
  allZone,
  allStation,
  allLocation,
  allFMS,
  cable_list,
  getCableFibreAction,
  removeCableList,
}: ConnectivityMainProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const createPDF = (html: any) => Doc.createPdf(html);
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [ZoneData, setZoneData] = useState<ZoneList[]>([]);
  const [StationData, setStationData] = useState<StationList[]>([]);
  const [LocData, setLocData] = useState<FMSLocationList[]>([]);
  const [FMSData, setFMSData] = useState<FMSList[]>([]);
  const [FMSName, setFMSName] = useState<string>("");
  const [selectedFMSId, setSelectedFMSId] = useState<number | null>(null);
  const createPdfRef = React.createRef();
  // const [expanded2, setExpanded2] = useState<string | false>("panel2");

  const ClearForm = () => {
    setValue("fms_id", "");
    setValue("zone_id", "");
    setValue("station_id", "");
    setValue("location_id", "");
    setZoneData(allZone);
    setStationData(allStation);
    setLocData(allLocation);
    setFMSData(
      allFMS?.filter((fmsData: FMSList) => fmsData.fms_type !== "FDB")
    );
  };

  const TColumn: any[] = [
    "Cable Short Code",
    "Cable color",
    "Cable Strength",
    "Action",
  ];

  const TData: any[] = ["Cab11", "red", "234 mt"];
  const generatePdf = () => {
    createPDF(createPdfRef.current);
  };
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm();
  const { errors } = formState;
  useEffect(() => {
    setZoneData(allZone);
    setStationData(allStation);
    setLocData(allLocation);
    setFMSData(
      allFMS?.filter((fmsData: FMSList) => fmsData.fms_type !== "FDB")
    );

    return () => removeCableList();
  }, [allZone, allStation, allLocation, allFMS]);
  useEffect(() => {
    allFMS.filter((fms: FMSList) => {
      if (fms.fms_id === selectedFMSId) {
        setFMSName(fms.fms_code);
      }
    });
  }, [selectedFMSId]);

  const onZoneChange = (data: number) => {
    if (!!data && data != 0) {
      setValue("fms_id", "");
      setValue("zone_id", "");
      setValue("station_id", "");
      setValue("location_id", "");
      setLocData(allLocation);
      setFMSData(allFMS);
      let newStationData: StationList[] = allStation.filter(
        (station: StationList) => station.zone_id === data
      );
      if (!!newStationData) {
        setStationData(newStationData);

        onStationChange(StationData[0].station_id);
      }
    } else {
      setStationData(allStation);
      setFMSData(allFMS);
    }
  };

  const onStationChange = (data: number) => {
    if (!!data && data != 0) {
      setValue("fms_id", "");
      setValue("location_id", "");
      setFMSData(allFMS);
      setLocData(allLocation);
      let newLocData: FMSLocationList[] = allLocation.filter(
        (Loc: FMSLocationList) => Loc.station_id === data
      );

      if (!!newLocData) {
        setLocData(newLocData);
        onLocChange(LocData[0].location_id);
      }
    } else {
      setLocData(allLocation);
    }
  };
  const onLocChange = (data: number) => {
    if (!!data && data != 0) {
      let newFMSData: FMSList[] = allFMS.filter(
        (fms: FMSList) => fms.location_id === data
      );
      if (!!newFMSData) setFMSData(newFMSData);
    } else {
      setFMSData(allFMS);
    }
  };

  const showCableConnectivity = async () => {
    if (!getValues("fms_id")) {
      await enqueueSnackbar("Please input the fms!", {
        variant: "error",
      });
    } else if (!!+getValues("fms_id")) {
      getCableFibreAction(+getValues("fms_id"));
      setExpanded(false);
    }
  };

  return (
    <Card sx={{ marginTop: "10px" }}>
      <Box>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange1("panel1")}
        >
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                endIcon={<FilterListIcon />}
                sx={{ color: "#fff" }}
              >
                Filter
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <form>
              {/*  grid container */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="flex-start"
                      alignItems="space-between"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Select Zone
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"zone_id"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            infoEvent={() => {}}
                            option={ZoneData}
                            keys={"zone_id"}
                            label={"zone_name"}
                            value={value}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onZoneChange(+value), onChange(value)
                            )}
                            placeholder={"Choose Zone "}
                            errors={errors["zone_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={5}
                  justifyContent="flex-start"
                  alignItems="space-between"
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Select Station
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"station_id"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            infoEvent={() => {}}
                            option={StationData}
                            keys={"station_id"}
                            label={"station_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value), onStationChange(+value)
                            )}
                            placeholder={"Choose Station"}
                            value={value}
                            errors={errors["station_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*  grid container */}

              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={4}>
                      <Typography style={{ fontWeight: 550 }}>
                        Select FMS Location
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"location_id"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            infoEvent={() => {}}
                            option={LocData}
                            keys={"location_id"}
                            label={"location_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value), onLocChange(+value)
                            )}
                            placeholder={"Choose FMSLocation"}
                            value={value}
                            errors={errors["location_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={5}
                  justifyContent="flex-start"
                  alignItems="space-between"
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Select FMS
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"fms_id"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            infoEvent={() => {}}
                            option={FMSData}
                            keys={"fms_id"}
                            label={"fms_code"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value), setSelectedFMSId(+value)
                            )}
                            placeholder={"Choose FMS "}
                            value={value}
                            errors={errors["fms_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  sx={{ color: "#fff", margin: "0 10px" }}
                  onClick={ClearForm}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ color: "#fff", margin: "0 10px" }}
                  onClick={() => showCableConnectivity()}
                >
                  Show
                </Button>
                <Button
                  variant="contained"
                  sx={{ color: "#fff" }}
                  onClick={generatePdf}
                  disabled={!!cable_list && cable_list.length < 1}
                >
                  Generate PDF
                </Button>
              </Box>
            </form>
          </AccordionDetails>
        </Accordion>
        {/*  table data  */}

        {!!cable_list && cable_list.length > 0 && FMSName !== "" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#00aaee",
            }}
          >
            <Typography variant="h5">
              CONNECTIVITY REPORT WITHIN FMS ( {FMSName} )
            </Typography>
          </Box>
        )}

        <ConvertToPDF ref={createPdfRef}>
          {!!cable_list && cable_list.length > 0 && (
            <Box
              sx={{ maxWidth: "100vw", maxHeight: "800px", overflow: "auto" }}
            >
              <TableData
                cableList={cable_list}
                getCableFibreAction={getCableFibreAction}
                selectedFMS={selectedFMSId}
                setSelectedFMSId={setSelectedFMSId}
              />
            </Box>
          )}
        </ConvertToPDF>
      </Box>
    </Card>
  );
};

export default ConnectivityMain;
interface ConnectivityMainProps {
  allZone: ZoneList[];
  allStation: StationList[];
  allLocation: FMSLocationList[];
  allFMS: FMSList[];
  getCableFibreAction?: any;
  cable_list: ConnectivityList[];
  removeCableList?: any;
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: 17,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
