import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react'
import { AnyIfEmpty, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ColorCode } from '../../../models/cableMasterModel';
import { StoreState } from '../../../models/reduxModels';
import { FibreCreate, FibreCreateLoads } from '../../../Stores/actions/cableMasterActions';
import AddFibreView from './AddFibreView'

function AddFibre({color,FibreCreate,FibreCreateLoads}: AddFibreProps) {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(()=> {
        FibreCreateLoads()
    },[])
    const submitData=(props: any)=> {
        FibreCreate({
            payload: props,
            history: history,
            enqueueSnackbar: enqueueSnackbar,
          });
    }
  return (
    <AddFibreView color={color} submitData={submitData} />
  )
}

const mapStateToProps = (state: StoreState) => {
    return {
        color: state.cable_master.color_code
    };
  };
  const mapDispatchToProps = {
    FibreCreate,
    FibreCreateLoads
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddFibre);
  interface AddFibreProps {
    FibreCreateLoads?: any;
    FibreCreate?: any;
    color: ColorCode[];
  }