import { 
    GetAllFMS,
    GetAllZone,
    GetAllStation,
    GetAllFMSLocation,
    getCableByFibreFMSIdService,
    getFibreByService,
    GetAllService,
    GetTraceFibre
} from "../../Services/Services"
import { ApiCallErrorAction ,BeginApiCallAction } from "./apiStatusActions";
import { UserLogoutSuccess } from "./userAction";

export enum ConnectivityActionTypes{
    filtering_list="[Connectivity] Filtering List Data Get Success",
    Get_Cable_Fibre_By_FMS="[Conectivity] Get_Cable_Fibre_By_FMS",
    Remove_Cable_Fibre_By_FMS="[Conectivity] Remove_Cable_Fibre_By_FMS",
    Get_Fibre_By_Service="[SERVICE] Get_Fibre_By_Service",
    Get_All_Service="[Connectivity] Get_All_Service",
    Get_Trace_Fibre_List="[Connectivity] Get_Trace_Fibre_List",
    Remove_Fibre_By_Service="[Connectivity] Remove_Fibre_By_Service"
}

export const removeCableList=()=>{
  return{type:ConnectivityActionTypes.Remove_Cable_Fibre_By_FMS}
}


export const ConnectivityActionSuccess=(data:any)=>{
    return {
        type:ConnectivityActionTypes.filtering_list,
        payload:data
    }
}
export const ConnectivityFilterAction=()=>(dispatch:any,getState:any)=>{
    return Promise.all([GetAllZone(),GetAllStation(),GetAllFMSLocation(),GetAllFMS()])
    .then((resp)=>{
        
        
        if(resp[0].status!=200){
            dispatch(ApiCallErrorAction(resp[0].data));
        }else if (resp[1].status != 200) {
            dispatch(ApiCallErrorAction(resp[1].data));
        } else if(resp[2].status!=200){
            dispatch(ApiCallErrorAction(resp[2].data));
        }else if(resp[3].status!=200){
            dispatch(ApiCallErrorAction(resp[3].data));
        }else{
            dispatch(
                ConnectivityActionSuccess({
                    zoneList:resp[0].data,
                    stationList:resp[1].data,
                    LocList:resp[2].data,
                    FMSList:resp[3].data
                })
            )
        }
    })
    .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        }
        else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
        else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        } 
      });
}


// Get_Cable_Fibre_By_FMS action


export const getCableFibreAction=(data:number)=>(dispatch:any,getState:any)=>{
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading Fibre by cable. Please Wait...",
      })
    );
    return getCableByFibreFMSIdService(data)
    .then((resp)=>{
        if(resp.status!==200){
            dispatch(ApiCallErrorAction(resp.data));
        }else{
            dispatch({
                type:ConnectivityActionTypes.Get_Cable_Fibre_By_FMS,
                payload:resp.data
            })
        }
    })
    .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        }
        else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
        else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        } 
      });
}


const GetAllFibreSuccess=(payload:any)=>{
  
  return{
      type:ConnectivityActionTypes.Get_Fibre_By_Service,
      payload:payload

  }
}


//   get all fibre by service
export const GetAllFibreByServiceAction=(data:number)=>(dispatch:any,getState:any)=>{
  dispatch(
    BeginApiCallAction({
      count: 1,
      message: "Loading Fibre. Please Wait...",
    })
  );
  return getFibreByService(data).then((resp)=>{
      if(resp.status!=200){
          dispatch(ApiCallErrorAction(resp.data));
      }else{
          dispatch(GetAllFibreSuccess(resp.data))
      }
  }) 
  .catch((error)=>{
      if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(UserLogoutSuccess());
        }
        else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
         else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
}

// get all service
export const getAllServiceAction=()=>(dispatch:any,getState:any)=>{
  dispatch(
    BeginApiCallAction({
      count: 1,
      message: "Loading Service. Please Wait...",
    })
  );
  return GetAllService().then((resp)=>{
    if(resp.status!=200){
      dispatch(ApiCallErrorAction(resp.data));
    }else{
        dispatch({
          type:ConnectivityActionTypes.Get_All_Service,
          payload:resp.data
        })
    }
  }).catch((error)=>{
    if (error.response.status === 401) {
      dispatch(
        ApiCallErrorAction({
          errorCode: "",
          message: "Please Login again to continue.",
        })
      );
      dispatch(UserLogoutSuccess());
    }
    else if (error.response.status === 500) {
      dispatch(
        ApiCallErrorAction({
          errorCode: "",
          message: error?.response?.data?.message,
        })
      );
    }
     else {
      dispatch(
        ApiCallErrorAction({
          errorCode: "",
          message: "Error encountered please try again later",
        })
      );
    }
  })
}


//   get trace fibre  information

export const GetTraceFibreAction=(sourceFibre:number,destinationFibre:number)=>(dispatch:any,getState:any)=>{
  dispatch(
    BeginApiCallAction({
      count: 1,
      message: "Loading Trace Fibre. Please Wait...",
    })
  );
  return GetTraceFibre({sourceFibre:sourceFibre,destinationFibre:destinationFibre}).then((resp)=>{
    if(resp.status!=200){
      dispatch(ApiCallErrorAction(resp.data));
    }else{
        dispatch({
          type:ConnectivityActionTypes.Get_Trace_Fibre_List,
          payload:resp.data
        })
    }
  })
  .catch((error)=>{
    if (error.response.status === 401) {
      dispatch(
        ApiCallErrorAction({
          errorCode: "",
          message: "Please Login again to continue.",
        })
      );
      dispatch(UserLogoutSuccess());
    }
    else if (error.response.status === 500) {
      dispatch(
        ApiCallErrorAction({
          errorCode: "",
          message: error?.response?.data?.message,
        })
      );
    }
     else {
      dispatch(
        ApiCallErrorAction({
          errorCode: "",
          message: "Error encountered please try again later",
        })
      );
    }

  })

}

export const removeGetAllFibreByServiceAction=()=>(dispatch:any,getState:any)=>{
  dispatch({type:ConnectivityActionTypes.Remove_Fibre_By_Service})
}