import { ServiceList } from "../../models/serviceMasterModel";
import { DeleteService, GetAllService, GetServicesById, ServiceAddService, ServiceEdit } from "../../Services/Services";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { UserLogoutSuccess } from "./userAction";




export enum ServiceActionTypes {
    Service_GetAll_Success = "[SERVICE] Service GetAll Success",
    Service_Add_Success_Action = "[SERVICE] Service Add Success Action",
    Get_Service_By_Id_Success_Action = "[SERVICE] Get Service By Id Success Action",
    Update_Service_Success_Action = "[SERVICE] Update Service Success Action",
    Service_Delete_Success_Action = "[SERVICE] Service Delete Success Action",
  }



export const GetAllServices = () => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Loading Zone Please Wait...",
        })
      );
      return GetAllService()
        .then((response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            dispatch(GetAllZoneSuccess(response.data));
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  
  export const GetAllZoneSuccess = (data: ServiceList[]) => {
    return { type: ServiceActionTypes.Service_GetAll_Success, payload: data };
  };


  export const AddServices = (data: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Adding Service Please Wait...",
        })
      );
      return ServiceAddService(data.payload)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            data.history.replace("/service-master");
            await data.enqueueSnackbar("Service Successfully Added!", {
              variant: "success",
            });
            dispatch(AddServiceSuccess());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
          else{
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
        });
    };
  };
  
  export const AddServiceSuccess = () => {
    return { type: ServiceActionTypes.Service_Add_Success_Action };
  };



  export const GetServicesByIds = (data: number) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Loading Service Please Wait...",
        })
      );
      return GetServicesById(data)
        .then((response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            dispatch(GetServicesByIdSuccess(response.data));
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // showToast("Please Login again to continue.");
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
          else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
        });
    };
  };
  export const GetServicesByIdSuccess = (payload: ServiceList) => {
    return {
      type: ServiceActionTypes.Get_Service_By_Id_Success_Action,
      payload: payload,
    };
  };

  export const UpdateServices = (data: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Updating Service Please Wait...",
        })
      );
      return ServiceEdit(data.payload)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            data.history.replace("/service-master");
            await data.enqueueSnackbar("Service Successfully Updated!", {
              variant: "success",
            });
            dispatch(UpdateServiceSuccess());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
           else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  
  export const UpdateServiceSuccess = () => {
    return { type: ServiceActionTypes.Update_Service_Success_Action };
  };


  export const DeleteServices = (data: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Deleting Service Please Wait...",
        })
      );
      return DeleteService(data.payload)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            await data.enqueueSnackbar("Deleted Successfully!", {
              variant: "success",
            });
            dispatch(DeleteServiceSuccess());
            dispatch(GetAllServices());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            dispatch(UserLogoutSuccess());
          }
          else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          }
          else{
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
        });
    };
  };
  
  export const DeleteServiceSuccess = () => {
    return { type: ServiceActionTypes.Service_Delete_Success_Action };
  };
  