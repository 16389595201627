import InitialState from "./initialState"
import {ConnectivityActionTypes} from "../actions/connectivityAction"
import { ConnectivityMain } from "../../models/connectivityModel"
const initialState:ConnectivityMain=InitialState.connectivity

export default function ConnectivityReducer(
    state:ConnectivityMain=initialState,
    action:any
){
    switch (action.type) {
        case ConnectivityActionTypes.filtering_list:           
            return{
                ...state,
                zone_list:action.payload.zoneList,
                station_list:action.payload.stationList,
                location_list:action.payload.LocList,
                fms_list:action.payload.FMSList
            }
        case ConnectivityActionTypes.Get_Cable_Fibre_By_FMS:
            return {
                ...state, 
                cable_list:action.payload
            }
        case ConnectivityActionTypes.Remove_Cable_Fibre_By_FMS:
            return{
                ...state, 
                cable_list:[]
            }
        case ConnectivityActionTypes.Get_All_Service:
            return{
                ...state, 
                service_list:action.payload
            }
        case ConnectivityActionTypes.Get_Fibre_By_Service:
        
            return{
                ...state, 
                fibre_list:action.payload
            }
        case ConnectivityActionTypes.Get_Trace_Fibre_List:
            return{
                ...state, 
                trace_fibre_list:action.payload
            }
        case ConnectivityActionTypes.Remove_Fibre_By_Service:
            return{
                ...state,
                fibre_list:[]

            }
        default:
            return state
    }
}
