import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  TablePagination,
  Fab,
  styled,
  TableCell,
  tableCellClasses,
  MenuItem,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import React, { useEffect, useState } from "react";
import { CableList } from "../../../models/cableModel";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CreateIcon from "@mui/icons-material/Create";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  DeleteButton,
  DetailButton,
  DetailButton2,
  InfoButton,
} from "../../../components/Button";
import InfoIcon from "@mui/icons-material/Info";
import Autocompletes from "../../../components/Autocompletes";
import { FMSList } from "../../../models/fmsModel";
import CableTreeDialog from "../../../components/CableTreeDialog";
import PreviewIcon from "@mui/icons-material/Preview";
import ChooseCableModal from "../AddCable/ChooseCableModal";
import { UserDetails } from "../../../models/userModels";
function CableMainView({
  cable_list,
  Delete,
  filterData,
  SetFilters,
  filters,
  handleFilterFmsWise,
  fms_list,
  CablePortDetail,
  user,
}: CableMainViewProps) {
  const history = useHistory();
  const columns: any[] = [
    "Cable Short Code",
    // "Cable color",
    "Cable Strength",
    "Cable Type",
    "Asymmetric",
    // "Cable Master Short Code",
    // "Cable Length",
    "Action",
  ];
  const theme = useTheme();
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  const matchesmdTheme = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslgTheme = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    if (matchesmdTheme) {
      setIsLargeScreen(false);
    } else if (matcheslgTheme) {
      setIsLargeScreen(true);
    }
  }, [matchesmdTheme, matcheslgTheme]);
  const [open, setOpen] = useState<boolean>(false);
  const [id, SetId] = useState<number>(-1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState<CableList[]>([]);
  const [searched, setSearched] = useState<string>("");
  const [isCableTreeOpen, setCableTreeOpen] = useState<boolean>(false);
  const [cableDetailsId, setCableDetailsId] = useState<number | undefined>();
  const [ModalOpen, setModalOpen] = useState<boolean>(false);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!cable_list
      ? cable_list?.filter((row: CableList) => {
          return (
            (!!row.cable_actual_length &&
              row?.cable_actual_length
                .toString()
                .toLowerCase()
                .includes(searchedVal.toLowerCase())) ||
            (!!row.cable_short_code &&
              row?.cable_short_code
                .toLowerCase()
                .includes(searchedVal.toLowerCase()))
          );
        })
      : [];
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  useEffect(() => {
    if (!!cable_list) {
      cancelSearch();
    }
  }, [cable_list]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const del = () => {
    setOpen(false);
    Delete(id);
  };
  const styles = {
    marginRight: "50px",
  };
  const chooseCableModalSubmit = (data: any) => {
    setModalOpen(false);
  };
  return (
    <>
      <CableTreeDialog
        isOpen={isCableTreeOpen}
        setOpen={setCableTreeOpen}
        cable_id={cableDetailsId}
      />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Cable?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            No
          </Button>
          <Button onClick={() => del()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          margin={1}
          spacing={2}
        >
          <Grid item xs={3}></Grid>
          <Grid item xs={5}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocompletes
              option={fms_list}
              keys={"fms_id"}
              label={"fms_code"}
              onChange={(val: number) => {
                SetFilters(val);
                handleFilterFmsWise(val);
              }}
              placeholder={"Filter FMS"}
              value={filters}
            />
          </Grid>
        </Grid>
        <TableContainer sx={{ maxHeight: 480 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell align="left" key={column}>
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.cable_detail_id}
                      >
                        <StyledTableCell align="left" key={columns[0]}>
                          {row.cable_short_code}
                        </StyledTableCell>

                        {/* <StyledTableCell align="left" key={columns[1]}>
                          {row.cable_colour}{" "}
                          {!!row.second_cable_colour &&
                            " - " + row.second_cable_colour}
                        </StyledTableCell> */}
                        <StyledTableCell align="left" key={columns[2]}>
                          {!!row.cable_strength && row.cable_strength + "F"}{" "}
                          {!!row.second_cable_strength &&
                            " - " + row.second_cable_strength + "F"}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[3]}>
                          {!!row.cable_type ? row.cable_type : "NA"}
                          {!!row.cable_type &&
                            !!row.second_cable_type &&
                            " - " + row.second_cable_type}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[4]}>
                          {row.asymmetric_flag === 1 && "Yes"}
                          {row.asymmetric_flag !== 1 && "No"}
                        </StyledTableCell>
                        {/* <StyledTableCell align="left" key={columns[1]}>
                          {row.cable_code}
                        </StyledTableCell> */}
                        {/* <StyledTableCell align="left" key={columns[2]}>
                          {row.cable_actual_length} Meter
                        </StyledTableCell> */}
                        <StyledTableCell align="left" key={columns[5]}>
                          {user?.roleType !== "R" &&
                            (!isLargeScreen ? (
                              <Tooltip title="Edit" arrow>
                                <EditIcon
                                  color="primary"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() =>
                                    history.push(
                                      `/cable/add-cable/${row.cable_detail_id}`
                                    )
                                  }
                                />
                              </Tooltip>
                            ) : (
                              <Button
                                onClick={() =>
                                  history.push(
                                    `/cable/add-cable/${row.cable_detail_id}`
                                  )
                                }
                                // onClick={() =>
                                //   history.push(
                                //     `/cable/add-cable/${row.cable_detail_id}`
                                //   )
                                // }
                                variant="contained"
                                color="primary"
                                size="small"
                                sx={{ color: "#fff", my: 1 }}
                                startIcon={<EditIcon />}
                              >
                                Edit
                              </Button>
                            ))}

                          {!isLargeScreen ? (
                            <Tooltip title="Show Station" arrow>
                              <InfoIcon
                                color="success"
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  setCableDetailsId(row.cable_detail_id);
                                  setCableTreeOpen(true);
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <Button
                              variant="contained"
                              color="success"
                              size="small"
                              style={{ color: "#fff", marginLeft: 5 }}
                              onClick={() => {
                                setCableDetailsId(row.cable_detail_id);
                                setCableTreeOpen(true);
                              }}
                              startIcon={<InfoIcon />}
                            >
                              Cable Info
                            </Button>
                          )}

                          {!isLargeScreen ? (
                            <Tooltip title="Delete" arrow>
                              <DeleteIcon
                                color="error"
                                sx={{ cursor: "pointer" }}
                                onClick={() => (
                                  SetId(row.cable_detail_id), setOpen(true)
                                )}
                              />
                            </Tooltip>
                          ) : (
                            <DeleteButton
                              onClick={() => (
                                SetId(row.cable_detail_id), setOpen(true)
                              )}
                              style={{ marginLeft: 10 }}
                              variant="outlined"
                              color="error"
                              size="small"
                              startIcon={<DeleteIcon />}
                            >
                              Delete
                            </DeleteButton>
                          )}

                          {/* {!isLargeScreen ? (
                            <Tooltip title="Service Detail" arrow>
                              <DisplaySettingsIcon
                                sx={{ cursor: "pointer", color: "#9d56a2" }}
                                onClick={() =>
                                  history.push(
                                    `/cable-service/cable-service-main/${row.cable_detail_id}`
                                  )
                                }
                              />
                            </Tooltip>
                          ) : (
                            <DetailButton
                              onClick={() =>
                                history.push(
                                  `/cable-service/cable-service-main/${row.cable_detail_id}`
                                )
                              }
                              style={{ marginLeft: 10 }}
                              variant="outlined"
                              color="primary"
                              size="small"
                              // import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

                              startIcon={<DisplaySettingsIcon />}
                            >
                              Service Detail
                            </DetailButton>
                          )} */}

                          {/* {!isLargeScreen ? (
                            <Tooltip title="Cable Port Detail" arrow>
                              <DeviceHubIcon
                                sx={{ cursor: "pointer", color: "#035779" }}
                                onClick={() => CablePortDetail(row)}
                              />
                            </Tooltip>
                          ) : (
                            <DetailButton2
                              onClick={() => CablePortDetail(row)}
                              style={{ marginLeft: 10 }}
                              variant="outlined"
                              color="primary"
                              size="small"
                              startIcon={<DeviceHubIcon />}
                            >
                              Port Detail
                            </DetailButton2>
                          )}*/}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={styles}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={cable_list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {user?.roleType !== "R" && user?.roleType !== "FW" && (
          <Fab
            sx={fabStyle}
            aria-label={"Add"}
            color={"primary"}
            onClick={() => history.push("/cable/add-cable/0")}
            style={{ marginBottom: 15 }}
          >
            {<AddIcon style={{ color: "#ffff" }} />}
          </Fab>
        )}
      </Paper>
    </>
  );
}

export default CableMainView;
interface CableMainViewProps {
  cable_list: CableList[];
  Delete?: any;
  filterData?: any;
  SetFilters?: any;
  filters?: number;
  handleFilterFmsWise?: any;
  fms_list: FMSList[];
  CablePortDetail?: any;
  user?: UserDetails;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 600,
    fontSize: 17,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const fabStyle = {
  position: "absolute",
  bottom: 40,
  right: 16,
};
