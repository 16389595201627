import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Grid,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect } from "react";
  import { Controller, useForm } from "react-hook-form";
  import { useHistory } from "react-router-dom";
import { ColorCode, FibreMater, TubeMater } from "../../../models/cableMasterModel";
function EditTubeView({allfibre,color,submitData,tube_deetail,fibre}: EditTubeViewProps) {
    const history = useHistory();
    const { control,register, handleSubmit, formState, setValue,getValues } = useForm();
    const { errors } = formState;
    const onSubmit = (props: any) => {
        submitData(props)
    };
    const fibreNoSelected =()=>{
        setValue('fibres', [])
      }
    useEffect(() => {
        if (!!tube_deetail) {
          setValue("tube_code", tube_deetail.tube_code);
          setValue("tube_colour_code", tube_deetail.tube_colour_code);
          setValue("no_of_fibres", tube_deetail.no_of_fibres);
          let b: number[]=[]
          tube_deetail.fibres.forEach(m=> {
              b.push(m.fibre_id)
          })
          setValue('fibres',b)
        }
      }, [tube_deetail]);
      const disableDat=(fibre: FibreMater)=> {
        let flag: boolean = false
        if(!!getValues('fibres')){
          if(+getValues('fibres').length >= +getValues('no_of_tubes')){
            flag= true
          }
         let t: FibreMater= getValues('fibres').find((m: any)=> m == fibre.fibre_id)
         if(!!t){
           flag= false 
         }
        }
        return flag
      }
  return (
//     <Card sx={{ marginTop: "10px" }}>
//     <CardHeader
//       style={{ backgroundColor: "#00AAEE", color: "#fff" }}
//       titleTypographyProps={{ fontWeight: 600 }}
//       title="Edit Tube"
//     />
//     <CardContent>
//     <form onSubmit={handleSubmit(onSubmit)}>
//             <Grid container justifyContent="center" alignItems={"center"}>
//               <Grid item xs={5}>
//                 <Grid
//                   container
//                   paddingTop={2}
//                   justifyContent="left"
//                   alignItems={"center"}
//                 >
//                   <Grid item xs={11.5}>
//                     <TextField
//                       label="Fibre Code"
//                       {...register("fibre_code", { required: true })}
//                       style={{ width: "100%", margin: "5px" }}
//                       type="text"
//                       variant="outlined"
//                       size="small"
//                       name="fibre_code"
//                       error={!!errors["fibre_code"]}
//                     />
//                   </Grid>
//                 </Grid>
//               </Grid>
//               <Grid item xs={5}>
//                 <Grid
//                   container
//                   flexDirection={"row"}
//                   paddingTop={2}
//                   justifyContent="right"
//                   alignItems={"center"}
//                 >
//                   <Grid item xs={11.5}>
//                 <Controller
//                 control={control}
//                 name={"fibre_type"}
//                 defaultValue={""}
//                 render={({ field: { onBlur, value, onChange } }) => (
//                   <TextField
//                     style={{ width: "100%", margin: "5px" }}
//                     variant="outlined"
//                     select
//                     label="Fibre Type"
//                     size="small"
//                     onBlur={onBlur}
//                     name="fibre_type"
//                     defaultValue={""}
//                     error={!!errors["fibre_type"]}
//                     value={value}
//                     onChange={(value) =>
//                       onChange(value.target.value)
//                     }
//                   >
//                 <MenuItem key={"SM"} value={"SM"}>
//                   SM
//                 </MenuItem>
//                 <MenuItem key={"MM"} value={"MM"}>
//                     MM
//                 </MenuItem>
//               </TextField>
//                 )}
//                 />
//                   </Grid>
//                 </Grid>
//               </Grid>
//             </Grid>
//             <Grid
//               container
//               justifyContent="center"
//               alignItems={"center"}
//               paddingTop={2}
//             >
//               <Grid item xs={10}>
//               <Controller
//                 control={control}
//                 name={"fibre_colour_code"}
//                 defaultValue={""}
                
//                 render={({ field: { onBlur, value, onChange } }) => (
//               <TextField
//                 onBlur={onBlur}
//                 style={{ width: "100%", margin: "5px" }}
//                 variant="outlined"
//                 select
//                 label="Fibre Color"
//                 size="small"
//                 defaultValue={""}
//                 name="fibre_colour_code"
//                 error={!!errors["fibre_colour_code"]}
//                 value={value}
//                 onChange={(value) =>
//                   onChange(value.target.value)
//                 }
//               >
//                   {
//                       !!color && color.map((item)=> (
//                         <MenuItem key={item.colour_master_id} value={item.colour_code}>
//                         <Grid container gap={1} direction={'row'}>
//                            <Box style={{height: '20px', width: '20px' ,backgroundColor: item.colour_code}}></Box>{item.colour_name}
//                        </Grid>
//                        </MenuItem>
//                       ))
//                   }
//               </TextField>
//                 )}/>
//               </Grid>
//             </Grid>
//             <Grid container justifyContent="center" alignItems={"center"}>
//               <Grid item>
//                 <Button
//                   onClick={() => history.push(`/cable-master/tube-main`)}
//                   style={{
//                     color: "#fff",
//                     marginRight: 10,
//                     background: "#C1B4B3",
//                   }}
//                   variant="contained"
//                   sx={{ mt: 3, mb: 2 }}
//                 >
//                   Back
//                 </Button>
//                 <Button
//                   type="submit"
//                   style={{ color: "#fff" }}
//                   variant="contained"
//                   sx={{ mt: 3, mb: 2 }}
//                 >
//                   Update
//                 </Button>
//               </Grid>
//             </Grid>
//           </form>
//     </CardContent>
//   </Card>
<Card sx={{ marginTop: "10px" }}>
<CardHeader
  style={{ backgroundColor: "#202a37", color: "#fff", height:14  }}
  titleTypographyProps={{ fontWeight: 500, fontSize:'1.3rem' }}
  title="Edit Tube"
/>
<CardContent>
<form onSubmit={handleSubmit(onSubmit)}>
<Grid container justifyContent="center" alignItems={"center"}>
        <Grid item xs={4} sx={{display:"flex"}}>
            <Typography style={{fontWeight: 550}}>Tube Code</Typography>
            <Box sx={{color:"red"}}>*</Box> 
        </Grid>
        <Grid item xs={1}>
          <Typography style={{fontWeight: 550}}>:</Typography>
        </Grid>
        <Grid item xs={5}>
        <TextField
            {...register("tube_code", { required: true })}
            style={{ width: "100%", margin: "5px" }}
            type="text"
            disabled
            variant="outlined"
            size="small"
            name="tube_code"
            placeholder="Tube Code"
            error={!!errors["tube_code"]}
          />
      </Grid>
  </Grid>

  <Grid container justifyContent="center" alignItems={"center"}>
          <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Tube Colour Code</Typography>
              <Box sx={{color:"red"}}>*</Box> 
          </Grid>
        <Grid item xs={1}>
          <Typography style={{fontWeight: 550}}>:</Typography>
        </Grid>
        <Grid item xs={5}>
        <Controller
            control={control}
            name={"tube_colour_code"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (

        <TextField
            style={{ width: "100%", margin: "5px" }}
            type="text"
            variant="outlined"
            size="small"
            select
            onBlur={onBlur}
            value={value}
            onChange={(value)=> onChange(value.target.value)}
            name="tube_colour_code"
            // defaultValue={""}
            placeholder="tube colour code"
            error={!!errors["tube_colour_code"]}
          >
              {
                  !!color && color.map((item)=> (
                    <MenuItem key={item.colour_master_id} value={item.colour_code}>
                    <Grid container gap={1} direction={'row'}>
                       <Box style={{height: '20px', width: '20px' ,backgroundColor: item.colour_code}}></Box>{item.colour_name}
                   </Grid>
                   </MenuItem>
                  ))
              }
          </TextField>
            )}/>



      </Grid>
  </Grid>
        

<Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>No. Of Fibre</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
        <Grid item xs={1}>
          <Typography style={{fontWeight: 550}}>:</Typography>
        </Grid>
        <Grid item xs={5}>
        <Controller
        control={control}
        name={"no_of_fibres"}
        defaultValue={""}
        rules={{ required: true }}
        render={({ field: { onBlur, value, onChange } }) => (
        <TextField
          {...register("no_of_fibres", { required: true })}
            style={{ width: "100%", margin: "5px" }}
            type="text"
            variant="outlined"
            size="small"
            select
            disabled
            value={value}
            onBlur={onBlur}
            name="no_of_fibres"
            placeholder="No Of Fibre"
            onChange={(event)=> (fibreNoSelected(),onChange(event.target.value))}
            error={!!errors["no_of_fibres"]}
          >
              <MenuItem key={'1'} value={'1'}>
                1
              </MenuItem>
              <MenuItem key={'2'} value={'2'}>
                2
              </MenuItem>
              <MenuItem key={'3'} value={'3'}>
                3
              </MenuItem>
              <MenuItem key={'4'} value={'4'}>
                4
              </MenuItem>
              <MenuItem key={'5'} value={'5'}>
                5
              </MenuItem>
              <MenuItem key={'6'} value={'6'}>
                6
              </MenuItem>
              <MenuItem key={'7'} value={'7'}>
                7
              </MenuItem>
              <MenuItem key={'8'} value={'8'}>
                8
              </MenuItem>
              <MenuItem key={'9'} value={'9'}>
                9
              </MenuItem>
              <MenuItem key={'10'} value={'10'}>
                10
              </MenuItem>
          </TextField>
      )}/>
      </Grid>
</Grid>
<Grid container justifyContent="center" alignItems={"center"}>
          <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Fibres</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
    <Grid item xs={1}>
      <Typography style={{fontWeight: 550}}>:</Typography>
    </Grid>
    <Grid item xs={5}>
    <Controller
      control={control}
      name={"fibres"}
      defaultValue={[]}
      rules={{ required: true }}
      render={({ field: { onBlur, value, onChange } }) => (
    <Select
      size='small'
      style={{ width: "100%", margin: "5px" }}
      variant="outlined"
      multiple
      onBlur={onBlur}
      value={value}
      disabled
      onChange={(event)=> onChange(event.target.value)
       }
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value: any) => {
            let item:FibreMater | undefined = allfibre.find(m=> +m.fibre_id == +value) 
            return !!item ? <Chip style={{backgroundColor: item.fibre_colour_code, color: '#ffff'}} key={item.fibre_id}
             label={`${item.fibre_colour}(${item.fibre_code})`} />: <></>
          }
          )}
        </Box>
      )}
      MenuProps={{
        sx: {
          "&& .Mui-selected": {
            backgroundColor: "#CCEBF2"
          }
        }
      }}
    >
      {allfibre.map((fibre) => (
        <MenuItem
        disabled={disableDat(fibre)}
          key={fibre.fibre_id}
          value={fibre.fibre_id}
          
        >
          <Grid container gap={1} direction={'row'}>
            <Box style={{height: '20px', width: '20px' ,backgroundColor: fibre.fibre_colour_code}}></Box>{fibre.fibre_colour}({fibre.fibre_code})
          </Grid>
        </MenuItem>
      ))}
    </Select>
    )}/>
  </Grid>
</Grid>



        <Grid container justifyContent="center" alignItems={"center"}>
          <Grid item>
            <Button
              onClick={() => history.push(`/cable-master/tube-main`)}
              style={{
                color: "#fff",
                marginRight: 10,
                background: "#C1B4B3",
              }}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Back
            </Button>


            <Button
                onClick={() => history.push(`/cable-master/fibre-add`)}
                style={{
                  color: "#fff",
                  marginRight: 10,
                  // background: "#C1B4B3",
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Add Fibre
              </Button>
            <Button
              type="submit"
              style={{ color: "#fff" }}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
</CardContent>
</Card>
  )
}
interface EditTubeViewProps {
    color: ColorCode[];
    submitData?: any;
    fibre?: FibreMater;
    tube_deetail?: TubeMater;
    allfibre: FibreMater[];
}

export default EditTubeView