import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
//@ts-ignore
import ReactFlow, { useEdgesState, useNodesState } from "react-flow-renderer";
import colorGenerator from "../../../Common/utility/colorGenerator";
import {
  CableList,
  FMSList,
  FmsSchematic,
  splice_fms,
} from "../../../models/fmsModel";
import domtoimage from "dom-to-image";
import generatePDF, { Margin, Resolution } from "react-to-pdf";
function FMSSchematicView({
  schematic,
  schematic2,
  cable_count,
  fdb_count,
  schematic3,
  fms,
  AddZoneSuccess,
  BeginApiCallAction,
}: FMSSchematicViewProps) {
  const [nodes, setNodes, onNodesChange] = useNodesState<any[]>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<any[]>([]);
  const [cables, Setcable] = useState<CableList[]>([]);
  const targetRef = useRef<any>();
  const [cbb, setcabbName] = React.useState<number[]>([]);
  const [animated, setsetanimated] = React.useState<number[]>([]);
  const [HideToggle, setHide] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [tooltip, setTooltip] = React.useState<Tooltipinfo | undefined>(
    undefined
  );
  const [hover, sethover] = React.useState(false);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  useEffect(() => {
    if (!!schematic && schematic.length > 0) {
      let cab: CableList[] = [];
      let temp_id: number = 0;
      schematic.forEach((m) => {
        if (temp_id != m.cable_detail_id) {
          cab.push(m);
          temp_id = m.cable_detail_id;
        }
      });
      Setcable(cab);
      CreateData();
    }
  }, [schematic]);
  const CreateData = async () => {
    if (cable_count != null && fdb_count != null && schematic && schematic2) {
      NodeCreate();
    }
  };

  const NodeCreate = () => {
    let Node: any[] = [];
    let item_per_side = Math.ceil(
      (cable_count + fdb_count + schematic3.length) / 4
    );
    let cable_cnt: number = 0;
    let fdb_cnt: number = 0;
    let splice_cnt: number = 0;
    let pre_cable_id: number = 0;
    let pre_fdb_id: number = 0;
    let pre_sp_id: number = 0;
    let xy: number[] = [0, 0];
    let startxy: number[] = [0, 0];
    schematic.forEach((item, index) => {
      //For Cable pont creation
      if (item.cable_detail_id != pre_cable_id) {
        cable_cnt = cable_cnt + 1;
        if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 1) {
          xy = [xy[0] + 30, xy[1]];
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 2) {
          xy = [xy[0] + 10, xy[1] + 35];
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 3) {
          xy = [xy[0] - 50, xy[1] + 10];
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 4) {
          xy = [xy[0], xy[1] - 25];
        }
        startxy = xy;
      }
      if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 1) {
        Node.push({
          id: item.fibre_impl_id.toString(),
          sourcePosition: "bottom",
          targetPosition: "bottom",
          className: "dark-node",
          data: { label: item.tube_colour + "\n" + item.fibre_colour },
          position: { x: xy[0], y: xy[1] },
          style: {
            height: "35px",
            width: "30px",
            fontSize: "6px",
          },
        });
        xy = [xy[0] + 30, xy[1]];
      } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 2) {
        Node.push({
          id: item.fibre_impl_id.toString(),
          sourcePosition: "left",
          targetPosition: "left",
          className: "dark-node",
          data: { label: item.tube_colour + "\n" + item.fibre_colour },
          position: { x: xy[0], y: xy[1] },
          style: {
            height: "30px",
            width: "35px",
            fontSize: "6px",
          },
        });
        xy = [xy[0], xy[1] + 30];
      } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 3) {
        Node.push({
          id: item.fibre_impl_id.toString(),
          sourcePosition: "top",
          targetPosition: "top",
          className: "dark-node",
          data: { label: item.tube_colour + "\n" + item.fibre_colour },
          position: { x: xy[0], y: xy[1] },
          style: {
            height: "35px",
            width: "30px",
            fontSize: "6px",
          },
        });
        xy = [xy[0] - 30, xy[1]];
      } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 4) {
        Node.push({
          id: item.fibre_impl_id.toString(),
          sourcePosition: "right",
          targetPosition: "right",
          className: "dark-node",
          data: { label: item.tube_colour + "\n" + item.fibre_colour },
          position: { x: 0, y: xy[1] },
          style: {
            height: "30px",
            width: "35px",
            fontSize: "6px",
          },
        });
        xy = [xy[0], xy[1] - 30];
      }
      if (
        index + 1 == schematic.length ||
        item.cable_detail_id != schematic[index + 1].cable_detail_id
      ) {
        if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 1) {
          Node.push({
            id: Math.random(),
            sourcePosition: "bottom",
            targetPosition: "bottom",
            className: "dark-node",
            data: {
              label: item.cable_short_code,
            },
            position: { x: startxy[0], y: startxy[1] - 60 },
            style: {
              height: "50px",
              width: xy[0] - startxy[0],
              fontSize: "12px",
            },
          });
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 2) {
          Node.push({
            id: Math.random(),
            sourcePosition: "bottom",
            targetPosition: "bottom",
            className: "dark-node",
            data: {
              label: (
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    width: xy[1] - startxy[1],
                    marginLeft: 20,
                    marginTop: -10,
                  }}
                >
                  {item.cable_short_code}
                </div>
              ),
            },
            position: { x: startxy[0] + 40, y: startxy[1] },
            style: {
              height: xy[1] - startxy[1],
              width: "50px",
              fontSize: "12px",
            },
          });
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 3) {
          Node.push({
            id: Math.random(),
            sourcePosition: "bottom",
            targetPosition: "bottom",
            className: "dark-node",
            data: {
              label: item.cable_short_code,
            },
            position: { x: xy[0] + 30, y: xy[1] + 40 },
            style: {
              height: "50px",
              width: startxy[0] - xy[0],
              fontSize: "12px",
            },
          });
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 4) {
          Node.push({
            id: Math.random(),
            sourcePosition: "bottom",
            targetPosition: "bottom",
            className: "dark-node",
            data: {
              label: (
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    width: startxy[1] - xy[1],
                    marginLeft: 20,
                    marginTop: -10,
                  }}
                >
                  {item.cable_short_code}
                </div>
              ),
            },
            position: { x: -60, y: xy[1] + 30 },
            style: {
              height: startxy[1] - xy[1],
              width: "50px",
              fontSize: "12px",
            },
          });
        }
      }
      pre_cable_id = item.cable_detail_id;
    });

    schematic2.forEach((item, index) => {
      //For Cable pont creation
      if (item.fdb_fms_id != pre_fdb_id) {
        fdb_cnt = fdb_cnt + 1;
        if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 1) {
          xy = [xy[0] + 30, xy[1]];
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 2) {
          xy = [xy[0], xy[1] + 35];
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 3) {
          xy = [xy[0] - 50, xy[1]];
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 4) {
          xy = [xy[0], xy[1] - 30];
        }
        startxy = xy;
      }
      if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 1) {
        Node.push({
          id: (
            item.fibre_impl_id +
            item.fdb_fms_id +
            item.cable_detail_id +
            10000000
          ).toString(),
          sourcePosition: "bottom",
          targetPosition: "bottom",
          className: "dark-node",
          data: {
            label: !!item.adapter_position ? item.adapter_position : "NA",
          },
          position: { x: xy[0], y: xy[1] },
          style: {
            height: "35px",
            width: "30px",
            fontSize: "6px",
          },
        });
        xy = [xy[0] + 30, xy[1]];
      } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 2) {
        Node.push({
          id: (
            item.fibre_impl_id +
            item.fdb_fms_id +
            item.cable_detail_id +
            10000000
          ).toString(),
          sourcePosition: "left",
          targetPosition: "left",
          className: "dark-node",
          data: {
            label: !!item.adapter_position ? item.adapter_position : "NA",
          },
          position: { x: xy[0], y: xy[1] },
          style: {
            height: "30px",
            width: "35px",
            fontSize: "6px",
          },
        });
        xy = [xy[0], xy[1] + 30];
      } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 3) {
        Node.push({
          id: (
            item.fibre_impl_id +
            item.fdb_fms_id +
            item.cable_detail_id +
            10000000
          ).toString(),
          sourcePosition: "top",
          targetPosition: "top",
          className: "dark-node",
          data: {
            label: !!item.adapter_position ? item.adapter_position : "NA",
          },
          position: { x: xy[0], y: xy[1] },
          style: {
            height: "35px",
            width: "30px",
            fontSize: "6px",
          },
        });
        xy = [xy[0] - 30, xy[1]];
      } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 4) {
        Node.push({
          id: (
            item.fibre_impl_id +
            item.fdb_fms_id +
            item.cable_detail_id +
            10000000
          ).toString(),
          sourcePosition: "right",
          targetPosition: "right",
          className: "dark-node",
          data: {
            label: !!item.adapter_position ? item.adapter_position : "NA",
          },
          position: { x: 0, y: xy[1] },
          style: {
            height: "30px",
            width: "35px",
            fontSize: "6px",
          },
        });
        xy = [xy[0], xy[1] - 30];
      }
      if (
        index + 1 == schematic2.length ||
        item.fdb_fms_id != schematic2[index + 1].fdb_fms_id
      ) {
        if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 1) {
          Node.push({
            id: Math.random(),
            sourcePosition: "bottom",
            targetPosition: "bottom",
            className: "dark-node",
            data: {
              label: !!item.fdb_fms_code ? item.fdb_fms_code : "NA",
            },
            position: { x: startxy[0], y: startxy[1] - 60 },
            style: {
              height: "50px",
              width: xy[0] - startxy[0],
              fontSize: "12px",
            },
          });
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 2) {
          Node.push({
            id: Math.random(),
            sourcePosition: "bottom",
            targetPosition: "bottom",
            className: "dark-node",
            data: {
              label: (
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    width: xy[1] - startxy[1],
                    marginLeft: 20,
                    marginTop: -10,
                  }}
                >
                  {!!item.fdb_fms_code ? item.fdb_fms_code : "NA"}
                </div>
              ),
            },
            position: { x: startxy[0] + 40, y: startxy[1] },
            style: {
              height: xy[1] - startxy[1],
              width: "50px",
              fontSize: "12px",
            },
          });
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 3) {
          Node.push({
            id: Math.random(),
            sourcePosition: "bottom",
            targetPosition: "bottom",
            className: "dark-node",
            data: {
              label: !!item.fdb_fms_code ? item.fdb_fms_code : "NA",
            },
            position: { x: xy[0] + 30, y: xy[1] + 40 },
            style: {
              height: "50px",
              width: startxy[0] - xy[0],
              fontSize: "12px",
            },
          });
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 4) {
          Node.push({
            id: Math.random(),
            sourcePosition: "bottom",
            targetPosition: "bottom",
            className: "dark-node",
            data: {
              label: (
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    width: startxy[1] - xy[1],
                    marginLeft: 20,
                    marginTop: -10,
                  }}
                >
                  {!!item.fdb_fms_code ? item.fdb_fms_code : "NA"}
                </div>
              ),
            },
            position: { x: -60, y: xy[1] + 30 },
            style: {
              height: startxy[1] - xy[1],
              width: "50px",
              fontSize: "12px",
            },
          });
        }
      }
      pre_fdb_id = item.fdb_fms_id;
    });

    schematic3.forEach((item, index) => {
      //For splice point creation
      if (item.fms_id != pre_sp_id) {
        splice_cnt = splice_cnt + 1;
        if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 1) {
          xy = [xy[0] + 30, xy[1]];
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 2) {
          xy = [xy[0], xy[1] + 35];
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 3) {
          xy = [xy[0] - 50, xy[1]];
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 4) {
          xy = [xy[0], xy[1] - 30];
        }
        startxy = xy;
      }
      if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 1) {
        Node.push({
          id: item.fibre_impl_id.toString(),
          sourcePosition: "bottom",
          targetPosition: "bottom",
          className: "dark-node",
          data: {
            label: item.splice_tube_colour + "\n" + item.splice_fibre_colour,
          },
          position: { x: xy[0], y: xy[1] },
          style: {
            height: "35px",
            width: "30px",
            fontSize: "6px",
          },
        });
        xy = [xy[0] + 30, xy[1]];
      } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 2) {
        Node.push({
          id: item.fibre_impl_id.toString(),
          sourcePosition: "left",
          targetPosition: "left",
          className: "dark-node",
          data: {
            label: item.splice_tube_colour + "\n" + item.splice_fibre_colour,
          },
          position: { x: xy[0], y: xy[1] },
          style: {
            height: "30px",
            width: "35px",
            fontSize: "6px",
          },
        });
        xy = [xy[0], xy[1] + 30];
      } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 3) {
        Node.push({
          id: item.fibre_impl_id.toString(),
          sourcePosition: "top",
          targetPosition: "top",
          className: "dark-node",
          data: {
            label: item.splice_tube_colour + "\n" + item.splice_fibre_colour,
          },
          position: { x: xy[0], y: xy[1] },
          style: {
            height: "35px",
            width: "30px",
            fontSize: "6px",
          },
        });
        xy = [xy[0] - 30, xy[1]];
      } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 4) {
        Node.push({
          id: item.fibre_impl_id.toString(),
          sourcePosition: "right",
          targetPosition: "right",
          className: "dark-node",
          data: {
            label: item.splice_tube_colour + "\n" + item.splice_fibre_colour,
          },
          position: { x: 0, y: xy[1] },
          style: {
            height: "30px",
            width: "35px",
            fontSize: "6px",
          },
        });
        xy = [xy[0], xy[1] - 30];
      }
      if (
        index + 1 == schematic3.length ||
        item.fms_id != schematic3[index + 1].fms_id
      ) {
        if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 1) {
          Node.push({
            id: Math.random(),
            sourcePosition: "bottom",
            targetPosition: "bottom",
            className: "dark-node",
            data: {
              label: !!item.fms_code
                ? item.fms_code + "(" + item.spliced_status + ")"
                : "NA",
            },
            position: { x: startxy[0], y: startxy[1] - 60 },
            style: {
              height: "50px",
              width: xy[0] - startxy[0],
              fontSize: "12px",
            },
          });
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 2) {
          Node.push({
            id: Math.random(),
            sourcePosition: "bottom",
            targetPosition: "bottom",
            className: "dark-node",
            data: {
              label: (
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    width: xy[1] - startxy[1],
                    marginLeft: 20,
                    marginTop: -10,
                  }}
                >
                  {!!item.fms_code
                    ? item.fms_code + "(" + item.spliced_status + ")"
                    : "NA"}
                </div>
              ),
            },
            position: { x: startxy[0] + 40, y: startxy[1] },
            style: {
              height: xy[1] - startxy[1],
              width: "50px",
              fontSize: "12px",
            },
          });
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 3) {
          Node.push({
            id: Math.random(),
            sourcePosition: "bottom",
            targetPosition: "bottom",
            className: "dark-node",
            data: {
              label: !!item.fms_code
                ? item.fms_code + "(" + item.spliced_status + ")"
                : "NA",
            },
            position: { x: xy[0] + 30, y: xy[1] + 40 },
            style: {
              height: "50px",
              width: startxy[0] - xy[0],
              fontSize: "12px",
            },
          });
        } else if ((cable_cnt + fdb_cnt + splice_cnt) / item_per_side <= 4) {
          Node.push({
            id: Math.random(),
            sourcePosition: "bottom",
            targetPosition: "bottom",
            className: "dark-node",
            data: {
              label: (
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    width: startxy[1] - xy[1],
                    marginLeft: 20,
                    marginTop: -10,
                  }}
                >
                  {!!item.fms_code
                    ? item.fms_code + "(" + item.spliced_status + ")"
                    : "NA"}
                </div>
              ),
            },
            position: { x: -60, y: xy[1] + 30 },
            style: {
              height: startxy[1] - xy[1],
              width: "50px",
              fontSize: "12px",
            },
          });
        }
      }
      pre_sp_id = item.fms_id;
    });

    setNodes(Node);
    let Edge: any[] = [];
    let generated: number[] = [];
    schematic.forEach((item, index) => {
      let ferul: string = item.ferule_name;
      item.ferule_name && item.service_name
        ? (ferul = ferul + " | ")
        : (ferul = ferul);
      !!item.service_name
        ? (ferul = ferul + item.service_name)
        : (ferul = ferul);
      if (item.fibre_status == "TERMINATED") {
        Edge.push({
          id: Math.random(),
          source: item.fibre_impl_id.toString(),
          target: (
            item.fibre_impl_id +
            item.fdb_fms_id +
            item.cable_detail_id +
            10000000
          ).toString(),
          style: {
            stroke: colorGenerator(String(item.cable_short_code), 0, 210),
            strokeWidth: 3,
            cursor: "pointer",
          },
          label: ferul,
          labelStyle: { zIndex: 999 },
          type: "smoothstep",
        });
        generated.push(item.fibre_impl_id);
      } else if (item.fibre_status == "SPLICED") {
        if (
          !generated.find((m) => m == item.fibre_impl_id) &&
          !!item.spliced_fibre_impl_id
        ) {
          Edge.push({
            id: Math.random(),
            source: item.fibre_impl_id.toString(),
            target: item.spliced_fibre_impl_id.toString(),
            style: {
              stroke: colorGenerator(String(item.cable_short_code), 100, 255),
              strokeWidth: 3,
              cursor: "pointer",
            },
            label: ferul,
            type: "smoothstep",
            labelStyle: { zIndex: 999 },
          });
        }
      }
    });
    setEdges(Edge);
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setcabbName(value);
  };
  const Filter = () => {
    if (cbb.length > 0) {
      let Edge: any[] = [];
      let generated: number[] = [];
      schematic.forEach((item, index) => {
        if (!!cbb.includes(item.cable_detail_id)) {
          let ferul: string = item.ferule_name;
          item.ferule_name && item.service_name
            ? (ferul = ferul + " | ")
            : (ferul = ferul);
          !!item.service_name
            ? (ferul = ferul + item.service_name)
            : (ferul = ferul);
          if (item.fibre_status == "TERMINATED") {
            Edge.push({
              id: Math.random(),
              source: item.fibre_impl_id.toString(),
              target: (
                item.fibre_impl_id +
                item.fdb_fms_id +
                item.cable_detail_id +
                10000000
              ).toString(),
              style: {
                stroke: colorGenerator(String(item.cable_short_code), 0, 210),
                strokeWidth: 3,
                cursor: "pointer",
              },
              label: ferul,
              type: "smoothstep",
            });
            generated.push(item.fibre_impl_id);
          } else if (item.fibre_status == "SPLICED") {
            if (
              !generated.find((m) => m == item.fibre_impl_id) &&
              !!item.spliced_fibre_impl_id
            ) {
              Edge.push({
                id: Math.random(),
                source: item.fibre_impl_id.toString(),
                target: item.spliced_fibre_impl_id.toString(),
                style: {
                  stroke: colorGenerator(
                    String(item.cable_short_code),
                    100,
                    255
                  ),
                  strokeWidth: 3,
                  cursor: "pointer",
                },
                label: ferul,
                type: "smoothstep",
              });
            }
          }
        }
      });
      setEdges(Edge);
    }
  };
  const Clear = () => {
    let Edge: any[] = [];
    let generated: number[] = [];
    schematic.forEach((item, index) => {
      let ferul: string = item.ferule_name;
      item.ferule_name && item.service_name
        ? (ferul = ferul + " | ")
        : (ferul = ferul);
      !!item.service_name
        ? (ferul = ferul + item.service_name)
        : (ferul = ferul);
      if (item.fibre_status == "TERMINATED") {
        Edge.push({
          id: Math.random(),
          source: item.fibre_impl_id.toString(),
          target: (
            item.fibre_impl_id +
            item.fdb_fms_id +
            item.cable_detail_id +
            10000000
          ).toString(),
          style: {
            stroke: colorGenerator(String(item.cable_short_code), 0, 210),
            strokeWidth: 3,
            cursor: "pointer",
          },
          label: ferul,
          type: "smoothstep",
        });
        generated.push(item.fibre_impl_id);
      } else if (item.fibre_status == "SPLICED") {
        if (
          !generated.find((m) => m == item.fibre_impl_id) &&
          !!item.spliced_fibre_impl_id
        ) {
          Edge.push({
            id: Math.random(),
            source: item.fibre_impl_id.toString(),
            target: item.spliced_fibre_impl_id.toString(),
            style: {
              stroke: colorGenerator(String(item.cable_short_code), 100, 255),
              strokeWidth: 3,
              cursor: "pointer",
            },
            label: ferul,
            type: "smoothstep",
            labelStyle: { zIndex: 999 },
          });
        }
      }
    });
    setEdges(Edge);
    setcabbName([]);
  };
  const OnClickEdge = (edge: any) => {
    let edgeTep: any[] = edges;
    let TempEdge = edgeTep.find((m) => m.id == edge.id);
    if (!!animated.find((m) => m === edge.id)) {
      TempEdge.animated = false;
      setsetanimated(animated.filter((m) => m != edge.id));
    } else {
      TempEdge.animated = true;
      setsetanimated([...animated, edge.id]);
    }
    setEdges(edgeTep);
    setHide(false);
  };
  const Hide = () => {
    setEdges(edges.filter((m: any) => animated.includes(m.id)));
    setHide(true);
  };
  const Snap = () => {
    let data: any = document.getElementById("flow_image");
    domtoimage.toSvg(data, { quality: 10 }).then(function (dataUrl) {
      var link: any = document.createElement("a");
      link.download = "my-image-name.svg";
      link.href = dataUrl;
      link.click();
    });
  };
  const dPdf = () => {
    BeginApiCallAction({
      count: 1,
      message: "Generating Pdf...",
    });
    generatePDF(targetRef, {
      filename: "page.pdf",
      page: {
        orientation: "landscape",
        format: "A4",
        margin: Margin.NONE,
      },
      resolution: Resolution.NORMAL,
      method: "open",
      overrides: {
        canvas: {
          scale: 8,
          height: 850,
          width: 1500,
        },
      },
      canvas: {
        mimeType: "image/png",
        qualityRatio: 1,
      },
    }).finally(() => {
      AddZoneSuccess();
    });
  };

  const onHoverEnter = (edge: any) => {
    if (!open) {
      let toolt: Tooltipinfo = {};
      let item: CableList | undefined = schematic.find(
        (m) => m.fibre_impl_id == +edge.source
      );
      let item2: CableList | undefined = schematic.find(
        (m) => m.fibre_impl_id == +edge.target
      );
      toolt = {
        source_cable: item?.cable_short_code,
        des_cable: !!item?.fibre_impl_id ? item2?.cable_short_code : undefined,
        source_fibre: item?.fibre_colour,
        source_tube: item?.tube_colour,
        des_fibre: item?.splice_fibre_colour,
        des_tube: item?.splice_tube_colour,
        fdb: item?.fdb_fms_code,
        port: item?.adapter_position,
        service: item?.service_name,
        service_desc: item?.service_desc,
        ferul: item?.ferule_name,
      };
      if (!item2) {
        let item3: splice_fms | undefined = schematic3.find(
          (m) => m.fibre_impl_id == +edge.target
        );
        toolt.fms_code = item3?.fms_code;
        toolt.des_tube = item3?.splice_tube_colour;
        toolt.des_fibre = item3?.splice_fibre_colour;
      }
      setTooltip(toolt);
    }
  };
  function sleeping(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  useEffect(() => {
    let sleep: any;
    const delay = async () => {
      await sleeping(2000);
      if (!!tooltip) {
        setOpen(true);
        sleep = setTimeout(() => {
          if (!hover) {
            setOpen(false);
          }
        }, 3000);
      }
    };
    delay();
    return clearTimeout(sleep);
  }, [tooltip, hover]);

  return (
    <Grid container height={"100%"} width={"100%"}>
      <Card style={{ width: "100%", padding: 0, margin: 0 }}>
        <CardContent style={{ padding: 5 }}>
          <Grid
            container
            style={{ background: "#fff", padding: 10 }}
            justifyContent={"space-around"}
            alignItems={"center"}
          >
            <Grid item xs={1}>
              <Typography>Filter Cable</Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Typography>:</Typography>
            </Grid>
            <Grid item xs={3}>
              <Select
                multiple
                value={cbb}
                onChange={(value) => handleChange(value)}
                fullWidth
                size="small"
                input={<OutlinedInput />}
                renderValue={(selected) =>
                  cables
                    .filter((name) => selected.includes(name.cable_detail_id))
                    .map((record) => record.cable_short_code)
                    .join(", ")
                }
                MenuProps={MenuProps}
              >
                {cables.map((item) => (
                  <MenuItem
                    key={item.cable_short_code}
                    value={item.cable_detail_id}
                  >
                    <Checkbox
                      checked={!!cbb.find((m) => m == item.cable_detail_id)}
                    />
                    <ListItemText primary={item.cable_short_code} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6.5}>
              <Button
                variant="contained"
                onClick={() => Filter()}
                style={{ marginRight: 10, color: "#fff" }}
              >
                Filter
              </Button>
              {!HideToggle ? (
                <Button
                  variant="contained"
                  onClick={() => Hide()}
                  style={{ marginRight: 10, color: "#fff" }}
                >
                  Hide Unselected Fibre
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => (cbb.length > 0 ? Filter() : Clear())}
                  style={{ marginRight: 10, color: "#fff" }}
                >
                  Show All Fibre
                </Button>
              )}
              <Button variant="outlined" onClick={() => Snap()}>
                Download SVG Image
              </Button>
              <Button variant="outlined" onClick={() => dPdf()}>
                Download PDF
              </Button>
              <Button
                style={{ marginRight: 10 }}
                variant="outlined"
                onClick={() => Clear()}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {!!fms && (
        <Typography
          style={{
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            backgroundColor: "#00AAEE",
            color: "#ffff",
          }}
        >
          Schematic View of {fms?.fms_code}({fms?.fms_type})
        </Typography>
      )}
      <Grid
        item
        height={"80%"}
        width={"100%"}
        style={{ backgroundColor: "#FFFF" }}
      >
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "700px", // Set your width here
              },
            },
          }}
          hideBackdrop={true}
          disableEnforceFocus={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            onMouseEnter={() => sethover(true)}
            onMouseLeave={() => sethover(false)}
          >
            <DialogContentText id="alert-dialog-description">
              {!!tooltip?.des_cable && (
                <Grid container direction={"row"}>
                  <Grid style={{ fontWeight: "600" }} xs={4}>
                    Splice/Patch Cable
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={1}>
                    :
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={7}>
                    {tooltip?.source_cable} {">>"} {tooltip?.des_cable}
                  </Grid>
                </Grid>
              )}
              {!!tooltip?.fdb && (
                <Grid container direction={"row"}>
                  <Grid style={{ fontWeight: "600" }} xs={4}>
                    Cable FDB Info
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={1}>
                    :
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={7}>
                    {tooltip?.source_cable} {">>"} {tooltip?.fdb}
                  </Grid>
                </Grid>
              )}
              {!!tooltip?.fms_code && (
                <Grid container direction={"row"}>
                  <Grid style={{ fontWeight: "600" }} xs={4}>
                    Cable FMS Info
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={1}>
                    :
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={7}>
                    {tooltip?.source_cable} {">>"} {tooltip?.fms_code}
                  </Grid>
                </Grid>
              )}
              {!!tooltip?.des_tube && (
                <Grid container direction={"row"}>
                  <Grid style={{ fontWeight: "600" }} xs={4}>
                    Fibre Splice/Patch Info
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={1}>
                    :
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={7}>
                    {tooltip?.source_tube}-{tooltip?.source_fibre} {">>"}
                    {tooltip?.des_tube}-{tooltip?.des_fibre}
                  </Grid>
                </Grid>
              )}
              {!!tooltip?.port && (
                <Grid container direction={"row"}>
                  <Grid style={{ fontWeight: "600" }} xs={4}>
                    Fibre Port Info
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={1}>
                    :
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={7}>
                    {tooltip?.source_tube}-{tooltip?.source_fibre} {">>"}
                    {tooltip?.port}
                  </Grid>
                </Grid>
              )}
              {!!tooltip?.ferul && (
                <Grid container direction={"row"}>
                  <Grid style={{ fontWeight: "600" }} xs={4}>
                    Ferrule Name
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={1}>
                    :
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={7}>
                    {tooltip?.ferul}
                  </Grid>
                </Grid>
              )}
              {!!tooltip?.service && (
                <Grid container direction={"row"}>
                  <Grid style={{ fontWeight: "600" }} xs={4}>
                    Service Name
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={1}>
                    :
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={7}>
                    {tooltip?.service}
                  </Grid>
                </Grid>
              )}
              {!!tooltip?.service_desc && (
                <Grid container direction={"row"}>
                  <Grid style={{ fontWeight: "600" }} xs={4}>
                    Service Description
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={1}>
                    :
                  </Grid>
                  <Grid style={{ fontWeight: "600" }} xs={7}>
                    {tooltip?.service_desc}
                  </Grid>
                </Grid>
              )}
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Grid style={{ height: "100%" }}>
          <ReactFlow
            id="flow_image"
            ref={targetRef}
            nodes={nodes}
            edges={edges}
            fitView
            maxZoom={100}
            minZoom={0.1}
            attributionPosition="bottom-center"
            style={{
              justifyContent: "center",
              alignItems: "bottom",
              margin: "0 auto",
              zIndex: "999",
            }}
            zoomOnScroll={true}
            defaultZoom={0.3}
            snapToGrid={true}
            onEdgeClick={(event: React.MouseEvent, edge: any) =>
              OnClickEdge(edge)
            }
            onEdgeMouseEnter={(event: React.MouseEvent, edge: any) =>
              onHoverEnter(edge)
            }
            // onEdgeMouseLeave={(event: React.MouseEvent, edge: any) => (
            //   sethover(false)
            // )}
          ></ReactFlow>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FMSSchematicView;
interface FMSSchematicViewProps {
  schematic: CableList[];
  schematic2: CableList[];
  cable_count: number;
  fdb_count: number;
  schematic3: splice_fms[];
  fms?: FMSList;
  BeginApiCallAction?: any;
  AddZoneSuccess?: any;
}

interface Tooltipinfo {
  source_cable?: string;
  des_cable?: string;
  source_tube?: string;
  source_fibre?: string;
  des_tube?: string;
  des_fibre?: string;
  port?: number;
  fdb?: number;
  service?: string;
  service_desc?: string;
  ferul?: string;
  fms_code?: string;
}
