import { Grid, Typography, Button, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import Iframe from "react-iframe";
import Autocompletes from "../../../components/Autocompletes";
import { Controller, useForm } from "react-hook-form";
import { StationList } from "../../../models/stationModel";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import { GetAllStations } from "../../../Stores/actions/stationAction";
import { useSnackbar } from "notistack";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
function RedudantRouteMain({ allstation, GetAllStations }: RedudantRouteProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm();
  const { errors } = formState;
  useEffect(() => {
    GetAllStations();
  }, []);
  const unsecuredCopyToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };
  const OnSubmit = async (data: any) => {
    let query = `MATCH (src:STATION {station_name: '${data.station_id}'} ),
    (dest:STATION {station_name: '${data.station_id2}'})
    MATCH p = shortestPath((src)-[*]-(dest))
    return p;`;
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(query);
    } else {
      unsecuredCopyToClipboard(query);
    }
    await enqueueSnackbar("Query copied to clipboard!", {
      variant: "success",
    });
  };
  const fullScreen = () => {
    var elem: any = document.getElementById("myId");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };
  return (
    <Grid
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#ffff",
      }}
    >
      <Grid container flexDirection={"column"}>
        <Grid item>
          <Typography
            style={{ fontWeight: 500, fontSize: 14, color: "#428BCA" }}
          >
            Constract Query
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item xs={2} justifyContent={"center"} alignItems={"center"}>
              <Typography style={{ fontWeight: 550, textAlign: "center" }}>
                Source Station A
              </Typography>
            </Grid>
            <Grid item xs={0.5} justifyContent={"center"} alignItems={"center"}>
              <Typography style={{ fontWeight: 550, textAlign: "center" }}>
                :
              </Typography>
            </Grid>
            <Grid item xs={2.5} justifyContent={"center"} alignItems={"center"}>
              <Controller
                control={control}
                name={"station_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={allstation}
                    keys={"station_name"}
                    label={"station_name"}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"Station A"}
                    value={value}
                    errors={errors["station_id"]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} justifyContent={"center"} alignItems={"center"}>
              <Typography style={{ fontWeight: 550, textAlign: "center" }}>
                Source Station B
              </Typography>
            </Grid>
            <Grid item xs={0.5} justifyContent={"center"} alignItems={"center"}>
              <Typography style={{ fontWeight: 550, textAlign: "center" }}>
                :
              </Typography>
            </Grid>
            <Grid item xs={2.5} justifyContent={"center"} alignItems={"center"}>
              <Controller
                control={control}
                name={"station_id2"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    infoEvent={() => {}}
                    option={allstation}
                    keys={"station_name"}
                    label={"station_name"}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"Station B"}
                    value={value}
                    errors={errors["station_id2"]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Grid container justifyContent={"center"} alignItems={"center"}>
                {
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#808090",
                      width: 100,
                    }}
                    onClick={handleSubmit(OnSubmit)}
                  >
                    Copy
                  </Button>
                }
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => fullScreen()}
                >
                  <OpenInFullIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Iframe
        url="https://browser.neo4j.io/"
        width="100%"
        height="100%"
        id="myId"
        className="myClassname"
        position="relative"
      />
    </Grid>
  );
}

interface RedudantRouteProps {
  allstation: StationList[];
  GetAllStations?: any;
}
const mapStateToProps = (state: StoreState) => {
  return {
    allstation: state.station.station_list,
  };
};
const mapDispatchToProps = {
  GetAllStations,
};
export default connect(mapStateToProps, mapDispatchToProps)(RedudantRouteMain);
