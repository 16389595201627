import { CableMasterMain } from "../../models/cableMasterModel";
import { CableMasterActionTypes } from "../actions/cableMasterActions";
import InitialState from "../reducers/initialState";

const initialState: CableMasterMain = InitialState.cable_master;

export default function CableMasterReducer(
  state: CableMasterMain = initialState,
  action: any
) {
  switch (action.type) {
    case  CableMasterActionTypes.Get_All_Fibre_Master_Success_Action:
        return { ...state, fibre_master: action.payload };
    case CableMasterActionTypes.Fibre_Create_Load_Success_Action:
      return { ...state, color_code: action.payload };
    case CableMasterActionTypes.Get_All_Tube_Master_Success_Action:
        return { ...state, tube_master: action.payload };
    case CableMasterActionTypes.Get_All_Cable_Master_Success_Action:
      return { ...state, cable_master_list: action.payload };
    case CableMasterActionTypes.Get_Cable_Detail_Success_Action:
      return {...state, cable_detail: action.payload}
    case CableMasterActionTypes.Tube_Create_Load_Success_Action:
      return {...state, fibre_master: action.payload}
    case CableMasterActionTypes.Get_Tube_Detail_Success_Action:
        return {...state, tube_detail: action.payload}
    default:
      return state;
  }
}