import { StationMain } from "../../models/stationModel";
import { CableMasterActionTypes } from "../actions/cableMasterActions";
import { ReportActionTypes } from "../actions/ReportAction";
import { StationActionTypes } from "../actions/stationAction";
import InitialState from "./initialState";

const initialState: StationMain = InitialState.station;

export default function StationReducer(
  state: StationMain = initialState,
  action: any
) {
  switch (action.type) {
    case StationActionTypes.Station_GetAll_Success:
      return { ...state, station_list: action.payload.station };
    case StationActionTypes.Get_Station_By_Id_Success_Action:
      return { ...state, single_station: action.payload };
    case StationActionTypes.Get_Station_By_ZoneId_Success_Action:
      return {
        ...state,
        station_list: action.payload.n == 1 && action.payload.station,
        station_list2: action.payload.n == 2 && action.payload.station,
      };
    case StationActionTypes.Filter_Update_Success_Action:
      return { ...state, has_filter: action.payload };
    case CableMasterActionTypes.Get_All_Fibre_Master_Success_Action:
      return { ...state, has_filter: undefined };
    case ReportActionTypes.Get_Damage_Fibre_Load_Success:
      return {
        ...state,
        station_list: action.payload[1].data,
      };
    case ReportActionTypes.End_To_End_Get_Station_Action:
      return {
        ...state,
        station_list:
          action.payload.key == 1 ? action.payload.data : state.station_list,
        station_list2:
          action.payload.key == 2 ? action.payload.data : state.station_list2,
      };
    default:
      return state;
  }
}
