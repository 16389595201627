import React,{useEffect} from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { StoreState } from "../../../models/reduxModels";
import { AddZones } from "../../../Stores/actions/zoneActions";
// import ZoneAddView from "./ZoneAddView";
import { useSnackbar } from "notistack";
import ServiceAddView from "./ServiceAddView";
import { AddServices } from "../../../Stores/actions/serviceMasterAction";
import { DomainTypes } from "../../../Stores/actions/domainAction";
function AddService({ AddServices,serviceType,DomainTypes }: AddServiceProps) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const submitData = (data: any) => {
    AddServices({
      payload: data,
      history: history,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  useEffect(()=>{
    DomainTypes("service_type")
  },[])

  return <ServiceAddView submitData={submitData} serviceType={serviceType} />;
}

const mapStateToProps = (state: StoreState) => {
  return {serviceType:state.domain.service_type};
};
const mapDispatchToProps = {
    AddServices,
    DomainTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(AddService);
interface AddServiceProps {
    AddServices?: any;
    DomainTypes?:any;
    serviceType:any;
}
