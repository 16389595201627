import { Card, CardHeader, CardContent, Grid, Typography, TextField, MenuItem, Box, Select, Chip, Button } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom';
import Autocompletes from '../../../components/Autocompletes';
import { CableMater, ColorCode, TubeMater } from '../../../models/cableMasterModel'

function EditCableView({alltube,color,submitData,cable_deetail}: EditCableViewProps) {
    const history = useHistory();
    const {control, register, handleSubmit, formState, setValue, getValues } = useForm();
    const { errors } = formState;
    const onSubmit = (props: any) => {
      submitData(props)
    };
    const tubeNoSelected =()=>{
      setValue('tubes', [])
    }
    const cbType: any[] = [
        {
          key: "UG",
          value: "UG",
        },
        {
          key: "Aerial",
          value: "Aerial",
        },
        {
          key: "OPGW",
          value: "OPGW",
        },
        {
          key: "ADSS",
          value: "ADSS",
        },
        
      ];
      const cbStrength: any[] = [
        {
          key: "6F",
          value: "6F",
        },
        {
          key: "12F",
          value: "12F",
        },
        {
          key: "18F",
          value: "18F",
        },
        {
          key: "24F",
          value: "24F",
        },
        {
          key: "48F",
          value: "48F",
        },
        
      ];
    useEffect(()=> {
        if(!!cable_deetail){
            setValue('cable_code', cable_deetail.cable_code) 
            setValue('cable_name', cable_deetail.cable_name) 
            setValue('cable_make', cable_deetail.cable_make) 
            setValue('cable_type', cable_deetail.cable_type)
            setValue('cable_strength', cable_deetail.cable_strength)
            setValue('cable_colour_code', cable_deetail.cable_colour_code);
            setValue('cable_desc', cable_deetail.cable_desc);
            let t: number[]=[]
            cable_deetail.tubes.forEach(m=> !!m && t.push(m.tube_id))
            setValue('tubes', t);
            setValue('no_of_tubes', cable_deetail.no_of_tubes);
        }
    },[cable_deetail])
    const disableDat=(tube: TubeMater)=> {
      let flag: boolean = false
      if(!!getValues('tubes')){
        if(+getValues('tubes').length >= +getValues('no_of_tubes')){
          flag= true
        }
       let t: TubeMater= getValues('tubes').find((m: any)=> m == tube.tube_id)
       if(!!t){
         flag= false 
       }
      }
      return flag
    }
  return (
    <Card sx={{ marginTop: "10px" }}>
    <CardHeader
      style={{ backgroundColor: "#202a37", color: "#fff",height:14 }}
      titleTypographyProps={{ fontWeight: 500,fontSize:'1.3rem' }}
      title="Edit Cable"
    />
    <CardContent style={{padding: 0}}>
    <form onSubmit={handleSubmit(onSubmit)}>
    {/* <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4}>
              <Typography style={{fontWeight: 550}}>Cable Name</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{fontWeight: 550}}>:</Typography>
            </Grid>
            <Grid item xs={5}>
            <TextField
                {...register("cable_name", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="cable_name"
                placeholder="Cable Name"
                error={!!errors["cable_name"]}
              />
          </Grid>
      </Grid> */}
      
      <Grid container justifyContent="center" alignItems={"center"} style={{marginTop: 22}}>
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Cable Make</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
            <Grid item xs={1}>
              <Typography style={{fontWeight: 550}}>:</Typography>
            </Grid>
            <Grid item xs={5}>
            <TextField
                {...register("cable_make", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                name="cable_make"
                placeholder="Cable Make"
                error={!!errors["cable_make"]}
              />
          </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Cable Type</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
            <Grid item xs={1}>
              <Typography style={{fontWeight: 550}}>:</Typography>
            </Grid>
            <Grid item xs={5}>
            <Controller
            control={control}
            name={"cable_type"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
                <Autocompletes
                    option={cbType}
                    keys={"key"}
                    label={"value"}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"Station Type"}
                    value={value}
                    errors={errors["cable_type"]}
                  />    
            // <TextField
            //     {...register("cable_type", { required: true })}
            //     style={{ width: "100%", margin: "5px" }}
            //     type="text"
            //     variant="outlined"
            //     onBlur={onBlur}
            //     value={value}
            //     onChange={(v)=> onChange(v.target.value)}
            //     size="small"
            //     select
            //     name="cable_type"
            //     placeholder="Cable Type"
            //     error={!!errors["cable_type"]}
            //   >
            //       <MenuItem key={'UG'} value={'UG'}>
            //         UG
            //       </MenuItem>
            //       <MenuItem key={'Aerial'} value={'Aerial'}>
            //         Aerial
            //       </MenuItem>
            //       <MenuItem key={'OPGW'} value={'OPGW'}>
            //         OPGW
            //       </MenuItem>
            //       <MenuItem key={'ADSS'} value={'ADSS'}>
            //         ADSS
            //       </MenuItem>
            //   </TextField>
            )}/>
          </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Cable Strength</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
            <Grid item xs={1}>
              <Typography style={{fontWeight: 550}}>:</Typography>
            </Grid>
            <Grid item xs={5}>
            <Controller
            control={control}
            name={"cable_strength"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
                <Autocompletes
                    option={cbStrength}
                    keys={"key"}
                    label={"value"}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"Cable Strength"}
                    value={value}
                    errors={errors["cable_strength"]}
                  />
            
            )}/>
          </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems={"center"}>
          <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Cable Color</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
            
            <Grid item xs={1}>
              <Typography style={{fontWeight: 550}}>:</Typography>
            </Grid>
            <Grid item xs={5}>
            <Controller
            control={control}
            name={"cable_colour_code"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
            <TextField
                style={{ width: "100%", margin: "5px" }}
                type="text"
                variant="outlined"
                size="small"
                select
                onBlur={onBlur}
                value={value}
                onChange={(value)=> onChange(value.target.value)}
                name="cable_colour_code"
                placeholder="Cable Color"
                error={!!errors["cable_colour_code"]}
              >
                  {
              !!color && color.map((item)=> (
                <MenuItem key={item.colour_master_id} value={item.colour_code}>
                  <Grid container gap={1} direction={'row'}>
                    <Box style={{height: '20px', width: '20px' ,backgroundColor: item.colour_code}}></Box>{item.colour_name}
                  </Grid>
                </MenuItem>
              ))
            }
              </TextField>
            )}/>
          </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>No Of Tube</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
            <Grid item xs={1}>
              <Typography style={{fontWeight: 550}}>:</Typography>
            </Grid>
            <Grid item xs={5}>
            <Controller
            control={control}
            name={"no_of_tubes"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
            <TextField
                style={{ width: "100%", margin: "5px" }}
                type="text"
                disabled
                variant="outlined"
                size="small"
                select
                value={value}
                onBlur={onBlur}
                name="no_of_tubes"
                placeholder="No Of Tube"
                onChange={(event)=> (tubeNoSelected(),onChange(event.target.value))}
                error={!!errors["no_of_tubes"]}
              >
                  <MenuItem key={'1'} value={'1'}>
                   1
                  </MenuItem>
                  <MenuItem key={'2'} value={'2'}>
                    2
                  </MenuItem>
                  <MenuItem key={'3'} value={'3'}>
                    3
                  </MenuItem>
                  <MenuItem key={'4'} value={'4'}>
                    4
                  </MenuItem>
                  <MenuItem key={'5'} value={'5'}>
                   5
                  </MenuItem>
                  <MenuItem key={'6'} value={'6'}>
                    6
                  </MenuItem>
                  <MenuItem key={'7'} value={'7'}>
                    7
                  </MenuItem>
                  <MenuItem key={'8'} value={'8'}>
                    8
                  </MenuItem>
                  <MenuItem key={'9'} value={'9'}>
                    9
                  </MenuItem>
                  <MenuItem key={'10'} value={'10'}>
                   10
                  </MenuItem>
              </TextField>
          )}/>
          </Grid>
      </Grid>
        <Grid container justifyContent="center" alignItems={"center"}>
        <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Tubes</Typography>
              <Box sx={{color:"red"}}>*</Box> 
        </Grid>
        <Grid item xs={1}>
          <Typography style={{fontWeight: 550}}>:</Typography>
        </Grid>
        <Grid item xs={5}>
        <Controller
          control={control}
          name={"tubes"}
          defaultValue={[]}
          rules={{ required: true }}
          render={({ field: { onBlur, value, onChange } }) => (
        <Select
          size='small'
          style={{ width: "100%", margin: "5px" }}
          variant="outlined"
          multiple
          onBlur={onBlur}
          disabled
          value={value}
          onChange={(event)=> onChange(event.target.value)
           }
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value: any) => {
                let item:TubeMater | undefined = alltube.find(m=> +m.tube_id == +value) 
                return !!item ? <Chip style={{backgroundColor: item.tube_colour_code, color: '#ffff'}} key={item.tube_id}
                 label={`${item.tube_colour}(${item.tube_code})`} />: <></>
              }
              )}
            </Box>
          )}
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "#CCEBF2"
              }
            }
          }}
        >
          {alltube.map((tube) => (
            <MenuItem
            disabled={disableDat(tube)}
              key={tube.tube_id}
              value={tube.tube_id}
              
            >
              <Grid container gap={1} direction={'row'}>
                <Box style={{height: '20px', width: '20px' ,backgroundColor: tube.tube_colour_code}}></Box>{tube.tube_colour}({tube.tube_code})
              </Grid>
            </MenuItem>
          ))}
        </Select>
        )}/>
      </Grid>
  </Grid>
  <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Short Code</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
            <Grid item xs={1}>
              <Typography style={{fontWeight: 550}}>:</Typography>
            </Grid>
            <Grid item xs={5}>
            <TextField
                {...register("cable_code", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                disabled
                type="text"
                variant="outlined"
                size="small"
                name="cable_code"
                placeholder="Cable Code"
                error={!!errors["cable_code"]}
              />
          </Grid>
      </Grid>
  
  <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Description</Typography>
              <Box sx={{color:"red"}}>*</Box> 
            </Grid>
            <Grid item xs={1}>
              <Typography style={{fontWeight: 550}}>:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...register("cable_desc", { required: true })}
                style={{ width: "100%", margin: "5px" }}
                multiline
                rows={4}
                variant="outlined"
                name={"cable_desc"}
                placeholder="Description"
                error={!!errors["cable_desc"]}
              />
            </Grid>
          </Grid>
       <Grid container justifyContent="center" alignItems={"center"}>
              <Grid item>
                <Button
                  onClick={() => history.push(`/cable-master/cable-main`)}
                  style={{
                    color: "#fff",
                    marginRight: 10,
                    background: "#C1B4B3",
                  }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Back
                </Button>
                <Button
                onClick={() => history.push(`/cable-master/tube-add`)}
                style={{
                  color: "#fff",
                  marginRight: 10,
                  // background: "#C1B4B3",
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Add Tube
              </Button>
                <Button
                  type="submit"
                  style={{ color: "#fff" }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
    </form>
    </CardContent>
    </Card>
  )
}

export default EditCableView
interface EditCableViewProps {
    color: ColorCode[];
    alltube: TubeMater[];
    submitData?: any;
    cable_deetail?:CableMater;
}