import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ColorCode, FibreMater } from '../../../models/cableMasterModel';
import { StoreState } from '../../../models/reduxModels';
import { FibreCreateLoads, UpdateFibre } from '../../../Stores/actions/cableMasterActions';
import EditFibreView from './EditFibreView'
import { useSnackbar } from "notistack";

function EditFibre({color,FibreCreateLoads,allfibre,UpdateFibre}: EditFibreProps) {
    let { id }: any = useParams();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(()=> {
        FibreCreateLoads()
    },[])
    const submitData = (data: any) => {
        UpdateFibre({
          payload: { ...allfibre.find(m=> m.fibre_id == +id), ...data },
          history: history,
          enqueueSnackbar: enqueueSnackbar,
        });
      };
  return (
   <EditFibreView color={color} submitData={submitData} fibre={allfibre.find(m=> m.fibre_id == +id)} />
  )
}

const mapStateToProps = (state: StoreState) => {
    return {
        color: state.cable_master.color_code,
        allfibre: state.cable_master.fibre_master
    };
  };
  const mapDispatchToProps = {
    FibreCreateLoads,
    UpdateFibre
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditFibre);
  interface EditFibreProps {
    color: ColorCode[];
    FibreCreateLoads?: any;
    allfibre: FibreMater[];
    UpdateFibre?: any;
  }