import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CableMsterList } from "../../../models/cableMasterModel";
import { CableList } from "../../../models/cableModel";
import { FMSList } from "../../../models/fmsModel";
import { StoreState } from "../../../models/reduxModels";
import {
  CableDetailGetByIdAction,
  CableUpdateAction,
} from "../../../Stores/actions/cableAction";
import { GetAllCableMaster } from "../../../Stores/actions/cableMasterActions";
import { GetALLFMS } from "../../../Stores/actions/fmsAction";
import EditcableView from "./EditcableView";

function EditCable({
  allFMS,
  GetALLFMS,
  GetAllCableMaster,
  master_cable_list,
  CableDetailGetByIdAction,
  cable,
  CableUpdateAction,
}: EditCableProps) {
  let { id }: any = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  useEffect(() => {
    GetAllCableMaster();
    CableDetailGetByIdAction(id);
  }, []);
  const submitData = (props: any) => {
    CableUpdateAction({
      payload: { ...cable, ...props },
      history: history,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
    <EditcableView
      cable={cable}
      submitData={submitData}
      master_cable_list={master_cable_list}
      allFMS={allFMS}

    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    master_cable_list: state.cable_master.cable_master_list,
    allFMS: state.fms.fms_list,
    cable: state.cable.single_cable,
  };
};
const mapDispatchToProps = {
  GetAllCableMaster,
  GetALLFMS,
  CableDetailGetByIdAction,
  CableUpdateAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCable);
interface EditCableProps {
  GetAllCableMaster?: any;
  master_cable_list: CableMsterList[];
  GetALLFMS?: any;
  allFMS: FMSList[];
  CableDetailGetByIdAction?: any;
  cable?: CableList;
  CableUpdateAction?: any;
}
