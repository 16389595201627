import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Autocompletes from "../../../components/Autocompletes";
import { ServiceList } from "../../../models/serviceMasterModel";
import { ZoneList } from "../../../models/zoneModels";

function ServiceEditView({
  submitData,
  SingleService,
  readable,
  serviceType,
}: ServiceEditViewProps) {
  const history = useHistory();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setFocus,
    setError,
    watch,
  } = useForm();
  const { errors } = formState;

  const onSubmit = (props: any) => {
    submitData({ ...props, service_type: null });
  };
  useEffect(() => {
    if (!!SingleService) {
      // setValue("zone_code", SingleService.);

      setValue("service_name", SingleService.service_name);
      setValue("service_desc", SingleService.service_desc);
      setValue("service_type", SingleService.service_type);
    }
  }, [SingleService]);
  return (
    <>
      <Card sx={{ marginTop: "10px" }}>
        <CardHeader
          style={{ backgroundColor: "#202a37", color: "#fff", height: 14 }}
          titleTypographyProps={{ fontWeight: 500, fontSize: "1.3rem" }}
          title={Boolean(readable) ? "View Service" : "Edit Service"}
        />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container justifyContent="center" alignItems={"center"}>
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>
                  Service Name
                </Typography>
                <Box sx={{ color: "red" }}>*</Box>
              </Grid>

              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("service_name", { required: true })}
                  style={{ width: "100%", margin: "5px" }}
                  type="text"
                  variant="outlined"
                  size="small"
                  name="service_name"
                  placeholder="Service Name"
                  error={!!errors["service_name"]}
                  InputProps={{
                    readOnly: Boolean(readable),
                  }}
                />
              </Grid>
            </Grid>

            {/* <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={4} sx={{display:"flex"}}>
              <Typography style={{fontWeight: 550}}>Service Type</Typography>
            </Grid>
            
            <Grid item xs={1}>
              <Typography style={{fontWeight: 550}}>:</Typography>
            </Grid>
            <Grid item xs={5}>
            {
            Boolean(+readable)?(
              
                  <TextField
                      {...register("service_type", { required: true })}
                      style={{ width: "100%", margin: "5px" }}
                      type="text"
                      variant="outlined"
                      size="small"
                      name="service_type"
                      placeholder="Service Type"
                      error={!!errors["service_type"]}
                      InputProps={{
                        readOnly: Boolean(readable),
                      }}
                    />


            ):(
              <Controller
                  control={control}
                  name={"service_type"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                     <Autocompletes
                     option={serviceType}
                     keys={"domain_value"}
                     
                     label={"domain_value"}
                     onBlur={onBlur}
                     onChange={(value: any) => (
                       onChange(value)
                     )}
                     placeholder={"Service Type"}
                     value={value}
                     errors={errors["service_type"]}
                   />
                 )}
               />
            )}
          </Grid>
        </Grid> */}

            <Grid container justifyContent="center" alignItems={"center"}>
              {/* <Grid item xs={4}>
              <Typography style={{fontWeight: 550}}>Description</Typography>
            </Grid> */}
              <Grid item xs={4} sx={{ display: "flex" }}>
                <Typography style={{ fontWeight: 550 }}>Description</Typography>
                {/* <Box sx={{color:"red"}}>*</Box>  */}
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 550 }}>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  {...register("service_desc", { required: false })}
                  style={{ width: "100%", margin: "5px" }}
                  multiline
                  rows={4}
                  variant="outlined"
                  name={"service_desc"}
                  placeholder="Description"
                  error={!!errors["service_desc"]}
                  InputProps={{
                    readOnly: Boolean(readable),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems={"center"}>
              <Grid item>
                <Button
                  onClick={() => history.push(`/service-master`)}
                  style={{
                    color: "#fff",
                    marginRight: 10,
                    background: "#C1B4B3",
                  }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Back
                </Button>
                {!Boolean(readable) && (
                  <Button
                    type="submit"
                    style={{ color: "#fff" }}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Update
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
    //   <>
    //     <Card sx={{ marginTop: "10px" }}>
    //       <CardHeader
    //         style={{ backgroundColor: "#00AAEE", color: "#fff" }}
    //         titleTypographyProps={{ fontWeight: 600 }}
    //         title="Edit Zone"
    //       />
    //       <CardContent>
    //         <form onSubmit={handleSubmit(onSubmit)}>
    //           <Grid container justifyContent="center" alignItems={"center"}>
    //             <Grid item xs={5}>
    //               <Grid
    //                 container
    //                 paddingTop={2}
    //                 justifyContent="left"
    //                 alignItems={"center"}
    //               >
    //                 <Grid item xs={11.5}>
    //                   <TextField
    //                     label="Zone Code"
    //                     {...register("zone_code", { required: true })}
    //                     style={{ width: "100%", margin: "5px" }}
    //                     type="text"
    //                     variant="outlined"
    //                     size="small"
    //                     name="zone_code"
    //                     placeholder="Zone Code"
    //                     error={!!errors["zone_code"]}
    //                   />
    //                 </Grid>
    //               </Grid>
    //             </Grid>
    //             <Grid item xs={5}>
    //               <Grid
    //                 container
    //                 flexDirection={"row"}
    //                 paddingTop={2}
    //                 justifyContent="right"
    //                 alignItems={"center"}
    //               >
    //                 <Grid item xs={11.5}>
    //                   <TextField
    //                     {...register("zone_name", { required: true })}
    //                     style={{ width: "100%", margin: "5px" }}
    //                     type="text"
    //                     variant="outlined"
    //                     size="small"
    //                     label="Zone Name"
    //                     name="zone_name"
    //                     placeholder="Zone Name"
    //                     error={!!errors["zone_name"]}
    //                   />
    //                 </Grid>
    //               </Grid>
    //             </Grid>
    //           </Grid>
    //           <Grid
    //             container
    //             justifyContent="center"
    //             alignItems={"center"}
    //             paddingTop={2}
    //           >
    //             <Grid item xs={10}>
    //               <TextField
    //                 {...register("zone_desc", { required: true })}
    //                 style={{ width: "100%", margin: "5px" }}
    //                 multiline
    //                 rows={4}
    //                 label="Description"
    //                 variant="outlined"
    //                 name={"zone_desc"}
    //                 placeholder="Description"
    //                 error={!!errors["zone_desc"]}
    //               />
    //             </Grid>
    //           </Grid>
    //           <Grid container justifyContent="center" alignItems={"center"}>
    //             <Grid item>
    //               <Button
    //                 onClick={() => history.push(`/zone`)}
    //                 style={{
    //                   color: "#fff",
    //                   marginRight: 10,
    //                   background: "#C1B4B3",
    //                 }}
    //                 variant="contained"
    //                 sx={{ mt: 3, mb: 2 }}
    //               >
    //                 Back
    //               </Button>
    //               <Button
    //                 type="submit"
    //                 style={{ color: "#fff" }}
    //                 variant="contained"
    //                 sx={{ mt: 3, mb: 2 }}
    //               >
    //                 Update
    //               </Button>
    //             </Grid>
    //           </Grid>
    //         </form>
    //       </CardContent>
    //     </Card>
    //   </>
  );
}

export default ServiceEditView;

interface ServiceEditViewProps {
  submitData?: any;
  SingleService?: ServiceList;
  readable: number;
  serviceType: any;
}
