import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ServiceOcupencyMain from "./ServiceOcupencyMain";

function ServiceOcupency() {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <Redirect to={`${path}/service-ocupency-main`} />}
      />
      <Route
        exact
        path={`${path}/service-ocupency-main`}
        render={() => <ServiceOcupencyMain />}
      />
    </Switch>
  );
}

export default ServiceOcupency;
