import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useHistory, useParams } from "react-router-dom";

import Autocompletes from "../../../components/Autocompletes";
import { connect } from "react-redux";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import {
  Button,
  TableContainer,
  Table,
  TableHead,
  styled,
  TableRow,
  TableBody,
  Card,
  TableCell,
  tableCellClasses,
  CardContent,
  Box,
  CardHeader,
  Dialog,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { StoreState } from "../../../models/reduxModels";

import { ServiceWiseCableDetailLoadAction } from "../../../Stores/actions/ReportAction";
import EndToEndDialog from "../../../components/EndToEndDialog";
import { GetAllServices } from "../../../Stores/actions/serviceMasterAction";
const ServiceWiseCableInfo = ({
  ServiceWiseCableDetailLoadAction,
  cable_details,
  GetAllServices,
  service_list,
}: //   cable_details1,
ServiceWiseCableInfoProps) => {
  let { id, readable }: any = useParams();
  const payload = {
    cable_detail_id: id,
    service_id: readable == "0" ? null : readable,
    // cable_detail_id: 15,
    // service_id: null,
  };
  useEffect(() => {
    GetAllServices();
    if (!!payload) {
      ServiceWiseCableDetailLoadAction(payload);
    }
  }, []);

  const { control, register, handleSubmit, formState, setValue, getValues } =
    useForm();
  const { errors } = formState;
  const [showNoOfPort, setShowNoOfPort] = useState<boolean>(false);
  const [showParentFMS, setshowParentFMS] = useState<boolean>(false);
  const [flag, Setflag] = useState<boolean>(true);
  const TColumnFMS: any[] = [
    "Tube/Fibre Color (A)",
    "Fibre Status (A)",
    "Tube/Fibre Color (B)",
    "Fibre Status (B)",
    "Fibre km",
    "Action",
  ];
  const [isEndToEndOpen, setEndToEndOpen] = useState<boolean>(false);
  const [implId, setimplId] = useState<number | undefined>();
  const s = null;

  const history = useHistory();
  console.log("service_list", service_list);

  return (
    <>
      <EndToEndDialog
        isOpen={isEndToEndOpen}
        setOpen={setEndToEndOpen}
        impl_id={implId}
        show_pdf={true}
      />
      {!!cable_details && (
        <>
          <Grid
            item
            sx={{
              backgroundColor: "#035779",
              marginY: 0,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          >
            {/* <Typography sx={{ color: '#fff', fontSize: 20, fontStyle: "italic" }}>{!!service_list && Array.isArray(service_list) && service_list.length > 0 && service_list?.find((m: any) => m?.service_id === readable)?.service_name}</Typography> */}
            <Typography
              sx={{
                color: "#fff",
                fontSize: 20,
                paddingY: 0.5,
                fontStyle: "italic",
                textAlign: "center",
                fontWeight: "bold",
                wordSpacing: 2,
              }}
            >
              {`${!!service_list &&
                Array.isArray(service_list) &&
                service_list.length > 0 &&
                service_list?.find((m: any) => m?.service_id == readable)
                  ?.service_name} SERVICE`}
            </Typography>
          </Grid>
          <Card sx={{}}>
            <Grid
              container
              style={{ marginTop: 10, justifyContent: "space-around" }}
            >
              <Grid item xs={5}>
                <h4 style={{ textAlign: "center" }}>
                  Source A{" - "}
                  {`${cable_details[0]?.station_name} -
            ${cable_details[0]?.fms_code}`}
                </h4>
              </Grid>

              <Grid item xs={5}>
                <h4 style={{ textAlign: "center" }}>
                  Source B {" - "}
                  {`${cable_details[0]?.des_station_name} - 
            ${cable_details[0]?.des_fms_code}`}
                </h4>
              </Grid>
            </Grid>

            <TableContainer
              sx={{ marginTop: "1.4rem", borderBottom: "1px solid #ddd" }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {TColumnFMS.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cable_details.map((row: any, index: any) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        <StyledTableCell align="left" key={TColumnFMS[0]}>
                          {`${cable_details[index].tube_colour} - 
                    ${cable_details[index]?.fibre_colour}`}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={TColumnFMS[1]}>
                          {cable_details[index]?.fibre_status}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={TColumnFMS[2]}>
                          {`${cable_details[index].des_tube_colour} - 
                    ${cable_details[index]?.des_fibre_colour}`}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={TColumnFMS[3]}>
                          {cable_details[index]?.des_fibre_status}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={TColumnFMS[4]}>
                          {`${
                            cable_details[index]?.cable_actual_length / 1000
                          } km`}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={TColumnFMS[5]}>
                          {(cable_details[index]?.spliced_fibre_impl_id ||
                            cable_details[index]
                              ?.des_spliced_fibre_impl_id) && (
                            <Button
                              style={{
                                color: "#fff",
                                marginRight: 10,
                                background: "#34a853",
                                scale: ".8",
                              }}
                              variant="contained"
                              onClick={() => {
                                setimplId(
                                  cable_details[index].spliced_fibre_impl_id &&
                                    cable_details[index]
                                      .des_spliced_fibre_impl_id
                                    ? cable_details[index].fibre_impl_id
                                    : cable_details[index].spliced_fibre_impl_id
                                    ? cable_details[index].des_fibre_impl_id
                                    : cable_details[index].fibre_impl_id
                                );
                                setEndToEndOpen(true);
                              }}
                            >
                              Trace
                            </Button>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: " 20px 0 20px 0",
              }}
            >
              <Button
                onClick={() => history.push(`/service-query-report`)}
                style={{
                  color: "#fff",
                  marginRight: 10,
                  background: "#C1B4B3",
                }}
                variant="contained"
              >
                Back
              </Button>
              <Button
                variant="contained"
                sx={{ color: "#fff" }}
                onClick={() => {
                  var temp: any[] = [];
                  for (let i = 0; i < cable_details.length; i++) {
                    temp[i] = [
                      `${cable_details[i].tube_colour} - ${cable_details[i]?.fibre_colour}`,
                      cable_details[i]?.fibre_status,
                      `${cable_details[i].des_tube_colour} - ${cable_details[i]?.des_fibre_colour}`,
                      cable_details[i]?.des_fibre_status,
                      `${cable_details[i].cable_actual_length / 1000} km`,
                    ];
                  }
                  pdfMake.vfs = pdfFonts.pdfMake.vfs;
                  var dd: any = {
                    pageOrientation: "landscape",
                    content: [
                      {
                        text: "Service Wise Cable Detail",
                        style: "header",
                      },

                      {
                        style: "",
                        table: {
                          headerRows: 1,
                          widths: [300, 300],
                          body: [
                            [
                              {
                                text: ` Source A - ${cable_details[0]?.station_name} - ${cable_details[0]?.fms_code}`,
                                style: "tableHeader1",
                                border: [false, false, false, false],
                                fillColor: "#fff",
                              },
                              {
                                text: ` Source B - ${cable_details[0]?.des_station_name} - ${cable_details[0]?.des_fms_code}`,
                                style: "tableHeader1",
                                border: [false, false, false, false],
                                fillColor: "#fff",
                              },
                            ],
                          ],
                        },
                        layout: {
                          fillColor: function (rowIndex: any) {
                            return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                          },
                          hLineWidth: function (i: any, node: any) {
                            return i === 0 || i === node.table.body.length
                              ? 2
                              : 1;
                          },
                          vLineWidth: function (i: any, node: any) {
                            return i === 0 || i === node.table.widths.length
                              ? 2
                              : 1;
                          },
                          hLineColor: function (i: any, node: any) {
                            return i === 0 || i === node.table.body.length
                              ? "black"
                              : "gray";
                          },
                          vLineColor: function (i: any, node: any) {
                            return i === 0 || i === node.table.widths.length
                              ? "black"
                              : "gray";
                          },
                          // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                          // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                          // paddingLeft: function(i, node) { return 4; },
                          // paddingRight: function(i, node) { return 4; },
                          // paddingTop: function(i, node) { return 2; },
                          // paddingBottom: function(i, node) { return 2; },
                          // fillColor: function (rowIndex, node, columnIndex) { return null; }
                        },
                      },

                      {
                        style: "tableExample",
                        table: {
                          headerRows: 1,
                          body: [
                            [
                              {
                                text: "Tube Fibre Color Source",
                                style: "tableHeader",
                              },
                              {
                                text: "Fibre Status Source",
                                style: "tableHeader",
                              },
                              {
                                text: "Tube Fibre Color Destination",
                                style: "tableHeader",
                              },
                              {
                                text: "Fibre Status Destination",
                                style: "tableHeader",
                              },
                              {
                                text: "Fibre km",
                                style: "tableHeader",
                              },
                            ],

                            ...temp,
                          ],
                        },
                        layout: {
                          fillColor: function (rowIndex: any) {
                            return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                          },
                          hLineWidth: function (i: any, node: any) {
                            return i === 0 || i === node.table.body.length
                              ? 2
                              : 1;
                          },
                          vLineWidth: function (i: any, node: any) {
                            return i === 0 || i === node.table.widths.length
                              ? 2
                              : 1;
                          },
                          hLineColor: function (i: any, node: any) {
                            return i === 0 || i === node.table.body.length
                              ? "black"
                              : "gray";
                          },
                          vLineColor: function (i: any, node: any) {
                            return i === 0 || i === node.table.widths.length
                              ? "black"
                              : "gray";
                          },
                          // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                          // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                          // paddingLeft: function(i, node) { return 4; },
                          // paddingRight: function(i, node) { return 4; },
                          // paddingTop: function(i, node) { return 2; },
                          // paddingBottom: function(i, node) { return 2; },
                          // fillColor: function (rowIndex, node, columnIndex) { return null; }
                        },
                      },
                    ],
                    styles: {
                      header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                        color: "#9d56a2",
                      },
                      subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5],
                      },
                      tableExample: {
                        margin: [0, 5, 0, 15],
                        fontSize: 12,
                      },
                      tableHeader: {
                        bold: true,
                        fontSize: 12,
                        color: "white",
                        fillColor: "#035779",
                      },
                      tableHeader1: {
                        bold: true,
                        fontSize: 14,
                        color: "#035779",

                        //   fillColor: "#035779",
                      },
                    },
                    defaultStyle: {
                      // alignment: 'justify'
                    },
                  };

                  pdfMake.createPdf(dd).open();
                }}
              >
                Generate PDF
              </Button>
            </div>
          </Card>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    cable_details: state.report.cable_report_by_service,
    service_list: state.service.service_list,
  };
};

const mapDispatchToProps = {
  ServiceWiseCableDetailLoadAction,
  GetAllServices,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceWiseCableInfo);
interface ServiceWiseCableInfoProps {
  cable_details?: any;
  ServiceWiseCableDetailLoadAction: any;
  GetAllServices?: any;
  CableDetailLoadAction?: any;
  service_list: any[];
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
